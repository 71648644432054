import React from "react";
import { InputFieldModel } from "../../../../data/models";
import { TableInputField } from "./table-input-field";

const getInputType = (
  type: "string" | "boolean" | "float" | "dropdown" | "table"
) => {
  switch (type) {
    case "float":
      return "number";
    case "boolean":
      return "checkbox";
    case "dropdown":
      return "dropdown";
    case "table":
      return "table";
    default:
      return "text";
  }
};

export interface InputFieldProps {
  inputFieldModel: InputFieldModel;
  values: any;
  validate: boolean;
  onValueChange: (dynamodb_name: string, value: string | number) => void;
}
export const InputField = ({
  inputFieldModel,
  values,
  validate,
  onValueChange,
}: InputFieldProps) => {
  const inputType = getInputType(inputFieldModel.type);
  const value = values[inputFieldModel.dynamodb_name];
  const onDropDownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onValueChange(inputFieldModel.dynamodb_name, e.target.value);
  };
  const onCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(inputFieldModel.dynamodb_name, e.target.checked.toString());
  };
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputType: string
  ) => {
    if (inputType === "float") {
      onValueChange(inputFieldModel.dynamodb_name, parseFloat(e.target.value));
    } else {
      onValueChange(inputFieldModel.dynamodb_name, e.target.value);
    }
  };
  const isInvalid =
    inputFieldModel.editable &&
    inputFieldModel.type === "float" &&
    validate &&
    (value === undefined || value === "");
  return (
    <div className={"flex items-center justify-between mb-2"}>
      {inputType === "number" && (
        <>
          <label htmlFor={inputFieldModel.id} className={"text-primary-500"}>
            {inputFieldModel.label}
          </label>
          <input
            id={inputFieldModel.id}
            key={inputFieldModel.id}
            className={`${isInvalid ? "border-red-500" : "border-black"}
              bg-primary-850 p-2 rounded-md border border-solid text-white`}
            required={inputFieldModel.required}
            type={inputType}
            disabled={!inputFieldModel.editable}
            //defaultValue={inputFieldModel.default_value}
            value={value}
            step="0.0001"
            onChange={(e) => onChange(e, inputType)}
          />
        </>
      )}
      {inputType === "checkbox" && (
        <>
          <input
            id={inputFieldModel.id}
            key={inputFieldModel.id}
            className={
              "bg-primary-850 p-2 rounded-md border border-white border-solid text-white"
            }
            required={inputFieldModel.required}
            type="checkbox"
            checked={value === true.toString()}
            onChange={onCheckedChanged}
            defaultValue={inputFieldModel.default_value}
            step="0.0001"
          />
          <label htmlFor={inputFieldModel.id} className={"text-primary-500"}>
            {inputFieldModel.label}
          </label>
        </>
      )}
      {inputType === "dropdown" && (
        <>
          <label htmlFor={inputFieldModel.id} className={"text-primary-500"}>
            {inputFieldModel.label}
          </label>
          <select
            className={
              "bg-primary-850 p-2 rounded-md border border-white border-solid text-white"
            }
            name={inputFieldModel.id}
            id={inputFieldModel.id}
            value={value}
            onChange={onDropDownChange}
          >
            {inputFieldModel.dropdownOptions.map((option: string) => (
              <option value={option}>{option}</option>
            ))}
          </select>
        </>
      )}
      {inputType === "table" && (
        <>
          <div className="pr-2 flex flex-col bg-primary-600 rounded-md">
            <label htmlFor={inputFieldModel.id} className={"text-primary-500"}>
              {inputFieldModel.label}
            </label>
          </div>
          <TableInputField model={inputFieldModel} values={values} />
        </>
      )}
    </div>
  );
};
