import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PhaseQuantificationInput from "./phase-quantification-input";
import { compareKeysV2 } from "../../../helpers/general";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { selectPhaseQuantificationDataFiles, selectPhaseQuantificationDataViewFile, selectPhaseQuantificationDataActiveFiles, selectPhaseQuantificationDebyeWallerParameter, selectPhaseQuantificationInstrumentalFile, selectPhaseQuantificationInstrumentalTemplateFile, selectPhaseQuantificationCifFiles, selectPhaseQuantificationCifTemplateFiles, selectPhaseQuantificationWavelength, selectPhaseQuantificationHistogram, selectPhaseQuantificationCifFilesABC, selectPhaseQuantificationFitting } from "../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import PhaseQuantificationGraph from "./phase-quantification-graph";
import { GET_PHASE_QUANTIFICATION_CALLBACK_FITTED, GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS, SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES, SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE } from "../../../redux/phaseQuantification/phaseQuantificationActions";
import { FilesMapping } from "./helpers";
import { useInstanceStatusPolling } from "../shared/instanceTrigger";

const PhaseQuantificationAnalysis = ({ dispatch, pipeline=false }: { dispatch: Dispatch, pipeline?: boolean }) => {
  const phaseQuantificationDataFiles = useSelector(selectPhaseQuantificationDataFiles);
  const phaseQuantificationFitting = useSelector(selectPhaseQuantificationFitting);
  const phaseQuantificationDataViewFile = useSelector(selectPhaseQuantificationDataViewFile);
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectPhaseQuantificationDataActiveFiles)
  const debyeWellerParameters = useSelector(selectPhaseQuantificationDebyeWallerParameter)
	const instrumentalFile = useSelector(selectPhaseQuantificationInstrumentalFile);
  const cifFiles = useSelector(selectPhaseQuantificationCifFiles);
  const cifTemplateFiles = useSelector(selectPhaseQuantificationCifTemplateFiles);
  const instrumentalTemplateFile = useSelector(selectPhaseQuantificationInstrumentalTemplateFile);
  const wavelength = useSelector(selectPhaseQuantificationWavelength);
  const dataFiles = useSelector(selectPhaseQuantificationDataFiles);
  const dataViewFile = useSelector(selectPhaseQuantificationDataViewFile);
  const histogram = useSelector(selectPhaseQuantificationHistogram);
  const cifFilesABC = useSelector(selectPhaseQuantificationCifFilesABC);

  const [filesInformation, setFilesInformation] = useState<any>([]);
  const [headersFileInformation, setHeadersFileInformation] = useState<any>([]);
  const [lastFile, setLastFile] = useState<string>("");

  useInstanceStatusPolling(instanceId, "phaseQuantification", dispatch, pipeline);

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_METHOD_DATA, payload: {type: "phaseQuantification"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "phaseQuantification"} })
  }, [dispatch, pipeline])

  useEffect(() => {
    if (!dataViewFile || pipeline) return;

    dispatch({ type: GET_PHASE_QUANTIFICATION_CALLBACK_FITTED })
  }, [dispatch, dataViewFile, pipeline])

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS })
  }, [dispatch, pipeline, dataActiveFiles])

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_PHASES, payload: { type: "phaseQuantification"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, pipeline, cifFilesABC]);

  useEffect(() => {
    if (lastFile === dataViewFile && histogram.data.x.length !== 0) return;
    if (!pipeline) return;

    if((cifFiles.length === 0 && cifTemplateFiles.length === 0) || (!instrumentalFile && !instrumentalTemplateFile) || !dataViewFile) return;

    setLastFile(dataViewFile)
  
    dispatch({ type: GET_PHASES, payload: { type: "pipelinePhaseQuantification"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, lastFile, histogram.data.x.length, pipeline]);

  useEffect(() => {
    const fileObject = FilesMapping(phaseQuantificationDataFiles, debyeWellerParameters, phaseQuantificationFitting)

    setFilesInformation(fileObject["filesMapping"])
    setHeadersFileInformation([...fileObject["headers"], ...fileObject["sortedKeysParams"]])
  }, [debyeWellerParameters, phaseQuantificationDataFiles, phaseQuantificationFitting])

  useEffect(() => {
    if (phaseQuantificationDataFiles.length > 0 && !phaseQuantificationDataViewFile) {
      const dataFiles = phaseQuantificationDataFiles.sort((a, b) => compareKeysV2(a, b))
      let selectedDataFile = dataFiles[0]

      dispatch({ type: SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE, payload: selectedDataFile });
    }
  }, [phaseQuantificationDataFiles, dispatch, phaseQuantificationDataViewFile])

  return (
    <div className={"flex h-full gap-4 flex-1 xl:flex-col flex-grow"} style={{ width: "calc(100vw - 30px)"}}>
      {instanceId && !pipeline &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Peak Fitting Analysis..."}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6"}>
        <div className={"flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <PhaseQuantificationInput dispatch={dispatch} pipeline={pipeline} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <PhaseQuantificationGraph dispatch={dispatch} />
        <AnalysisFilesTable
          data={filesInformation}
          dataViewFile={phaseQuantificationDataViewFile}
          setDataViewFile={(file) => dispatch({type: SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE, payload: file })}
          setDataActiveFiles={(files) => dispatch({type: SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES, payload: files})}
          dataActiveFiles={dataActiveFiles}
          headersOrdered={headersFileInformation}
          pipeline={pipeline}
        />
      </div>
    </div>
  );
};

export default PhaseQuantificationAnalysis;
