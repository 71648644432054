import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PeakFittingInput from "./peak-fitting-input";
import { compareKeysV2 } from "../../../helpers/general";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { GET_PEAK_FITTING_CALLBACK, SET_PEAK_FITTING_DATA_ACTIVE_FILES, SET_PEAK_FITTING_DATA_VIEW_FILE } from "../../../redux/peakFitting/peakFittingActions";
import { selectPeakFittingDataFiles, selectPeakFittingDataViewFile, selectPeakFittingDataActiveFiles, selectPeakFittingCifFiles, selectPeakFittingCifFilesABC, selectPeakFittingCifTemplateFiles, selectPeakFittingInstrumentalFile, selectPeakFittingInstrumentalTemplateFile, selectPeakFittingWavelength, selectPeakFittingFitting } from "../../../redux/peakFitting/peakFittingSelectors";
import PeakFittingGraph from "./peak-fitting-graph";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import { useInstanceStatusPolling } from "../shared/instanceTrigger";
import { FileMapping } from "./helpers";


const PeakFittingAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const dataViewFile = useSelector(selectPeakFittingDataViewFile);
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectPeakFittingDataActiveFiles)
  const cifFiles = useSelector(selectPeakFittingCifFiles);
  const cifTemplateFiles = useSelector(selectPeakFittingCifTemplateFiles);
  const instrumentalTemplateFile = useSelector(selectPeakFittingInstrumentalTemplateFile);
  const wavelength = useSelector(selectPeakFittingWavelength);
  const dataFiles = useSelector(selectPeakFittingDataFiles);
  const cifFilesABC = useSelector(selectPeakFittingCifFilesABC);
  const instrumentalFile = useSelector(selectPeakFittingInstrumentalFile);
  const peakFittingFitting = useSelector(selectPeakFittingFitting);

  const [filesInformation, setFilesInformation] = useState<any>([]);

  useInstanceStatusPolling(instanceId, "peakFitting", dispatch);

  useEffect(() => {
    dispatch({ type: GET_METHOD_DATA, payload: {type: "peakFitting"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "peakFitting"} })
  }, [dispatch])

  useEffect(() => {
    if (!dataViewFile) return;

    dispatch({ type: GET_PEAK_FITTING_CALLBACK })
  }, [dispatch, dataViewFile])

  useEffect(() => {
    dispatch({ type: GET_PHASES, payload: { type: "peakFitting"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, cifFilesABC]);

  useEffect(() => {
    setFilesInformation(FileMapping(dataFiles, peakFittingFitting))
  }, [dataFiles, peakFittingFitting])

  useEffect(() => {
    if (dataFiles.length > 0 && !dataViewFile) {
      const dataFilesObj = dataFiles.sort((a, b) => compareKeysV2(a, b))
      dispatch({ type: SET_PEAK_FITTING_DATA_VIEW_FILE, payload: dataFilesObj[0] });
    }
  }, [dataFiles, dataViewFile, dispatch])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Peak Fitting Analysis..."}
        />
      }
      <div className={"flex flex-col bg-primary-800 h-full rounded-md w-2/6"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <PeakFittingInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
          <PeakFittingGraph dispatch={dispatch} />
          <AnalysisFilesTable
            data={filesInformation}
            dataViewFile={dataViewFile}
            setDataViewFile={(file) => dispatch({type: SET_PEAK_FITTING_DATA_VIEW_FILE, payload: file })}
            setDataActiveFiles={(files) => dispatch({type: SET_PEAK_FITTING_DATA_ACTIVE_FILES, payload: files})}
            dataActiveFiles={dataActiveFiles}
          />
        </div>
    </div>
  );
};

export default PeakFittingAnalysis;
