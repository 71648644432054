import { useEffect, useState } from "react";
import { getInitialValues, getInputContainerIsValid } from "./input-utils";
import { PanelInput } from "./panel-input";
import { InputContainerModel, PanelModel } from "../../../../data/models";
import { ProjectFileModel } from "../../../../models/project.model";

export interface InputContainerProps {
  containerModel: InputContainerModel;
  fileSettings: ProjectFileModel;
  validate: boolean;
  onFileSettingsChange: (fileSettings: ProjectFileModel) => void;
  onIsValidChange: (isValid: boolean) => void;
}

export const InputContainer = ({
  containerModel,
  fileSettings,
  validate,
  onFileSettingsChange,
  onIsValidChange,
}: InputContainerProps) => {
  const initialValues = getInitialValues(fileSettings, containerModel);
  const [valuesState, setValuesState] = useState(initialValues);

  useEffect(() => {
    let values = getInitialValues(fileSettings, containerModel);
    setValuesState(values);

    const isValidInitially = getInputContainerIsValid(values, containerModel);
    onIsValidChange(isValidInitially);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSettings]);

  const onChange = (
    dynamodb_name: string,
    value: string | number | boolean
  ) => {
    fileSettings[dynamodb_name] = value;
    const newValues = Object.assign({}, valuesState);
    newValues[dynamodb_name] = value;
    setValuesState(newValues);
    const isValid = getInputContainerIsValid(newValues, containerModel);
    onFileSettingsChange(fileSettings);
    onIsValidChange(isValid);
  };
  const panels = containerModel.panels.map((panel: PanelModel) => (
    <PanelInput
      onValueChange={onChange}
      key={panel.id}
      validate={validate}
      panelModel={panel}
      values={valuesState}
    />
  ));
  return (
    <div className={"flex flex-col flex-1 bg-primary-800 rounded-md"}>
      <h1 className={"text-white font-bold mb-2"}>{containerModel.header}</h1>
      {panels}
    </div>
  );
};
