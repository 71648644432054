import { apiPhaseQuantification, requestApi } from "../../api";

export const preProcessObject = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));

  delete newObj.histogram;
  delete newObj.fitting;
  delete newObj.partitionFitting;
  delete newObj.partition;

  return newObj;
}

export const getPhaseQuantificationCaller = async (token, req) => {
  return await requestApi(`caller`, "POST", req, token, {}, apiPhaseQuantification);
};

export const getPhaseQuantificationCallbackFitted = async (token, req) => {
  return await requestApi(`callback-fitted`, "POST", req, token, {}, apiPhaseQuantification);
}

export const getPhaseQuantificationCallbackParameters = async (token, req) => {
  return await requestApi(`callback-parameters`, "POST", req, token, {}, apiPhaseQuantification);
}
