// SETTERS
export const SET_PEAK_FITTING_DATA_FILES = "SET_PEAK_FITTING_DATA_FILES";
export const SET_PEAK_FITTING_DATA_VIEW_FILE = "SET_PEAK_FITTING_DATA_VIEW_FILE"
export const SET_PEAK_FITTING_DATA_ACTIVE_FILES = "SET_PEAK_FITTING_DATA_ACTIVE_FILES";

export const SET_PEAK_FITTING_CIF_FILES = "SET_PEAK_FITTING_CIF_FILES";
export const SET_PEAK_FITTING_CIF_TEMPLATE_FILES = "SET_PEAK_FITTING_CIF_TEMPLATE_FILES";
export const SET_PEAK_FITTING_CIF_FILES_ABC = "SET_PEAK_FITTING_CIF_FILES_ABC";

export const SET_PEAK_FITTING_INSTRUMENTAL_FILE = "SET_PEAK_FITTING_INSTRUMENTAL_FILE";
export const SET_PEAK_FITTING_INSTRUMENTAL_TEMPLATE_FILE = "SET_PEAK_FITTING_INSTRUMENTAL_TEMPLATE_FILE";

export const SET_PEAK_FITTING_WAVELENGTH = "SET_PEAK_FITTING_WAVELENGTH";
export const SET_PEAK_FITTING_INSTRUMENTAL_WAVELENGTH = "SET_PEAK_FITTING_INSTRUMENTAL_WAVELENGTH";

export const SET_PEAK_FITTING = "SET_PEAK_FITTING"
export const SET_PEAK_FITTING_RESET = "SET_PEAK_FITTING_RESET"

export const SET_PEAK_FITTING_PARTITIONS = "SET_PEAK_FITTING_PARTITIONS"

export const SET_PEAK_FITTING_RESULT = "SET_PHASE_QUANTIFICATION_RESULT"

export const SET_PEAK_FITTING_ACTIVE_INDEX = "SET_PEAK_FITTING_ACTIVE_INDEX"
export const SET_PEAK_FITTING_SELECTING_PEAKS = "SET_PEAK_FITTING_SELECTING_PEAKS"

export const SET_PEAK_FITTING_HISTOGRAM = "SET_PEAK_FITTING_HISTOGRAM"

export const SET_PEAK_FITTING_PARTITION_FITTING = "SET_PEAK_FITTING_PARTITION_FITTING"

export const SET_PEAK_FITTING_PARAMETERS = "SET_PEAK_FITTING_PARAMETERS"
export const SET_PEAK_FITTING_FITTING = "SET_PEAK_FITTING_FITTING"

// GETTERS
export const GET_PEAK_FITTING = "GET_PEAK_FITTING";
export const GET_PEAK_FITTING_CALLBACK = "GET_PEAK_FITTING_CALLBACK"
