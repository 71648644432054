import { getName, truncate } from "../../../helpers/name";
import { LabelsMappingPeakFitting, LabelsMappingStressStrain, LabelsMappingStressStrainBin, ReportScreenModel } from "../../../models/report-screen.model";
import { FormatLongFileName } from "../../shared/utils";

export const getStressStrainLineData = (report: ReportScreenModel) => {
  const newPlotData = [];

  report.stressStrainReport.yAxis.lineChart.forEach((chartTypeSelected) => {
    const xValues: string[] = [];
    const yValues = [];

    report.dataViewFiles.forEach((dataViewFile) => {
      const dataFileRef = dataViewFile;
      
      // peak fitting
      if (report.stressStrainReport.analysis[dataFileRef]) {
        const analysisData = report.stressStrainReport.analysis[dataFileRef];
        if (analysisData) {
          Object.entries(analysisData).forEach(([peak, peakValues]) => {
            Object.entries(peakValues).forEach(([key, value]) => {
              const chartType = `peak_${peak}_${key}`;
              if (!key.endsWith('_std_dev') && chartTypeSelected === chartType) {
                if (LabelsMappingPeakFitting[key]) {
                  xValues.push(FormatLongFileName(dataFileRef));
                  yValues.push(value);
                }
              }
            });
          });
        }
      }
      // Stress Bin
      if (report.stressStrainReport.stressStrainBinParameters[dataFileRef]) {
        const analysisData = report.stressStrainReport.stressStrainBinParameters[dataFileRef];
        if (analysisData) {
          Object.entries(analysisData).forEach(([peak, peakValues]) => {
            Object.entries(peakValues).forEach(([key, value]) => {
              const chartType = `bin_${peak}_${key}`;
              if (!key.endsWith('center_err') && !key.endsWith('strain_err') && chartTypeSelected === chartType) {
                if (LabelsMappingStressStrainBin[key]) {
                  xValues.push(FormatLongFileName(dataFileRef));
                  yValues.push(value);
                }
              }
            });
          });
        }
      }

      // Stress Strain
      if (report.stressStrainReport.stressStrainParameters[dataFileRef]) {
        const analysisData = report.stressStrainReport.stressStrainParameters[dataFileRef];
        if (analysisData) {
          Object.entries(analysisData).forEach(([peak, peakValues]) => {
            Object.entries(peakValues).forEach(([key, value]) => {
              const chartType = `stress_${peak}_${key}`;
              if (!key.includes("std_dev") && chartTypeSelected === chartType) {
                if (LabelsMappingStressStrain[key]) {
                  xValues.push(FormatLongFileName(dataFileRef));
                  yValues.push(value);
                }
              }
            });
          });
        }
      }
    });

    newPlotData.push({
      x: xValues,
      y: yValues,
      mode: 'lines',
      name: truncate(chartTypeSelected, 20),
    });
  });

  return newPlotData;
}

export const getStressStrainHeatMapData = (report: ReportScreenModel) => {
  const newPlotData = [];

  const heatMapWidth = report.heatMap.x;
  const heatMapHeight = report.heatMap.y;

  if (!heatMapWidth || !heatMapHeight) return [];

  const z: number[][] | string[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(undefined));
  const hoverText: string[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(''));
  const maxItems = heatMapWidth * heatMapHeight;

  report.dataViewFiles.slice(0, maxItems).forEach((dataViewFile, xIndex) => {
    const dataFileRef = dataViewFile;

    // peak fitting
    if (report.stressStrainReport.analysis[dataFileRef]) {
      const analysisData = report.stressStrainReport.analysis[dataFileRef];
      if (analysisData) {
        Object.entries(analysisData).forEach(([peak, peakValues]) => {
          Object.entries(peakValues).forEach(([key, value]) => {
            const chartType = `peak_${peak}_${key}`;
            if (key.endsWith('_std_dev') || report.stressStrainReport.yAxis.heatMap !== chartType) return;
            const x = xIndex % heatMapWidth;
            const y = Math.floor(xIndex / heatMapWidth);
            const newY = (heatMapHeight - 1) - y;
            let reversedX = x;

            if (report.swipeMode.swipe) {
              if (report.swipeMode.swipeRowEven) {
                if (newY % 2 === 0) {
                  reversedX = (heatMapWidth - 1) - x;
                }
              } else {
                if (newY % 2 !== 0) {
                  reversedX = (heatMapWidth - 1) - x;
                }
              }
            }

            z[newY][reversedX] = value;
            hoverText[newY][reversedX] = getName(dataFileRef);
          })
        })
      }
    }

    // Stress Bin
    if (report.stressStrainReport.stressStrainBinParameters[dataFileRef]) {
      const analysisData = report.stressStrainReport.stressStrainBinParameters[dataFileRef];
      if (analysisData) {
        Object.entries(analysisData).forEach(([peak, peakValues]) => {
          Object.entries(peakValues).forEach(([key, value]) => {
            const chartType = `bin_${peak}_${key}`;
            if (key.endsWith('center_err') || key.endsWith('strain_err') || report.stressStrainReport.yAxis.heatMap !== chartType) return;
            const x = xIndex % heatMapWidth;
            const y = Math.floor(xIndex / heatMapWidth);
            const newY = (heatMapHeight - 1) - y;
            let reversedX = x;

            if (report.swipeMode.swipe) {
              if (report.swipeMode.swipeRowEven) {
                if (newY % 2 === 0) {
                  reversedX = (heatMapWidth - 1) - x;
                }
              } else {
                if (newY % 2 !== 0) {
                  reversedX = (heatMapWidth - 1) - x;
                }
              }
            }

            z[newY][reversedX] = value;
            hoverText[newY][reversedX] = getName(dataFileRef);
          });
        });
      }
    }

    // Stress Strain
    if (report.stressStrainReport.stressStrainParameters[dataFileRef]) {
      const analysisData = report.stressStrainReport.stressStrainParameters[dataFileRef];
      if (analysisData) {
        Object.entries(analysisData).forEach(([peak, peakValues]) => {
          Object.entries(peakValues).forEach(([key, value]) => {
            const chartType = `stress_${peak}_${key}`;
            if (key.includes("std_dev") || report.stressStrainReport.yAxis.heatMap !== chartType) return;
            const x = xIndex % heatMapWidth;
            const y = Math.floor(xIndex / heatMapWidth);
            const newY = (heatMapHeight - 1) - y;
            let reversedX = x;

            if (report.swipeMode.swipe) {
              if (report.swipeMode.swipeRowEven) {
                if (newY % 2 === 0) {
                  reversedX = (heatMapWidth - 1) - x;
                }
              } else {
                if (newY % 2 !== 0) {
                  reversedX = (heatMapWidth - 1) - x;
                }
              }
            }

            z[newY][reversedX] = value;
            hoverText[newY][reversedX] = getName(dataFileRef);
          });
        });
      }
    }
  });

  newPlotData.push({
    z: z,
    type: 'heatmap',
    text: hoverText,
    hoverinfo: 'z+text',
    colorbar: {
      tickfont: {
        color: 'white'
      }
    },
    colorscale: report.heatMap.colorMap
  });

  return newPlotData;
}
