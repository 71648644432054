// SETTERS
export const SET_RIETVELD_DATA_FILES = "SET_RIETVELD_DATA_FILES";
export const SET_RIETVELD_DATA_ACTIVE_FILES = "SET_RIETVELD_DATA_ACTIVE_FILES";
export const SET_RIETVELD_DATA_VIEW_FILE = "SET_RIETVELD_DATA_VIEW_FILE"

export const SET_RIETVELD_CIF_FILES = "SET_RIETVELD_CIF_FILES";
export const SET_RIETVELD_CIF_TEMPLATE_FILES = "SET_RIETVELD_CIF_TEMPLATE_FILES";
export const SET_RIETVELD_CIF_FILES_ABC = "SET_RIETVELD_CIF_FILES_ABC";

export const SET_RIETVELD_INSTRUMENTAL_FILE = "SET_RIETVELD_INSTRUMENTAL_FILE";
export const SET_RIETVELD_INSTRUMENTAL_TEMPLATE_FILE = "SET_RIETVELD_INSTRUMENTAL_TEMPLATE_FILE";

export const SET_RIETVELD_WAVELENGTH = "SET_RIETVELD_WAVELENGTH";
export const SET_RIETVELD_INSTRUMENTAL_WAVELENGTH = "SET_RIETVELD_INSTRUMENTAL_WAVELENGTH";

export const SET_RIETVELD_RANGE = "SET_RIETVELD_RANGE";
export const SET_RIETVELD_RANGE_LOWER = "SET_RIETVELD_RANGE_LOWER"
export const SET_RIETVELD_RANGE_HIGHER = "SET_RIETVELD_RANGE_HIGHER"
export const SET_RIETVELD_RANGE_INITIAL = "SET_RIETVELD_RANGE_INITIAL"

export const SET_RIETVELD_HISTOGRAM = "SET_RIETVELD_HISTOGRAM";
export const SET_RIETVELD_REFINED_HISTOGRAM = "SET_RIETVELD_REFINED_HISTOGRAM";

export const SET_RIETVELD_CYCLES = "SET_RIETVELD_CYCLES"
export const SET_RIETVELD_PARAMETERS = "SET_RIETVELD_PARAMETERS"
export const SET_RIETVELD_MUSTRAINS = "SET_RIETVELD_MUSTRAINS"
export const SET_RIETVELD_CRYSTALLITE_SIZE = "SET_RIETVELD_CRYSTALLITE_SIZE"

export const SET_RIETVELD_RESET = "SET_RIETVELD_RESET"
export const SET_RIETVELD = "SET_RIETVELD"

// GETTERS
export const GET_RIETVELD_REFINED_HISTOGRAM = "GET_RIETVELD_REFINED_HISTOGRAM";
export const GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK = "GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK"
export const GET_RIETVELD_DOWNLOAD_DATA = "RIETVELD_DOWNLOAD_DATA"
