import { GlobalState } from "../../store/models";

export const selectAnalysisType = (state: GlobalState) => state.projectReducer.analysisType;
export const selectCurrentProject = (state: GlobalState) => state.projectReducer.currentProject;
export const selectUserToken = (state: GlobalState) => state.generalReducer.user.userToken;
export const selectUserId = (state: GlobalState) => state.generalReducer.user.userId;
export const selectUserName = (state: GlobalState) => state.generalReducer.user.userName;
export const selectProjectSettings = (state: GlobalState) => state.projectReducer.projectSettings;
export const selectFilesForUpload = (state: GlobalState) => state.projectReducer.selectedFilesForUpload;
export const selectCurrentSelectedFile = (state: GlobalState) => state.projectReducer.currentFile;
export const selectChartReportSettings = (state: GlobalState) => state.projectReducer.reportSettings;
export const selectCurrentProjectFiles = (state: GlobalState) => state.projectReducer.currentProjectFiles;
export const selectFilesForAdding = (state: GlobalState) => state.projectReducer.selectedFilesForAdding;
export const selectFileToDelete = (state: GlobalState) => state.projectReducer.fileToDelete;
export const selectProjectToDelete = (state: GlobalState) => state.projectReducer.projectToDelete;
export const selectProjectFiles = (state: GlobalState) => state.projectReducer.userFiles;
export const selectProjectName = (state: GlobalState) => state.projectReducer.projectSettings.projectName;
export const selectIsOperationInProgress = (state: GlobalState) => state.generalReducer.operationInProgress;
export const selectUserFiles = (state: GlobalState) => state.projectReducer.userFiles;
export const selectProjectsList = (state: GlobalState) => state.projectReducer.projectsList;
export const selectCurrentProjectId = (state: GlobalState) => state.projectReducer.currentProject?.projectId;
export const selectBrowsingProject = (state: GlobalState) => state.projectReducer.browsingProject;

export const selectReportScreen = (state: GlobalState) => state.projectReducer.reportScreen;
export const selectIsInInInlineOperationInProgress = (state: GlobalState) => state.generalReducer.inInInlineOperationInProgress;

export const selectPipelineStatus = (state: GlobalState) => state.projectReducer.pipeline.status;
export const selectOnDemandStatus = (state: GlobalState) => state.projectReducer.onDemand.status;