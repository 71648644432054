import { reformatString } from "../helpers/redux";
import { DropDownOptions } from "./models";

export const DataCalibrationFactoriesName: string[] = [
	"adsc_q315",
	"quantum_315",
	"adsc_q210",
	"quantum_210",
	"adsc_q270",
	"quantum_270",
	"adsc_q4",
	"quantum_4",
	"hf_130k",
	"hf_262k",
	"hf_1m",
	"hf_2m",
	"hf_2.4m",
	"hf_4m",
	"hf_9m",
	"hf_9.4m",
	"eiger_500k",
	"eiger_1m",
	"eiger_4m",
	"eiger_9m",
	"eiger_16m",
	"eiger2_250k",
	"eiger2_500k",
	"eiger2_1m",
	"eiger2_1m_w",
	"eiger2_2m_w",
	"eiger2_4m",
	"eiger2_9m",
	"eiger2_16m",
	"eiger2_cdte_500k",
	"eiger2_cdte_1m",
	"eiger2_cdte_1m_w",
	"eiger2_cdte_2m_w",
	"eiger2_cdte_4m",
	"eiger2_cdte_9m",
	"eiger2_cdte_16m",
	"mythen",
	"mythen_1280",
	"pilatus_100k",
	"pilatus_200k",
	"pilatus_300k",
	"pilatus_300kw",
	"pilatus_900k",
	"pilatus_1m",
	"pilatus_2m",
	"pilatus_6m",
	"pilatus_cdte_300k",
	"pilatus_cdte_300kw",
	"pilatus_cdte_900kw",
	"pilatus_cdte_1m",
	"pilatus_cdte_2m",
	"imxpads10",
	"imxpads70",
	"imxpads70v",
	"imxpads140",
	"xpad_flat",
	"xpad_s540_flat",
	"d5",
	"cirpad",
	"xcirpad",
	"rayonix_133",
	"mar_133",
	"rayonix_sx165",
	"mar_165",
	"rayonix_sx200",
	"rayonix_lx170",
	"rayonix_lx170_hs",
	"rayonix_mx170",
	"rayonix_mx170_hs",
	"rayonix_lx255",
	"rayonix_lx255_hs",
	"rayonix_mx225",
	"mar_225",
	"rayonix_mx225_hs",
	"rayonix_mx300",
	"mar_300",
	"rayonix_mx300_hs",
	"rayonix_mx340_hs",
	"rayonix_sx30_hs",
	"rayonix_sx85_hs",
	"rayonix_mx425_hs",
	"rayonix_mx325",
	"mar_345",
	"mar_3450",
	"mar_555",
	"frelon",
	"maxipix",
	"maxipix_1x1",
	"maxipix_2x2",
	"maxipix_5x1",
	"lambda_60k",
	"lambda_250k",
	"lambda_750k",
	"lambda_2m",
	"lambda_7.5m",
	"lambda_10m",
	"jungfrau_500k",
	"jungfrau_4m",
	"jungfrau_16m_cor",
	"aarhus",
	"rapid",
	"rapidii",
	"fairchild",
	"condor",
	"fairchild_condor_486",
	"titan_2k_x_2k",
	"oxd_titan",
	"agilent_titan",
	"dexela_2923",
	"basler",
	"aca1300",
	"perkin_detector",
	"perkin_elmer",
	"pixium_4700",
	"pixium_4700_detector",
	"thales_electronics",
	"apexii",
	"bruker",
	"raspberrypi_5m",
	"picam_v1",
	"raspberrypi_8m",
	"picam_v2",
	"pixirad-1",
	"pixirad-2",
	"pixirad-4",
	"pixirad-8",
]

const detectorMappingSpecial = {
    "imxpads_10": "imxpads10",
    "imxpads_70": "imxpads70",
    "imxpads_70v": "imxpads70v",
    "imxpads_140": "imxpads140",
    "xpad_s540_flat": "xpads540flat",
    "raspberrypi_5m": "raspberrypi5m",
    "raspberrypi_8m": "raspberrypi8m",
    "picam_v1": "picamv1",
    "picam_v2": "picamv2",
    "titan_2k_x_2k": "titan2kx2k",
    "oxd_titan": "oxdtitan",
    "agilent_titan": "agilenttitan",
    "dexela_2923": "dexela2923",
    "perkin_detector": "perkindetector",
    "perkin_elmer": "perkinelmer",
    "pixium_4700": "pixium4700",
    "pixium_4700_detector": "pixium4700detector",
    "thales_electronics": "thaleselectronics",
    "maxipix_1x1": "maxipix1x1",
    "maxipix_2x2": "maxipix2x2",
    "maxipix_5x1": "maxipix5x1",
    "fairchild_condor_486:90": "fairchildcondor486:90",
    "lambda_60k": "lambda60k",
    "lambda_250k": "lambda250k",
    "lambda_750k": "lambda750k",
    "lambda_2m": "lambda2m",
    "lambda_7.5m": "lambda7.5m",
    "lambda_10m": "lambda10m",
    "jungfrau_500k": "jungfrau500k",
    "jungfrau_4m": "jungfrau4m",
    "jungfrau_16m_cor": "jungfrau16mcor",
    "mar_133": "mar133",
    "mar_165": "mar165",
    "mar_225": "mar225",
    "mar_300": "mar300",
    "mar_345": "mar345",
    "mar_3450": "mar3450",
    "mar_555": "mar555",
};


function getDropDownDataCalibrationDetectorTypes() {
	const calibrant: DropDownOptions = {
		id: "calibrationType",
	 label: "Calibration Type",
	 options: [{
		id: "calibrationType",
		label: "Calibration Type",
		values: []
	 }],
	};

	DataCalibrationFactoriesName.forEach((name) => {
        if (name in detectorMappingSpecial) {
            name = detectorMappingSpecial[name];
        }
		calibrant.options[0].values.push({ label: reformatString(name), value: name });
	});

	return calibrant;
}

export const DataCalibrationDetectorTypes: DropDownOptions = getDropDownDataCalibrationDetectorTypes();


export const DataCalibrationCalibrationType: DropDownOptions = {
  id: "calibrationType",
  label: "Calibration Type",
  options: [{
      id: "calibrationType",
      label: "Calibration Type",
      values: [{
              label: "Si_SRM640e",
              value: "Si_SRM640e"
          },
          {
              label: "Au",
              value: "Au"
          },
          {
              label: "CeO2",
              value: "CeO2"
          },
          {
              label: "LaB6_SRM660b",
              value: "LaB6_SRM660b"
          },
          {
              label: "Si_SRM640a",
              value: "Si_SRM640a"
          },
          {
              label: "TiO2",
              value: "TiO2"
          },
          {
              label: "cristobaltite",
              value: "cristobaltite"
          },
          {
              label: "AgBh",
              value: "AgBh"
          },
          {
              label: "Ni",
              value: "Ni"
          },
          {
              label: "LaB6_SRM660a",
              value: "LaB6_SRM660a"
          },
          {
              label: "Si_SRM640b",
              value: "Si_SRM640b"
          },
          {
              label: "LaB6",
              value: "LaB6"
          },
          {
              label: "Pt",
              value: "Pt"
          },
          {
              label: "Si",
              value: "Si"
          },
          {
              label: "quartz",
              value: "quartz"
          },
          {
              label: "CrOx",
              value: "CrOx"
          },
          {
              label: "Si_SRM640c",
              value: "Si_SRM640c"
          },
          {
              label: "C14H30O",
              value: "C14H30O"
          },
          {
              label: "NaCl",
              value: "NaCl"
          },
          {
              label: "PBBA",
              value: "PBBA"
          },
          {
              label: "ZnO",
              value: "ZnO"
          },
          {
              label: "CuO",
              value: "CuO"
          },
          {
              label: "vanadinite",
              value: "vanadinite"
          },
          {
              label: "Si_SRM640",
              value: "Si_SRM640"
          },
          {
              label: "alpha_Al2O3",
              value: "alpha_Al2O3"
          },
          {
              label: "hydrocerussite",
              value: "hydrocerussite"
          },
          {
              label: "Si_SRM640d",
              value: "Si_SRM640d"
          },
          {
              label: "LaB6_SRM660c",
              value: "LaB6_SRM660c"
          },
          {
              label: "Al",
              value: "Al"
          },
          {
              label: "mock",
              value: "mock"
          },
          {
              label: "Cr2O3",
              value: "Cr2O3"
          }
      ],
  }, ],
};

export const DataCalibrationUnit: DropDownOptions = {
	id: "xAxis",
	label: "x-axis unit",
	options: [
		{
			id: "xAxis",
			label: "x-axis unit",
			values: [
				{ label: "2θ", value: "2th_deg" },
        { label: "Q(Å^-1)", value: "q_A^-1" },
        { label: "Q(nm^-1)", value: "q_nm^-1" },
				{ label: "2θ(rad)", value: "2th_rad" },
      ],
    },
	],
};

export const DataCalibrationPixelSize = {
    "adsc_q315": {
        "x": 51.0,
        "y": 51.0
    },
    "quantum_315": {
        "x": 51.0,
        "y": 51.0
    },
    "quantum315": {
        "x": 51.0,
        "y": 51.0
    },
    "adsc_q210": {
        "x": 51.0,
        "y": 51.0
    },
    "quantum_210": {
        "x": 51.0,
        "y": 51.0
    },
    "quantum210": {
        "x": 51.0,
        "y": 51.0
    },
    "adsc_q270": {
        "x": 64.8,
        "y": 64.8
    },
    "quantum_270": {
        "x": 64.8,
        "y": 64.8
    },
    "quantum270": {
        "x": 64.8,
        "y": 64.8
    },
    "adsc_q4": {
        "x": 82.0,
        "y": 82.0
    },
    "quantum_4": {
        "x": 82.0,
        "y": 82.0
    },
    "quantum4": {
        "x": 82.0,
        "y": 82.0
    },
    "hf_130k": {
        "x": 150.0,
        "y": 150.0
    },
    "hf-130k": {
        "x": 150.0,
        "y": 150.0
    },
    "hf_262k": {
        "x": 150.0,
        "y": 150.0
    },
    "hf-262k": {
        "x": 150.0,
        "y": 150.0
    },
    "hf_1m": {
        "x": 150.0,
        "y": 150.0
    },
    "hf-1m": {
        "x": 150.0,
        "y": 150.0
    },
    "hf_2m": {
        "x": 150.0,
        "y": 150.0
    },
    "hf-2.4m": {
        "x": 150.0,
        "y": 150.0
    },
    "hf_4m": {
        "x": 150.0,
        "y": 150.0
    },
    "hf-4m": {
        "x": 150.0,
        "y": 150.0
    },
    "hf_9m": {
        "x": 150.0,
        "y": 150.0
    },
    "hf-9.4m": {
        "x": 150.0,
        "y": 150.0
    },
    "eiger500k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger_500k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger1m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger_1m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger4m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger_4m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger9m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger_9m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger16m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger_16m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_250k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2250k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_500k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2500k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_1m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger21m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_1mw": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_1m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger21m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_2mw": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_2m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger22m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_4m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger24m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_9m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger29m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_16m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger216m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte_500k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_cdte_500k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte500k": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte_1m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_cdte_1m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte1m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte_1mw": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_cdte_1m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte1m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte_2mw": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_cdte_2m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte2m-w": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte_4m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_cdte_4m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte4m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte_9m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_cdte_9m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte9m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte_16m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2_cdte_16m": {
        "x": 75.0,
        "y": 75.0
    },
    "eiger2cdte16m": {
        "x": 75.0,
        "y": 75.0
    },
    "mythen": {
        "x": 8000.0,
        "y": 50.0
    },
    "mythen_1280": {
        "x": 8000.0,
        "y": 50.0
    },
    "mythen1280": {
        "x": 8000.0,
        "y": 50.0
    },
    "pilatus100k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_100k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus200k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_200k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus300k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_300k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus300kw": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_300kw": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus900k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_900k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus1m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_1m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus2m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_2m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus6m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_6m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatuscdte300k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_cdte_300k": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_300k_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus300kcdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus300k_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatuscdte300kw": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_cdte_300kw": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_300kw_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus300kwcdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus300kw_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatuscdte900kw": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_cdte_900kw": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_900kw_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus900kwcdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus900kw_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatuscdte1m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_cdte_1m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_1m_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus1mcdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus1m_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatuscdte2m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_cdte_2m": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus_2m_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus2mcdte": {
        "x": 172.0,
        "y": 172.0
    },
    "pilatus2m_cdte": {
        "x": 172.0,
        "y": 172.0
    },
    "imxpads10": {
        "x": 130.0,
        "y": 130.0
    },
    "imxpad_s10": {
        "x": 130.0,
        "y": 130.0
    },
    "imxpads70": {
        "x": 130.0,
        "y": 130.0
    },
    "imxpad_s70": {
        "x": 130.0,
        "y": 130.0
    },
    "imxpads70v": {
        "x": 130.0,
        "y": 130.0
    },
    "imxpad_s70_v": {
        "x": 130.0,
        "y": 130.0
    },
    "imxpads140": {
        "x": 130.0,
        "y": 130.0
    },
    "imxpad_s140": {
        "x": 130.0,
        "y": 130.0
    },
    "xpad_flat": {
        "x": 130.0,
        "y": 130.0
    },
    "xpad_s540_flat": {
        "x": 130.0,
        "y": 130.0
    },
    "xpads540flat": {
        "x": 130.0,
        "y": 130.0
    },
    "d5": {
        "x": 130.0,
        "y": 130.0
    },
    "cirpad": {
        "x": 130.0,
        "y": 130.0
    },
    "xcirpad": {
        "x": 130.0,
        "y": 130.0
    },
    "rayonix133": {
        "x": 64.0,
        "y": 64.0
    },
    "mar_133": {
        "x": 64.0,
        "y": 64.0
    },
    "mar133": {
        "x": 64.0,
        "y": 64.0
    },
    "rayonixsx165": {
        "x": 39.5,
        "y": 39.5
    },
    "rayonix_sx165": {
        "x": 39.5,
        "y": 39.5
    },
    "mar_165": {
        "x": 39.5,
        "y": 39.5
    },
    "mar165": {
        "x": 39.5,
        "y": 39.5
    },
    "rayonixsx200": {
        "x": 48.0,
        "y": 48.0
    },
    "rayonix_sx200": {
        "x": 48.0,
        "y": 48.0
    },
    "rayonixlx170": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_lx170": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_lx170-hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixlx170-hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_lx170_hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixlx170hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixmx170": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_mx170": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_mx170-hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixmx170-hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixmx170hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_mx170_hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixlx255": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_lx255": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_lx255-hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixlx255-hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_lx_255hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixlx255hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixlx225hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixmx225": {
        "x": 73.242,
        "y": 73.242
    },
    "rayonix_mx225": {
        "x": 73.242,
        "y": 73.242
    },
    "mar_225": {
        "x": 73.242,
        "y": 73.242
    },
    "mar225": {
        "x": 73.242,
        "y": 73.242
    },
    "rayonixmx225hs": {
        "x": 78.125,
        "y": 78.125
    },
    "rayonix_mx225hs": {
        "x": 78.125,
        "y": 78.125
    },
    "rayonix_mx225_hs": {
        "x": 78.125,
        "y": 78.125
    },
    "rayonixmx300": {
        "x": 73.242,
        "y": 73.242
    },
    "rayonix_mx300": {
        "x": 73.242,
        "y": 73.242
    },
    "mar_300": {
        "x": 73.242,
        "y": 73.242
    },
    "mar300": {
        "x": 73.242,
        "y": 73.242
    },
    "rayonixmx300hs": {
        "x": 78.125,
        "y": 78.125
    },
    "rayonix_mx300hs": {
        "x": 78.125,
        "y": 78.125
    },
    "rayonix_mx300_hs": {
        "x": 78.125,
        "y": 78.125
    },
    "rayonixmx340hs": {
        "x": 88.5417,
        "y": 88.5417
    },
    "rayonix_mx340hs": {
        "x": 88.5417,
        "y": 88.5417
    },
    "rayonixsx30hs": {
        "x": 15.625,
        "y": 15.625
    },
    "rayonix_sx30hs": {
        "x": 15.625,
        "y": 15.625
    },
    "rayonix_sx30_hs": {
        "x": 15.625,
        "y": 15.625
    },
    "rayonixsx85hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_sx85hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_sx85_hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixmx425hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_mx425hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonix_mx425_hs": {
        "x": 44.2708,
        "y": 44.2708
    },
    "rayonixmx325": {
        "x": 79.34599999999999,
        "y": 79.34599999999999
    },
    "rayonix_mx325": {
        "x": 79.34599999999999,
        "y": 79.34599999999999
    },
    "mar345": {
        "x": 100.0,
        "y": 100.0
    },
    "mar_345": {
        "x": 100.0,
        "y": 100.0
    },
    "mar3450": {
        "x": 100.0,
        "y": 100.0
    },
    "mar555": {
        "x": 139.0,
        "y": 139.0
    },
    "mar_555": {
        "x": 139.0,
        "y": 139.0
    },
    "frelon": {
        "x": 50.0,
        "y": 50.0
    },
    "maxipix": {
        "x": 55.0,
        "y": 55.0
    },
    "maxipix_1x1": {
        "x": 55.0,
        "y": 55.0
    },
    "maxipix1x1": {
        "x": 55.0,
        "y": 55.0
    },
    "maxipix2x2": {
        "x": 55.0,
        "y": 55.0
    },
    "maxipix_2x2": {
        "x": 55.0,
        "y": 55.0
    },
    "maxipix5x1": {
        "x": 55.0,
        "y": 55.0
    },
    "maxipix_5x1": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda60k": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda_60k": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda250k": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda_250k": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda750k": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda_750k": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda2m": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda_2m": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda7m5": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda_7.5m": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda7.5m": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda10m": {
        "x": 55.0,
        "y": 55.0
    },
    "lambda_10m": {
        "x": 55.0,
        "y": 55.0
    },
    "jungfrau": {
        "x": 75.0,
        "y": 75.0
    },
    "jungfrau_500k": {
        "x": 75.0,
        "y": 75.0
    },
    "jungfrau500k": {
        "x": 75.0,
        "y": 75.0
    },
    "jungfrau4m": {
        "x": 75.0,
        "y": 75.0
    },
    "jungfrau_4m": {
        "x": 75.0,
        "y": 75.0
    },
    "jungfrau_16m_cor": {
        "x": 75.0,
        "y": 75.0
    },
    "jungfrau16mcor": {
        "x": 75.0,
        "y": 75.0
    },
    "aarhus": {
        "x": 24.893,
        "y": 24.893
    },
    "rapid": {
        "x": 100.0,
        "y": 100.0
    },
    "rapidii": {
        "x": 100.0,
        "y": 100.0
    },
    "fairchild": {
        "x": 15.0,
        "y": 15.0
    },
    "condor": {
        "x": 15.0,
        "y": 15.0
    },
    "fairchild_condor_486:90": {
        "x": 15.0,
        "y": 15.0
    },
    "fairchildcondor486:90": {
        "x": 15.0,
        "y": 15.0
    },
    "titan": {
        "x": 60.0,
        "y": 60.0
    },
    "titan_2k_x_2k": {
        "x": 60.0,
        "y": 60.0
    },
    "titan2kx2k": {
        "x": 60.0,
        "y": 60.0
    },
    "oxd_titan": {
        "x": 60.0,
        "y": 60.0
    },
    "oxdtitan": {
        "x": 60.0,
        "y": 60.0
    },
    "agilent_titan": {
        "x": 60.0,
        "y": 60.0
    },
    "agilenttitan": {
        "x": 60.0,
        "y": 60.0
    },
    "dexela2923": {
        "x": 75.0,
        "y": 75.0
    },
    "dexela_2923": {
        "x": 75.0,
        "y": 75.0
    },
    "basler": {
        "x": 3.75,
        "y": 3.75
    },
    "aca1300": {
        "x": 3.75,
        "y": 3.75
    },
    "perkin": {
        "x": 200.0,
        "y": 200.0
    },
    "perkin_detector": {
        "x": 200.0,
        "y": 200.0
    },
    "perkindetector": {
        "x": 200.0,
        "y": 200.0
    },
    "perkin_elmer": {
        "x": 200.0,
        "y": 200.0
    },
    "perkinelmer": {
        "x": 200.0,
        "y": 200.0
    },
    "pixium": {
        "x": 308.0,
        "y": 308.0
    },
    "pixium_4700": {
        "x": 308.0,
        "y": 308.0
    },
    "pixium4700": {
        "x": 308.0,
        "y": 308.0
    },
    "pixium_4700_detector": {
        "x": 308.0,
        "y": 308.0
    },
    "pixium4700detector": {
        "x": 308.0,
        "y": 308.0
    },
    "thales_electronics": {
        "x": 308.0,
        "y": 308.0
    },
    "thaleselectronics": {
        "x": 308.0,
        "y": 308.0
    },
    "apex2": {
        "x": 120.0,
        "y": 120.0
    },
    "apexii": {
        "x": 120.0,
        "y": 120.0
    },
    "bruker": {
        "x": 120.0,
        "y": 120.0
    },
    "raspberrypi5m": {
        "x": 1.4,
        "y": 1.4
    },
    "picam_v1": {
        "x": 1.4,
        "y": 1.4
    },
    "picamv1": {
        "x": 1.4,
        "y": 1.4
    },
    "raspberrypi8m": {
        "x": 1.12,
        "y": 1.12
    },
    "picam_v2": {
        "x": 1.12,
        "y": 1.12
    },
    "picamv2": {
        "x": 1.12,
        "y": 1.12
    },
    "pixirad1": {
        "x": 51.96152422706632,
        "y": 60.0
    },
    "pixirad-1": {
        "x": 51.96152422706632,
        "y": 60.0
    },
    "pixirad2": {
        "x": 51.96152422706632,
        "y": 60.0
    },
    "pixirad-2": {
        "x": 51.96152422706632,
        "y": 60.0
    },
    "pixirad4": {
        "x": 51.96152422706632,
        "y": 60.0
    },
    "pixirad-4": {
        "x": 51.96152422706632,
        "y": 60.0
    },
    "pixirad8": {
        "x": 51.96152422706632,
        "y": 60.0
    },
    "pixirad-8": {
        "x": 51.96152422706632,
        "y": 60.0
    }
}