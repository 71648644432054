import { AnalysisTypesModel, InputContainerModel } from "./models";
export const AnalysisTypes: AnalysisTypesModel[] = [
  {
    id: "azimuthal_integration_phase_quantification",
    analysis_name: "Azimuthal Integration and Phase Quantification",
    header: "Azimuthal Integration and Phase Quantification",
    lambda_name: "azimuthal_integration_phase_quantification",
    type: "pipeline",
  },
  {
    id: "azimuthal_integration_stress_analysis",
    analysis_name: "Azimuthal Integration and Stress Analysis",
    header: "Azimuthal Integration and Stress Analysis",
    lambda_name: "azimuthal_integration_stress_analysis",
    type: "pipeline",
  },
  {
    id: "data_calibration",
    analysis_name: "Data Calibration",
    header: "Data Calibration",
    lambda_name: "data_calibration",
    type: "wide",
  },
  {
    id: "azimuthal_integration",
    analysis_name: "Azimuthal integration",
    header: "Azimuthal integration",
    lambda_name: "azimuthal_integration",
    type: "wide",
  },
  {
    id: "instrumental_file_creation",
    analysis_name: "Instrumental File Creation",
    header: "Instrumental File Creation",
    lambda_name: "instrumental_file_creation",
    type: "wide",
  },
  {
    id: "le_bail",
    analysis_name: "Le Bail Refinement",
    header: "Le Bail Refinement",
    lambda_name: "le_bail",
    type: "wide",
  },
  {
    id: "peak_fitting",
    analysis_name: "Peak Fitting",
    header: "Peak Fitting",
    lambda_name: "peak_fitting",
    type: "wide",
  },
  {
    id: "stress_strain",
    analysis_name: "Stress Strain",
    header: "Stress Strain",
    lambda_name: "stress_strain",
    type: "wide",
  },
  {
    id: "phase_quantification",
    analysis_name: "Phase Quantification",
    header: "Phase Quantification",
    lambda_name: "phase_quantification",
    type: "wide",
  },
  {
    id: "dislocation_density",
    analysis_name: "Dislocation Density Analysis",
    header: "Dislocation Density Analysis",
    lambda_name: "dislocation_density",
    type: "wide",
  },
  {
    id: "rietveld",
    analysis_name: "Rietveld Refinement (Experimental)",
    header: "Rietveld Refinement (Experimental)",
    lambda_name: "rietveld",
    type: "wide",
  },
  {
    id: "background_calculation",
    analysis_name: "Background calculations",
    header: "Background calculations",
    lambda_name: "backgrounds_orchestra",
    type: "small",
  },
  {
    id: "scattering_invariant",
    analysis_name: "Scattering invariant",
    header: "Scattering invariant",
    lambda_name: "scattering_invariant",
    type: "small",
  },
  {
    id: "volume_fraction",
    analysis_name: "Volume fraction",
    header: "Volume fraction",
    lambda_name: "volume_fraction",
    type: "small",
  },
  {
    id: "spinodal_decomposition",
    analysis_name: "Spinodal decomposition",
    header: "Spinodal decomposition",
    lambda_name: "spinodal_decomposition",
    type: "small",
  },
  {
    id: "data_extrapolation",
    analysis_name: "Data extrapolation",
    header: "Data extrapolation",
    lambda_name: "data_extrapolation",
    type: "small",
  },
];

export const AllAnalysisDescriptions: InputContainerModel[] = [
  {
    id: "background_analysis",
    analysis_name: "Background calculations",
    header: "Background calculations",
    lambda_name: "backgrounds_orchestra",
    type: "small",
    report_line_name: ["power_law_background"],
    panels: [
      {
        id: "background_analysis_panel1",
        conditional: false,
        visible: true,
        dynamodb_value: "flat_background_panel",
        header: "Flat background",
        toggleGroups: [
          {
            id: "background_analysis_toggle11",
            label: "Data range",
            dynamodb_name: "flat_background_toggle",
            toggle1: [
              {
                id: "start_q_flat_background",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_flat_background",
                required: true,
              },
              {
                id: "end_q_flat_background",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_flat_background",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "flat_background",
                label: "Flat background",
                type: "float",
                dynamodb_name: "flat_background",
                displayedInReports: true,
                required: true,
              },
            ],
          },
        ],
      },
      {
        id: "background_analysis_panel2",
        dynamodb_value: "power_law_background",
        header: "Power law background",
        conditional: false,
        toggleGroups: [
          {
            id: "background_analysis_toggle21",
            label: "Slope",
            dynamodb_name: "slope_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
                default_value: 0.77,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "slope",
                label: "Slope",
                type: "float",
                dynamodb_name: "slope",
                displayedInReports: true,
                required: true,
              },
            ],
          },
          {
            id: "background_analysis_toggle22",
            label: "Scale",
            dynamodb_name: "scale_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "scale",
                label: "Scale",
                type: "float",
                dynamodb_name: "scale",
                displayedInReports: true,
                required: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "scattering_invariant_analysis",
    analysis_name: "Scattering invariant",
    header: "Scattering invariant",
    lambda_name: "scattering_invariant",
    report_line_name: ["scattering_invariant"],
    type: "small",
    panels: [
      {
        id: "scattering_invariant_use_subset",
        conditional: true,
        header: "Use subset",
        condition_label: "Use subset",
        dynamodb_value: "scattering_invariant_use_subset",
        inputFields: [
          {
            id: "scattering_invariant_subset_start_q",
            type: "float",
            required: true,
            label: "Subset start q",
            dynamodb_name: "scattering_invariant_subset_start_q",
            default_value: 0,
            editable: true,
          },
          {
            id: "scattering_invariant_subset_end_q",
            type: "float",
            required: true,
            label: "Subset end q",
            dynamodb_name: "scattering_invariant_subset_end_q",
            default_value: 0,
            editable: true,
          },
        ],
      },
      {
        id: "scattering_invariant_panel1",
        conditional: true,
        visible: true,
        dynamodb_value: "scattering_invariant_use_flat_background",
        condition_label: "Use flat background",
        header: "Flat background",
        toggleGroups: [
          {
            id: "scattering_invariant_toggle11",
            label: "Data range",
            dynamodb_name: "flat_background_toggle",
            toggle1: [
              {
                id: "start_q_flat_background",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_flat_background",
                required: true,
              },
              {
                id: "end_q_flat_background",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_flat_background",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "flat_background",
                label: "Flat background",
                type: "float",
                dynamodb_name: "flat_background",
                required: true,
              },
            ],
          },
        ],
      },
      {
        id: "scattering_invariant_panel2",
        conditional: true,
        dynamodb_value: "scattering_invariant_use_power_law_background",
        condition_label: "Use power law background",
        header: "Power law background",
        toggleGroups: [
          {
            id: "scattering_invariant_toggle21",
            label: "Slope",
            dynamodb_name: "slope_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
                default_value: 0.77,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "q_slope",
                label: "Slope",
                type: "float",
                dynamodb_name: "slope",
                required: true,
              },
            ],
          },
          {
            id: "scattering_invariant_toggle22",
            label: "Scale",
            dynamodb_name: "scale_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "scale",
                label: "Scale",
                type: "float",
                dynamodb_name: "scale",
                required: true,
              },
            ],
          },
        ],
      },
      {
        id: "scattering_invariant_panel3",
        conditional: false,
        dynamodb_value: "",
        condition_label: "",
        header: "",
        inputFields: [
          {
            id: "scattering_invariant",
            label: "Scattering invariant",
            required: true,
            dynamodb_name: "scattering_invariant",
            type: "float",
            displayedInReports: true,
            editable: false,
          },
        ],
      },
    ],
  },
  {
    id: "volume_fraction_analysis",
    analysis_name: "Volume fraction",
    lambda_name: "volume_fraction",
    header: "Volume fraction",
    report_line_name: ["scattering_invariant"],
    type: "small",
    panels: [
      {
        id: "volume_fraction_panel1",
        header: "Scattering invariant",
        conditional: false,
        panels: [
          {
            id: "scattering_invariant_use_subset",
            conditional: true,
            header: "Use subset",
            condition_label: "Use subset",
            dynamodb_value: "scattering_invariant_use_subset",
            inputFields: [
              {
                id: "scattering_invariant_subset_start_q",
                type: "float",
                required: true,
                label: "Subset start q",
                dynamodb_name: "scattering_invariant_subset_start_q",
                default_value: 0,
                editable: true,
              },
              {
                id: "scattering_invariant_subset_end_q",
                type: "float",
                required: true,
                label: "Subset end q",
                dynamodb_name: "scattering_invariant_subset_end_q",
                default_value: 0,
                editable: true,
              },
            ],
          },
          {
            id: "volume_fraction_panel11",
            conditional: true,
            dynamodb_value: "scattering_invariant_use_flat_background",
            condition_label: "Use flat background",
            header: "Flat background",
            toggleGroups: [
              {
                id: "volume_fraction_toggle11",
                label: "Flat background",
                dynamodb_name: "flat_background_toggle",
                toggle1: [
                  {
                    id: "start_q_flat_background",
                    label: "Start point (q value)",
                    type: "float",
                    dynamodb_name: "start_q_flat_background",
                    required: true,
                  },
                  {
                    id: "end_q_flat_background",
                    label: "End point (q value)",
                    type: "float",
                    dynamodb_name: "end_q_flat_background",
                    required: true,
                  },
                ],
                toggle2: [
                  {
                    id: "flat_background",
                    label: "Flat background",
                    type: "float",
                    dynamodb_name: "flat_background",
                    required: true,
                  },
                ],
              },
            ],
          },
          {
            id: "volume_fraction_panel12",
            conditional: true,
            condition_label: "Use power law background",
            dynamodb_value: "scattering_invariant_use_power_law_background",
            header: "Power law background",
            toggleGroups: [
              {
                id: "volume_fraction_toggle21",
                label: "Slope",
                dynamodb_name: "slope_toggle",
                toggle1: [
                  {
                    id: "start_q_slope_scale",
                    label: "Start point (q value)",
                    type: "float",
                    dynamodb_name: "start_q_slope_scale",
                    required: true,
                  },
                  {
                    id: "end_q_slope_scale",
                    label: "End point (q value)",
                    type: "float",
                    dynamodb_name: "end_q_slope_scale",
                    required: true,
                  },
                ],
                toggle2: [
                  {
                    id: "q_slope",
                    label: "Slope",
                    type: "float",
                    dynamodb_name: "slope",
                    required: true,
                  },
                ],
              },
              {
                id: "volume_fraction_toggle22",
                label: "Scale",
                dynamodb_name: "scale_toggle",
                toggle1: [
                  {
                    id: "start_q_slope_scale",
                    label: "Start point (q value)",
                    type: "float",
                    dynamodb_name: "start_q_slope_scale",
                    required: true,
                  },
                  {
                    id: "end_q_slope_scale",
                    label: "End point (q value)",
                    type: "float",
                    dynamodb_name: "end_q_slope_scale",
                    required: true,
                  },
                ],
                toggle2: [
                  {
                    id: "scale",
                    label: "Scale",
                    type: "float",
                    dynamodb_name: "scale",
                    required: true,
                  },
                ],
              },
            ],
          },
        ],
        inputFields: [
          {
            id: "interfacial_preceipitation",
            type: "boolean",
            required: true,
            label: "Interfacial Preceipitation",
            dynamodb_name: "interfacial_preceipitation",
            default_value: true,
            editable: true,
          },
          {
            id: "SLD1",
            label: "SLD1",
            required: true,
            dynamodb_name: "SLD1",
            type: "float",
            default_value: 0.77,
            editable: true,
          },
          {
            id: "SLD2",
            label: "SLD2",
            required: true,
            dynamodb_name: "SLD2",
            type: "float",
            default_value: 0.23,
            editable: true,
          },
          {
            id: "volume_fraction",
            label: "Volume fraction",
            required: true,
            dynamodb_name: "volume_fraction",
            type: "float",
            displayedInReports: true,
            editable: false,
          },
        ],
      },
    ],
  },
  {
    id: "spinodal_decomposition_analysis",
    analysis_name: "Spinodal decomposition",
    lambda_name: "spinodal_decomposition",
    header: "Spinodal decomposition",
    report_line_name: [
      "spinodal_decomposition_0",
      "spinodal_decomposition_1",
      "spinodal_decomposition_2",
      "spinodal_decomposition_3",
      "spinodal_decomposition_4",
    ],
    type: "small",
    panels: [
      {
        id: "spinodal_decomposition_use_subset",
        conditional: true,
        header: "Use subset",
        condition_label: "Use subset",
        dynamodb_value: "spinodal_decomposition_use_subset",
        inputFields: [
          {
            id: "spinodal_decomposition_subset_start_q",
            type: "float",
            required: true,
            label: "Subset start q",
            dynamodb_name: "spinodal_decomposition_subset_start_q",
            default_value: 0,
            editable: true,
          },
          {
            id: "spinodal_decomposition_subset_end_q",
            type: "float",
            required: true,
            label: "Subset end q",
            dynamodb_name: "spinodal_decomposition_subset_end_q",
            default_value: 0,
            editable: true,
          },
        ],
      },
      {
        id: "spinodal_decomposition_panel11",
        conditional: true,
        condition_label: "Use flat background",
        dynamodb_value: "spinodal_decomposition_use_flat_background",
        header: "Flat background",
        toggleGroups: [
          {
            id: "spinodal_decomposition_toggle11",
            label: "Data range",
            dynamodb_name: "flat_background_toggle",
            toggle1: [
              {
                id: "start_q_flat_background",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_flat_background",
                required: true,
              },
              {
                id: "end_q_flat_background",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_flat_background",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "flat_background",
                label: "Flat background",
                type: "float",
                dynamodb_name: "flat_background",
                required: true,
              },
            ],
          },
        ],
      },
      {
        id: "spinodal_decomposition_panel12",
        conditional: true,
        dynamodb_value: "spinodal_decomposition_use_power_law_background",
        condition_label: "Use power law background",
        header: "Power law background",
        toggleGroups: [
          {
            id: "spinodal_decomposition_toggle21",
            label: "Slope",
            dynamodb_name: "slope_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "slope",
                label: "Slope",
                type: "float",
                dynamodb_name: "slope",
                required: true,
              },
            ],
          },
          {
            id: "spinodal_decomposition_toggle22",
            label: "Scale",
            dynamodb_name: "scale_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "scale",
                label: "Scale",
                type: "float",
                dynamodb_name: "scale",
                required: true,
              },
            ],
          },
        ],
      },
      {
        id: "spinodal_decomposition_panel1",
        header: "",
        conditional: false,
        panels: [],
        inputFields: [
          {
            id: "spinodal_decomposition_smooth_peaks",
            type: "boolean",
            required: true,
            label: "Smooth peaks",
            dynamodb_name: "spinodal_decomposition_smooth_peaks",
            default_value: false,
            editable: true,
          },
          {
            id: "gamma",
            label: "Gamma",
            required: true,
            dynamodb_name: "gamma",
            type: "dropdown",
            editable: true,
            dropdownOptions: ["2", "3", "4", "6"],
          },
          {
            id: "peaks_output",
            label: "Peaks",
            type: "table",
            table_columns: ["x", "y"],
            dynamodb_name: "peaks",
            displayedInReports: true,
            required: false,
            editable: false,
          },
        ],
      },
    ],
  },
  {
    id: "data_extrapolation_analysis",
    type: "small",
    analysis_name: "Data extrapolation",
    lambda_name: "data_extrapolation",
    header: "Data extrapolation",
    report_line_name: [
      "new_extrapolated_curve",
      "additional_extrapolated_points",
      "used_data_extrapolation_subset",
      "data_extrapolation_rc",
    ],
    panels: [
      {
        id: "data_extrapolation_use_subset",
        conditional: true,
        header: "Use subset",
        condition_label: "Use subset",
        dynamodb_value: "data_extrapolation_use_subset",
        inputFields: [
          {
            id: "data_extrapolation_subset_start_q",
            type: "float",
            required: true,
            label: "Subset start q",
            dynamodb_name: "data_extrapolation_subset_start_q",
            default_value: 0,
            editable: true,
          },
          {
            id: "data_extrapolation_subset_end_q",
            type: "float",
            required: true,
            label: "Subset end q",
            dynamodb_name: "data_extrapolation_subset_end_q",
            default_value: 0,
            editable: true,
          },
        ],
      },
      {
        id: "volume_fraction_panel11",
        conditional: true,
        condition_label: "Use flat background",
        dynamodb_value: "data_extrapolation_use_flat_background",
        header: "Flat background",
        toggleGroups: [
          {
            id: "volume_fraction_toggle11",
            label: "Flat background",
            dynamodb_name: "flat_background_toggle",
            toggle1: [
              {
                id: "start_q_flat_background",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_flat_background",
                required: true,
              },
              {
                id: "end_q_flat_background",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_flat_background",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "flat_background",
                label: "Flat background",
                type: "float",
                dynamodb_name: "flat_background",
                required: true,
              },
            ],
          },
        ],
      },
      {
        id: "volume_fraction_panel12",
        conditional: true,
        dynamodb_value: "data_extrapolation_use_power_law_background",
        condition_label: "Use power law background",
        header: "Power law background",
        toggleGroups: [
          {
            id: "volume_fraction_toggle21",
            label: "Slope",
            dynamodb_name: "slope_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "slope",
                label: "Slope",
                type: "float",
                dynamodb_name: "slope",
                required: true,
              },
            ],
          },
          {
            id: "volume_fraction_toggle22",
            label: "Scale",
            dynamodb_name: "scale_toggle",
            toggle1: [
              {
                id: "start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "start_q_slope_scale",
                required: true,
              },
              {
                id: "end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "end_q_slope_scale",
                required: true,
              },
            ],
            toggle2: [
              {
                id: "scale",
                label: "Scale",
                type: "float",
                dynamodb_name: "scale",
                required: true,
              },
            ],
          },
        ],
      },
      {
        id: "slope_panel_1",
        conditional: true,
        dynamodb_value: "data_extrapolation_new_slope_calculation",
        condition_label: "Calculate new data extrapolation slope",
        header: "Data extrapolation slope",
        toggleGroups: [
          {
            id: "slope_toggle_group_1",
            label: "Data range",
            dynamodb_name: "data_extrapolation_new_slope_toggle",
            toggle1: [
              {
                id: "data_extrapolation_start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "data_extrapolation_start_q_slope_scale",
                required: true,
                default_value: 0.0,
              },
              {
                id: "data_extrapolation_end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "data_extrapolation_end_q_slope_scale",
                required: true,
                default_value: 0.0,
              },
            ],
            toggle2: [
              {
                id: "data_extrapolation_slope",
                label: "Data extrapolation slope",
                type: "float",
                dynamodb_name: "data_extrapolation_slope",
                required: true,
                displayedInReports: true,
                default_value: 0.0,
              },
            ],
          },
        ],
      },
      {
        id: "scale_panel_2",
        conditional: true,
        dynamodb_value: "data_extrapolation_new_scale_calculation",
        condition_label: "Calculate new data extrapolation scale",
        header: "Data extapolation scale",
        toggleGroups: [
          {
            id: "scale_toggle_group_1",
            label: "Data range",
            dynamodb_name: "data_extrapolation_new_scale_toggle",
            toggle1: [
              {
                id: "data_extrapolation_start_q_slope_scale",
                label: "Start point (q value)",
                type: "float",
                dynamodb_name: "data_extrapolation_start_q_slope_scale",
                required: true,
                default_value: 0.0,
              },
              {
                id: "data_extrapolation_end_q_slope_scale",
                label: "End point (q value)",
                type: "float",
                dynamodb_name: "data_extrapolation_end_q_slope_scale",
                required: true,
                default_value: 0.0,
              },
            ],
            toggle2: [
              {
                id: "data_extrapolation_scale",
                label: "Data extrapolation scale",
                type: "float",
                dynamodb_name: "data_extrapolation_scale",
                required: true,
                displayedInReports: true,
                default_value: 0.0,
              },
            ],
          },
        ],
      },
      {
        id: "input_fields_panel",
        conditional: false,
        header: "",
        inputFields: [
          {
            id: "data_extrapolation_start_q_range",
            type: "float",
            required: true,
            label: "Start q range",
            dynamodb_name: "data_extrapolation_start_q_range",
            default_value: 0,
            editable: true,
          },
          {
            id: "data_extrapolation_end_q_range",
            label: "End q range",
            required: true,
            dynamodb_name: "data_extrapolation_end_q_range",
            type: "float",
            default_value: 0,
            editable: true,
          },
        ],
      },
    ],
  },
];
