import { AzimuthalIntegrationModel } from "../../models/analysis.azimuthalIntegration.model";
import { RESET_AZIMUTHAL_INTEGRATION, SET_AZIMUTHAL_INTEGRATION, SET_AZIMUTHAL_INTEGRATION_ACTIVE_IMAGE_PATH, SET_AZIMUTHAL_INTEGRATION_ACTIVE_TAB, SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING, SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING_INDEX, SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INFO, SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION, SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION_INDEX, SET_AZIMUTHAL_INTEGRATION_ANALYSIS_X, SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_BINNING, SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_LOWER, SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_UPPER, SET_AZIMUTHAL_INTEGRATION_CAKING_RESULT, SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE, SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE_ROW, SET_AZIMUTHAL_INTEGRATION_IMAGE_COLOR_MAP, SET_AZIMUTHAL_INTEGRATION_IMAGE_COMBINED, SET_AZIMUTHAL_INTEGRATION_IMAGE_LOG, SET_AZIMUTHAL_INTEGRATION_IMAGE_VIDEO, SET_AZIMUTHAL_INTEGRATION_INTEGRATION, SET_AZIMUTHAL_INTEGRATION_INTEGRATION_RESULT, SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_AZIMUTHAL_BINS, SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_PARTITIONS, SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_POINTS, SET_AZIMUTHAL_INTEGRATION_PARTITIONS, SET_AZIMUTHAL_INTEGRATION_RANGE_LOWER, SET_AZIMUTHAL_INTEGRATION_RANGE_UPPER, SET_AZIMUTHAL_INTEGRATION_X_AXIS_UNIT } from "./azimuthalIntegrationActions";

const initialState: AzimuthalIntegrationModel = {
  numberOfPartitions: 4,
  partitions: [
    {name: 'Partition 1', dataFiles: [], calibrationFile: '', darkCurrentFile: '', maskFile: ''},
    {name: 'Partition 2', dataFiles: [], calibrationFile: '', darkCurrentFile: '', maskFile: ''},
    {name: 'Partition 3', dataFiles: [], calibrationFile: '', darkCurrentFile: '', maskFile: ''},
    {name: 'Partition 4', dataFiles: [], calibrationFile: '', darkCurrentFile: '', maskFile: ''},
  ],
  xAxisUnit: '2th_deg',
  range: {
    lower: 0,
    upper: 0,
  },
  numberOfPoints: 0,
  azimuthalRange: {
    lower: 0,
    upper: 360,
  },
  azimuthalBinning: false,
  integration: false,
  numberOfAzimuthalBins: 0,
  analysis: {
    x: [],
    integration: {
    },
    caking: {
    },
    info: {
      bin: 0,
      caking: 0,
      integration: 0,
    }
  },
  integrationResult: {},
  cakingResult: {},
  activeTab: '',
  activeImagePath: '',
  activeCombinedImagePath: {},
  dataViewFile: '',
  imageVideo: [],
  dataViewFileRow: '',
  imageOptions: {
    colorMap: 'VIRIDIS',
    log: true,
  },
};

export default function azimuthalIntegrationReducer(state = initialState, { type, payload }: { type: string; payload: any }): AzimuthalIntegrationModel {
  if (type.includes("AZIMUTHAL_INTEGRATION")) {
    console.debug(`[AZIMUTHAL_INTEGRATION REDUCER] ${type}`, payload);
  }
  switch (type) {
    case SET_AZIMUTHAL_INTEGRATION:
      return {
        ...state,
        ...payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_PARTITIONS:
      return {
        ...state,
        numberOfPartitions: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_PARTITIONS:
      return {
        ...state,
        partitions: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_X_AXIS_UNIT:
      return {
        ...state,
        xAxisUnit: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_RANGE_LOWER:
      return {
        ...state,
        range: {
          ...state.range,
          lower: payload,
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_RANGE_UPPER:
      return {
        ...state,
        range: {
          ...state.range,
          upper: payload,
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_POINTS:
      return {
        ...state,
        numberOfPoints: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_LOWER:
      return {
        ...state,
        azimuthalRange: {
          ...state.azimuthalRange,
          lower: payload,
        }
      };
    case SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_UPPER:
      return {
        ...state,
        azimuthalRange: {
          ...state.azimuthalRange,
          upper: payload,
        }
      };
    case SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_BINNING:
      return {
        ...state,
        azimuthalBinning: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_AZIMUTHAL_BINS:
      return {
        ...state,
        numberOfAzimuthalBins: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_ANALYSIS_X:
      return {
        ...state,
        analysis: {
          ...state.analysis,
          x: payload,
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION:
      return {
        ...state,
        analysis: {
          ...state.analysis,
          integration: {
            ...state.analysis.integration,
            y: payload,
          },
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING:
      return {
        ...state,
        analysis: {
          ...state.analysis,
          caking: payload,
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_ACTIVE_IMAGE_PATH:
      return {
        ...state,
        activeImagePath: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_IMAGE_VIDEO:
      return {
        ...state,
        imageVideo: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING_INDEX:
      return {
        ...state,
        analysis: {
          ...state.analysis,
          caking: {
            ...state.analysis.caking,
            [payload.fileRow]: {
              ...state.analysis.caking[payload.fileRow],
              [payload.bin]: payload.value,
            }
          },
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION_INDEX:
      return {
        ...state,
        analysis: {
          ...state.analysis,
          integration: {
            ...state.analysis.integration,
            [payload.fileRow]: payload.value,
          }
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INFO:
      return {
        ...state,
        analysis: {
          ...state.analysis,
          info: {
            bin: payload.bin,
            integration: payload.integration,
            caking: payload.caking
          }
        }
      }
    case SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE_ROW:
      return {
        ...state,
        dataViewFileRow: payload,
      };
    case RESET_AZIMUTHAL_INTEGRATION:
      return {
        ...initialState,
      };
    case SET_AZIMUTHAL_INTEGRATION_IMAGE_COLOR_MAP:
      return {
        ...state,
        imageOptions: {
          ...state.imageOptions,
          colorMap: payload,
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_IMAGE_LOG:
      return {
        ...state,
        imageOptions: {
          ...state.imageOptions,
          log: payload,
        },
      };
    case SET_AZIMUTHAL_INTEGRATION_IMAGE_COMBINED:
      return {
        ...state,
        activeCombinedImagePath: payload,
      };
    case SET_AZIMUTHAL_INTEGRATION_INTEGRATION:
      return {
        ...state,
        integration: payload,
      }
    case SET_AZIMUTHAL_INTEGRATION_INTEGRATION_RESULT:
      return {
        ...state,
        integrationResult: payload,
      }
    case SET_AZIMUTHAL_INTEGRATION_CAKING_RESULT:
      return {
        ...state,
        cakingResult: payload
      }
    default:
      return state;
  }
}
