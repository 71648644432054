import { apiPeakFitting, requestApi } from "../../api";

export const preProcessObject = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));

  delete newObj.histogram;
  delete newObj.fitting;
  delete newObj.partitionFitting;
  delete newObj.partition;

  return newObj;
}

export const getPeakFittingCallback = async (token, req) => {
  return await requestApi(`callback`, "POST", req, token, {}, apiPeakFitting);
};

export const getPeakFitting = async (token, req) => {
  return await requestApi(`caller`, "POST", req, token, {}, apiPeakFitting);
};
