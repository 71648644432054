import { GlobalState } from "../../store/models";

export const selectPhaseQuantification = (state: GlobalState) => state.phaseQuantificationReducer;

export const selectPhaseQuantificationDataFiles = (state: GlobalState) => state.phaseQuantificationReducer.dataFiles;
export const selectPhaseQuantificationDataViewFile = (state: GlobalState) => state.phaseQuantificationReducer.dataViewFile;
export const selectPhaseQuantificationDataActiveFiles = (state: GlobalState) => state.phaseQuantificationReducer.dataActiveFiles;

export const selectPhaseQuantificationCifFiles = (state: GlobalState) => state.phaseQuantificationReducer.cifFiles;
export const selectPhaseQuantificationCifTemplateFiles = (state: GlobalState) => state.phaseQuantificationReducer.templateCifFiles;
export const selectPhaseQuantificationCifFilesABC = (state: GlobalState) => state.phaseQuantificationReducer.cifFilesABC;

export const selectPhaseQuantificationInstrumentalFile = (state: GlobalState) => state.phaseQuantificationReducer.instrumentalFile;
export const selectPhaseQuantificationInstrumentalTemplateFile = (state: GlobalState) => state.phaseQuantificationReducer.templateInstrumentalFile;
export const selectPhaseQuantificationInstrumentalFileWaveLength = (state: GlobalState) => state.phaseQuantificationReducer.instrumentalFileWaveLength;

export const selectPhaseQuantificationPartitions = (state: GlobalState) => state.phaseQuantificationReducer.partitions;
export const selectPhaseQuantificationMovingPeaks = (state: GlobalState) => state.phaseQuantificationReducer.movingPeaks;

export const selectPhaseQuantificationWavelength = (state: GlobalState) => state.phaseQuantificationReducer.wavelength;

export const selectPhaseQuantificationFitting = (state: GlobalState) => state.phaseQuantificationReducer.fitting;

export const selectPhaseQuantificationPartitionActiveIndex = (state: GlobalState) => state.phaseQuantificationReducer.partition.activeIndex;
export const selectPhaseQuantificationPartitionSelectingPeaks = (state: GlobalState) => state.phaseQuantificationReducer.partition.selectingPeaks;

export const selectPhaseQuantificationHistogram = (state: GlobalState) => state.phaseQuantificationReducer.histogram;

export const selectPhaseQuantificationPartitionFitting = (state: GlobalState) => state.phaseQuantificationReducer.partitionFitting;

export const selectPhaseQuantificationDebyeWallerParameter = (state: GlobalState) => state.phaseQuantificationReducer.debyeWallerParameter;
export const selectPhaseQuantificationBFactor = (state: GlobalState) => state.phaseQuantificationReducer.bFactor;