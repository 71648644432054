import { AllAnalysisDescriptions } from "../../../../data/analysis_descriptions";
import { InputContainerModel, InputFieldModel, PanelModel, ToggleGroupModel } from "../../../../data/models";
import { ProjectFileModel } from "../../../../models/project.model";

export const addPanelValues = (
  panelModel: PanelModel,
  values: any,
  fileSettings: ProjectFileModel
) => {
  if (panelModel.dynamodb_value && fileSettings[panelModel.dynamodb_value]) {
    values[panelModel.dynamodb_value] = fileSettings[panelModel.dynamodb_value];
  } else if (panelModel.dynamodb_value) {
    values[panelModel.dynamodb_value] = "";
  }
  if (panelModel.inputFields) {
    for (const field of panelModel.inputFields) {
      if (field.dynamodb_name && fileSettings[field.dynamodb_name]) {
        values[field.dynamodb_name] = fileSettings[field.dynamodb_name] || "";
      } else if (field.dynamodb_name) {
        values[field.dynamodb_name] = field.default_value || "";
      }
    }
  }
  if (panelModel.toggleGroups) {
    for (const tGroup of panelModel.toggleGroups) {
      if (tGroup.dynamodb_name && fileSettings[tGroup.dynamodb_name]) {
        values[tGroup.dynamodb_name] = fileSettings[tGroup.dynamodb_name];
      }
      for (const input1 of tGroup.toggle1) {
        if (input1.dynamodb_name && fileSettings[input1.dynamodb_name]) {
          values[input1.dynamodb_name] = fileSettings[input1.dynamodb_name];
        } else if (input1.dynamodb_name) {
          values[input1.dynamodb_name] = input1.default_value || "";
        }
      }
      for (const input2 of tGroup.toggle2) {
        if (input2.dynamodb_name && fileSettings[input2.dynamodb_name]) {
          values[input2.dynamodb_name] = fileSettings[input2.dynamodb_name];
        } else if (input2.dynamodb_name) {
          values[input2.dynamodb_name] = input2.default_value || "";
        }
      }
    }
  }
  if (panelModel.panels) {
    for (const panel of panelModel.panels) {
      addPanelValues(panel, values, fileSettings);
    }
  }
};

export const getInitialValues = (
  fileSettings: ProjectFileModel,
  containerModel: InputContainerModel
) => {
  const values = {};
  for (const panel of containerModel.panels) {
    addPanelValues(panel, values, fileSettings);
  }
  return values;
};

const validateToggleGroup = (group: ToggleGroupModel, values: any): boolean => {
  let isValid = true;
  if (values[group.dynamodb_name]) {
    for (var inputField of group.toggle2) {
      const isInvalid =
        inputField.required &&
        inputField.type === "float" &&
        (values[inputField.dynamodb_name] === undefined ||
          values[inputField.dynamodb_name] === "");
      isValid = isValid && !isInvalid;
    }
  } else {
    for (inputField of group.toggle1) {
      const isInvalid =
        inputField.required &&
        inputField.type === "float" &&
        (values[inputField.dynamodb_name] === undefined ||
          values[inputField.dynamodb_name] === "");
      isValid = isValid && !isInvalid;
    }
  }
  return isValid;
};
const validateInputField = (field: InputFieldModel, values: any): boolean => {
  const isInvalid =
    field.editable &&
    field.type === "float" &&
    (values[field.dynamodb_name] === undefined ||
      values[field.dynamodb_name] === "");
  return !isInvalid;
};
const validatePanel = (panel: PanelModel, values: any): boolean => {
  let isValid = true;
  if (panel.conditional && !values[panel.dynamodb_value]) {
    return isValid;
  }
  if (panel.panels) {
    for (const childPanel of panel.panels) {
      isValid = isValid && validatePanel(childPanel, values);
    }
  }
  if (panel.toggleGroups) {
    for (const tGroup of panel.toggleGroups) {
      isValid = isValid && validateToggleGroup(tGroup, values);
    }
  }
  if (panel.inputFields) {
    for (const field of panel.inputFields) {
      if (field.editable) {
        isValid = isValid && validateInputField(field, values);
      }
    }
  }
  return isValid;
};
export const getInputContainerIsValid = (
  values: any,
  model: InputContainerModel
) => {
  let isValid = true;
  for (const p of model.panels) {
    isValid = isValid && validatePanel(p, values);
  }
  return isValid;
};

export const getReportYOptions = (): {
  label: string;
  dynamo_db_name: string;
}[] => {
  // const currentAnalysis = AllAnalysisDescriptions.find(
  //   (x) => x.lambda_name === currentAnalysisType
  // );
  const reportYOptions = [];
  for (let analysis of AllAnalysisDescriptions) {
    for (let panel of analysis.panels) {
      addPanelReportYOptions(panel, reportYOptions);
    }
  }
  return reportYOptions;
};

const addPanelReportYOptions = (
  panel: PanelModel,
  options: {
    label: string;
    dynamo_db_name: string;
    inner_name?: string;
    table_dynamo_db_name?: string;
  }[]
) => {
  const inputFieldsInReports = panel.inputFields
    ?.filter((x) => x.displayedInReports === true)
    ?.map((value) => {
      return {
        label: value.label,
        innerLabels: value.table_columns,
        dynamo_db_name: value.dynamodb_name,
      };
    });
  if (inputFieldsInReports) {
    inputFieldsInReports.forEach((x) => {
      if (x.innerLabels) {
        for (const inner of x.innerLabels) {
          options.push({
            label: `${x.label}_${inner}`,
            dynamo_db_name: `${x.dynamo_db_name}_${inner}`,
            inner_name: inner,
            table_dynamo_db_name: x.dynamo_db_name,
          });
        }
      } else {
        options.push(x);
      }
    });
  }
  if (panel.toggleGroups) {
    for (let tGroup of panel.toggleGroups) {
      addReportYOptionsFromTGroup(tGroup, options);
    }
  }
  if (panel.panels) {
    for (let childPanel of panel.panels) {
      addPanelReportYOptions(childPanel, options);
    }
  }
};

const addReportYOptionsFromTGroup = (
  tGroup: ToggleGroupModel,
  options: { label: string; dynamo_db_name: string }[]
) => {
  const inReportFrom1 = tGroup.toggle1
    .filter((x) => x.displayedInReports === true)
    .map((value) => {
      return {
        label: value.label,
        dynamo_db_name: value.dynamodb_name,
      };
    });
  if (inReportFrom1) {
    inReportFrom1.forEach((x) => {
      options.push(x);
    });
  }
  const inReportFrom2 = tGroup.toggle2
    .filter((x) => x.displayedInReports === true)
    .map((value) => {
      return {
        label: value.label,
        dynamo_db_name: value.dynamodb_name,
      };
    });
  if (inReportFrom2) {
    inReportFrom2.forEach((x) => {
      options.push(x);
    });
  }
};
