import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { GET_DOWNLOAD, SAVE_PROJECT_INFO } from "../../../redux/project/projectActions";
import { selectStressStrain, selectStressStrainCifFiles, selectStressStrainCifFilesABC, selectStressStrainCifTemplateFiles, selectStressStrainDataActiveFiles, selectStressStrainDataFiles, selectStressStrainFitting, selectStressStrainInstrumentalFile, selectStressStrainInstrumentalFileWaveLength, selectStressStrainInstrumentalTemplateFile, selectStressStrainPartitionActiveIndex, selectStressStrainPartitionSelectingPeaks, selectStressStrainPartitions, selectStressStrainPlaneStressCondition, selectStressStrainRotation, selectStressStrainStressPeaks, selectStressStrainWavelength } from "../../../redux/stressStrain/stressStrainSelectors";
import { FileSelection } from "../shared/file-selection/file-selection";
import { GET_STRESS_STRAIN, SET_STRESS_STRAIN_ACTIVE_INDEX, SET_STRESS_STRAIN_CIF_FILES, SET_STRESS_STRAIN_CIF_FILES_ABC, SET_STRESS_STRAIN_CIF_TEMPLATE_FILES, SET_STRESS_STRAIN_DATA_FILES, SET_STRESS_STRAIN_INSTRUMENTAL_FILE, SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE, SET_STRESS_STRAIN_PARTITIONS, SET_STRESS_STRAIN_PLANE_STRESS_CONDITION, SET_STRESS_STRAIN_ROTATION_X, SET_STRESS_STRAIN_ROTATION_Y, SET_STRESS_STRAIN_ROTATION_Z, SET_STRESS_STRAIN_SELECTING_PEAKS, SET_STRESS_STRAIN_STRESS_PEAKS, SET_STRESS_STRAIN_WAVELENGTH } from "../../../redux/stressStrain/stressStrainActions";
import { cifFilesDirectories } from "../../../data/cif_files";
import { instrumentalFilesDirectories } from "../../../data/instrumental_files";
import WavelengthInput from "../shared/wavelength";
import PeakPicking from "../shared/peak-picking";
import { CifFileABC } from "../shared/cifFileABC";
import StressStrainPeaksPicker from "../shared/stress-strain-peaks";
import { selectCurrentProject } from "../../../redux/project/projectSelectors";


const StressStrainInput = ({ dispatch, pipeline=false }: { dispatch: Dispatch, pipeline?: boolean }) => {
	const dataFiles = useSelector(selectStressStrainDataFiles);
	const dataActiveFiles = useSelector(selectStressStrainDataActiveFiles);
	const cifFiles = useSelector(selectStressStrainCifFiles);
	const cifTemplateFiles = useSelector(selectStressStrainCifTemplateFiles);
	const instrumentalFile = useSelector(selectStressStrainInstrumentalFile);
	const instrumentalFileTemplate = useSelector(selectStressStrainInstrumentalTemplateFile);
	const fileWaveLength = useSelector(selectStressStrainInstrumentalFileWaveLength);
	const wavelength = useSelector(selectStressStrainWavelength);
	const fitting = useSelector(selectStressStrainFitting);
	const partitions = useSelector(selectStressStrainPartitions)
	const selectedPartition = useSelector(selectStressStrainPartitionActiveIndex)
	const selectingPeaks = useSelector(selectStressStrainPartitionSelectingPeaks)
	const cifFilesABC = useSelector(selectStressStrainCifFilesABC);
	const strainRotation = useSelector(selectStressStrainRotation);
	const stressPeaks = useSelector(selectStressStrainStressPeaks);
	const planeStressCondition = useSelector(selectStressStrainPlaneStressCondition);
	const currentProject = useSelector(selectCurrentProject);
	const stressStrain = useSelector(selectStressStrain);

	return (
		<div className={"flex flex-col flex-1 bg-primary-800 rounded-md"}>
			<h1 className={`text-white font-bold mb-2 pl-2 text-center ${pipeline ? "text-3xl": "text-xl"}`}>Stress Strain</h1>
			<div className={`p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1 ${pipeline ? "mt-4": ""}`}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Select Files
				</h1>
				{!pipeline && (
					<>
					<FileSelection
						dispatch={dispatch}
						selected={dataFiles}
						setSelected={files => dispatch({ type: SET_STRESS_STRAIN_DATA_FILES, payload: files })}
						title="Select Data File(s)"
						extensions={[".dat"]}
						defaultExtension={".dat"}
						multiple={true}
					/>
					<br />
					</>
				)}
				<FileSelection
					dispatch={dispatch}
					selected={cifFiles}
					selectedTemplates={cifTemplateFiles}
					setSelected={files => dispatch({ type: SET_STRESS_STRAIN_CIF_FILES, payload: files })}
					setSelectedTemplates={files => dispatch({ type: SET_STRESS_STRAIN_CIF_TEMPLATE_FILES, payload: files })}
					title="Select Cif File(s)"
					extensions={[".cif"]}
					defaultExtension={".cif"}
					multiple={true}
					templates={cifFilesDirectories}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={instrumentalFile}
					selectedTemplates={instrumentalFileTemplate}
					setSelected={file => dispatch({ type: SET_STRESS_STRAIN_INSTRUMENTAL_FILE, payload: file })}
					setSelectedTemplates={file => dispatch({ type: SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE, payload: file })}
					title="Select Instrumental File"
					extensions={[".instprm"]}
					defaultExtension=".instprm"
					multiple={false}
					templates={instrumentalFilesDirectories}
				/>
				<br />
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Inputs
				</h1>
				<WavelengthInput
					dispatch={dispatch}
					fileWaveLength={fileWaveLength}
					wavelength={wavelength}
					setWavelength={(wavelength: number) => dispatch({ type: SET_STRESS_STRAIN_WAVELENGTH, payload: wavelength })}
					method={"stressStrain"}
					instrumentalFile={instrumentalFile}
					instrumentalTemplateFile={instrumentalFileTemplate}
				/>
				<CifFileABC
					dispatch={dispatch}
					cifFilesABC={cifFilesABC}
					cifFiles={cifFiles}
					cifTemplateFiles={cifTemplateFiles}
					analysisMethod={"stressStrain"}
					setABCValues={(abcValues) => dispatch({ type: SET_STRESS_STRAIN_CIF_FILES_ABC, payload: abcValues })}
				/>
			</div>
			<PeakPicking
				setPartitions={(partitions) => dispatch({ type: SET_STRESS_STRAIN_PARTITIONS, payload: partitions })}
				partitions={partitions}
				setActiveIndex={(index) => dispatch({ type: SET_STRESS_STRAIN_ACTIVE_INDEX, payload: index })}
				selectedPartition={selectedPartition}
				setSelectingPeaks={(value) => dispatch({ type: SET_STRESS_STRAIN_SELECTING_PEAKS, payload: value })}
				selectPeaks={selectingPeaks}
			/>
			<div className={"flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"p-2 font-bold text-white text-base mb-2"}>
					Stress Strain Inputs
				</h1>
				<StressStrainPeaksPicker
					setStressStrainPeaks={(peaks) => dispatch({ type: SET_STRESS_STRAIN_STRESS_PEAKS, payload: peaks })}
					stressStrainPeaks={stressPeaks}
					partitions={partitions}
					setActiveIndex={(index) => dispatch({ type: SET_STRESS_STRAIN_ACTIVE_INDEX, payload: index })}
					selectedPartition={selectedPartition}
				/>
				<h1 className={"pl-2 font-bold text-white text-base"}>
					Rotation
				</h1>
				<div className={"flex flex-row justify-around"}>
					<div className={"flex flex-col items-center w-1/3 p-2"}>
						<label className={"text-white mb-1"}>X</label>
						<input
							className={"bg-primary-700 p-2 rounded-md border border-solid text-white w-full"}
							type={"number"}
							value={strainRotation.x}
							onChange={(e) => dispatch({ type: SET_STRESS_STRAIN_ROTATION_X, payload: parseFloat(e.target.value) })}
						/>
					</div>
					<div className={"flex flex-col items-center w-1/3 p-2"}>
						<label className={"text-white mb-1"}>Y</label>
						<input
							className={"bg-primary-700 p-2 rounded-md border border-solid text-white w-full"}
							type={"number"}
							value={strainRotation.y}
							onChange={(e) => dispatch({ type: SET_STRESS_STRAIN_ROTATION_Y, payload: parseFloat(e.target.value) })}
						/>
					</div>
					<div className={"flex flex-col items-center w-1/3 p-2"}>
						<label className={"text-white mb-1"}>Z</label>
						<input
							className={"bg-primary-700 p-2 rounded-md border border-solid text-white w-full"}
							type={"number"}
							value={strainRotation.z}
							onChange={(e) => dispatch({ type: SET_STRESS_STRAIN_ROTATION_Z, payload: parseFloat(e.target.value) })}
						/>
					</div>
				</div>
				<h1 className="font-bold text-white text-base mb-2 mt-5 p-2">
					Stress Conditions
				</h1>
				<div className="flex flex-row items-center justify-center mb-3">
					<div className="flex items-center justify-start w-3/4">
						<input
							type="checkbox"
							id="plane-stress-checkbox"
							className="w-4 h-4 bg-white border border-white-300 rounded mr-2"
							onChange={() =>
								dispatch({
									type: SET_STRESS_STRAIN_PLANE_STRESS_CONDITION,
									payload: !planeStressCondition,
								})
							}
							checked={planeStressCondition}
						/>
						<label htmlFor="plane-stress-checkbox" className="flex items-center cursor-pointer text-white">
							Plane Stress
						</label>
					</div>
				</div>
			</div>
			<button
				className={"cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded"}
				onClick={() => {
					if (pipeline) {
						dispatch({ type: SAVE_PROJECT_INFO, payload: {
							...stressStrain,
							userId: currentProject.userId,
							projectId: currentProject.projectId,
							projectType: "stressStrain"
						} });
						return;
					}

					if (!cifFiles.length && !cifTemplateFiles.length) {
						toast.error("Please add at least one cif file");
						return;
					}

					if (!instrumentalFile && !instrumentalFileTemplate) {
						toast.error("Please add a instrumental file");
						return;
					}

					if (!dataActiveFiles.length) {
						toast.error("Please add at least one data file");
						return;
					}

					dispatch({ type: GET_STRESS_STRAIN })
				}}
			>
				{pipeline ? "Save Inputs" : "Save and calculate"}
			</button>
			{!pipeline && (
				<button
					className={`cursor-pointer ${Object.keys(fitting).length ? "text-white" : "text-gray-500 cursor-not-allowed"} font-bold bg-primary-600 m-4 p-4 rounded-md`}
					onClick={() => {
						dispatch({ type: GET_DOWNLOAD, payload: { type: "stressStrain" } })
					}}
				>
					Download Data
				</button>
			)}
		</div>
	);
};

export default StressStrainInput;
