import { PhaseQuantificationModel } from "../../models/analysis.phaseQuantification.model";
import { SET_PHASE_QUANTIFICATION_DATA_FILES, SET_PHASE_QUANTIFICATION_CIF_FILES, SET_PHASE_QUANTIFICATION_INSTRUMENTAL_FILE, SET_PHASE_QUANTIFICATION_WAVELENGTH, SET_PHASE_QUANTIFICATION_CIF_TEMPLATE_FILES, SET_PHASE_QUANTIFICATION_INSTRUMENTAL_TEMPLATE_FILE, SET_PHASE_QUANTIFICATION, SET_PHASE_QUANTIFICATION_INSTRUMENTAL_WAVELENGTH, SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE, SET_PHASE_QUANTIFICATION_RESET, SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES, SET_PHASE_QUANTIFICATION_PARTITIONS, SET_PHASE_QUANTIFICATION_ACTIVE_INDEX, SET_PHASE_QUANTIFICATION_SELECTING_PEAKS, SET_PHASE_QUANTIFICATION_HISTOGRAM, SET_PHASE_QUANTIFICATION_PARAMETERS, SET_PHASE_QUANTIFICATION_FITTING, SET_PHASE_QUANTIFICATION_DEBYE_WALLER_PARAMETER, SET_PHASE_QUANTIFICATION_B_FACTOR, SET_PHASE_QUANTIFICATION_CIF_FILES_ABC } from "./phaseQuantificationActions";

const initialState: PhaseQuantificationModel = {
  dataFiles: [],
  dataViewFile: "",
  dataActiveFiles: [],
  cifFiles: [],
  cifFilesABC: {},
  templateCifFiles: [],
  instrumentalFile: "",
  templateInstrumentalFile: "",
  instrumentalFileWaveLength: 0,
  partitions: [{
    name: "1",
    rangeHistory: [0, 0],
    rangeLower: {
      x: 0,
      y: 0
    },
    rangeHigher: {
      x: 0,
      y: 0
    },
    peaks: [],
  }],
  partition: {
    activeIndex: 0,
    selectingPeaks: false,
  },
  movingPeaks: false,
  wavelength: 0,
  debyeWallerParameter: {},
  partitionFitting: {},
  fitting: {},
  histogram: {
    data: {
      x: [],
      y: []
    },
    phases: {}
  },
  bFactor: [],
};

export default function phaseQuantificationReducer(state = initialState, { type, payload }: { type: string; payload: any }): PhaseQuantificationModel {
  switch (type) {
    case SET_PHASE_QUANTIFICATION_DATA_FILES:
      return {
        ...state,
        dataFiles: payload,
      }
    case SET_PHASE_QUANTIFICATION_CIF_FILES:
      return {
        ...state,
        cifFiles: payload,
      }
    case SET_PHASE_QUANTIFICATION_INSTRUMENTAL_FILE:
      return {
        ...state,
        instrumentalFile: payload,
      }
    case SET_PHASE_QUANTIFICATION_WAVELENGTH:
      return {
        ...state,
        wavelength: payload,
      }
    case SET_PHASE_QUANTIFICATION_CIF_TEMPLATE_FILES:
      return {
        ...state,
        templateCifFiles: payload,
      }
    case SET_PHASE_QUANTIFICATION_INSTRUMENTAL_TEMPLATE_FILE:
      return {
        ...state,
        templateInstrumentalFile: payload,
      }
    case SET_PHASE_QUANTIFICATION:
      return {
        ...payload
      }
    case SET_PHASE_QUANTIFICATION_INSTRUMENTAL_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload,
      }
    case SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      }
    case SET_PHASE_QUANTIFICATION_RESET:
      return {
        ...initialState
      }
    case SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload
      }
    case SET_PHASE_QUANTIFICATION_PARTITIONS:
      return {
        ...state,
        partitions: payload
      }
    case SET_PHASE_QUANTIFICATION_ACTIVE_INDEX:
      return {
        ...state,
        partition: {
          ...state.partition,
          activeIndex: payload
        }
      }
    case SET_PHASE_QUANTIFICATION_SELECTING_PEAKS:
      return {
        ...state,
        partition: {
          ...state.partition,
          selectingPeaks: payload
        }
      }
    case SET_PHASE_QUANTIFICATION_HISTOGRAM:
      return {
        ...state,
        histogram: payload
      }
    case SET_PHASE_QUANTIFICATION_DEBYE_WALLER_PARAMETER:
      return {
        ...state,
        debyeWallerParameter: {
          ...payload
        }
      }
    case SET_PHASE_QUANTIFICATION_FITTING:
      return {
        ...state,
        partitionFitting: {
          [payload.file]: payload.data
        }
      }
    case SET_PHASE_QUANTIFICATION_PARAMETERS:
      return {
        ...state,
        fitting: {
          ...payload
        }
      }
    case SET_PHASE_QUANTIFICATION_B_FACTOR:
      return {
        ...state,
        bFactor: payload
      }
    case SET_PHASE_QUANTIFICATION_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload
        }
      }
    default:
      return state;
  }
}
