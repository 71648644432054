import { useState, useEffect, useMemo } from "react";
import { faArrowLeft, faFile, faFolder, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch } from "redux";
import { DELETE_PROJECT_FILE, GET_PROJECT_FILES } from "../../redux/project/projectActions";
import { selectFileFetchingInProgress } from "../../redux/general/generalSelectors";
import { useSelector } from "react-redux";
import { selectCurrentProject, selectCurrentProjectFiles } from "../../redux/project/projectSelectors";
import Loader from "react-loader-spinner";
import UploadFiles from "./upload-table";
import { FileSystemS } from "../analysis-settings/shared/file-selection/file-system";
import React from "react";


export default function ManageFiles({ dispatch, type }: { dispatch: Dispatch, type: string }) {
  const files = useSelector(selectCurrentProjectFiles)
  const loading = useSelector(selectFileFetchingInProgress)
  const currentProject = useSelector(selectCurrentProject);

  const [currentPath, setCurrentPath] = useState<string>("/");
  const [fileSystem, setFileSystem] = useState<FileSystemS>(new FileSystemS());
  const [fileSearch, setFileSearch] = useState<string>("");

  useEffect(() => {
    dispatch({ type: GET_PROJECT_FILES });
  }, [dispatch]);

  const handleFileSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileSearch(event.target.value);
  }

  useEffect(() => {
    let newFileSystem = new FileSystemS();

    files.forEach(filePath => {
      const modifiedFileName = filePath.fileRef.replace(`${currentProject.projectId}/`, "");
      newFileSystem.addFile(modifiedFileName);
    });
    setFileSystem(newFileSystem);
  }, [currentProject.projectId, files]);

  const currentDirectory = useMemo(() => {
    return fileSystem.navigateTo(currentPath);
  }, [currentPath, fileSystem]);


  const renderDirectories = () => {
    let directoriesFiltered;

    try {
      directoriesFiltered = currentDirectory.subdirectories.filter(dir => dir.name.includes(fileSearch));
    } catch (e) {
      setCurrentPath("/");
      return;
    }

    const filesFiltered = currentDirectory.files.filter(file => file.name.includes(fileSearch));
    const maxLength = directoriesFiltered.length + filesFiltered.length;
    let currentIndex = 0;

    return (
      <>
      {directoriesFiltered.map((dir) => {
        currentIndex++;

        return (
          <React.Fragment key={dir.path}>
            <div className={`flex items-center space-x-2 cursor-pointer p-2`}>
              <div
                className="flex flex-row items-center w-full"
                onClick={() => setCurrentPath(dir.path)}
              >
                <FontAwesomeIcon
                  icon={faFolder}
                  size="lg"
                  color={"white"}
                />
                <h4 className="ml-2 text-white">
                  {dir.name}
                </h4>
              </div>
              <div className="flex flex-row items-center justify-end flex-grow">
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  color={"red"}
                  onClick={() => {
                    dispatch({ type: DELETE_PROJECT_FILE, payload: dir.path })
                  }}
                />
              </div>
            </div>
            {currentIndex < maxLength && <div style={{borderTop: "1px dotted #c7c7c7", width: "100%"}}></div>}
          </React.Fragment>
        );
      })}
      {filesFiltered.filter(file => file.name.includes(fileSearch)).map((file) => {
        currentIndex++;

        return (
          <React.Fragment key={file.path}>
            <div className={`flex items-center space-x-2 cursor-pointer p-2`}>
              <FontAwesomeIcon
                icon={faFile}
                size="lg"
                color={"white"}
              />
              <h4 className="text-white">{file.name}</h4>
              <div className="flex flex-row items-center justify-end flex-grow">
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  color={"red"}
                  onClick={() => {
                    dispatch({ type: DELETE_PROJECT_FILE, payload: file.path })
                  }}
                />
              </div>
            </div>
            {currentIndex < maxLength && <div style={{borderTop: "1px dotted #c7c7c7", width: "100%"}}></div>}
          </React.Fragment>
        );
      })}
      </>
    )
  }

  const goBack = () => {
    const parts = currentPath.split("/").filter(p => p.length > 0);
    parts.pop();

    setCurrentPath("/" + parts.join("/"));
  }

  return (
    <div className={"flex rounded-md bg-primary-800 flex-1 xl:flex-col"}>
      <div className="w-3/5 border-r border-dotted border-gray-300">
        <UploadFiles dispatch={dispatch} type={type} />
      </div>
      <div className="w-2/5">
        <div className="px-2 pt-2 flex justify-between">
          <div>
            <button
              onClick={() => dispatch({ type: GET_PROJECT_FILES })}
              className="bg-primary-600 text-white px-4 py-2 rounded-md"
            >
              <FontAwesomeIcon icon={faSync} color="white" /> Reload Files
            </button>
          </div>
          <div>
            <button onClick={goBack} className="bg-primary-600 text-white px-4 py-2 rounded-md">
              <FontAwesomeIcon icon={faArrowLeft} color="white" />
            </button>
          </div>
        </div>
        <div className="flex-1">
          <div className="px-2 pt-1">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="mt-1 relative rounded-md shadow-sm border-gray-500 border">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-white sm:text-sm">🔍</span>
              </div>
              <input
                type="text"
                name="search"
                id="search-2"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm text-white py-2 border-gray-500 rounded-md bg-primary-600"
                placeholder="Find by name"
                value={fileSearch}
                onChange={handleFileSearch}
              />
            </div>
          </div>
        </div>
        <div className="text-white px-4 py-2 rounded-md">
          Current Path: {currentPath || '/'}
        </div>
        <div 
          className="overflow-y-auto" 
          style={{ maxHeight: "calc(100vh - 275px)", position: 'relative' }}
        >
          {loading ?
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: 'calc((100vh + 400px) / 2)' 
            }}>
              <Loader type="Oval" color="#68d391" height={50} width={50} />
            </div>
          :
          <div className={`mt-2 p-1 ${files.length > 0 ? "bg-primary-700" : ""} `}>
            {renderDirectories()}
          </div>
          }
        </div>
      </div>
    </div>
  );
}
