import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { Dispatch } from "redux";
import { selectPhaseQuantificationDebyeWallerParameter, selectPhaseQuantificationFitting } from "../../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { useSelector } from "react-redux";
import { compareKeysV2 } from "../../../../helpers/general";

const PhaseQuantificationReport = ({ dispatch }: { dispatch: Dispatch }) => {
  const debyeWellerParameters = useSelector(selectPhaseQuantificationDebyeWallerParameter);
  const fitting = useSelector(selectPhaseQuantificationFitting);

  const [debyeWeller, setDebyeWeller] = useState<any>({});
  const [peakCenterData, setPeakCenterData] = useState<any>({});
  const [peakFwhmData, setPeakFwhmData] = useState<any>({});

  useEffect(() => {
    let parameters = {};
    let dataFiles = [];
    let stdDevMap = {};
    let debyeWallerGraphs = {};
    
    let data = Object.keys(debyeWellerParameters).map((dataFile) => {
      let obj = { dataFile: dataFile.split("-")[1].split(".")[0], parameters: {}, std_dev: null };
      Object.keys(debyeWellerParameters[dataFile]).forEach((parameter) => {
        if (parameter === "std_dev") {
          obj.std_dev = debyeWellerParameters[dataFile][parameter];
        } else {
          if (!parameters[parameter]) {
            parameters[parameter] = [];
          }
          obj.parameters[parameter] = debyeWellerParameters[dataFile][parameter];
        }
      });
      return obj;
    });
  
    data.sort((a, b) => compareKeysV2(a.dataFile, b.dataFile));
  
    data.forEach((item) => {
      dataFiles.push(item.dataFile);
      Object.keys(item.parameters).forEach((parameter) => {
        parameters[parameter].push(item.parameters[parameter]);
      });
      stdDevMap[item.dataFile] = item.std_dev;
    });
  
    Object.keys(parameters).forEach((parameter) => {
      const stdDevs = parameters[parameter].map((_, index) => stdDevMap[dataFiles[index]]);
  
      debyeWallerGraphs[parameter] = [
        {
          x: dataFiles,
          y: parameters[parameter],
          type: "scatter",
          mode: "lines+markers",
          name: parameter,
          line: {
            color: "#00BFFF",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF1493",
            size: 8,
          },
          error_y: {
            type: "data",
            array: stdDevs,
            visible: true,
            color: "#FF1493",
          },
        },
      ];
    });
  
    setDebyeWeller(debyeWallerGraphs);
  }, [debyeWellerParameters]);

  useEffect(() => {
    let peakParameters = {};
    let dataFiles = [];
    let peakCenterGraphs = {};
    let peakFwhmGraphs = {};
  
    let data = Object.keys(fitting).map((dataFile) => {
      let peaks = Object.keys(fitting[dataFile])
        .sort()
        .map((peak) => {
          const peakData = fitting[dataFile][peak];
          return {
            peak: peak,
            center: peakData.center,
            center_std_dev: peakData.center_std_dev,
            fwhm: peakData.fwhm,
            fwhm_std_dev: peakData.fwhm_std_dev,
          };
        });
      return {
        dataFile: dataFile.split("-")[1].split(".")[0],
        peaks: peaks,
      };
    });
  
    data.sort((a, b) => compareKeysV2(a.dataFile, b.dataFile));
  
    data.forEach((item) => {
      dataFiles.push(item.dataFile);
      item.peaks.forEach((peakData) => {
        const peak = peakData.peak;
        if (!peakParameters[peak]) {
          peakParameters[peak] = {
            center: [],
            center_std_dev: [],
            fwhm: [],
            fwhm_std_dev: [],
          };
        }
  
        peakParameters[peak].center.push(peakData.center);
        peakParameters[peak].center_std_dev.push(peakData.center_std_dev);
        peakParameters[peak].fwhm.push(peakData.fwhm);
        peakParameters[peak].fwhm_std_dev.push(peakData.fwhm_std_dev);
      });
    });
  
    Object.keys(peakParameters).forEach((peak) => {
      peakCenterGraphs[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].center,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Center`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].center_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];
  
      peakFwhmGraphs[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].fwhm,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} FWHM`,
          line: {
            color: "#32CD32",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#008000",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].fwhm_std_dev,
            visible: true,
            color: "#008000",
          },
        },
      ];
    });
  
    setPeakCenterData(peakCenterGraphs);
    setPeakFwhmData(peakFwhmGraphs);
  }, [fitting]);

  return (
    <div className="bg-primary-600 rounded-md w-full h-full p-4 overflow-y-scroll px-10">
      <div className="flex justify-between items-center text-white text-4xl font-bold mt-16">
        <h1>Volume Fraction</h1>
      </div>
      <div className="flex flex-wrap mt-5">
        {Object.keys(debyeWeller).map((parameter, index) => (
          <div key={parameter} className="w-1/2 px-2 mb-4 justify-center">
            <Plot
              data={debyeWeller[parameter]}
              layout={{
                autosize: true,
                paper_bgcolor: "rgba(0,0,0,0)",
                plot_bgcolor: "rgba(0,0,0,0)",
                legend: {
                  orientation: "h",
                  font: {
                    family: "Arial, sans-serif",
                    size: 14,
                    color: "#EDEDED",
                  },
                  x: 0.5,
                  y: -0.2,
                  xanchor: "center",
                },
                xaxis: {
                  color: "#EDEDED",
                  gridcolor: "#5A5A5A",
                },
                yaxis: {
                  color: "#EDEDED",
                  gridcolor: "#5A5A5A",
                  title: {
                    text: "Fraction (%)",
                    font: {
                      family: "Arial, sans-serif",
                      size: 18,
                      color: "#EDEDED",
                    },
                  },
                },
                modebar: {
                  orientation: "h",
                  bgcolor: "#333335",
                  color: "#EDEDED",
                  activecolor: "#FF1493",
                },
                hovermode: "closest",
                margin: {
                  l: 80,
                  r: 30,
                  b: 50,
                  t: 30,
                },
                title: {
                  text: parameter,
                  font: {
                    family: "Arial, sans-serif",
                    size: 22,
                    color: "#EDEDED",
                  },
                  x: 0.5,
                  xanchor: "center",
                },
              }}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center text-white text-4xl font-bold mt-16">
        <h1>Peak Data - Center</h1>
      </div>
      <div className="flex flex-wrap mt-5">
        {Object.keys(peakCenterData).map((peak, index) => (
          <div key={peak} className="w-1/2 px-2 mb-4 justify-center">
            <Plot
              data={peakCenterData[peak]}
              layout={{
                autosize: true,
                paper_bgcolor: "rgba(0,0,0,0)",
                plot_bgcolor: "rgba(0,0,0,0)",
                legend: {
                  orientation: "h",
                  font: {
                    family: "Arial, sans-serif",
                    size: 14,
                    color: "#EDEDED",
                  },
                  x: 0.5,
                  y: -0.2,
                  xanchor: "center",
                },
                xaxis: {
                  color: "#EDEDED",
                  gridcolor: "#5A5A5A",
                },
                yaxis: {
                  color: "#EDEDED",
                  gridcolor: "#5A5A5A",
                  title: {
                    text: "Center Value",
                    font: {
                      family: "Arial, sans-serif",
                      size: 18,
                      color: "#EDEDED",
                    },
                  },
                },
                modebar: {
                  orientation: "h",
                  bgcolor: "#333335",
                  color: "#EDEDED",
                  activecolor: "#FF1493",
                },
                hovermode: "closest",
                margin: {
                  l: 80,
                  r: 30,
                  b: 50,
                  t: 30,
                },
                title: {
                  text: peak,
                  font: {
                    family: "Arial, sans-serif",
                    size: 22,
                    color: "#EDEDED",
                  },
                  x: 0.5,
                  xanchor: "center",
                },
              }}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center text-white text-4xl font-bold mt-16">
        Peak Data - FWHM
      </div>
      <div className="flex flex-wrap mt-5">
        {Object.keys(peakFwhmData).map((peak, index) => (
          <div key={peak} className="w-1/2 px-2 mb-4 justify-center">
            <Plot
              data={peakFwhmData[peak]}
              layout={{
                autosize: true,
                paper_bgcolor: "rgba(0,0,0,0)",
                plot_bgcolor: "rgba(0,0,0,0)",
                legend: {
                  orientation: "h",
                  font: {
                    family: "Arial, sans-serif",
                    size: 14,
                    color: "#EDEDED",
                  },
                  x: 0.5,
                  y: -0.2,
                  xanchor: "center",
                },
                xaxis: {
                  color: "#EDEDED",
                  gridcolor: "#5A5A5A",
                },
                yaxis: {
                  color: "#EDEDED",
                  gridcolor: "#5A5A5A",
                  title: {
                    text: "FWHM Value",
                    font: {
                      family: "Arial, sans-serif",
                      size: 18,
                      color: "#EDEDED",
                    },
                  },
                },
                modebar: {
                  orientation: "h",
                  bgcolor: "#333335",
                  color: "#EDEDED",
                  activecolor: "#FF1493",
                },
                hovermode: "closest",
                margin: {
                  l: 80,
                  r: 30,
                  b: 50,
                  t: 30,
                },
                title: {
                  text: peak,
                  font: {
                    family: "Arial, sans-serif",
                    size: 22,
                    color: "#EDEDED",
                  },
                  x: 0.5,
                  xanchor: "center",
                },
              }}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhaseQuantificationReport;
