import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Divider } from "../../shared/divider";

export interface PartitionConstraint {
  centerMin: number;
  centerMax: number;
  sigmaMin: number;
  sigmaMax: number;
  sigma: number;
  heightMin: number;
  heightMax: number;
  height: number;
}

const DropDownConstraints = ({ handleChange }: { handleChange: (constraints: PartitionConstraint) => void }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const absoluteRef = useRef<HTMLDivElement>(null);

  const handleHover = () => {
    const targetElement = targetRef.current;
    const targetRect = targetElement?.getBoundingClientRect();

    const absoluteElement = absoluteRef.current;
    if (targetRect && absoluteElement) {
      absoluteElement.style.position = 'absolute';
      if (targetRect.left - 240 < 0) {
        absoluteElement.style.left = `0px`;
      } else {
        absoluteElement.style.left = `${targetRect.left - 200}px`;
      }
    }
  };

  const labelToKeyMapping = {
    "Center Min": "centerMin",
    "Center Max": "centerMax",
    "Sigma Min": "sigmaMin",
    "Sigma Max": "sigmaMax",
    "Height Min": "heightMin",
    "Height Max": "heightMax",
  };

  const initialInputState: PartitionConstraint = {
    centerMin: undefined,
    centerMax: undefined,
    sigmaMin: undefined,
    sigmaMax: undefined,
    sigma: undefined,
    heightMin: undefined,
    heightMax: undefined,
    height: undefined
  };

  const [inputs, setInputs] = useState(initialInputState);

  const handleInput = (name: keyof PartitionConstraint, value: any) => {
    const updatedInputs = {
      ...inputs,
      [name]: value,
    };
    setInputs(updatedInputs);
    handleChange(updatedInputs);
  };

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (document.activeElement instanceof HTMLInputElement && 
          document.activeElement.type === 'number' && 
          document.activeElement.classList.contains('noscrollInputSen')) {
        document.activeElement.blur();
      }
    };

    document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return(
    <div className={`w-24 parentd`} onMouseEnter={handleHover}>
      <div ref={targetRef}>
        <button
          type="button"
          className={`inline-flex w-full center-text rounded-md border-primary-500 p-1 bg-primary-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          
        >
          <div className="flex flex-row items-center justify-center w-full">
            <h1 className="text-white text-center truncate">
              Enter...
            </h1>
            <div className="ml-2">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </button>
      </div>
      <div className={`childss w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} ref={absoluteRef}>
        <div
          className={`py-1 overflow-auto`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          style={{ maxHeight: "350px"}}
        >
          {Object.entries(labelToKeyMapping).map(([label, key], index) => (
            <div key={index}>
              <div className="flex flex-col px-4 pb-1 mt-1 text-gray-700 font-bold text-center">
                {label}
              </div>
              <div className="flex flex-col px-4 mb-2">
              <input
                type="number"
                className="border rounded w-full py-2 px-3 text-gray-900 noscrollInputSen"
                value={inputs[key as keyof PartitionConstraint] ? inputs[key as keyof PartitionConstraint] : ''}
                onChange={(e) => handleInput(key as keyof PartitionConstraint, e.target.value)}
              />
              </div>
              {(key === 'centerMax' || key === 'sigmaMax') && (
                <div className="my-4">
                  <Divider color="gray" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
		</div>
	);
};

export default DropDownConstraints;
