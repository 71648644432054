// SETTERS
export const SET_PHASE_QUANTIFICATION_DATA_FILES = 'SET_PHASE_QUANTIFICATION_DATA_FILES';
export const SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE = 'SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE';
export const SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES = 'SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES';

export const SET_PHASE_QUANTIFICATION_CIF_FILES = 'SET_PHASE_QUANTIFICATION_CIF_FILES';
export const SET_PHASE_QUANTIFICATION_CIF_TEMPLATE_FILES = 'SET_PHASE_QUANTIFICATION_CIF_TEMPLATE_FILES';
export const SET_PHASE_QUANTIFICATION_CIF_FILES_ABC = 'SET_PHASE_QUANTIFICATION_CIF_FILES_ABC';

export const SET_PHASE_QUANTIFICATION_INSTRUMENTAL_FILE = 'SET_PHASE_QUANTIFICATION_INSTRUMENTAL_FILE';
export const SET_PHASE_QUANTIFICATION_INSTRUMENTAL_TEMPLATE_FILE = 'SET_PHASE_QUANTIFICATION_INSTRUMENTAL_TEMPLATE_FILE';

export const SET_PHASE_QUANTIFICATION_INSTRUMENTAL_WAVELENGTH = 'SET_PHASE_QUANTIFICATION_INSTRUMENTAL_WAVELENGTH';

export const SET_PHASE_QUANTIFICATION_WAVELENGTH = 'SET_PHASE_QUANTIFICATION_WAVELENGTH';

export const SET_PHASE_QUANTIFICATION = 'SET_PHASE_QUANTIFICATION';
export const SET_PHASE_QUANTIFICATION_RESET = 'SET_PHASE_QUANTIFICATION_RESET';

export const SET_PHASE_QUANTIFICATION_PARTITIONS = 'SET_PHASE_QUANTIFICATION_PARTITIONS';
export const SET_PHASE_QUANTIFICATION_ACTIVE_INDEX = 'SET_PHASE_QUANTIFICATION_ACTIVE_INDEX';
export const SET_PHASE_QUANTIFICATION_SELECTING_PEAKS = 'SET_PHASE_QUANTIFICATION_SELECTING_PEAKS';

export const SET_PHASE_QUANTIFICATION_HISTOGRAM = 'SET_PHASE_QUANTIFICATION_HISTOGRAM';
export const SET_PHASE_QUANTIFICATION_PARAMETERS = 'SET_PHASE_QUANTIFICATION_PARAMETERS';
export const SET_PHASE_QUANTIFICATION_FITTING = 'SET_PHASE_QUANTIFICATION_FITTING';
export const SET_PHASE_QUANTIFICATION_B_FACTOR = 'SET_PHASE_QUANTIFICATION_B_FACTOR';
export const SET_PHASE_QUANTIFICATION_DEBYE_WALLER_PARAMETER = 'SET_PHASE_QUANTIFICATION_DEBYE_WALLER_PARAMETER';

// GETTERS
export const GET_PHASE_QUANTIFICATION_FITTING = 'GET_PHASE_QUANTIFICATION_FITTING';
export const GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS = 'GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS';
export const GET_PHASE_QUANTIFICATION_CALLBACK_FITTED = 'GET_PHASE_QUANTIFICATION_CALLBACK_FITTED';
