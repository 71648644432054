export const SET_DATA_CALIBRATION = "SET_DATA_CALIBRATION";
export const SET_DATA_CALIBRATION_DATA_FILE = "SET_DATA_CALIBRATION_DATA_FILE";
export const SET_DATA_CALIBRATION_META_FILE = "SET_DATA_CALIBRATION_META_FILE";
export const SET_DATA_CALIBRATION_THRESHOLD_MASK = "SET_DATA_CALIBRATION_THRESHOLD_MASK";
export const SET_DATA_CALIBRATION_CLICKED_COORDINATES = "SET_DATA_CALIBRATION_CLICKED_COORDINATES";
export const SET_DATA_CALIBRATION_CALIBRANT_TYPE = "SET_DATA_CALIBRATION_CALIBRANT_TYPE";
export const SET_DATA_CALIBRATION_WAVELENGTH = "SET_DATA_CALIBRATION_WAVELENGTH";
export const SET_DATA_CALIBRATION_RING_PICKING = "SET_DATA_CALIBRATION_RING_PICKING";
export const SET_DATA_CALIBRATION_NUMBER_OF_DATA_POINTS = "SET_DATA_CALIBRATION_NUMBER_OF_DATA_POINTS";
export const SET_DATA_CALIBRATION_RADIAL_RANGE = "SET_DATA_CALIBRATION_RADIAL_RANGE";
export const SET_DATA_CALIBRATION_RADIAL_RANGE_LOWER = "SET_DATA_CALIBRATION_RADIAL_RANGE_LOWER";
export const SET_DATA_CALIBRATION_RADIAL_RANGE_UPPER = "SET_DATA_CALIBRATION_RADIAL_RANGE_UPPER";
export const SET_DATA_CALIBRATION_AZIMUTHAL_RANGE = "SET_DATA_CALIBRATION_AZIMUTHAL_RANGE";
export const SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_LOWER = "SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_LOWER";
export const SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_UPPER = "SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_UPPER";
export const SET_DATA_CALIBRATION_POLARIZATION_RANGE_LOWER = "SET_DATA_CALIBRATION_POLARIZATION_RANGE_LOWER";
export const SET_DATA_CALIBRATION_POLARIZATION_RANGE_UPPER = "SET_DATA_CALIBRATION_POLARIZATION_RANGE_UPPER";
export const SET_DATA_CALIBRATION_POLARIZATION_FACTOR = "SET_DATA_CALIBRATION_POLARIZATION_FACTOR";
export const SET_DATA_CALIBRATION_UNIT = "SET_DATA_CALIBRATION_UNIT";
export const SET_DATA_CALIBRATION_DETECTOR_TYPE = "SET_DATA_CALIBRATION_DETECTOR_TYPE";
export const SET_DATA_CALIBRATION_DETECTOR_SIZE = "SET_DATA_CALIBRATION_DETECTOR_SIZE";
export const SET_DATA_CALIBRATION_DETECTOR_SIZE_X = "SET_DATA_CALIBRATION_DETECTOR_SIZE_X";
export const SET_DATA_CALIBRATION_DETECTOR_SIZE_Y = "SET_DATA_CALIBRATION_DETECTOR_SIZE_Y";
export const SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE = "SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE";
export const SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_X = "SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_X";
export const SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_Y = "SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_Y";
export const SET_DATA_CALIBRATION_MASK = "SET_DATA_CALIBRATION_MASK";
export const SET_DATA_CALIBRATION_MASK_BELOW = "SET_DATA_CALIBRATION_MASK_BELOW";
export const SET_DATA_CALIBRATION_MASK_ABOVE = "SET_DATA_CALIBRATION_MASK_ABOVE";
export const SET_DATA_CALIBRATION_DRAWING_MODE = "SET_DATA_CALIBRATION_DRAWING_MODE";
export const SET_DATA_CALIBRATION_DRAWINGS = "SET_DATA_CALIBRATION_DRAWINGS";
export const SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX = "SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX";
export const SET_DATA_CALIBRATION_DRAWING_VISIBLE = "SET_DATA_CALIBRATION_DRAWING_VISIBLE";
export const SET_DATA_CALIBRATION_PEAK_THRESHOLD = "SET_DATA_CALIBRATION_PEAK_THRESHOLD";
export const SET_DATA_CALIBRATION_RING_PICKING_RING = "SET_DATA_CALIBRATION_RING_PICKING_RING";
export const SET_DATA_CALIBRATION_RING_PICKING_RING_MODE = "SET_DATA_CALIBRATION_RING_PICKING_RING_MODE";
export const SET_DATA_CALIBRATION_IMAGE_OPTIONS_COLOR_MAP = "SET_DATA_CALIBRATION_COLOR_MAP";
export const SET_DATA_CALIBRATION_IMAGE_OPTIONS_LOG = "SET_DATA_CALIBRATION_LOG";
export const SET_DATA_CALIBRATION_IMAGE_OPTIONS = "SET_DATA_CALIBRATION_IMAGE_OPTIONS";
export const SET_DATA_CALIBRATION_ACTIVE_IMAGE_PATH = "SET_DATA_CALIBRATION_ACTIVE_IMAGE_PATH";
export const SET_DATA_CALIBRATION_DRAWINGS_OBJECT = "SET_DATA_CALIBRATION_DRAWINGS_OBJECT";
export const SET_DATA_CALIBRATION_RING_PICKING_OBJECT = "SET_DATA_CALIBRATION_RING_PICKING_OBJECT";
export const SET_DATA_CALIBRATION_IMAGE_MASK = "SET_DATA_CALIBRATION_IMAGE_MASK";
export const SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS = "SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS";
export const SET_DATA_CALIBRATION_IMAGE_GEOMETRY = "SET_DATA_CALIBRATION_IMAGE_GEOMETRY";
export const SET_DATA_CALIBRATION_CALIBRATION_FILE = "SET_DATA_CALIBRATION_CALIBRATION_FILE";
export const SET_DATA_CALIBRATION_ANALYSIS = "SET_DATA_CALIBRATION_ANALYSIS";
export const SET_DATA_CALIBRATION_CUSTOM_DETECTOR = "SET_DATA_CALIBRATION_CUSTOM_DETECTOR";
export const SET_DATA_CALIBRATION_MASKING_FILE = "SET_DATA_CALIBRATION_MASKING_FILE";

export const GET_DATA_CALIBRATION_META_FILE_INFO = "GET_DATA_CALIBRATION_META_FILE_INFO";
export const GET_DATA_CALIBRATION_IMAGE = "GET_DATA_CALIBRATION_IMAGE";
export const GET_DATA_CALIBRATION_RING_PICKING = "GET_DATA_CALIBRATION_RING_PICKING";
export const GET_DATA_CALIBRATION_RING_PICKING_COMPILE = "GET_DATA_CALIBRATION_RING_PICKING_COMPILE";
export const GET_DATA_CALIBRATION_MASKING = "GET_DATA_CALIBRATION_MASKING";
export const GET_DATA_CALIBRATION_INTEGRATION = "GET_DATA_CALIBRATION_INTEGRATION";
export const GET_DATA_CALIBRATION_CALLBACK = "GET_DATA_CALIBRATION_CALLBACK";
export const GET_DATA_CALIBRATION_DETECTOR_SIZE = "GET_DATA_CALIBRATION_DETECTOR_SIZE";
export const GET_DATA_CALIBRATION_DOWNLOAD_MASK = "GET_DATA_CALIBRATION_DOWNLOAD_MASK";

export const APPEND_DATA_CALIBRATION_DRAWINGS = "APPEND_DATA_CALIBRATION_DRAWINGS";

export const UPDATE_DATA_CALIBRATION_DRAWINGS = "UPDATE_DATA_CALIBRATION_DRAWINGS";
export const UPDATE_DATA_CALIBRATION_RING_PICKING = "UPDATE_DATA_CALIBRATION_RING_PICKING";

export const REMOVE_DATA_CALIBRATION_DRAWINGS = "REMOVE_DATA_CALIBRATION_DRAWINGS";
export const REMOVE_DATA_CALIBRATION_RING_PICKING = "REMOVE_DATA_CALIBRATION_RING_PICKING";