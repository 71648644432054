import { faCheckSquare, faChevronDown, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { DropDownOptions, Option, SubOptions } from "../../../data/models";
import { Divider } from "../../shared/divider";

interface DropDownProps {
  DropDownOptions: DropDownOptions;
  title: string;
  setSelected: any;
  selected: string[] | number[] | string | number;
  multiple: boolean;
  width?: string;
  padding?: string;
  childWidth?: string;
  height?: string;
  closeOnClick?: boolean;
  searchEnb?: boolean;
  displayHeaders?: boolean;
  truncateText?: boolean;
}

const DropDown = ({
  DropDownOptions,
  title,
  setSelected,
  selected,
  multiple,
  width = "w-3/4",
  padding = "p-4",
  childWidth = "",
  height = "",
  closeOnClick = false,
  searchEnb = false,
  displayHeaders = true,
  truncateText = false
}: DropDownProps) => {
  const dropdownMultipleRef = useRef<HTMLDivElement>(null);
  const [dropdownMultipleExpand, setDropdownMultipleExpand] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (dropdownMultipleRef.current && !dropdownMultipleRef.current.contains(event.target as Node)) {
        setDropdownMultipleExpand(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleOptionClick = (value: string | number, subOptions: SubOptions) => {
    if (multiple) {
      if (Array.isArray(selected)) {
        const selectedCopy: any = [...selected];
        if (selectedCopy.includes(value)) {
          setSelected(selectedCopy.filter((item) => item !== value));
        } else {
          setSelected([...selected, value]);
        }
      } else {
        setSelected([value]);
      }
    } else {
      if (selected === value) {
        setSelected("");
      } else {
        setSelected(value);
      }
    }

    if (closeOnClick && !subOptions) {
      setDropdownMultipleExpand(false)
    }
  };

  return(
    <div className={`relative ${width}`} ref={dropdownMultipleRef}>
      <div>
        <button
          type="button"
          className={`inline-flex items-center w-full justify-center rounded-md border-primary-500 ${padding} bg-primary-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setDropdownMultipleExpand(!dropdownMultipleExpand)}
        >
          <h1 className={`text-white text-center ${truncateText ? "truncate flex-grow" : "flex-grow"}`}>
            {title}
          </h1>
          <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
        </button>
      </div>
      {dropdownMultipleExpand && (
        <div className={`origin-top-right absolute left-0 mt-2 ${childWidth ? childWidth : "w-full"} rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
          style={{ maxHeight: height, overflowY: "auto" }}
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {DropDownOptions.options.map((element: Option) => (
              <div key={element.id}>
                {displayHeaders &&
                  <div
                    className="flex flex-col px-4 py-2 text-gray-700 font-bold text-center"
                    role="menuitem"
                  >
                    {element.label}
                  </div>
                }

                {searchEnb &&
                  <div className="flex flex-row justify-center items-center px-4 py-2">
                    <input
                      className="w-full border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none text-gray-700"
                      type="search"
                      name="search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value.toLowerCase())}
                    />
                  </div>
                }

                {element.values.filter((value) => {
                  if (searchEnb && typeof value.value === "string") {
                    if (value.value.toLowerCase().includes(search)) {
                      return value;
                    } else {
                      return null;
                    }
                  } else {
                    return value;
                  }
                }).map((element) => {
                  const selectedCopy: any = selected;

                  return (
                  <div key={element.value}>
                    <div
                      
                      className={`flex flex-col px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer ${multiple ? selectedCopy.includes(element.value) : selected === element.value ? 'bg-gray-100' : ''}`}
                      role="menuitem"
                      onClick={() => handleOptionClick(element.value, element.subOptions)}
                    >
                      <div className="flex flex-row items-center">
                        <div className="flex justify-center items-center">
                          {multiple ? (
                            selectedCopy.includes(element.value) ? (
                              <FontAwesomeIcon icon={faCheckSquare}
                                size="lg"
                                color="blue"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faSquare}
                                size="lg"
                                color="gray"
                              />
                            )
                          ) : (
                            selected === element.value ? (
                              <FontAwesomeIcon icon={faCheckSquare}
                                size="lg"
                                color="blue"
                              />
                            ) : (
                              <FontAwesomeIcon icon={faSquare}
                                size="lg"
                                color="gray"
                              />
                            )
                          )}
                        </div>
                        <div className="ml-3">{element.label}</div>
                      </div>
                    </div>
                    {element.subOptions && selected === element.value &&
                      <div
                        className={`flex flex-col px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer`}
                        role="menuitem"
                      >
                        {element.subOptions.values.map((element: SubOptions, index) => 
                        <div key={`dropDownSub-${index}`}>
                          {element}
                        </div>
                      )}
                    </div>
                  }
                  </div>
                )})}
                <Divider color="gray" />
              </div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default DropDown;