import { Dispatch } from "redux";
import { InputContainer } from "./analysis-input-fields/input-container";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../store/models";
import { AllAnalysisDescriptions } from "../../../data/analysis_descriptions";
import { ProjectFileModel } from "../../../models/project.model";
import { useState } from "react";
import { SAVE_AND_CALCULATE, SET_CURRENT_CHART_DATA } from "../../../redux/project/projectActions";
import { getChartSeriesDataPlotly } from "../../../chart-utils";

const selectCurrentFile = (state: GlobalState) =>
  state.projectReducer.currentFile;

const AnalysisInput = ({
  type,
  dispatch,
}: {
  type: string;
  dispatch: Dispatch;
}) => {
  const analysisModel = AllAnalysisDescriptions.find(
    (x) => x.lambda_name === type
  );
  const currentFile = useSelector(selectCurrentFile);
  const [isInputPanelValid, setIsInputPanelValid] = useState(true);
  const onFileSettingsChanged = (changedFile: ProjectFileModel) => {};
  const onIsValidChanged = (isValid: boolean) => {
    setIsInputPanelValid(isValid);
  };
  const isButtonDisabled = !isInputPanelValid || !currentFile;
  const saveAndCalculate = () => {
    dispatch({ type: SAVE_AND_CALCULATE });

    const chartData = getChartSeriesDataPlotly(currentFile);
    dispatch({ type: SET_CURRENT_CHART_DATA, payload: chartData });
  };
  return (
    <div
      className={
        "flex flex-col bg-primary-800 rounded-md w-3/12 xl:w-full max-h-80 overflow-y-auto"
      }
    >
      <div
        className={"p-2 flex flex-col border-b border-primary-900 border-solid"}
      >
        {analysisModel && currentFile && (
          <InputContainer
            validate={true}
            fileSettings={currentFile}
            containerModel={analysisModel}
            onIsValidChange={onIsValidChanged}
            onFileSettingsChange={onFileSettingsChanged}
          />
        )}
      </div>
      <button
        className={`${
          isButtonDisabled
            ? "cursor-not-allowed text-primary-800 font-bold"
            : "cursor-pointer text-primary-400 font-bold"
        }
          bg-primary-600 m-4 p-4 rounded-md`}
        onClick={saveAndCalculate}
        disabled={isButtonDisabled}
      >
        Save and calculate
      </button>
    </div>
  );
};

export default AnalysisInput;
