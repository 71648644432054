import { ROUTES } from "../constants/routes";
import { Dispatch } from "redux";
import { useHistory } from "react-router";
import { connect, useSelector } from "react-redux";
import { SETUP_NEW_PROJECT, SET_ANALYSIS_TYPE } from "../redux/project/projectActions";
import { LOG_OUT } from "../redux/general/generalActions";
import { selectUserName } from "../redux/project/projectSelectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

const Header = ({ dispatch }: { dispatch: Dispatch; }) => {
  const currentUser = useSelector(selectUserName);

  const history = useHistory();

  return (
    <>
      <header className={"bg-primary-800 w-full p-4 px-16 flex justify-between items-center md:px-4"}>
        <h1
          className={"text-white font-bold cursor-pointer"}
          data-testid={"header"}
          onClick={() => {
            history.push(ROUTES.HOME)
            dispatch({ type: SETUP_NEW_PROJECT });
            dispatch({ type: SET_ANALYSIS_TYPE, payload: "" });
          }}
        >
          SCATTERIN
        </h1>
        <div className={"flex items-center"}>
          {currentUser && (
            <button
              onClick={() => dispatch({ type: LOG_OUT })}
              className={"text-white ml-12 text-sm bg-primary-600 py-2 px-5 rounded-md cursor-pointer"}
            >
              Logout
              <FontAwesomeIcon icon={faSignOut} style={{ marginLeft: "10px" }}/>
            </button>
          )}
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  authReducer: state.authReducer,
});

export default connect(mapStateToProps)(Header);
