import { Dispatch } from "redux";
import LeBailGraph from "./le-bail-graph";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LeBailInput from "./le-bail-input";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { compareKeysV2 } from "../../../helpers/general";
import { GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK, SET_LE_BAIL_DATA_ACTIVE_FILES, SET_LE_BAIL_DATA_VIEW_FILE } from "../../../redux/leBail/leBailActions";
import { selectLeBailCifFiles, selectLeBailCifFilesABC, selectLeBailCifTemplateFiles, selectLeBailDataActiveFiles, selectLeBailDataFiles, selectLeBailDataViewFile, selectLeBailInstrumentalFile, selectLeBailInstrumentalTemplateFile, selectLeBailRefinedHistogram, selectLeBailWavelength } from "../../../redux/leBail/leBailSelectors";
import ProgressBar from "../shared/progress-bar";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import { FilesMapping } from "./helpers";
import { useInstanceStatusPolling } from "../shared/instanceTrigger";


const LeBailAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const refineHistogram = useSelector(selectLeBailRefinedHistogram);
  const dataFiles = useSelector(selectLeBailDataFiles)
  const dataViewFile = useSelector(selectLeBailDataViewFile)
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectLeBailDataActiveFiles)
  const cifFiles = useSelector(selectLeBailCifFiles);
  const cifTemplateFiles = useSelector(selectLeBailCifTemplateFiles);
  const instrumentalFile = useSelector(selectLeBailInstrumentalFile);
  const instrumentalTemplateFile = useSelector(selectLeBailInstrumentalTemplateFile);
  const wavelength = useSelector(selectLeBailWavelength);
  const cifFilesABC = useSelector(selectLeBailCifFilesABC);
  
  const [filesInformation, setFilesInformation] = useState<any>([]);

  useInstanceStatusPolling(instanceId, "leBail", dispatch);

  useEffect(() => {
		dispatch({ type: GET_METHOD_DATA, payload: {type: "leBail"} });
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "leBail"} })
	}, [dispatch]);

  useEffect(() => {
    dispatch({ type: GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK })
  }, [dispatch, dataViewFile]);

  useEffect(() => {
    dispatch({ type: GET_PHASES, payload: { type: "leBail"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, cifFilesABC]);

  useEffect(() => {
    setFilesInformation(FilesMapping(dataFiles, refineHistogram.phases))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFiles, dispatch, refineHistogram])

  useEffect(() => {
    if (dataFiles.length > 0 && !dataViewFile) {
      const dataFilesMod = dataFiles.sort((a, b) => compareKeysV2(a, b))
      dispatch({ type: SET_LE_BAIL_DATA_VIEW_FILE, payload: dataFilesMod[0] });
    }
  }, [dispatch, dataFiles, dataViewFile])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Refinement"}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6 xl:w-full overflow-y-auto resize-x hover:resize min-w-min"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid"}>
          <LeBailInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <LeBailGraph dispatch={dispatch} />
        <AnalysisFilesTable
          data={filesInformation}
          dataViewFile={dataViewFile}
          setDataViewFile={(file) => dispatch({type: SET_LE_BAIL_DATA_VIEW_FILE, payload: file})}
          setDataActiveFiles={(files) => dispatch({type: SET_LE_BAIL_DATA_ACTIVE_FILES, payload: files})}
          dataActiveFiles={dataActiveFiles}
        />
      </div>
    </div>
  );
};

export default LeBailAnalysis;
