import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { GET_DOWNLOAD, SAVE_PROJECT_INFO } from "../../../redux/project/projectActions";
import { FileSelection } from "../shared/file-selection/file-selection";
import { cifFilesDirectories } from "../../../data/cif_files";
import { instrumentalFilesDirectories } from "../../../data/instrumental_files";
import WavelengthInput from "../shared/wavelength";
import { ChangeEvent, useEffect } from "react";
import PeakPicking from "../shared/peak-picking";
import { selectPhaseQuantificationDataFiles, selectPhaseQuantificationDataActiveFiles, selectPhaseQuantificationCifFiles, selectPhaseQuantificationCifTemplateFiles, selectPhaseQuantificationInstrumentalFile, selectPhaseQuantificationInstrumentalTemplateFile, selectPhaseQuantificationWavelength, selectPhaseQuantificationFitting, selectPhaseQuantificationPartitions, selectPhaseQuantificationPartitionActiveIndex, selectPhaseQuantificationPartitionSelectingPeaks, selectPhaseQuantificationInstrumentalFileWaveLength, selectPhaseQuantificationDebyeWallerParameter, selectPhaseQuantificationBFactor, selectPhaseQuantification, selectPhaseQuantificationCifFilesABC } from "../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { SET_PHASE_QUANTIFICATION_DATA_FILES, SET_PHASE_QUANTIFICATION_CIF_FILES, SET_PHASE_QUANTIFICATION_CIF_TEMPLATE_FILES, SET_PHASE_QUANTIFICATION_INSTRUMENTAL_FILE, SET_PHASE_QUANTIFICATION_INSTRUMENTAL_TEMPLATE_FILE, SET_PHASE_QUANTIFICATION_WAVELENGTH, SET_PHASE_QUANTIFICATION_PARTITIONS, SET_PHASE_QUANTIFICATION_ACTIVE_INDEX, SET_PHASE_QUANTIFICATION_SELECTING_PEAKS, GET_PHASE_QUANTIFICATION_FITTING, SET_PHASE_QUANTIFICATION_B_FACTOR, SET_PHASE_QUANTIFICATION_CIF_FILES_ABC } from "../../../redux/phaseQuantification/phaseQuantificationActions";
import { FormatName } from "../../shared/utils";
import { selectCurrentProject } from "../../../redux/project/projectSelectors";
import { CifFileABC } from "../shared/cifFileABC";


const PhaseQuantificationInput = ({ dispatch, pipeline=false }: { dispatch: Dispatch, pipeline?: boolean }) => {
	const dataFiles = useSelector(selectPhaseQuantificationDataFiles);
	const dataActiveFiles = useSelector(selectPhaseQuantificationDataActiveFiles);
	const cifFiles = useSelector(selectPhaseQuantificationCifFiles);
	const cifTemplateFiles = useSelector(selectPhaseQuantificationCifTemplateFiles);
	const instrumentalFile = useSelector(selectPhaseQuantificationInstrumentalFile);
	const instrumentalFileTemplate = useSelector(selectPhaseQuantificationInstrumentalTemplateFile);
	const fileWaveLength = useSelector(selectPhaseQuantificationInstrumentalFileWaveLength);
	const wavelength = useSelector(selectPhaseQuantificationWavelength);
	const fitting = useSelector(selectPhaseQuantificationFitting);
	const partitions = useSelector(selectPhaseQuantificationPartitions)
	const selectedPartition = useSelector(selectPhaseQuantificationPartitionActiveIndex)
	const selectingPeaks = useSelector(selectPhaseQuantificationPartitionSelectingPeaks)
	const bFactor = useSelector(selectPhaseQuantificationBFactor);
	const debyeWeller = useSelector(selectPhaseQuantificationDebyeWallerParameter);
	const phaseQuantification = useSelector(selectPhaseQuantification);
	const currentProject = useSelector(selectCurrentProject);
	const cifFilesABC = useSelector(selectPhaseQuantificationCifFilesABC);

	useEffect(() => {
		const cifFilesCurrent = [
			...cifFiles,
		]

		const newBFactor = bFactor.filter((x) => {
			const cifFile = cifFilesCurrent.find(fileName => fileName === x.name);
			if (cifFile) {
				return true;
			} else {
				return false;
			}
		});

		cifFilesCurrent.forEach((cifFile: string) => {
			const existingBFactor = newBFactor.find((x) => x.name === cifFile);
			if (!existingBFactor) {
			  newBFactor.push({ name: cifFile, value: 0.35 });
			}
		});

		cifTemplateFiles.forEach((name: string) => {
			const existingBFactor = newBFactor.find((x) => x.name === name);
			if (!existingBFactor) {
				newBFactor.push({ name: name, value: 0.35 });
			}
		})
		dispatch({type: SET_PHASE_QUANTIFICATION_B_FACTOR, payload: newBFactor})
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cifFiles, cifTemplateFiles]);

	const handleBFactorChange = (factor, event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		const updatedBFactor = bFactor.map((bf) =>
			bf.name === factor.name ? { ...bf, value } : bf
		);

		dispatch({type: SET_PHASE_QUANTIFICATION_B_FACTOR, payload: updatedBFactor})
	}

	return (
		<div className={"flex flex-col flex-1 pt-2 bg-primary-800 rounded-md overflow-x-hidden"}>
			<h1 className={`text-white font-bold mb-2 pl-2 text-center ${pipeline ? "text-3xl": "text-xl"}`}>Phase Quantification</h1>
			<div className={`p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1 ${pipeline ? "mt-4": ""}`}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Select Files
				</h1>
				{!pipeline && (
					<>
					<FileSelection
						dispatch={dispatch}
						selected={dataFiles}
						setSelected={files => dispatch({ type: SET_PHASE_QUANTIFICATION_DATA_FILES, payload: files })}
						title="Select Data File(s)"
						extensions={[".dat"]}
						defaultExtension={".dat"}
						multiple={true}
					/>
					<br />
					</>
				)}
				<FileSelection
					dispatch={dispatch}
					selected={cifFiles}
					selectedTemplates={cifTemplateFiles}
					setSelected={files => dispatch({ type: SET_PHASE_QUANTIFICATION_CIF_FILES, payload: files })}
					setSelectedTemplates={files => dispatch({ type: SET_PHASE_QUANTIFICATION_CIF_TEMPLATE_FILES, payload: files })}
					title="Select Cif File(s)"
					extensions={[".cif"]}
					defaultExtension={".cif"}
					multiple={true}
					templates={cifFilesDirectories}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={instrumentalFile}
					selectedTemplates={instrumentalFileTemplate}
					setSelected={file => dispatch({ type: SET_PHASE_QUANTIFICATION_INSTRUMENTAL_FILE, payload: file })}
					setSelectedTemplates={file => dispatch({ type: SET_PHASE_QUANTIFICATION_INSTRUMENTAL_TEMPLATE_FILE, payload: file })}
					title="Select Instrumental File"
					extensions={[".instprm"]}
					defaultExtension=".instprm"
					multiple={false}
					templates={instrumentalFilesDirectories}
				/>
				<br />
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Inputs
				</h1>
				<WavelengthInput
					dispatch={dispatch}
					fileWaveLength={fileWaveLength}
					wavelength={wavelength}
					setWavelength={(wavelength: number) => dispatch({ type: SET_PHASE_QUANTIFICATION_WAVELENGTH, payload: wavelength })}
					method="phaseQuantification"
					instrumentalFile={instrumentalFile}
					instrumentalTemplateFile={instrumentalFileTemplate}
				/>
				<CifFileABC
					dispatch={dispatch}
					cifFilesABC={cifFilesABC}
					cifFiles={cifFiles}
					cifTemplateFiles={cifTemplateFiles}
					analysisMethod={"phaseQuantification"}
					setABCValues={(abcValues) => dispatch({ type: SET_PHASE_QUANTIFICATION_CIF_FILES_ABC, payload: abcValues })}
				/>
			</div>
			<PeakPicking
				setPartitions={(partitions) => dispatch({ type: SET_PHASE_QUANTIFICATION_PARTITIONS, payload: partitions })}
				partitions={partitions}
				setActiveIndex={(index) => dispatch({ type: SET_PHASE_QUANTIFICATION_ACTIVE_INDEX, payload: index })}
				selectedPartition={selectedPartition}
				setSelectingPeaks={(value) => dispatch({ type: SET_PHASE_QUANTIFICATION_SELECTING_PEAKS, payload: value })}
				selectPeaks={selectingPeaks}
			/>
			{bFactor?.length > 0 && (
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1 text-white"}>
					<h1 className={"font-bold text-white text-base mb-2"}>
						Debye-Waller factor
					</h1>
					<div className={"flex flex-col items-center"}>
						{bFactor?.map((factor) => (
							<div className={"p-2 flex flex-col w-3/4 items-center"} key={factor.name}>
								<p>{FormatName(factor.name, false)}</p>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.0001"
									onChange={(event) => handleBFactorChange(factor, event)}
									value={factor.value ? factor.value : ""}
								/>
							</div>
						))}
					</div>
				</div>
			)}
			<button
				className={"cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded"}
				onClick={() => {
					if (pipeline) {
						dispatch({ type: SAVE_PROJECT_INFO, payload: {
							...phaseQuantification,
							userId: currentProject.userId,
							projectId: currentProject.projectId,
							projectType: "phaseQuantification"
						} });
						return;
					}

					if (!cifFiles.length && !cifTemplateFiles.length) {
						toast.error("Please add at least one cif file");
						return;
					}

					if (!instrumentalFile && !instrumentalFileTemplate) {
						toast.error("Please add a instrumental file");
						return;
					}

					if (!dataActiveFiles.length) {
						toast.error("Please add at least one data file");
						return;
					}

					dispatch({ type: GET_PHASE_QUANTIFICATION_FITTING })
				}}
			>
				{pipeline ? "Save Inputs" : "Save and calculate"}
			</button>
			{!pipeline && (
				<button
					className={`cursor-pointer ${Object.keys(fitting).length && Object.keys(debyeWeller).length ? "text-white" : "text-gray-500 cursor-not-allowed"} font-bold bg-primary-600 m-4 p-4 rounded-md`}
					onClick={() => {
						dispatch({ type: GET_DOWNLOAD, payload: { type: "phaseQuantification" } })
					}}
				>
					Download Data
				</button>
			)}
		</div>
	);
};

export default PhaseQuantificationInput;
