import Plot from "react-plotly.js";

const MainChart = ({
  chartData,
  onChange,
  axisType,
}: {
  chartData: any;
  onChange: any;
  axisType: string;
}) => {
  if (chartData && chartData.length !== 0) {
    return (
      <>
        <div className={"flex"}>
          <input
            type="checkbox"
            checked={axisType === "log"}
            onChange={onChange}
          ></input>
          <label className={"text-white select-none"}>Logarithmic chart</label>
        </div>

        {axisType === "log" && (
          <div className={"flex justify-around"}>
            <Plot
              data={chartData}
              layout={{
                autosize: true,
                paper_bgcolor: "rgba(0,0,0,0)",
                plot_bgcolor: "rgba(0,0,0,0)",
                legend: {
                  orientation: "v",
                  font: {
                    family: "sans-serif",
                    size: 12,
                    color: "white",
                  },
                },
                xaxis: {
                  color: "white",
                  gridcolor: "grey",
                  type: "log",
                },
                yaxis: {
                  color: "white",
                  gridcolor: "grey",
                  type: "log",
                },
                modebar: {
                  orientation: "h",
                  bgcolor: "#434446",
                  color: "white",
                },
              }}
              config={{
                modeBarButtonsToRemove: ["lasso2d"],
              }}
              useResizeHandler={true}
              style={{ width: "80%", height: "100%" }}
            />
          </div>
        )}
        {axisType === "linear" && (
          <div className={"flex justify-around"}>
            <Plot
              data={chartData}
              layout={{
                autosize: true,
                paper_bgcolor: "rgba(0,0,0,0)",
                plot_bgcolor: "rgba(0,0,0,0)",
                legend: {
                  orientation: "v",
                  font: {
                    family: "sans-serif",
                    size: 12,
                    color: "white",
                  },
                },
                xaxis: {
                  color: "white",
                  gridcolor: "grey",
                },
                yaxis: {
                  color: "white",
                  gridcolor: "grey",
                },
                modebar: {
                  orientation: "h",
                  bgcolor: "#434446",
                  color: "white",
                },
              }}
              config={{
                modeBarButtonsToRemove: ["lasso2d"],
              }}
              useResizeHandler={true}
              style={{ width: "80%", height: "100%" }}
            />
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {/* <h1 className={"text-primary-600 text-5xl items-center"}>Loading...</h1> */}
      </>
    );
  }
};

export default MainChart;