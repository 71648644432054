import { DropDownOptions } from "./models";

export const componentFunction: DropDownOptions = {
	id: "component_function",
	label: "Peak Function",
	options: [
		{
			id: "components",
			label: "Peak Function",
			values: [
				{ label: "PV", value: "pv" },
				{ label: "Gaussian", value: "g" },
				{ label: "Lorentzian", value: "l" },
				{ label: "Pearson7", value: "p" },
			],
		},
	],
};

export const backgroundFunctions: DropDownOptions = {
	id: "background_functions",
	label: "Background Functions",
	options: [
		{
			id: "background_functions",
			label: "Background Functions",
			values: [
				{ label: "Polynomial", value: "polynomial" },
				{ label: "Linear", value: "linear" },
				{ label: "Spline", value: "spline" },
			],
		},
	],
};