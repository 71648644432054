import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import StressStrainInput from "./stress-strain-input";
import { compareKeysV2 } from "../../../helpers/general";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { selectStressStrainDataFiles, selectStressStrainDataViewFile, selectStressStrainDataActiveFiles, selectStressStrainCifFiles, selectStressStrainCifFilesABC, selectStressStrainCifTemplateFiles, selectStressStrainInstrumentalFile, selectStressStrainInstrumentalTemplateFile, selectStressStrainWavelength, selectStressStrainFitting, selectStressStrainStressFitting, selectStressStrainHistogram } from "../../../redux/stressStrain/stressStrainSelectors";
import StressStrainGraph from "./stress-strain-graph";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import { useInstanceStatusPolling } from "../shared/instanceTrigger";
import { FileMapping } from "./helpers";
import { GET_STRESS_STRAIN_CALLBACK, SET_STRESS_STRAIN_DATA_VIEW_FILE, SET_STRESS_STRAIN_DATA_ACTIVE_FILES, GET_STRESS_STRAIN_CALLBACK_FITTING } from "../../../redux/stressStrain/stressStrainActions";


const StressStrainAnalysis = ({ dispatch, pipeline=false }: { dispatch: Dispatch, pipeline?: boolean }) => {
  const dataViewFile = useSelector(selectStressStrainDataViewFile);
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectStressStrainDataActiveFiles)
  const cifFiles = useSelector(selectStressStrainCifFiles);
  const cifTemplateFiles = useSelector(selectStressStrainCifTemplateFiles);
  const instrumentalTemplateFile = useSelector(selectStressStrainInstrumentalTemplateFile);
  const wavelength = useSelector(selectStressStrainWavelength);
  const dataFiles = useSelector(selectStressStrainDataFiles);
  const cifFilesABC = useSelector(selectStressStrainCifFilesABC);
  const instrumentalFile = useSelector(selectStressStrainInstrumentalFile);
  const stressStrainFitting = useSelector(selectStressStrainFitting);
  const stressStrainFittingParameters = useSelector(selectStressStrainStressFitting);
  const histogram = useSelector(selectStressStrainHistogram);

  const [filesInformation, setFilesInformation] = useState<any>([]);
  const [lastFile, setLastFile] = useState<string>("");

  useInstanceStatusPolling(instanceId, "stressStrain", dispatch);

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_METHOD_DATA, payload: {type: "stressStrain"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "stressStrain"} })

  }, [dispatch, pipeline])

  useEffect(() => {
    if (!dataViewFile || pipeline) return;

    dispatch({ type: GET_STRESS_STRAIN_CALLBACK_FITTING })
  }, [dispatch, dataViewFile, pipeline])

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_STRESS_STRAIN_CALLBACK })
  }, [dispatch, dataViewFile, pipeline])

  useEffect(() => {
    if (lastFile === dataViewFile && histogram.data.x.length !== 0) return;
    if (!pipeline) return;

    if((cifFiles.length === 0 && cifTemplateFiles.length === 0) || (!instrumentalFile && !instrumentalTemplateFile) || !dataViewFile) return;

    setLastFile(dataViewFile)
  
    dispatch({ type: GET_PHASES, payload: { type: "stressStrain"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, lastFile, histogram.data.x.length, pipeline]);

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_PHASES, payload: { type: "stressStrain"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, cifFilesABC, pipeline]);

  useEffect(() => {
    setFilesInformation(FileMapping(dataFiles, stressStrainFitting, stressStrainFittingParameters))
  }, [dataFiles, stressStrainFitting, stressStrainFittingParameters])

  useEffect(() => {
    if (dataFiles.length > 0 && !dataViewFile) {
      const dataFilesObj = dataFiles.sort((a, b) => compareKeysV2(a, b))
      dispatch({ type: SET_STRESS_STRAIN_DATA_VIEW_FILE, payload: dataFilesObj[0] });
    }
  }, [dataFiles, dataViewFile, dispatch])

  return (
    <div className={"flex h-full gap-4 flex-1 xl:flex-col flex-grow"} style={{ width: "calc(100vw - 30px)"}}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Stress Strain Analysis..."}
        />
      }
      <div className={"flex flex-col bg-primary-800 h-full rounded-md w-2/6"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <StressStrainInput dispatch={dispatch} pipeline={pipeline} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
          <StressStrainGraph dispatch={dispatch} />
          <AnalysisFilesTable
            data={filesInformation}
            dataViewFile={dataViewFile}
            setDataViewFile={(file) => dispatch({type: SET_STRESS_STRAIN_DATA_VIEW_FILE, payload: file })}
            setDataActiveFiles={(files) => dispatch({type: SET_STRESS_STRAIN_DATA_ACTIVE_FILES, payload: files})}
            dataActiveFiles={dataActiveFiles}
            pipeline={pipeline}
          />
        </div>
    </div>
  );
};

export default StressStrainAnalysis;
