import { useState } from "react";
import { AnalysisTypes } from "../../../data/analysis_descriptions";

export default function SelectAnalysis({ setAnalysis, analysisSelected }: { setAnalysis: (analysis: string) => void, analysisSelected: string }) {
  const [activeTab, setActiveTab] = useState("small" as "small" | "wide" | "pipeline");

  return (
    <div className={"flex-2 p-4"}>
      <div className={`flex justify-between gap-2 mb-2 sm:flex-col`}>
        <div
          className={`${
            activeTab === "small" ? "bg-primary-600" : "bg-primary-700"
          } flex-1 text-white text-sm text-center font-bold p-4  rounded-md cursor-pointer`}
          onClick={() => setActiveTab("small")}
        >
          Small angle scattering applications
        </div>
        <div
          className={`${
            activeTab === "wide" ? "bg-primary-600" : "bg-primary-700"
          } flex-1 text-white text-sm text-center font-bold p-4  rounded-md cursor-pointer`}
          onClick={() => setActiveTab("wide")}
        >
          Wide angle scattering applications
        </div>
        <div
          className={`${
            activeTab === "pipeline" ? "bg-primary-600" : "bg-primary-700"
          } flex-1 text-white text-sm text-center font-bold p-4  rounded-md cursor-pointer`}
          onClick={() => setActiveTab("pipeline")}
        >
          Pipeline for Continuous Automated Analysis
        </div>
      </div>
      <div className={"grid grid-cols-4 gap-2 sm:grid-cols-2"}>
        {AnalysisTypes.filter((x) => x.type === activeTab).map((analysis, index) => (
          <div
            key={index}
            onClick={() => {
              setAnalysis(analysis.lambda_name);
            }}
            className={
              `${
                analysisSelected === analysis.lambda_name ? "bg-primary-600" : "bg-primary-700"
              } flex flex-col h-40 p-4 rounded-md justify-between cursor-pointer`
            }
          >
          <h1 className={
            `${
              analysisSelected === analysis.lambda_name ? "text-white" : "text-primary-600"
              } text-5xl`
            }
          >
            {index + 1}
          </h1>
          <p className={
            `${
              analysisSelected === analysis.lambda_name ? "text-white" : "text-primary-500"
              }`
            }
          >{
          analysis.analysis_name}
          </p>
          </div>
        ))}
      </div>
    </div>
  );
}
