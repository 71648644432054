import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../store/models";
import MainChart from "./main-chart";

const selectSelectedX = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectSelectedX;

const AnalysisChart = ({ chartData }: { chartData: any }) => {
  const [axisType, setAxisType] = useState("log");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAxisType = !e.target.checked ? "linear" : "log";
    setAxisType(newAxisType);
  };

  const selectedX = useSelector(selectSelectedX);

  useEffect(() => {
    if (!selectedX) {
      setAxisType("log");
    }
  }, [selectedX]);

  return (
    <MainChart chartData={chartData} onChange={onChange} axisType={axisType} />
  );
};

export default AnalysisChart;
