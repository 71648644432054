import { DropDownOptions } from "./models";

export const AzimuthalIntegrationXAxis: DropDownOptions = {
	id: "xAxis",
	label: "x-axis unit",
	options: [
		{
			id: "xAxis",
			label: "x-axis unit",
			values: [
				{ label: "2θ", value: "2th_deg" },
        { label: "Q(Å^-1)", value: "q_A^-1" },
        { label: "Q(nm^-1)", value: "q_nm^-1" },
      ],
    },
	],
};

export const AzimuthalIntegrationImageColorMap: DropDownOptions = {
	id: "imageColorMap",
	label: "Image color map",
	options: [
		{
			id: "imageColorMap",
			label: "Image color map",
			values: [
				{ label: "Equalize", value: "EQUALIZE" },
				{ label: "Greater or equal", value: "GREATER_EQUAL" },
				{ label: "Greater", value: "GREATER" },
				{ label: "Less or equal", value: "LESS_EQUAL" },
				{ label: "Less", value: "LESS" },
				{ label: "Not equal", value: "NOT_EQUAL" },
				{ label: "Autumn", value: "AUTUMN" },
				{ label: "Bone", value: "BONE" },
				{ label: "Cividis", value: "CIVIDIS" },
				{ label: "Cool", value: "COOL" },
				{ label: "Deep green", value: "DEEPGREEN" },
				{ label: "Hot", value: "HOT" },
				{ label: "HSV", value: "HSV" },
				{ label: "Inferno", value: "INFERNO" },
				{ label: "Jet", value: "JET" },
				{ label: "Magma", value: "MAGMA" },
				{ label: "Ocean", value: "OCEAN" },
				{ label: "Parula", value: "PARULA" },
				{ label: "Pink", value: "PINK" },
				{ label: "Plasma", value: "PLASMA" },
				{ label: "Rainbow", value: "RAINBOW" },
				{ label: "Spring", value: "SPRING" },
				{ label: "Summer", value: "SUMMER" },
				{ label: "Turbo", value: "TURBO" },
				{ label: "Twilight", value: "TWILIGHT" },
				{ label: "Twilight shifted", value: "TWILIGHT_SHIFTED" },
				{ label: "Viridis", value: "VIRIDIS" },
				{ label: "Winter", value: "WINTER" },
			],
		},
	],
};
				