import { Dispatch } from "redux"
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import DropDownFileSystem from "./drop-down";

interface Props {
  dispatch: Dispatch;
  selected?: string[] | string;
  selectedTemplates?: string[] | string;
  setSelected?: (selected: string[] | string) => void;
  setSelectedTemplates?: (selected: string[] | string) => void
  title: string;
  extensions: string[];
  multiple: boolean;
  defaultExtension?: string;
  templates?: { label: string, value: string, directory: string }[];
  multipleFileSelection?: {
    multipleTitle: string;
    key: string;
    selected: any[];
    setSelected: (selected: any[]) => void;
  }
}

export const FileSelection = ({ dispatch, selected, setSelected, title, extensions, multiple, defaultExtension = "", templates = [], selectedTemplates, setSelectedTemplates = (selected: string | string[]) => {}, multipleFileSelection } : Props) => {
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);

  const toggleHeight = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    let newItems = []
    if (Array.isArray(selected)) {
      newItems = [...selected]
    }

    if (Array.isArray(selectedTemplates)) {
      newItems = [...selectedTemplates, ...newItems]
    }

    setData(newItems)
  }, [selected, selectedTemplates])

  return (
    <>
    <div className={"flex items-center justify-between flex-col mb-2"}>
      <DropDownFileSystem
        dispatch={dispatch}
        title={title}
        extensions={extensions}
        multiple={multiple}
        singleSelected={selected}
        singleSetSelected={setSelected}
        defaultExtension={defaultExtension}
        templates={templates}
        selectedTemplates={selectedTemplates}
        setSelectedTemplates={setSelectedTemplates}
        multipleFileSelection={multipleFileSelection}
      />
      {multiple && (
        <>
        <div
          className={`relative overflow-y-auto transition-height duration-500 border-b border-dotted border-r border-l rounded-b-xl w-full ${data.length > 0 ? '' : 'hidden'}`}
          style={{ maxHeight: expanded ? "500px" : "160px" }}
        >
          <div className="text-white bg-primary-600 text-xs overflow-x-auto">
            {data.map((str, index) => {
              let lastTwoParts;

              if (str.includes("/")) {
                const parts = str.split("/");
                parts[parts.length - 1] = parts[parts.length - 1].split('-').slice(1).join('-');
                lastTwoParts = parts.slice(-2).join("/");
              } else {
                lastTwoParts = str;
              }

              return (
                <div
                  key={index}
                  className={`${
                    index !== 0 ? "border-t" : ""
                  } border-gray-300 whitespace-nowrap p-1.5 flex items-center`}
                >
                  {lastTwoParts}
                </div>
              );
            })}
          </div>
        </div>
        {data.length > 5 && (
          <div className={`${selected.length > 0 ? 'duration-500 h-2 relative' : 'hidden'}`}>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-full" style={{ top: '-15px' }}>
              <div className="w-8 h-8 flex items-center justify-center border-1 border-gray-800 bg-primary-500 rounded-full">
                <button
                  className="p-2"
                  onClick={toggleHeight}
                >
                  <FontAwesomeIcon
                    icon={expanded ? faArrowUp : faArrowDown}
                    color={"white"}
                    className={'duration-500'}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        </>
      )}
    {!multiple && !multipleFileSelection && (
      <div
        className={`relative overflow-y-auto transition-height duration-500 border-b border-dotted border-r border-l rounded-b-xl w-full ${selected || selectedTemplates ? '' : 'hidden'}`}
      >
        <div className="text-white bg-primary-600 text-xs overflow-x-auto">
          <div
            className={`border-gray-300 whitespace-nowrap p-1.5 flex items-center`}
          >
            {selected && (
              formatPath(selected, templates)
            )}
            {selectedTemplates && (
              formatPath(selectedTemplates, templates)
            )}
          </div>
        </div>
      </div>
    )}
    </div>
  </>
  );
};

function formatPath(path: string | string[], templates: any[]) {
  if (Array.isArray(path)) return

  if (path.includes("/")) {
    const parts = path.split("/");
    parts[parts.length - 1] = parts[parts.length - 1].split('-').slice(1).join('-');
    return parts.slice(-2).join("/");
  } else {
    const template = templates.find((template) => template.value === path);
    return template ? template.label : path;
  }
}
