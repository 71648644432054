import { faCheckSquare, faChevronDown, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { DropDownOptions, Option } from "../../../data/models";
import { Divider } from "../../shared/divider";

const DropDownSen = ({ DropDownOptions, title, setSelected, selected, multiple, width = "w-3/4", padding = "p-4", childWidth = "", maxHeight = ""}: { DropDownOptions: DropDownOptions, title: string, setSelected: any, selected: string[] | string, multiple: boolean, width?: string, padding?: string, childWidth?: string, maxHeight?: string}) => {

  const handleOptionClick = (value: string) => {
    if (multiple) {
      if (Array.isArray(selected)) {
        if (selected.includes(value)) {
          setSelected(selected.filter((item) => item !== value));
        } else {
          setSelected([...selected, value]);
        }
      }
    } else if (selected === value) {
      setSelected("");
    } else {
      setSelected(value);
    }
  };

  const targetRef = useRef<HTMLDivElement>(null);
  const absoluteRef = useRef<HTMLDivElement>(null);

  const handleHover = () => {
    const targetElement = targetRef.current;
    const targetRect = targetElement?.getBoundingClientRect();

    const absoluteElement = absoluteRef.current;
    if (targetRect && absoluteElement) {
      absoluteElement.style.position = 'absolute';
      if (targetRect.left - 200 < 0) {
        absoluteElement.style.left = `0px`;
      } else {
        absoluteElement.style.left = `${targetRect.left - 200}px`;
      }
    }
  };

  return(
    <div className={`${width} parentd`} onMouseEnter={handleHover}>
      <div ref={targetRef}>
        <button
          type="button"
          className={`inline-flex w-full center-text rounded-md border-primary-500 ${padding} bg-primary-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          
        >
          <div className="flex flex-row items-center justify-center w-full">
            <h1 className="text-white text-center truncate">
              {title}
            </h1>
            <div className="ml-2">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </button>
      </div>
      <div className={`childss ${childWidth ? childWidth : "w-full"} rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} ref={absoluteRef}>
        <div
          className={`py-1 overflow-auto`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          style={{ maxHeight: maxHeight ? maxHeight : "" }}
        >
          {DropDownOptions.options.map((element: Option) => (
            <div key={element.id}>
              <div
                className="flex flex-col px-4 py-2 text-gray-700 font-bold text-center"
                role="menuitem"
              >
                {element.label}
              </div>

              {element.values.map((element: {label: string, value: string}) => (
                <div
                  key={element.value}
                  className={`flex flex-col px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer ${selected.includes(element.value) ? 'bg-gray-100' : ''}`}
                  role="menuitem"
                  onClick={() => handleOptionClick(element.value)}
                >
                  <div className="flex flex-row items-center">
                    <div className="flex justify-center items-center">
                      {multiple ? (
                        selected.includes(element.value) ? (
                          <FontAwesomeIcon icon={faCheckSquare}
                            size="lg"
                            color="blue"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faSquare}
                            size="lg"
                            color="gray"
                          />
                        )
                      ) : (
                        selected === element.value ? (
                          <FontAwesomeIcon icon={faCheckSquare}
                            size="lg"
                            color="blue"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faSquare}
                            size="lg"
                            color="gray"
                          />
                        )
                      )}
                    </div>
                    <div className="ml-3">{element.label}</div>
                  </div>
                </div>
              ))}
              <Divider color="gray" />
            </div>
          ))}
        </div>
      </div>
		</div>
	);
};

export default DropDownSen;