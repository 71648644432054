import { DislocationDensityModel } from "../../models/analysis.dislocationDensity.model";
import { SET_DISLOCATION_DENSITY_DATA_FILES, SET_DISLOCATION_DENSITY_CIF_FILES, SET_DISLOCATION_DENSITY_INSTRUMENTAL_FILE, SET_DISLOCATION_DENSITY_WAVELENGTH, SET_DISLOCATION_DENSITY_CIF_TEMPLATE_FILES, SET_DISLOCATION_DENSITY_INSTRUMENTAL_TEMPLATE_FILE, SET_DISLOCATION_DENSITY, SET_DISLOCATION_DENSITY_INSTRUMENTAL_WAVELENGTH, SET_DISLOCATION_DENSITY_DATA_VIEW_FILE, SET_DISLOCATION_DENSITY_RESET, SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES, SET_DISLOCATION_DENSITY_PARTITIONS, SET_DISLOCATION_DENSITY_ACTIVE_INDEX, SET_DISLOCATION_DENSITY_SELECTING_PEAKS, SET_DISLOCATION_DENSITY_HISTOGRAM, SET_DISLOCATION_DENSITY_PARAMETERS, SET_DISLOCATION_DENSITY_FITTING, SET_DISLOCATION_DENSITY_WH, SET_DISLOCATION_DENSITY_CH00, SET_DISLOCATION_DENSITY_L0, SET_DISLOCATION_DENSITY_LN, SET_DISLOCATION_DENSITY_RHO, SET_DISLOCATION_DENSITY_TTH1, SET_DISLOCATION_DENSITY_TTH2, SET_DISLOCATION_DENSITY_L0_PICKER_RANGE, SET_DISLOCATION_DENSITY_L0_PICKER_SELECTION, SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE, SET_DISLOCATION_DENSITY_RHO_PICKER_SELECTION, SET_DISLOCATION_DENSITY_K2CDK, SET_DISLOCATION_DENSITY_K2CLNA, SET_DISLOCATION_DENSITY_L0_ANALYSIS, SET_DISLOCATION_DENSITY_RHO_ANALYSIS, SET_DISLOCATION_DENSITY_RHO_PICKER_DATA, SET_DISLOCATION_DENSITY_L0_PICKER_DATA, SET_DISLOCATION_DENSITY_MWH_PARAMS, SET_DISLOCATION_DENSITY_WH_PARAMS, SET_DISLOCATION_DENSITY_CIF_FILES_ABC } from "./dislocationDensityActions";

const initialState: DislocationDensityModel = {
  dataFiles: [],
  dataActiveFiles: [],
  dataViewFile: "",
  cifFiles: "",
  cifFilesABC: {},
  instrumentalFile: "",
  wavelength: 0,
  templateCifFiles: "",
  templateInstrumentalFile: "",
  instrumentalFileWaveLength: 0,
  partitions: [{
    name: "1",
    rangeHistory: [0, 0],
    rangeLower: {
      x: 0,
      y: 0
    },
    rangeHigher: {
      x: 0,
      y: 0
    },
    peaks: [],
  }],
  partition: {
    activeIndex: 0,
    selectingPeaks: false,
  },
  movingPeaks: false,
  fitting: {},
  histogram: {
    data: {
      x: [],
      y: []
    },
    phases: {}
  },
  partitionFitting: {},
  ch00: 0.285,
  tth1: 4,
  tth2: 4.3,
  ln: 15,
  l0: [0, 0],
  rho: [0, 0],
  l0Picker: {
    rangeHistory: [0, 0],
    selectionActive: false
  },
  rhoPicker: {
    rangeHistory: [0, 0],
    selectionActive: false
  },
  wh: {
    x: [],
    y: [],
    fit: []
  },
  k2cdk: [],
  k2clna: [],
  l0Analysis: {
    ablineX: [],
    ablineY: [],
    x: [],
    y: []
  },
  rhoAnalysis: {
    ablineX: [],
    ablineY: [],
    x: [],
    y: []
  },
  rhoPickerData: {
    x: [],
    y: []
  },
  l0PickerData: {
    x: [],
    y: []
  },
  whParams: {},
  mwhParams: {},
};

export default function dislocationDensityReducer(state = initialState, { type, payload }: { type: string; payload: any }): DislocationDensityModel {
  switch (type) {
    case SET_DISLOCATION_DENSITY_DATA_FILES:
      return {
        ...state,
        dataFiles: payload,
      }
    case SET_DISLOCATION_DENSITY_CIF_FILES:
      return {
        ...state,
        cifFiles: payload,
      }
    case SET_DISLOCATION_DENSITY_INSTRUMENTAL_FILE:
      return {
        ...state,
        instrumentalFile: payload,
      }
    case SET_DISLOCATION_DENSITY_WAVELENGTH:
      return {
        ...state,
        wavelength: payload,
      }
    case SET_DISLOCATION_DENSITY_CIF_TEMPLATE_FILES:
      return {
        ...state,
        templateCifFiles: payload,
      }
    case SET_DISLOCATION_DENSITY_INSTRUMENTAL_TEMPLATE_FILE:
      return {
        ...state,
        templateInstrumentalFile: payload,
      }
    case SET_DISLOCATION_DENSITY:
      return {
        ...payload
      }
    case SET_DISLOCATION_DENSITY_INSTRUMENTAL_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload,
      }
    case SET_DISLOCATION_DENSITY_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      }
    case SET_DISLOCATION_DENSITY_RESET:
      return {
        ...initialState
      }
    case SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload
      }
    case SET_DISLOCATION_DENSITY_PARTITIONS:
      return {
        ...state,
        partitions: [...payload]
      }
    case SET_DISLOCATION_DENSITY_ACTIVE_INDEX:
      return {
        ...state,
        partition: {
          ...state.partition,
          activeIndex: payload
        }
      }
    case SET_DISLOCATION_DENSITY_SELECTING_PEAKS:
      return {
        ...state,
        partition: {
          ...state.partition,
          selectingPeaks: payload
        },
        rhoPicker: {
          ...state.rhoPicker,
          selectionActive: false
        },
        l0Picker: {
          ...state.l0Picker,
          selectionActive: false
        }
      }
    case SET_DISLOCATION_DENSITY_HISTOGRAM:
      return {
        ...state,
        histogram: payload
      }
    case SET_DISLOCATION_DENSITY_PARAMETERS:
      return {
        ...state,
        fitting: {
          ...payload
        }
      }
    case SET_DISLOCATION_DENSITY_FITTING:
      return {
        ...state,
        partitionFitting: {
          [payload.file]: payload.data
        }
      }
    case SET_DISLOCATION_DENSITY_WH:
      return {
        ...state,
        wh: payload
      }
    case SET_DISLOCATION_DENSITY_CH00:
      return {
        ...state,
        ch00: payload
      }
    case SET_DISLOCATION_DENSITY_LN:
      return {
        ...state,
        ln: payload
      }
    case SET_DISLOCATION_DENSITY_TTH1:
      return {
        ...state,
        tth1: payload
      }
    case SET_DISLOCATION_DENSITY_TTH2:
      return {
        ...state,
        tth2: payload
      }
    case SET_DISLOCATION_DENSITY_L0_PICKER_SELECTION:
      return {
        ...state,
        l0Picker: {
          ...state.l0Picker,
          selectionActive: payload
        },
        rhoPicker: {
          ...state.rhoPicker,
          selectionActive: false
        },
        partition: {
          ...state.partition,
          selectingPeaks: false
        }
      }
    case SET_DISLOCATION_DENSITY_L0_PICKER_RANGE:
      let range = state.l0Picker.rangeHistory;
      range[1] = range[0];
      range[0] = payload;

      let rangeLower = range[0] < range[1] ? range[0] : range[1];
      let rangeHigher = range[0] <= range[1] ? range[1] : range[0];

      return {
        ...state,
        l0Picker: {
          ...state.l0Picker,
          rangeHistory: range
        },
        l0: [rangeLower, rangeHigher]
      }
    case SET_DISLOCATION_DENSITY_RHO_PICKER_SELECTION:
      return {
        ...state,
        rhoPicker: {
          ...state.rhoPicker,
          selectionActive: payload
        },
        l0Picker: {
          ...state.l0Picker,
          selectionActive: false
        },
        partition: {
          ...state.partition,
          selectingPeaks: false
        }
      }
    case SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE:
      let rhoRange = state.rhoPicker.rangeHistory;
      rhoRange[1] = rhoRange[0];
      rhoRange[0] = payload;

      let rhoRangeLower = rhoRange[0] < rhoRange[1] ? rhoRange[0] : rhoRange[1];
      let rhoRangeHigher = rhoRange[0] <= rhoRange[1] ? rhoRange[1] : rhoRange[0];
      return {
        ...state,
        rhoPicker: {
          ...state.rhoPicker,
          rangeHistory: rhoRange
        },
        rho: [rhoRangeLower, rhoRangeHigher]
      }
    case SET_DISLOCATION_DENSITY_K2CDK:
      return {
        ...state,
        k2cdk: payload
      }
    case SET_DISLOCATION_DENSITY_K2CLNA:
      return {
        ...state,
        k2clna: payload
      }
    case SET_DISLOCATION_DENSITY_L0_ANALYSIS:
      return {
        ...state,
        l0Analysis: payload
      }
    case SET_DISLOCATION_DENSITY_RHO_ANALYSIS:
      return {
        ...state,
        rhoAnalysis: payload
      }
    case SET_DISLOCATION_DENSITY_RHO_PICKER_DATA:
      return {
        ...state,
        rhoPickerData: payload
      }
    case SET_DISLOCATION_DENSITY_L0_PICKER_DATA:
      return {
        ...state,
        l0PickerData: payload
      }
    case SET_DISLOCATION_DENSITY_L0:
      return {
        ...state,
        l0: payload
      }
    case SET_DISLOCATION_DENSITY_RHO:
      return {
        ...state,
        rho: payload
      }
    case SET_DISLOCATION_DENSITY_WH_PARAMS:
      return {
        ...state,
        whParams: payload
      }
    case SET_DISLOCATION_DENSITY_MWH_PARAMS:
      return {
        ...state,
        mwhParams: payload
      }
    case SET_DISLOCATION_DENSITY_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload
        }
      }
    default:
      return state;
  }
}
