import { GlobalState } from "../../store/models";

export const selectInstrumentalFileCreation = (state: GlobalState) => state.instrumentalFileCreationReducer;

export const selectInstrumentalFileCreationCifFile = (state: GlobalState) => state.instrumentalFileCreationReducer.cifFile;
export const selectInstrumentalFileCreationCifTemplateFile = (state: GlobalState) => state.instrumentalFileCreationReducer.cifTemplateFile;
export const selectInstrumentalFileCreationCifFilesABC = (state: GlobalState) => state.instrumentalFileCreationReducer.cifFilesABC;

export const selectInstrumentalFileCreationInstrumentalTemplateFile = (state: GlobalState) => state.instrumentalFileCreationReducer.instrumentalTemplateFile;

export const selectInstrumentalFileCreationDataFiles = (state: GlobalState) => state.instrumentalFileCreationReducer.dataFiles;
export const selectInstrumentalFileCreationDataActiveFiles = (state: GlobalState) => state.instrumentalFileCreationReducer.dataActiveFiles;
export const selectInstrumentalFileCreationDataViewFile = (state: GlobalState) => state.instrumentalFileCreationReducer.dataViewFile;

export const selectInstrumentalFileCreationWavelength = (state: GlobalState) => state.instrumentalFileCreationReducer.wavelength;
export const selectInstrumentalFileCreationCrystalliteSize = (state: GlobalState) => state.instrumentalFileCreationReducer.crystalliteSize;
export const selectInstrumentalFileCreationMicrostrain = (state: GlobalState) => state.instrumentalFileCreationReducer.microstrain;

export const selectInstrumentalFileCreationRefinementRange = (state: GlobalState) => state.instrumentalFileCreationReducer.refinementRange;
export const selectInstrumentalFileCreationRangeHistory = (state: GlobalState) => state.instrumentalFileCreationReducer.rangeHistory;
export const selectInstrumentalFileCreationRangeInitial = (state: GlobalState) => state.instrumentalFileCreationReducer.rangeInitial;

export const selectInstrumentalFileCreationHistogram = (state: GlobalState) => state.instrumentalFileCreationReducer.histogram;
export const selectInstrumentalFileCreationInstrumentalFileWaveLength = (state: GlobalState) => state.instrumentalFileCreationReducer.instrumentalFileWaveLength;
export const selectInstrumentalFileCreationAnalysis = (state: GlobalState) => state.instrumentalFileCreationReducer.analysis;
