import React, { useState, useEffect, useRef } from "react";
import { faCheckSquare, faCircleCheck, faClock, faExclamationCircle, faSpinner, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch } from "redux";
import { SET_UPLOAD_FILES_PROGRESS, SET_UPLOAD_STATUS, UPLOAD_FILES } from "../../redux/project/projectActions";
import { selectFileDownloadProgress, selectUploadStatus } from "../../redux/general/generalSelectors";
import { useSelector } from "react-redux";
import { FormatFileSize, GetFileType } from "../shared/utils";


type FileWithMetadata = {
  file: File;
  fullPath: string | null; // This will be null for browsers that don't support webkitRelativePath
  size: number;
  type: string;
  lastModified: number
  id: string;
};

export default function UploadFiles({ dispatch, type }: { dispatch: Dispatch, type: string }) {
  const uploadStatus = useSelector(selectUploadStatus);
  const fileUploadProgress = useSelector(selectFileDownloadProgress);

  const [files, setFiles] = useState<FileWithMetadata[]>([]);
  const [fileSearch, setFileSearch] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [pipelineData, setPipelineData] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    folderInputRef.current.setAttribute("directory", "");
    folderInputRef.current.setAttribute("webkitdirectory", "");
    folderInputRef.current.setAttribute("mozdirectory", "");
    folderInputRef.current.setAttribute("msdirectory", "");
    folderInputRef.current.setAttribute("odirectory", "");
    folderInputRef.current.setAttribute("multiple", "");
  }, [folderInputRef, uploadStatus]);

  const handleFileChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetFiles = event.target.files;
    const fileDataArray: FileWithMetadata[] = [];

    if (targetFiles) {
      for (let i = 0; i < targetFiles.length; i++) {
        const file = targetFiles[i];

        if (file.name === ".DS_Store") {
          continue;
        }

        fileDataArray.push({
          file,
          fullPath: (file as any).webkitRelativePath || file.name,
          size: file.size,
          type: GetFileType(file),
          lastModified: file.lastModified,
          id: Math.random().toString(36).substr(2, 9),
        });
      }

      setFiles((prevFiles) => [...prevFiles, ...fileDataArray]);
    }

    if (folderInputRef.current !== null) {
      folderInputRef.current.value = "";
    }
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileSearch(event.target.value);
  }

  const toggleRowSelection = (index: string) => {
    setSelectedRows(prevSelectedRows => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(index)) {
        newSelectedRows.delete(index);
      } else {
        newSelectedRows.add(index);
      }
      return newSelectedRows;
    });
  };

  const selectAllRows = () => {
    const visibleFiles = searchFiles(files, fileSearch);
    if (selectedRows.size === visibleFiles.length) {
      setSelectedRows(new Set());
    } else {
      const newSelectedRows = new Set(visibleFiles.map((file) => file.id));
      setSelectedRows(newSelectedRows);
    }
  };

  const removeSelectedFiles = () => {
    setFiles(currentFiles => currentFiles.filter((file) => !selectedRows.has(file.id)));
    setSelectedRows(new Set());
  };

  const areAllSearchResultsSelected = () => {
    const visibleFilesIndices = searchFiles(files, fileSearch).map((file) => file.id);
    return visibleFilesIndices.every(index => selectedRows.has(index));
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.classList.add("border-gray-300");
    e.currentTarget.classList.remove("border-gray-500");
  
    let filesToUpdate = [];
    const items: any = e.dataTransfer?.items;
    if (items) {
      const promises = [];
      for (let i = 0; i < items.length; i++) {
        let entry = items[i].webkitGetAsEntry ? items[i].webkitGetAsEntry() : items[i].getAsEntry();
        if (entry) {
          promises.push(traverseFileTree(entry, "", filesToUpdate));
        }
      }
      await Promise.all(promises);
    }
    setFiles(prevFiles => [...prevFiles, ...filesToUpdate]);
  };

  function searchFiles(filesWithMetadata: FileWithMetadata[], searchVariable: string): FileWithMetadata[] {
    if (searchVariable === '') {
      return filesWithMetadata;
    }
  
    return filesWithMetadata.filter(fileMeta => {
      const comparisonString = fileMeta.fullPath || fileMeta.file.name;
      return comparisonString.toLowerCase().includes(searchVariable.toLowerCase());
    });
  }

  async function traverseFileTree(item: FileSystemEntry, path: string = "", filesToUpdate): Promise<void> {
    if (item.isFile) {
      await new Promise(resolve => {
        (item as FileSystemFileEntry).file((file: File) => {
          filesToUpdate.push({
            file,
            fullPath: path + item.name,
            size: file.size,
            type: GetFileType(file),
            lastModified: file.lastModified,
            id: Math.random().toString(36).substr(2, 9),
          });
          resolve(null);
        });
      });
    } else if (item.isDirectory) {
      let dirReader = (item as FileSystemDirectoryEntry).createReader();
      await new Promise(resolve => {
        dirReader.readEntries(async (entries: FileSystemEntry[]) => {
          const promises = [];
          for (let entry of entries) {
            promises.push(traverseFileTree(entry, path + item.name + "/", filesToUpdate));
          }
          await Promise.all(promises);
          resolve(null);
        });
      });
    }
  }

  const formatUploadProgress = (progress: number) => {
    switch (true) {
      case progress === 100:
        return (
          <>
          <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" />
          {" "} Succeeded
          </>
        )
      case progress === 0 || progress === 10:
        return (
          <>
          <FontAwesomeIcon icon={faClock} className="text-gray-500" />
          {" "} Pending
          </>
        )
      case progress === -1:
        return (
          <>
          <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500" />
          {" "} Failed
          </>
        )
      default:
        return (
        <>
        <FontAwesomeIcon icon={faSpinner} className="fa-spin text-blue-500" />
        {" In Progress "} {progress}%
        </>
      );
    }
  }

  const uploadStatusDesign = () => {
    const currentCompleted = Object.values(fileUploadProgress).filter(progress => progress === 100).length;

    return (
      <div className="flex flex-col justify-center items-center">
        <div className="w-full h-2 bg-primary-600 rounded-md">
          <div className="h-full bg-blue-500 rounded-md" style={{ width: `${(currentCompleted / Object.values(files).length) * 100 }%` }} />
        </div>
        <div className="text-white mt-2">
          {currentCompleted} / {files.length} files completed
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4">
      <div className="flex justify-center items-center mb-4">
        <h1 className="font-bold text-white text-2xl text-center">
          Upload Data
        </h1>
      </div>

      <div className={"flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-md p-6 mb-4"}
        onDragOver={(e) => {
          e.preventDefault();
          e.currentTarget.classList.remove("border-gray-300");
          e.currentTarget.classList.add("border-gray-500");
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.currentTarget.classList.add("border-gray-300");
          e.currentTarget.classList.remove("border-gray-500");
        }}
        onDrop={handleDrop}
      >
        <p className="text-sm text-gray-400">
          Drag and drop files and folders you want to upload here, or choose{" "}
          <span
            className="text-blue-600 hover:underline cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
          >
            Add files
          </span>
          {" "}or{" "}
          <span
            className="text-blue-600 hover:underline cursor-pointer"
            onClick={() => folderInputRef.current?.click()}
          >
            Add folder
          </span>
          .
        </p>
      </div>

      <input
        ref={folderInputRef}
        type="file"
        id="folder-input"
        multiple
        className="hidden"
        onChange={handleFileChange()}
      />

      <div className="flex flex-col">
        <div className="px-4 py-5 sm:px-6 bg-primary-700 border-b border-gray-200">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
            {uploadStatus === 0 ? (
              <>
              <h3 className="text-lg leading-6 font-medium text-white">
                Files and folders ({files.length}, {FormatFileSize(files.reduce((acc, file) => acc + file.size, 0))})
              </h3>
              <p className="text-sm text-white">
                All files and folders in this table will be uploaded.
              </p>
              </>
            ) : (
              <>
              {uploadStatusDesign()}
              </>
            )}
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              {uploadStatus === 0 ? (
              <>
              <button
                type="button"
                className={`relative inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium rounded-md text-white 
                  ${selectedRows.size === 0 ? 'bg-primary-600 cursor-not-allowed opacity-50' : 'bg-primary-600 hover:bg-gray-600'}`}
                onClick={selectedRows.size > 0 ? removeSelectedFiles : undefined}
                disabled={selectedRows.size === 0}
              >
                Remove
              </button>
              <input
                ref={fileInputRef}
                type="file"
                id="file-input"
                multiple
                className="hidden"
                onChange={handleFileChange()}
              />
              <button
                type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-gray-600"
                onClick={() => fileInputRef.current?.click()}
              >
                Add files
              </button>

              <button
                type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-gray-600"
                onClick={() => folderInputRef.current?.click()}
              >
                Add folder
              </button>
              {(type === "azimuthal_integration_phase_quantification" || type ===  "azimuthal_integration_stress_analysis") && (
                <button
                  type="button"
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-gray-600"
                  onClick={() => setPipelineData(!pipelineData)}
                >
                  <p> {pipelineData ? <FontAwesomeIcon icon={faCheckSquare} size="lg" style={{ marginRight: "4px" }} /> : <FontAwesomeIcon icon={faSquare} size="lg" style={{ marginRight: "4px" }} />} Pipeline Data</p>
                </button>
              )}
              <button
                type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-blue-700 text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700"
                onClick={() => dispatch({ type: UPLOAD_FILES, payload: { files: files, pipelineData: pipelineData } })}
              >
                Upload files
              </button>
              </>
              ) : null}
                {uploadStatus === 1 ? (
              <>
              <button
                type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-red-700 text-sm font-medium rounded-md text-white bg-red-800 hover:bg-red-700"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: SET_UPLOAD_STATUS, payload: 0 })
                  dispatch({ type: SET_UPLOAD_FILES_PROGRESS, payload: {} })
                }}
              >
                Cancel Upload
              </button>
              </>
              ) : null}
              {uploadStatus === 2 ? (
              <>
              <button
                type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-blue-700 text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700"
                onClick={(e) => {
                  e.preventDefault();
                  setFiles([]);
                  dispatch({ type: SET_UPLOAD_STATUS, payload: 0 })
                  dispatch({ type: SET_UPLOAD_FILES_PROGRESS, payload: {} })
                }}
              >
                Upload More?
              </button>
              </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex-1 bg-primary-700">
          <div className="p-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="mt-1 relative rounded-md shadow-sm border-gray-500 border">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-white sm:text-sm">🔍</span>
              </div>
              <input
                type="text"
                name="search"
                id="search-3"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm text-white py-2 border-gray-500 rounded-md bg-primary-600"
                placeholder="Find by name"
                value={fileSearch}
                onChange={handleFileSearch}
              />
            </div>
          </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden sm:rounded-lg">
              <div className="relative">
                <div className="relative overflow-y-auto" style={{ maxHeight: "calc(100vh - 470px)" }}>
                  {uploadStatus === 0 ? (
                    <table className="min-w-full divide-gray-200">
                      <thead className="bg-primary-600">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                            <FontAwesomeIcon
                              icon={areAllSearchResultsSelected() ? faCheckSquare : faSquare}
                              size="lg"
                              onClick={selectAllRows}
                              className="cursor-pointer"
                            />
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Folder
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Type
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                            Size
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-primary-700 divide-y divide-primary-700">
                        {files.length > 0 ? (
                          searchFiles(files, fileSearch).map((file, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                <FontAwesomeIcon
                                  icon={selectedRows.has(file.id) ? faCheckSquare : faSquare}
                                  size="lg"
                                  onClick={() => toggleRowSelection(file.id)}
                                  className="cursor-pointer"
                                />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                {file.file.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                {file.fullPath}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                {file.type || ""}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                {FormatFileSize(file.size)}
                              </td>
                            </tr>
                          ))
                        ) : null}
                      </tbody>
                    </table>
                  ) : null}
                  {uploadStatus === 1 || uploadStatus === 2 ? (
                  <table className="min-w-full divide-gray-200">
                    <thead className="bg-primary-600">
                      <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider" style={{ minWidth: '180px', maxWidth: '180px' }}>
                          Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                          Folder
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                          Type
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                          Size
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-primary-700 divide-y divide-primary-700">
                      {files.length > 0 ? (
                        searchFiles(files, fileSearch).map((file, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {formatUploadProgress(fileUploadProgress[file.fullPath])}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                              {file.file.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                              {file.fullPath}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                              {file.type || ""}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                              {FormatFileSize(file.size)}
                            </td>
                          </tr>
                        ))
                      ) : null}
                    </tbody>
                  </table>
                  ) : null}
                </div>
              </div>
              {files.length === 0 ? (
                <div
                  className="flex items-center justify-center text-center text-sm text-white h-32 bg-primary-700"
                  style={{ minHeight: "150px" }}
                >
                  You have not chosen any files or folders to upload.
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
