import { DropDownOptions } from "../data/models";

export function getName(name: string): string {
  let finalName = name;

  if (finalName.includes("/")) {
    let splitArray: string[] = name.split("/");
    finalName = splitArray[splitArray.length - 1];
  }

  if (finalName.includes("-")) {
    const nameArray = finalName.split("-");
    nameArray.shift();
    return nameArray.join("-");
  }

  return finalName;
}

export function numberShortener(input: any, useExponentialFormat: boolean = true): string {
  try {
    // Handle null, undefined, and NaN values
    if (input === null || input === undefined || (typeof input === "number" && isNaN(input))) {
      return "";
    }

    // Convert string values to numbers
    if (typeof input === "string") {
      const parsedNumber = parseFloat(input);

      // If parsedNumber is NaN, handle based on string length
      if (isNaN(parsedNumber)) {
        return input.length > 1 ? input : "";
      }

      input = parsedNumber;
    }

    // Check if the input is not a number after potential conversion
    if (typeof input !== "number") {
      return ""; // Return empty string for other types
    }

    // Number shortening logic
    if (input > 1000) {
      const power = Math.floor(Math.log10(input));
      const shortenedNumber = input / Math.pow(10, power);
      return `${shortenedNumber.toFixed(2)}e${power}`;
    } else {
      const fixedValue = input.toFixed(6);
      
      if (useExponentialFormat && /^0\.000000$/.test(fixedValue)) {
        const power = Math.floor(Math.log10(input));
        const baseValue = input * Math.pow(10, -power);
        return `${baseValue.toFixed(2)}e${power}`;
      }

      return fixedValue;
    }
  } catch (e) {
    // If an exception occurs, return the original value if it's a number or based on string length for strings
    if (typeof input === "number") {
      return input.toString();
    } else if (typeof input === "string") {
      return input.length > 1 ? input : "";
    } else {
      return ""; // Return empty string for other types
    }
  }
}

export function getTitle(title: string): string {
  // Replace _ With " "
  let formattedTitle = title.replace(/_/g, ' ');

  // Capitalize the start word character
  formattedTitle = formattedTitle.replace(/\b\w/g, (match) =>
    match.toUpperCase()
  );

  return formattedTitle;
}

export function getLabelsForValues(dropdownOptions: DropDownOptions, targetValue: string | number | (string | number)[]): string {
  const targetValues = Array.isArray(targetValue) ? targetValue : [targetValue];
  const foundLabels: string[] = [];
  const notFound: (string | number)[] = [...targetValues];  // Clone the array to track not found values

  for (const option of dropdownOptions.options) {
    for (const valueObj of option.values) {
      const index = notFound.indexOf(valueObj.value);
      if (index > -1) {
        foundLabels.push(valueObj.label);
        notFound.splice(index, 1); // Remove the found value from the notFound list
      }
    }
  }

  // Convert any remaining not found numbers to strings
  const notFoundStr = notFound.map(val => typeof val === "number" ? val.toString() : val);

  return [...foundLabels, ...notFoundStr].join(', ');
}

export function truncate(str, num) {
  return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + '...' : str;
}

export const toReadableLabel = (input: string): string => {
  // Split the input string at uppercase letters and join with a space
  let result = input.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  // Convert the first character of each word to uppercase
  return result.replace(/\b\w/g, char => char.toUpperCase());
}