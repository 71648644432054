import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import { getReportYOptions } from "./analysis-input-fields/input-utils";
import { GlobalState } from "../../../store/models";
import { SET_SELECTED_X, SET_SELECTED_Y, SET_TEMP_FROM, SET_TEMP_TO, SET_TIME_FROM, SET_TIME_TO } from "../../../redux/project/projectActions";
import { transformChartDataForExport } from "../../../chart-utils";

const selectFromTemp = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectTemperatureFrom;
const selectToTemp = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectTemperatureTo;
const selectFromTime = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectTimeFrom;
const selectToTime = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectTimeTo;
const selectSelectedX = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectSelectedX;
const selectSelectedY = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectSelectedY;

const ReportInput = ({
  dispatch,
  chartData,
}: {
  dispatch: Dispatch;
  chartData: any;
}) => {
  const tempFrom = useSelector(selectFromTemp);
  const tempTo = useSelector(selectToTemp);
  const timeFrom = useSelector(selectFromTime);
  const timeTo = useSelector(selectToTime);
  const selectedX = useSelector(selectSelectedX);
  const selectedY = useSelector(selectSelectedY);

  const currentProjectSelector = (state: GlobalState) =>
    state.projectReducer.currentProject;

  const setTempFrom = (tempFrom: string) => {
    dispatch({ type: SET_TEMP_FROM, payload: tempFrom });
  };
  const setTempTo = (tempTo: string) => {
    dispatch({ type: SET_TEMP_TO, payload: tempTo });
  };
  const setTimeFrom = (timeFrom: string) => {
    dispatch({ type: SET_TIME_FROM, payload: timeFrom });
  };
  const setTimeTo = (timeTo: string) => {
    dispatch({ type: SET_TIME_TO, payload: timeTo });
  };
  const setSelectedX = (selectedX: string) => {
    dispatch({ type: SET_SELECTED_X, payload: selectedX });
  };

  useEffect(() => {
    if (!selectedX) {
      setSelectedX("q");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleYValueReport = (
    checked: boolean,
    selectedY: { label: string; dynamo_db_name: string }
  ) => {
    dispatch({ type: SET_SELECTED_Y, payload: { checked, selectedY } });
  };

  const currentProject = useSelector(currentProjectSelector);
  const yValues = getReportYOptions();

  function isChecked(label: string) {
    if (selectedY) {
      for (let i = 0; i < selectedY.length; i++) {
        if (selectedY[i].label === label) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <div className={"flex flex-col bg-primary-800 rounded-md w-3/12 xl:w-full"}>
      <label className={"text-primary-500 p-4"}>Impact of</label>
      <div className={"p-4 flex flex-col bg-primary-600 mx-4 mb-4 rounded-md"}>
        <h1 className={"font-bold text-white text-base mb-2"}>Temperature</h1>
        <div className={"flex gap-4"}>
          <div className={"flex flex-col flex-1 max-w-1/2"}>
            <label className={"text-primary-500 mb-2"}>From</label>
            <input
              type="number"
              value={tempFrom}
              className={
                "bg-primary-850 p-2 rounded-md border border-white border-solid text-white"
              }
              onChange={(event) => setTempFrom(event.target.value)}
            />
          </div>
          <div className={"flex flex-col flex-1 max-w-1/2"}>
            <label className={"text-primary-500 mb-2"}>To</label>
            <input
              type="number"
              value={tempTo}
              className={
                "bg-primary-850 p-2 rounded-md border border-white border-solid text-white"
              }
              onChange={(event) => setTempTo(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={"p-4 flex flex-col bg-primary-600 mx-4 mb-4 rounded-md"}>
        <h1 className={"font-bold text-white text-base mb-2"}>Time</h1>
        <div className={"flex gap-4"}>
          <div className={"flex flex-col flex-1 max-w-1/2"}>
            <label className={"text-primary-500 mb-2"}>From</label>
            <input
              type="number"
              value={timeFrom}
              className={
                "bg-primary-850 p-2 rounded-md border border-white border-solid text-white"
              }
              onChange={(event) => setTimeFrom(event.target.value)}
            />
          </div>
          <div className={"flex flex-col flex-1 max-w-1/2"}>
            <label className={"text-primary-500 mb-2"}>To</label>
            <input
              type="number"
              value={timeTo}
              className={
                "bg-primary-850 p-2 rounded-md border border-white border-solid text-white"
              }
              onChange={(event) => setTimeTo(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={"p-4 flex flex-col bg-primary-600 mx-4 mb-4 rounded-md"}>
        <div className={"p-4 flex flex-col mx-4 mb-4"}>
          <h1 className={"text-primary-500 mb-2"}>X:</h1>

          <select
            className={
              "text-white text-center p-4 flex flex-col bg-primary-500  rounded-md w-full"
            }
            name="x"
            id="x"
            value={selectedX}
            onChange={(e) => setSelectedX(e.target.value)}
          >
            <option value="q">Q</option>
            <option value="measurement_duration">Time</option>
            <option value="temperature">Temperature</option>
            <option value="data_file_name">Data file name</option>
          </select>
        </div>
        {selectedX !== "q" && (
          <div className={"p-4 flex flex-col mx-4 mb-4"}>
            <h1 className={"text-primary-500 mb-2"}>Y:</h1>

            {yValues.map((yValue) => (
              <div
                key={yValue.dynamo_db_name}
                className={"flex flex-row justify-between"}
              >
                <label className={"text-primary-500"}>{yValue.label}</label>
                <input
                  type="checkbox"
                  onChange={(ev) =>
                    toggleYValueReport(ev.target.checked, yValue)
                  }
                  checked={isChecked(yValue.label)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <CSVLink
        className={
          "text-white text-center p-4 flex flex-col bg-primary-600 mx-4 mb-4 rounded-md"
        }
        data={transformChartDataForExport(chartData, selectedX)}
        filename={currentProject.projectSettings.projectName + ".csv"}
      >
        Export as CSV
      </CSVLink>
    </div>
  );
};

export default ReportInput;
