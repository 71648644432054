import { compareKeysV2 } from "../../../helpers/general"
import { numberShortener } from "../../../helpers/name"
import { PeakFittingResultModel } from "../../../models/analysis.general.model"

export const FileMapping = (peakFittingDataFiles: string[], peakFittingFitting: { [dataFile: string]: PeakFittingResultModel }) => {
  let filesMapping = []

  for (const file of peakFittingDataFiles) {
    filesMapping.push({ fileName: file })
  }

  filesMapping = filesMapping.sort((a, b) => compareKeysV2(a.fileName, b.fileName))

  for (const key in peakFittingFitting) {
    const fitting = peakFittingFitting[key]
    const index = filesMapping.findIndex((file) => file.fileName === key)
    if (index !== -1) {
      let newItems = {}
      for (const key in fitting) {
        for (const innerKey in fitting[key]) {
          newItems[`${key}: ${innerKey}`] = numberShortener(fitting[key][innerKey])
        }
      }

      const sortedKeys = Object.keys(newItems).sort((a, b) => {
        const matchA = a.match(/^(\D+)(\d+)$/);
        const matchB = b.match(/^(\D+)(\d+)$/);
        if (matchA && matchB) {
          const letterCompare = matchA[1].localeCompare(matchB[1]);
          if (letterCompare !== 0) return letterCompare;
          return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
        }
        return 0;
      });

      const sortedNewItems = {};
      for (const sortedKey of sortedKeys) {
        sortedNewItems[sortedKey] = newItems[sortedKey];
      }

      filesMapping[index] = { ...filesMapping[index], ...sortedNewItems };
    }
  }

  return filesMapping;
}
