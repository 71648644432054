import { apiGeneral, apiLeBail, requestApi } from "../../api";
import { LeBailDataPoints, LeBailRefinedHistogram } from "../../models/analysis.leBail.model";

export const preProcessObject = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));

  delete newObj.histogram;
  delete newObj.refinedHistogram;

  return newObj;
}

export function updateLeBailRefinedHistogram(originalData: LeBailRefinedHistogram, newData: LeBailRefinedHistogram): LeBailRefinedHistogram {
	if (Object.keys(originalData).length === 0 && newData.constructor === Object) {
		return newData;
	}

	const updatedPhases = newData.phases.reduce((accumulator, newPhase) => {
		const existingPhaseIndex = accumulator.findIndex(phase => phase.name === newPhase.name);
		if (existingPhaseIndex === -1) {
			accumulator.push(newPhase);
		} else {
			accumulator.splice(existingPhaseIndex, 1, newPhase);
		}
		return accumulator;
	}, [...originalData.phases]);

	return {
		phases: updatedPhases,
		background: updateLeBailDataPoints(originalData.background, newData.background),
		calculations: updateLeBailDataPoints(originalData.calculations, newData.calculations),
		residual: updateLeBailDataPoints(originalData.residual, newData.residual)
	};
}

function updateLeBailDataPoints(originalData: LeBailDataPoints[], newData: LeBailDataPoints[]): LeBailDataPoints[] {
	return newData.reduce((accumulator, newPhase) => {
		const existingPhaseIndex = accumulator.findIndex(phase => phase.name === newPhase.name);
		if (existingPhaseIndex === -1) {
			accumulator.push(newPhase);
		} else {
			accumulator.splice(existingPhaseIndex, 1, newPhase);
		}
		return accumulator;
	}, [...originalData]);
}

export const getLeBailHistogram = async (token, req) => {
  return await requestApi(`phases`, "POST", req, token, {}, apiGeneral);
};

export const getLeBailRefinedHistogram = async (token, req) => {
  return await requestApi(`caller`, "POST", req, token, {}, apiLeBail);
};

export const getLeBailRefinedHistogramCallback = async (token, req) => {
  return await requestApi(`callback`, "POST", req, token, {}, apiLeBail);
};
