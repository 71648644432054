import { apiGeneral, apiInstrumentalFileCreation, requestApi } from "../../api";

export const getInstrumentalFileCreationHistogram = async (token, userId, projectId, cifFiles, templateCifFiles, templateInstrumentalFile, dataFiles, wavelength) => {
  const req = {
    projectId,
    userId,
    cifFiles: [cifFiles],
    templateCifFiles: [templateCifFiles],
    templateInstrumentalFile,
    dataFiles: [dataFiles],
    wavelength,
  };

  return await requestApi(`/phases`, "POST", req, token, {}, apiGeneral);
};

export const getInstrumentalFileCreation = async (token, userId, projectId, cifFiles, templateCifFiles, templateInstrumentalFile, dataFiles, dataActiveFiles, wavelength, crystalliteSize, microstrain, rangeLower, rangeUpper) => {
  const req = {
    projectId,
    userId,
    cifFiles: cifFiles ? [cifFiles] : [],
    templateCifFiles: [templateCifFiles],
    templateInstrumentalFile,
    dataFiles: dataFiles,
    wavelength,
    crystalliteSize,
    microstrain,
    rangeLower,
    rangeUpper,
    dataActiveFiles
  };

  return await requestApi(`/caller`, "POST", req, token, {}, apiInstrumentalFileCreation);
}

export const getInstrumentalFileCreationCallback = async (token, projectId, dataViewFile) => {
  const req = {
    projectId,
    dataViewFile,
  };

  return await requestApi(`/callback`, "POST", req, token, {}, apiInstrumentalFileCreation);
}
