import { all, put, select, takeLatest } from "redux-saga/effects";
import { LeBailModel } from "../../models/analysis.leBail.model";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { GET_LE_BAIL_REFINED_HISTOGRAM, GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK, SET_LE_BAIL_REFINED_HISTOGRAM } from "./leBailActions";
import toast from "react-hot-toast";
import { waitFiveSeconds } from "../../helpers/redux";
import { getLeBailRefinedHistogram, getLeBailRefinedHistogramCallback, preProcessObject } from "./leBailHelpers";
import { selectLeBail } from "./leBailSelectors";
import { selectCurrentProject, selectCurrentProjectFiles, selectUserToken } from "../project/projectSelectors";


function* get_le_bail_refined_histogram_callback() {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  const currentProjectFiles = yield select(selectCurrentProjectFiles);
  let leBail = yield select(selectLeBail);

  try {
    if (!leBail.dataViewFile) return
    
    leBail = preProcessObject(leBail);

    const res = yield getLeBailRefinedHistogramCallback(
      token, {
        ...leBail,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
        projectFiles: currentProjectFiles.map((x) => x.fileRef),
      }
    );

    yield put({ type: SET_LE_BAIL_REFINED_HISTOGRAM, payload: res.data });
  } catch (error) {
    console.error(error)
    toast.error("Failed to load data")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });

    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

function* get_le_bail_refined_histogram() {
  let leBail: LeBailModel = yield select(selectLeBail);    
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);

  try {

    toast.promise(waitFiveSeconds(), {
      loading: "Starting Refinement",
      success: "Refinement Running",
      error: "Refinement Running",
    })

    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    leBail = preProcessObject(leBail);
    
    const res = yield getLeBailRefinedHistogram(
      token,
      {
        ...leBail,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to load Refined Histogram check inputs")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

export default function* leBailSaga() {
  yield all([
    takeLatest(GET_LE_BAIL_REFINED_HISTOGRAM, get_le_bail_refined_histogram),
    takeLatest(GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK, get_le_bail_refined_histogram_callback),
  ]);
}
