import { all, put, select, takeLatest } from "redux-saga/effects";
import { GET_AZIMUTHAL_INTEGRATION_CALLBACK_CAKING, GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES, GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION, GET_AZIMUTHAL_INTEGRATION_IMAGE, SET_AZIMUTHAL_INTEGRATION_ACTIVE_IMAGE_PATH, SET_AZIMUTHAL_INTEGRATION_CAKING_RESULT, SET_AZIMUTHAL_INTEGRATION_IMAGE_VIDEO, SET_AZIMUTHAL_INTEGRATION_INTEGRATION_RESULT } from "./azimuthalIntegrationActions";
import { AzimuthalIntegrationModel } from "../../models/analysis.azimuthalIntegration.model";
import { selectAzimuthalIntegration } from "./azimuthalIntegrationSelectors";
import toast from "react-hot-toast";
import { fetchData } from "../../helpers/redux";
import { SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS } from "../general/generalActions";
import { selectCurrentProject, selectUserToken } from "../project/projectSelectors";
import { getAzimuthalIntegrationCallbackCaking, getAzimuthalIntegrationCallbackImages, getAzimuthalIntegrationCallbackIntegration, getAzimuthalIntegrationImage } from "./azimuthalIntegrationHelpers";

function* get_azimuthal_integration_image() {
  try {
    const azimuthalIntegration: AzimuthalIntegrationModel = yield select(selectAzimuthalIntegration);

    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    
    const res = yield getAzimuthalIntegrationImage(
      token,
      currentProject.projectId,
      azimuthalIntegration.dataViewFile,
      azimuthalIntegration.imageOptions.colorMap,
      azimuthalIntegration.imageOptions.log
    );

    yield put({ type: SET_AZIMUTHAL_INTEGRATION_ACTIVE_IMAGE_PATH, payload: res.data});
  } catch (error) {
    console.error(error)
    toast.error("Failed to load Detector Image")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_azimuthal_integration_callback_images() {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getAzimuthalIntegrationCallbackImages(
      token,
      currentProject.projectId,
    )

    yield put({ type: SET_AZIMUTHAL_INTEGRATION_IMAGE_VIDEO, payload: res.data})
  } catch (error) {
    console.error(error)
    toast.error("Failed to load image video")
  }
}

function* get_azimuthal_integration_callback_integration(payload) {
  yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getAzimuthalIntegrationCallbackIntegration(
      token,
      currentProject.projectId,
      payload.payload,
    )

    const dataDict = res.data;

    const newDataDict: { [key: string]: any } = {};

    for (const [key, value] of Object.entries(dataDict)) {
      const data = yield fetchData(value);
      newDataDict[key] = data
    }

    yield put({ type: SET_AZIMUTHAL_INTEGRATION_INTEGRATION_RESULT, payload: newDataDict })
  } catch (error) {
    console.error(error)
    toast.error("Failed to load integration data")
  }

  yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
}

function* get_azimuthal_integration_callback_caking(payload) {
  yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getAzimuthalIntegrationCallbackCaking(
      token,
      currentProject.projectId,
      payload.payload.index,
      payload.payload.cakingRange
    )

    const dataDict = res.data;

    const newDataDict: { [key: string]: any } = {};

    for (const [key, value] of Object.entries(dataDict)) {
      const data = yield fetchData(value);
      newDataDict[key] = data
    }

    yield put({ type: SET_AZIMUTHAL_INTEGRATION_CAKING_RESULT, payload: newDataDict })
  } catch (error) {
    console.error(error)
    toast.error("Failed to load integration data")
  }

  yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
}

export default function* azimuthalIntegrationSaga() {
  yield all([
    takeLatest(GET_AZIMUTHAL_INTEGRATION_IMAGE, get_azimuthal_integration_image),
    takeLatest(GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES, get_azimuthal_integration_callback_images),
    takeLatest(GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION, get_azimuthal_integration_callback_integration),
    takeLatest(GET_AZIMUTHAL_INTEGRATION_CALLBACK_CAKING, get_azimuthal_integration_callback_caking),
  ]);
}
