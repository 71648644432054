import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../../store/models";
import MainChart from "./main-chart";

const selectSelectedX = (state: GlobalState) =>
  state.projectReducer.reportSettings.projectSelectedX;

const LineChart = ({ chartData }: { chartData: any }) => {
  const [axisType, setAxisType] = useState("log");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedX === "q" || !selectedX) {
      const newAxisType = !e.target.checked ? "linear" : "log";
      setAxisType(newAxisType);
    } else {
      setAxisType("linear");
    }
  };

  const selectedX = useSelector(selectSelectedX);

  useEffect(() => {
    if (!selectedX) {
      setAxisType("log");
    } else if (selectedX !== "q") {
      setAxisType("linear");
    } else {
      setAxisType("log");
    }
  }, [selectedX]);

  return (
    <MainChart onChange={onChange} axisType={axisType} chartData={chartData} />
  );
};

export default LineChart;
