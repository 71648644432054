export function FormatLongFileName(fileName: string): string {  
  if (fileName.includes("/")) {
    const parts = fileName.split("/");
    parts[parts.length - 1] = parts[parts.length - 1].split('-').slice(1).join('-');
    return parts.slice(-2).join("/");
  } else {
    return fileName;
  }
}

export function FormatName(fileName: string, extension: boolean = false): string {
  const parts = fileName.split("/");
  parts[parts.length - 1] = parts[parts.length - 1].split('-').slice(1).join('-');

  if (extension) {
    return parts[parts.length - 1];
  } else {
    return parts[parts.length - 1].split(".")[0];
  }
}

export function FormatFileSize(size: number): string {
  if (size < 1024) return size + " bytes";
  else if (size < 1024 ** 2) return (size / 1024).toFixed(1) + " KB";
  else if (size < 1024 ** 3) return (size / 1024 ** 2).toFixed(1) + " MB";
  else return (size / 1024 ** 3).toFixed(1) + " GB";
};

export function GetFileType(file: File): string {
  let fileType = file.type;

  if (!fileType) {
    switch (true) {
      case file.name.endsWith(".dat"):
        fileType = "data";
        break;
      case file.name.endsWith(".instprm"):
        fileType = "instrumental";
        break;
      case file.name.endsWith(".cif"):
        fileType = "cif";
        break;
      case file.name.endsWith(".cbf"):
        fileType = "image/cbf";
        break;
      case file.name.endsWith(".meta"):
        fileType = "meta";
        break;
      case file.name.endsWith(".h5"):
        fileType = "hdf5";
        break;
      case file.name.endsWith(".hdf5"):
        fileType = "hdf5";
        break;
      case file.name.endsWith(".hdf"):
        fileType = "hdf5";
        break;
      case file.name.endsWith(".poni"):
        fileType = "poni";
        break;
      case file.name.endsWith(".msk"):
        fileType = "mask";
        break;
      case file.name.endsWith(".edf"):
        fileType = "image/edf";
        break;
      case file.name.endsWith(".npy"):
        fileType = "image/npy";
        break;
      default:
        fileType = "";
        break;
    }
  }
  return fileType;
};

export function convertConstraintsToNumbers(model: any): any {
  const newModel = JSON.parse(JSON.stringify(model)) as any;

  newModel.partitions.forEach(partition => {
    partition.position.forEach(pos => {
      const constraint = pos.constraint;

      constraint.centerMin = parseFloat(constraint.centerMin);
      constraint.centerMax = parseFloat(constraint.centerMax);
      constraint.sigmaMin = parseFloat(constraint.sigmaMin);
      constraint.sigmaMax = parseFloat(constraint.sigmaMax);
      constraint.heightMin = parseFloat(constraint.heightMin);
      constraint.heightMax = parseFloat(constraint.heightMax);
    });
  });

  return newModel;
}
