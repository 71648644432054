import { AzimuthalIntegrationModel, AzimuthalIntegrationPartition } from "../../models/analysis.azimuthalIntegration.model";
import { GlobalState } from "../../store/models";

export const selectAzimuthalIntegration = (state: GlobalState): AzimuthalIntegrationModel => state.azimuthalIntegrationReducer;
export const selectAzimuthalIntegrationNumberOfPartitions = (state: GlobalState): number => state.azimuthalIntegrationReducer.numberOfPartitions;
export const selectAzimuthalIntegrationPartitions = (state: GlobalState): AzimuthalIntegrationPartition[] => state.azimuthalIntegrationReducer.partitions;
export const selectAzimuthalIntegrationXAxisUnit = (state: GlobalState): string => state.azimuthalIntegrationReducer.xAxisUnit;
export const selectAzimuthalIntegrationRange = (state: GlobalState): {lower: number, upper: number} => state.azimuthalIntegrationReducer.range;
export const selectAzimuthalIntegrationNumberOfPoints = (state: GlobalState): number => state.azimuthalIntegrationReducer.numberOfPoints;
export const selectAzimuthalIntegrationAzimuthalRange = (state: GlobalState): {lower: number, upper: number} => state.azimuthalIntegrationReducer.azimuthalRange;
export const selectAzimuthalIntegrationAzimuthalBinning = (state: GlobalState): boolean => state.azimuthalIntegrationReducer.azimuthalBinning;
export const selectAzimuthalIntegrationNumberOfAzimuthalBins = (state: GlobalState): number => state.azimuthalIntegrationReducer.numberOfAzimuthalBins;
export const selectAzimuthalIntegrationImage = (state: GlobalState): string => state.azimuthalIntegrationReducer.activeImagePath;
export const selectAzimuthalIntegrationDataViewFile = (state: GlobalState): string => state.azimuthalIntegrationReducer.dataViewFile;
export const selectAzimuthalIntegrationImageVideo = (state: GlobalState): string[] => state.azimuthalIntegrationReducer.imageVideo;
export const selectAzimuthalIntegrationAnalysis = (state: GlobalState): AzimuthalIntegrationModel['analysis'] => state.azimuthalIntegrationReducer.analysis;
export const selectAzimuthalIntegrationAnalysisXValues = (state: GlobalState): number[] => state.azimuthalIntegrationReducer.analysis.x;
export const selectAzimuthalIntegrationAnalysisIntegration = (state: GlobalState): AzimuthalIntegrationModel["analysis"]["integration"] => state.azimuthalIntegrationReducer.analysis.integration;
export const selectAzimuthalIntegrationAnalysisCaking= (state: GlobalState): AzimuthalIntegrationModel["analysis"]["caking"] => state.azimuthalIntegrationReducer.analysis.caking;
export const selectAzimuthalIntegrationAnalysisInfo = (state: GlobalState): AzimuthalIntegrationModel['analysis']["info"] => state.azimuthalIntegrationReducer.analysis.info;
export const selectAzimuthalIntegrationDataViewFileRow = (state: GlobalState): string => state.azimuthalIntegrationReducer.dataViewFileRow;
export const selectAzimuthalIntegrationImageOptions = (state: GlobalState): AzimuthalIntegrationModel['imageOptions'] => state.azimuthalIntegrationReducer.imageOptions;
export const selectAzimuthalIntegrationImageCombined = (state: GlobalState): {[fileRow: string]: string} => state.azimuthalIntegrationReducer.activeCombinedImagePath;
export const selectAzimuthalIntegrationIntegration = (state: GlobalState): boolean => state.azimuthalIntegrationReducer.integration;
export const selectAzimuthalIntegrationIntegrationResult = (state: GlobalState): AzimuthalIntegrationModel['integrationResult'] => state.azimuthalIntegrationReducer.integrationResult;
export const selectAzimuthalIntegrationCakingResult = (state: GlobalState): AzimuthalIntegrationModel['cakingResult'] => state.azimuthalIntegrationReducer.cakingResult;