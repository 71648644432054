import { compareKeys } from "../../../helpers/general"
import { numberShortener } from "../../../helpers/name"

export const FileHanding = (parameters, rietveldDataFiles: string[]) => {
  let filesMapping = []

  for (const file of rietveldDataFiles) {
    filesMapping.push({ fileName: file })
  }

  filesMapping = filesMapping.sort((a, b) => compareKeys(a.fileName, b.fileName))

  if (parameters) {
    let copyParameters = {}

    for (let key in parameters) {
      let fileName = key.split('/')[1];
      let matchingObject = filesMapping.find(obj => obj.fileName.includes(fileName));

      if (matchingObject) {
        copyParameters[matchingObject.fileName] = parameters[key];
      }
    }
    filesMapping.forEach((file) => {
      if (file.fileName in copyParameters) {
        Object.keys(copyParameters[file.fileName]).forEach((key) => {
          if (key.includes("Mustrain")) return;
          file[key] = numberShortener(copyParameters[file.fileName][key]);
        });
      };
    });
  }

  return filesMapping;
}


export const GraphData = (refinedHistogram) => {
  let refinedHistogramMod = []

  if (refinedHistogram && refinedHistogram.calculations.length) {
    refinedHistogramMod = [
      {
        x: refinedHistogram.x,
        y: refinedHistogram.calculations,
        name: "Calculations",
        type: "linear",
        mode: "lines",
        line: {
          color: "orange",
          width: 2,
        },
      },
      {
        x: refinedHistogram.x,
        y: refinedHistogram.background,
        name: "Background",
        type: "linear",
        mode: "lines",
        line: {
          color: "green",
          width: 2,
        },
      },
      {
        x: refinedHistogram.x,
        y: refinedHistogram.residual,
        name: "Residual",
        type: "linear",
        mode: "lines",
        line: {
          color: "purple",
          width: 2,
        },
      },
      {
        x: refinedHistogram.x,
        y: refinedHistogram.observations,
        name: "Observations",
        type: "linear",
        mode: "lines",
        line: {
          color: "white",
          width: 2,
        },
      },
    ];
  }

  return refinedHistogramMod;
}
