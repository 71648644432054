import { ToggleInputList } from "./toggle-input-list";
import "./toggle-group-input.css";
import { ToggleGroupModel } from "../../../../data/models";

export interface ToggleGroupInputProps {
  toggleGroupModel: ToggleGroupModel;
  values: any;
  validate: boolean;
  onValueChange: (
    dynamodb_name: string,
    value: string | number | boolean
  ) => void;
}

export const ToggleGroupInput = ({
  toggleGroupModel,
  values,
  validate,
  onValueChange,
}: ToggleGroupInputProps) => {
  const toggleValue = values[toggleGroupModel.dynamodb_name] === true;
  const onToggle = (toggle: boolean) => {
    onValueChange(toggleGroupModel.dynamodb_name, toggle);
  };
  const onChange = (dynamodb_name: string, value: string | number) => {
    onValueChange(dynamodb_name, value);
  };
  return (
    <div className={"p-2 flex flex-col bg-primary-600 rounded-md"}>
      <h1 className={"font-bold text-white text-base mb-2"}>
        {toggleGroupModel.label}
      </h1>
      <div className={"flex gap-4"}>
        <div className="flex flex-col flex-1 toggle-group-inputs">
          <ToggleInputList
            disabled={toggleValue}
            validate={validate && !toggleValue}
            key={toggleGroupModel.id + "1"}
            toggleInputsModel={toggleGroupModel.toggle1}
            values={values}
            onValueChange={onChange}
          />{" "}
        </div>
        <div className="flex flex-col flex-1 items-center justify-center toggle-group-switch">
          <ToggleSwitch
            key={toggleGroupModel.id + "switch"}
            checked={toggleValue}
            onToggle={onToggle}
          />
        </div>
        <div className="flex flex-col flex-1 toggle-group-inputs">
          <ToggleInputList
            disabled={!toggleValue}
            validate={validate && toggleValue}
            key={toggleGroupModel.id + "2"}
            toggleInputsModel={toggleGroupModel.toggle2}
            values={values}
            onValueChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

const ToggleSwitch = ({ onToggle, checked }) => {
  const toggleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(e.target.checked);
  };
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={toggleOnChange}
      className={"cursor-pointer toggle"}
    />
  );
};
