import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import DataCalibrationInput from "./data-calibration-input";
import DataCalibrationGraph from "./data-calibration-graph";
import { selectInstanceId } from "../../../redux/general/generalSelectors";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { GET_DATA_CALIBRATION_CALLBACK } from "../../../redux/dataCalibration/dataCalibrationActions";
import { GET_INSTANCE_RUNNING_STATUS, GET_LAMBDA_CACHE, GET_METHOD_DATA } from "../../../redux/project/projectActions";

const DataCalibration = ({ dispatch }: {dispatch: Dispatch}) => {
  const instanceId = useSelector(selectInstanceId)
  const [performedDelete, setPerformedDelete] = useState(false);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  const handleToggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch({ type: GET_DATA_CALIBRATION_CALLBACK })
		dispatch({ type: GET_METHOD_DATA, payload: {type: "dataCalibration"} });
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "dataCalibration"} })
  }, [dispatch])

  useEffect(() => {
		if (intervalId !== undefined) {
			clearInterval(intervalId);
		}

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
				dispatch({ type: GET_INSTANCE_RUNNING_STATUS, payload: { instanceId: instanceId, projectType: "dataCalibration" } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

  return (
    <div className="flex w-full h-full gap-4 flex-1 xl:flex-col flex-grow">
      <div
        className={`${
          isSidebarCollapsed ? 'w-16' : 'w-2/6'
        } flex flex-col bg-primary-800 rounded-md`}
      >
        <div className="flex flex-col border-b border-primary-900 border-solid relative transition-transform overflow-y-auto">
          {!isSidebarCollapsed && (
            <div className="overflow-y-auto h-full">
              <DataCalibrationInput dispatch={dispatch} setPerformedDelete={setPerformedDelete} performedDelete={performedDelete} />
            </div>
          )}
          <button
            onClick={handleToggleSidebar}
            className={`${
              isSidebarCollapsed ? '' : 'right-0 absolute'
            }  top-0 w-16 h-16 rounded-md transition-transform duration-300 bg-primary-600`}
          >
            <FontAwesomeIcon
              icon={faChevronUp}
              rotation={isSidebarCollapsed ? 90 : 270}
              color="white"
              className="transition-transform duration-500"
              size="lg"
            />
          </button>
        </div>
        
      </div>
      <div className="flex flex-col flex-1 bg-primary-800 rounded-md w-2/3">
        <DataCalibrationGraph dispatch={dispatch} isSidebarCollapsed={isSidebarCollapsed} performedDelete={performedDelete} />
      </div>
    </div>
  );
};

export default DataCalibration;
