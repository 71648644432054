import { all, put, select, takeLatest } from "redux-saga/effects";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { waitFiveSeconds } from "../../helpers/redux";
import toast from "react-hot-toast";
import { selectUserToken, selectCurrentProject } from "../project/projectSelectors";
import { PeakFittingModel } from "../../models/analysis.peakFitting.model";
import { GET_PEAK_FITTING, GET_PEAK_FITTING_CALLBACK, SET_PEAK_FITTING_FITTING, SET_PEAK_FITTING_PARAMETERS } from "./peakFittingActions";
import { getPeakFitting, getPeakFittingCallback, preProcessObject } from "./peakFittingHelpers";
import { selectPeakFitting } from "./peakFittingSelectors";


function* get_peak_fitting() {
  try {
    toast.promise(waitFiveSeconds(), {
      loading: "Starting Computing Process",
      success: "Running Peak Fitting...",
      error: "Running Peak Fitting...",
    })

    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let peakFitting: PeakFittingModel = yield select(selectPeakFitting);
    peakFitting = preProcessObject(peakFitting);
    
    const res = yield getPeakFitting(
      token,
      {
        ...peakFitting,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to perform peak fitting. Please try again.")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_peak_fitting_callback() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let peakFitting: PeakFittingModel = yield select(selectPeakFitting);
    peakFitting = preProcessObject(peakFitting);

    const res = yield getPeakFittingCallback(
      token,
      {
        ...peakFitting,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_PEAK_FITTING_PARAMETERS, payload: res.data.parametersData });
    yield put({ type: SET_PEAK_FITTING_FITTING, payload: { data: res.data.fittedData, file: peakFitting.dataViewFile }});
  } catch (error) {
    console.error(error)
    toast.error("Failed to fetch peak fitting results. Please try again.")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

export default function* peakFittingSaga() {
  yield all([
    takeLatest(GET_PEAK_FITTING_CALLBACK, get_peak_fitting_callback),
    takeLatest(GET_PEAK_FITTING, get_peak_fitting),
  ]);
}
