import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { cifFilesDirectories } from "../../../data/cif_files";
import { instrumentalFilesDirectories } from "../../../data/instrumental_files";
import toast from "react-hot-toast";
import { GET_DOWNLOAD } from "../../../redux/project/projectActions";
import { SET_RIETVELD_CIF_FILES, SET_RIETVELD_INSTRUMENTAL_FILE, SET_RIETVELD_CIF_TEMPLATE_FILES, SET_RIETVELD_INSTRUMENTAL_TEMPLATE_FILE, SET_RIETVELD_RANGE_LOWER, SET_RIETVELD_RANGE_HIGHER, GET_RIETVELD_REFINED_HISTOGRAM, SET_RIETVELD_CRYSTALLITE_SIZE, SET_RIETVELD_MUSTRAINS, SET_RIETVELD_DATA_FILES, SET_RIETVELD_WAVELENGTH, SET_RIETVELD_CIF_FILES_ABC } from "../../../redux/rietveld/rietveldActions";
import { selectRietveldCifTemplateFiles, selectRietveldInstrumentalTemplateFile, selectRietveldWavelength, selectRietveldInstrumentalFileWaveLength, selectRietveldRangeHigher, selectRietveldRangeInitial, selectRietveldRangeLower, selectRietveldCrystalliteSize, selectRietveldMustrains, selectRietveldDataFiles, selectRietveldCifFiles, selectRietveldInstrumentalFile, selectRietveldCifFilesABC, selectRietveldDataActiveFiles, selectRietveldRefinedHistogram } from "../../../redux/rietveld/rietveldSelectors";
import { getName } from "../../../helpers/name";
import { FileSelection } from "../shared/file-selection/file-selection";
import WavelengthInput from "../shared/wavelength";
import { CifFileABC } from "../shared/cifFileABC";
import { useEffect } from "react";


const RietveldInput = ({ dispatch  }: { dispatch: Dispatch }) => {
	const cifTemplateFiles = useSelector(selectRietveldCifTemplateFiles);
	const instrumentalTemplateFile = useSelector(selectRietveldInstrumentalTemplateFile);
	const rangeLower = useSelector(selectRietveldRangeLower);
	const rangeHigher = useSelector(selectRietveldRangeHigher);
	const wavelength = useSelector(selectRietveldWavelength);
	const fileWavelength = useSelector(selectRietveldInstrumentalFileWaveLength)
	const initialRange = useSelector(selectRietveldRangeInitial);
	const crystalizeSize = useSelector(selectRietveldCrystalliteSize);
	const mustrains = useSelector(selectRietveldMustrains)
	const dataFiles = useSelector(selectRietveldDataFiles);
	const cifFiles = useSelector(selectRietveldCifFiles);
	const instrumentalFile = useSelector(selectRietveldInstrumentalFile);
	const cifFilesABC = useSelector(selectRietveldCifFilesABC);
	const dataActiveFiles = useSelector(selectRietveldDataActiveFiles);
	const refinedHistogram = useSelector(selectRietveldRefinedHistogram);

	useEffect(() => {
    let mustrains = {}

    cifFiles.forEach((cifFile) => {
      mustrains[cifFile] = 0
    })

    cifTemplateFiles.forEach((cifFile) => {
      mustrains[cifFile] = 0
    })

    dispatch({ type: SET_RIETVELD_MUSTRAINS, payload: mustrains })

	}, [dispatch, cifFiles, cifTemplateFiles]);

	return (
		<>
		<div className={"flex flex-col flex-1 bg-primary-800 rounded-md"}>
			<h1 className={"text-white font-bold mb-2"}>Rietveld</h1>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Select Files
				</h1>
				<FileSelection
					dispatch={dispatch}
					selected={dataFiles}
					setSelected={files => dispatch({ type: SET_RIETVELD_DATA_FILES, payload: files })}
					title="Select Data File(s)"
					extensions={[".dat"]}
					defaultExtension={".dat"}
					multiple={true}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={cifFiles}
					selectedTemplates={cifTemplateFiles}
					setSelected={files => dispatch({ type: SET_RIETVELD_CIF_FILES, payload: files })}
					setSelectedTemplates={files => dispatch({ type: SET_RIETVELD_CIF_TEMPLATE_FILES, payload: files })}
					title="Select Cif File(s)"
					extensions={[".cif"]}
					defaultExtension={".cif"}
					multiple={true}
					templates={cifFilesDirectories}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={instrumentalFile}
					selectedTemplates={instrumentalTemplateFile}
					setSelected={file => dispatch({ type: SET_RIETVELD_INSTRUMENTAL_FILE, payload: file })}
					setSelectedTemplates={file => dispatch({ type: SET_RIETVELD_INSTRUMENTAL_TEMPLATE_FILE, payload: file })}
					title="Select Instrumental File"
					extensions={[".instprm"]}
					defaultExtension=".instprm"
					multiple={false}
					templates={instrumentalFilesDirectories}
				/>
				<br />
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Inputs
				</h1>
				<WavelengthInput
					dispatch={dispatch}
					fileWaveLength={fileWavelength}
					wavelength={wavelength}
					setWavelength={(wavelength: number) => dispatch({ type: SET_RIETVELD_WAVELENGTH, payload: wavelength })}
					method={"rietveld"}
					instrumentalFile={instrumentalFile}
					instrumentalTemplateFile={instrumentalTemplateFile}
				/>
				<CifFileABC
					dispatch={dispatch}
					cifFilesABC={cifFilesABC}
					cifFiles={cifFiles}
					cifTemplateFiles={cifTemplateFiles}
					analysisMethod={"rietveld"}
					setABCValues={(abcValues) => dispatch({ type: SET_RIETVELD_CIF_FILES_ABC, payload: abcValues })}
				/>
				<div className={"flex flex-row items-center justify-center"}>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Range Lower
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={rangeLower}
								onChange={(event) => {dispatch({type: SET_RIETVELD_RANGE_LOWER, payload: parseFloat(event.target.value)})}}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {dispatch({type: SET_RIETVELD_RANGE_LOWER, payload: initialRange[0]})}}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Range Upper
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={rangeHigher}
								onChange={(event) => {dispatch({type: SET_RIETVELD_RANGE_HIGHER, payload: parseFloat(event.target.value)}); }}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {dispatch({type: SET_RIETVELD_RANGE_HIGHER, payload: initialRange[1]}); }}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
				</div>
				<div className={"flex flex-row items-center justify-center mt-4"}>
					<div className={"flex flex-col w-1/2 ml-5 mr-5"}>
						<label className={"text-white text-center"}>
							Micrometer [μm]
						</label>
						<input
							className={`${false ? "border-red-500" : "border-gray-500"}
								bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
							type={"number"}
							step="0.0001"
							onChange={(event) => {
								dispatch({ type: SET_RIETVELD_CRYSTALLITE_SIZE, payload: parseFloat(event.target.value) })
							}}
							value={!crystalizeSize ? "" : crystalizeSize}
						/>
					</div>
				</div>
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 mt-10 rounded-md w-full text-white"}>
					<h1 className={"font-bold text-white text-base mb-2"}>
						Microstrains [x10^6]
					</h1>
					<div className={"flex flex-col items-center"}>
						{Object.keys(mustrains).map((key: string) => (
							<div className={"p-2 flex flex-col w-2/4 items-center"} key={key}>
								<p>{getName(key)}</p>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.0001"
									onChange={(event) => {
										dispatch({ type: SET_RIETVELD_MUSTRAINS, payload: {key: key, value: parseFloat(event.target.value)} })
									}}
									value={mustrains[key] ? mustrains[key] : ""}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<button
				className={"cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded-md"}
				onClick={() => {
			if(!dataActiveFiles.length) {
				toast.error("Please Select Data Files");
				return;
			}

			if (!rangeLower || !rangeHigher) {
				toast.error("Please Enter Range Lower and Range Higher");
				return;
			}

			if (!instrumentalFile && (!instrumentalTemplateFile && !wavelength)) {
				toast.error("Please Select Instrumental File or Enter Wavelength");
				return;
			}

			if (!cifFiles.length && !cifTemplateFiles.length) {
				toast.error("Please Select CIF Files");
				return;
			}
			
					dispatch({ type: GET_RIETVELD_REFINED_HISTOGRAM })
				}}
			>
				Save and calculate
			</button>
			<button
				className={`cursor-pointer ${dataFiles.length && refinedHistogram && refinedHistogram.calculations && refinedHistogram.calculations.length ?  "text-white bg-primary-600" : "text-gray-600 bg-primary-700"} font-bold m-4 p-4 rounded-md`}
				onClick={() => {
					dispatch({ type: GET_DOWNLOAD, payload: { type: "rietveld" } })
				}}
			>
				Download Data
			</button>
		</div>
	</>
	);
};

export default RietveldInput;
