import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../store/models";
import "./../../../styles/load-progress.css";


const selectOperationInProgress = (state: GlobalState) =>
  state.generalReducer.operationInProgress;

export default function LoadProgress() {
  const operationInProgress = useSelector(selectOperationInProgress);
  return operationInProgress ? (
    <div className={"progress-overlay z-50"}>
      <Loader type="Oval" color="#68d391" height={200} width={200} />
    </div>
  ) : (
    <></>
  );
}
