import { DropDownOptions } from "./models";

export const CH00: DropDownOptions = {
	id: "CH00",
	label: "CH00",
	options: [
		{
			id: "CH00",
			label: "CH00",
			values: [
				{ label: "bcc-Fe", value: 0.285 },
                { label: "fcc-Fe", value: 0.33 },
            ],
        },
	],
};