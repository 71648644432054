import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { compareKeysV2 } from "../../../../helpers/general";
import { selectStressStrainStressPipelinePeakParameters, selectStressStrainStressResult } from "../../../../redux/stressStrain/stressStrainSelectors";

function extractFrameAndBin(dataFile: string): string {
  const parts = dataFile.split('/');

  const framePart = parts.find(part => part.startsWith('frame_'));
  const binPart = parts[parts.length - 1].split('-')[1].replace('.dat', '');

  return `${framePart}/${binPart}`;
}

const StressStrainReport = ({ dispatch }: { dispatch: Dispatch }) => {
  const fitting = useSelector(selectStressStrainStressPipelinePeakParameters);
  const result = useSelector(selectStressStrainStressResult)

  const [peakCenterData, setPeakCenterData] = useState<any>({});
  const [peakBreadth, setPeakBreadth] = useState<any>({});
  const [strainXX, setStrainXX] = useState<any>({});
  const [strainXZ, setStrainXZ] = useState<any>({});
  const [strainZZ, setStrainZZ] = useState<any>({});
  const [stressXX, setStressXX] = useState<any>({});
  const [stressXZ, setStressXZ] = useState<any>({});
  const [stressZZ, setStressZZ] = useState<any>({});
  const [vonMises, setVonMises] = useState<any>({});

  const sortedDataRef = useRef({
    dataFiles: [],
    peakParameters: {},
  });
  
  useEffect(() => {
    let currentData = {
      strain_xx: {},
      strain_xz: {},
      strain_zz: {},
      stress_xx: {},
      stress_xz: {},
      stress_zz: {},
      von_mises: {},
    };
  
    let { dataFiles, peakParameters } = sortedDataRef.current;
  
    let newDataFiles = Object.keys(result).filter(
      (dataFile) => !dataFiles.includes(dataFile)
    );
  
    if (newDataFiles.length > 0) {
      let newData = newDataFiles.map((dataFile) => {
        let peaks = Object.keys(result[dataFile])
          .sort()
          .map((peak) => {
            const peakData = result[dataFile][peak];
            return {
              ...peakData,
              peak: peak,
            };
          });
  
        return {
          dataFile: dataFile,
          peaks: peaks,
        };
      });
  
      newData.sort((a, b) => compareKeysV2(a.dataFile, b.dataFile));
      dataFiles = [...dataFiles, ...newDataFiles];
  
      newData.forEach((item) => {
        item.peaks.forEach((peakData) => {
          const peak = peakData.peak;
          if (!peakParameters[peak]) {
            peakParameters[peak] = {
              strainXX: [],
              strainXX_std_dev: [],
              strainXZ: [],
              strainXZ_std_dev: [],
              strainZZ: [],
              strainZZ_std_dev: [],
              stressXX: [],
              stressXX_std_dev: [],
              stressXZ: [],
              stressXZ_std_dev: [],
              stressZZ: [],
              stressZZ_std_dev: [],
              vonMises: [],
              vonMises_std_dev: [],
            };
          }
  
          peakParameters[peak].strainXX.push(peakData.strain_xx);
          peakParameters[peak].strainXX_std_dev.push(peakData["strain_xx: std_dev"]);
          peakParameters[peak].strainXZ.push(peakData.strain_xz);
          peakParameters[peak].strainXZ_std_dev.push(peakData["strain_xz: std_dev"]);
          peakParameters[peak].strainZZ.push(peakData.strain_zz);
          peakParameters[peak].strainZZ_std_dev.push(peakData["strain_zz: std_dev"]);
          peakParameters[peak].stressXX.push(peakData["stress_xx [MPa]"]);
          peakParameters[peak].stressXX_std_dev.push(peakData["stress_xx: std_dev [MPa]"]);
          peakParameters[peak].stressXZ.push(peakData["stress_xz [MPa]"]);
          peakParameters[peak].stressXZ_std_dev.push(peakData["stress_xz: std_dev [MPa]"]);
          peakParameters[peak].stressZZ.push(peakData["stress_zz [MPa]"]);
          peakParameters[peak].stressZZ_std_dev.push(peakData["stress_zz: std_dev [MPa]"]);
          peakParameters[peak].vonMises.push(peakData["von_mises [MPa]"]);
          peakParameters[peak].vonMises_std_dev.push(peakData["von_mises: std_dev [MPa]"]);
        });
      });
  
      sortedDataRef.current = { dataFiles, peakParameters };
    } else {
      return;
    }

    Object.keys(peakParameters).forEach((peak) => {
      currentData.strain_xx[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].strainXX,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Strain XX`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].strainXX_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];

      currentData.strain_xz[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].strainXZ,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Strain XZ`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].strainXZ_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];

      currentData.strain_zz[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].strainZZ,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Strain ZZ`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].strainZZ_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];

      currentData.stress_xx[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].stressXX,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Stress XX`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].stressXX_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];

      currentData.stress_xz[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].stressXZ,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Stress XZ`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].stressXZ_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];

      currentData.stress_zz[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].stressZZ,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Stress ZZ`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].stressZZ_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];

      currentData.von_mises[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].vonMises,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Von Mises`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].vonMises_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];
    });

    setStrainXX(currentData.strain_xx);
    setStrainXZ(currentData.strain_xz);
    setStrainZZ(currentData.strain_zz);
    setStressXX(currentData.stress_xx);
    setStressXZ(currentData.stress_xz);
    setStressZZ(currentData.stress_zz);
    setVonMises(currentData.von_mises);
  }, [result]);



  const sortedFittingDataRef = useRef({
    dataFiles: [],
    peakParameters: {},
  });

  useEffect(() => {
    let peakCenterGraphs = {};
    let peakBreadthGraphs = {};

    let { dataFiles, peakParameters } = sortedFittingDataRef.current;

    let newDataFiles = Object.keys(fitting).filter(
      (dataFile) => !dataFiles.includes(extractFrameAndBin(dataFile))
    );

    if (newDataFiles.length > 0) {
      let newData = newDataFiles.map((dataFile) => {
        let peaks = Object.keys(fitting[dataFile])
          .sort()
          .map((peak) => {
            const peakData = fitting[dataFile][peak];
            return {
              peak: peak,
              center: peakData.center,
              center_std_dev: peakData.center_std_dev,
              breadth: peakData.breadth,
              breadth_std_dev: peakData.breadth_std_dev,
            };
          });

        return {
          dataFile: extractFrameAndBin(dataFile),
          peaks: peaks,
        };
      });

      newData.sort((a, b) => compareKeysV2(a.dataFile, b.dataFile));
      dataFiles = [...dataFiles, ...newData.map((item) => item.dataFile)];

      while (dataFiles.length > 3000) {
        dataFiles.shift();
      }

      newData.forEach((item) => {
        item.peaks.forEach((peakData) => {
          const peak = peakData.peak;
          if (!peakParameters[peak]) {
            peakParameters[peak] = {
              center: [],
              center_std_dev: [],
              breadth: [],
              breadth_std_dev: [],
            };
          }

          peakParameters[peak].center.push(peakData.center);
          peakParameters[peak].center_std_dev.push(peakData.center_std_dev);
          peakParameters[peak].breadth.push(peakData.breadth);
          peakParameters[peak].breadth_std_dev.push(peakData.breadth_std_dev);
        });
      });

      sortedFittingDataRef.current = { dataFiles, peakParameters };
    } else {
      return
    }

    Object.keys(peakParameters).forEach((peak) => {
      peakCenterGraphs[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].center,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Center`,
          line: {
            color: "#FFA500",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#FF4500",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].center_std_dev,
            visible: true,
            color: "#FF4500",
          },
        },
      ];

      peakBreadthGraphs[peak] = [
        {
          x: dataFiles,
          y: peakParameters[peak].breadth,
          type: "scatter",
          mode: "lines+markers",
          name: `${peak} Breadth`,
          line: {
            color: "#32CD32",
            width: 2,
            dash: "solid",
          },
          marker: {
            color: "#008000",
            size: 8,
          },
          error_y: {
            type: "data",
            array: peakParameters[peak].breadth_std_dev,
            visible: true,
            color: "#008000",
          },
        },
      ];
    });

    setPeakCenterData(peakCenterGraphs);
    setPeakBreadth(peakBreadthGraphs);
  }, [fitting]);

  const mapping = [
    { key: "Peak Data - Center", data: peakCenterData, yLabel: "2θ" },
    { key: "Peak Data - Breadth", data: peakBreadth, yLabel: "2θ" },
    { key: "Strain XX", data: strainXX, yLabel: "Strain" },
    { key: "Strain XZ", data: strainXZ, yLabel: "Strain" },
    { key: "Strain ZZ", data: strainZZ, yLabel: "Strain" },
    { key: "Stress XX", data: stressXX, yLabel: "Stress [MPa]" },
    { key: "Stress XZ", data: stressXZ, yLabel: "Stress [MPa]" },
    { key: "Stress ZZ", data: stressZZ, yLabel: "Stress [MPa]" },
    { key: "Von Mises", data: vonMises, yLabel: "Stress [MPa]" },
  ]

  return (
    <div className="bg-primary-600 rounded-md w-full h-full p-4 overflow-y-scroll px-10">
      {mapping.map((item) => (
        <>
        <div className="flex justify-between items-center text-white text-4xl font-bold mt-16">
          <h1>{item.key}</h1>
        </div>
        <div className="flex flex-wrap mt-5">
          {Object.keys(item.data).map((peak) => (
            <div key={peak} className="w-1/2 px-2 justify-center" style={{ height: "700px" }}>
              <Plot
                data={item.data[peak]}
                layout={{
                  autosize: true,
                  paper_bgcolor: "rgba(0,0,0,0)",
                  plot_bgcolor: "rgba(0,0,0,0)",
                  legend: {
                    orientation: "h",
                    font: {
                      family: "Arial, sans-serif",
                      size: 14,
                      color: "#EDEDED",
                    },
                    x: 0.5,
                    y: -0.2,
                    xanchor: "center",
                  },
                  xaxis: {
                    color: "#EDEDED",
                    gridcolor: "#5A5A5A",
                    nticks: 15,
                  },
                  yaxis: {
                    color: "#EDEDED",
                    gridcolor: "#5A5A5A",
                    title: {
                      text: item.yLabel,
                      font: {
                        family: "Arial, sans-serif",
                        size: 18,
                        color: "#EDEDED",
                      },
                    },
                  },
                  modebar: {
                    orientation: "h",
                    bgcolor: "#333335",
                    color: "#EDEDED",
                    activecolor: "#FF1493",
                  },
                  hovermode: "closest",
                  title: {
                    text: peak,
                    font: {
                      family: "Arial, sans-serif",
                      size: 22,
                      color: "#EDEDED",
                    },
                    x: 0.5,
                    xanchor: "center",
                  },
                }}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          ))}
        </div>
        </>
      ))}
    </div>
  );
};

export default StressStrainReport;
