import { useState } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { selectProjectSettings, selectProjectName } from "../../../redux/project/projectSelectors";
import { CREATE_PROJECT, SET_ACTIVE_GEOMETRY, SET_ACTIVE_LENGTH, SET_DATABASE, SET_INSTRUMENT, SET_PROJECT_NAME } from "../../../redux/project/projectActions";

export default function CreateProject({ dispatch }: { dispatch: Dispatch }) {
  const projectSettings = useSelector(selectProjectSettings);
  const projectName = useSelector(selectProjectName);

  const [activeTab, setActiveTab] = useState("1d_diffraction_patterns");

  const setActiveLength = (length: string) => {
    dispatch({ type: SET_ACTIVE_LENGTH, payload: length });
  };
  const setActiveGeometry = (geo: string) => {
    dispatch({ type: SET_ACTIVE_GEOMETRY, payload: geo });
  };

  const setProjectName = (name: string) => {
    dispatch({ type: SET_PROJECT_NAME, payload: name });
  };

  const setInstrument = (instr: string) => {
    dispatch({ type: SET_INSTRUMENT, payload: instr });
  };

  const setDatabase = (database: string) => {
    dispatch({ type: SET_DATABASE, payload: database });
  };

  return (
    <>
    <div className={"flex flex-col"}>
      <h1 className={"font-bold text-white text-base mb-4 pl-4 pt-4"}>
        Select Method
      </h1>
    </div>
    <div className={"flex justify-between gap-2 sm:flex-col pl-2 pr-2 pb-2"}>
      <div
        className={`${
          activeTab === "1d_diffraction_patterns" ? "bg-primary-600" : "bg-primary-700"
        } flex-1 text-white text-sm text-center font-bold p-4  rounded-md cursor-pointer`}
        onClick={() => setActiveTab("1d_diffraction_patterns")}
      >
        Diffraction
      </div>
      <div
        className={`${
          activeTab === "1d_sas_pattern" ? "bg-primary-600" : "bg-primary-700"
        } flex-1 text-white text-sm text-center font-bold p-4  rounded-md cursor-pointer`}
        onClick={() => setActiveTab("1d_sas_pattern")}
      >
        Small-angle scattering
      </div>
    </div>
    {activeTab === "1d_sas_pattern" && (
      <>
      <div className={"flex"}>
        <div
          className={
            "flex-1 flex flex-col p-4 border-r border-primary-900 border-solid xl:border-b xl:border-r-0"
          }
        >
          <h1 className={"font-bold text-white text-base mb-4"}>
            Material database
          </h1>
          <div className={"flex flex-col mb-4"}>
            <label className={"text-primary-500"}>Project name</label>
            <input
              type="text"
              className={
                "mt-2 bg-primary-850 p-2 rounded-md border border-white border-solid text-white z-1"
              }
              value={projectName}
              onChange={(event) => setProjectName(event.target.value)}
              required={activeTab === "1d_sas_pattern"}
            />
            {!projectName && (
              <label className={"text-red-600"}>
                Input a name to start a project
              </label>
            )}

            <label className={"text-primary-500 mt-4"}>Database selection</label>
            <select
              className={
                "text-small font-bold mt-2 p-4 rounded-md bg-primary-600 text-primary-400"
              }
              onChange={(ev) => setDatabase(ev.target.value)}
              value={projectSettings.database}
            >
              <option value="Fe-based-alloys">Fe-based alloys</option>
              <option value="hard_metals_database">Hard metals database</option>
            </select>
          </div>

          <div className={"flex flex-col mb-4"}>
            <label className={"text-primary-500 mb-2"}>
              Scattering length density
            </label>
            <div className={"flex w-full gap-4"}>
              <div
                className={`${
                  projectSettings.activeLength === "auto"
                    ? "bg-primary-600"
                    : "bg-primary-700"
                } p-4 rounded-md cursor-pointer text-primary-400 font-bold`}
                onClick={() => setActiveLength("auto")}
              >
                Auto
              </div>
              <div
                className={`${
                  projectSettings.activeLength === "manual"
                    ? "bg-primary-600"
                    : "bg-primary-700"
                } p-4 rounded-md cursor-pointer text-primary-400 font-bold`}
                onClick={() => setActiveLength("manual")}
              >
                Manual
              </div>
            </div>
          </div>
          <div className={"flex flex-col mb-4"}>
            <label className={"text-primary-500"}>Shape factor</label>
            <select
              className={
                "text-small font-bold mt-2 p-4 rounded-md bg-primary-600 text-primary-400"
              }
            >
              <option value="Fe-based-alloys">Spherical</option>
            </select>
          </div>
        </div>
        <div
          className={
            "flex-1 flex flex-col p-4 w-1/3"
          }
        >
          <h1 className={"font-bold text-white text-base mb-4"}>
            Experimental parameters
          </h1>
          <div className={"flex flex-col mb-4"}>
            <div className={"flex flex-col mb-4"}>
              <label className={"text-primary-500"}>
                Instrument name and facility
              </label>
              <select
                className={
                  "text-small font-bold mt-2 p-4 rounded-md bg-primary-600 text-primary-400"
                }
                onChange={(ev) => setInstrument(ev.target.value)}
                value={projectSettings.instrument}
              >
                <option value="instrument_independent">
                  Instrument independent
                </option>
                <option value="iil_d33">ILL D33</option>
                <option value="mlz_sans1">MLZ SANS1</option>
                <option value="isis_mantid">ISIS Mantid</option>
              </select>
            </div>
            <div className={"flex flex-col mb-4"}>
              <label className={"text-primary-500 mb-2"}>
                Instrumental geometry
              </label>
              <div className={"flex w-full gap-4"}>
                <div
                  className={`${
                    projectSettings.activeGeometry === "pin"
                      ? "bg-primary-600"
                      : "bg-primary-700"
                  } p-4 rounded-md cursor-pointer text-primary-400 font-bold`}
                  onClick={() => setActiveGeometry("pin")}
                >
                  Pinhole
                </div>
                <div
                  className={`${
                    projectSettings.activeGeometry === "slit"
                      ? "bg-primary-600"
                      : "bg-primary-700"
                  } p-4 rounded-md cursor-pointer text-primary-400 font-bold`}
                  onClick={() => setActiveGeometry("slit")}
                >
                  Slit-smeared
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )}
    {activeTab !== "1d_sas_pattern" && (
      <div className="p-4 flex flex-col items-center mt-8">
        <h1 className="font-bold text-white text-4xl pb-4">
          Project Name
        </h1>
        <div className="flex flex-col w-full items-center mt-1">
          <input
            type="text"
            placeholder="Enter project name"
            className="mt-2 bg-primary-850 py-3 px-4 text-lg rounded-lg border-2 border-white text-white w-96 shadow-lg focus:outline-none focus:border-primary-500 focus:shadow-outline"
            value={projectName}
            onChange={(event) => setProjectName(event.target.value)}
            required={activeTab !== "1d_sas_pattern"}
            id="projectName"
          />
          <button
            type="button"
            className="mt-4 bg-primary-500 text-white py-3 px-4 text-lg rounded-lg w-96 shadow transition duration-300 ease-in-out hover:bg-primary-600 focus:outline-none focus:shadow-outline"
            onClick={() => {
              dispatch({ type: CREATE_PROJECT, payload: { projectName }});
              setProjectName("");
            }}
          >
            Create Project
          </button>
        </div>
      </div>
    )}
    </>
  );
}