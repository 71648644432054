import { all, put, select, takeLatest } from "redux-saga/effects";
import { InstrumentalFileCreationModel } from "../../models/analysis.instrumentalFileCreation.model";
import { selectInstrumentalFileCreation } from "./instrumentalFileCreationSelectors";
import { selectCurrentProject, selectUserToken } from "../project/projectSelectors";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { getInstrumentalFileCreation, getInstrumentalFileCreationCallback } from "./instrumentalFileCreationHelpers";
import { GET_INSTRUMENTAL_FILE_CREATION, GET_INSTRUMENTAL_FILE_CREATION_CALLBACK, SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS } from "./instrumentalFileCreationActions";
import toast from "react-hot-toast";
import { waitFiveSeconds } from "../../helpers/redux";


function* get_instrumental_file_creation() {
  try {
    const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation);    

    toast.promise(waitFiveSeconds(), {
      loading: "Starting Refinement",
      success: "Refinement Running",
      error: "Refinement Running",
    })

    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getInstrumentalFileCreation(
      token,
      currentProject.userId,
      currentProject.projectId,
      instrumentalFileCreation.cifFile,
      instrumentalFileCreation.cifTemplateFile,
      instrumentalFileCreation.instrumentalTemplateFile,
      instrumentalFileCreation.dataFiles,
      instrumentalFileCreation.dataActiveFiles,
      instrumentalFileCreation.wavelength,
      instrumentalFileCreation.crystalliteSize,
      instrumentalFileCreation.microstrain,
      instrumentalFileCreation.refinementRange.lower,
      instrumentalFileCreation.refinementRange.upper,
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to Call API endpoint, check inputs")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_instrumental_file_creation_callback() {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation);    

    const res = yield getInstrumentalFileCreationCallback(
      token,
      currentProject.projectId,
      instrumentalFileCreation.dataViewFile
    )

    yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS, payload: res.data });
  } catch (error) {
    console.error(error)
    toast.error("Failed to load data")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });

    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

export default function* instrumentalFileCreationSaga() {
  yield all([
    takeLatest(GET_INSTRUMENTAL_FILE_CREATION, get_instrumental_file_creation),
    takeLatest(GET_INSTRUMENTAL_FILE_CREATION_CALLBACK, get_instrumental_file_creation_callback),
  ]);
}
