import Loader from "react-loader-spinner";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getInInInlineOperationInProgress, getInInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { Dispatch } from "redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {  SET_RIETVELD_RANGE } from "../../../redux/rietveld/rietveldActions";
import { selectRietveldHistogram, selectRietveldRefinedHistogram } from "../../../redux/rietveld/rietveldSelectors";
import { GraphData } from "./helpers";
import { HistogramGraph } from "../shared/graphs/histogram";
import { GraphConfig, GraphLayout, GraphStyling } from "../../../constants/graphs";


const RietveldGraph = ({ dispatch }: {dispatch: Dispatch}) => {
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress)
  const inInInlineOperationInProgress = useSelector(getInInInlineOperationInProgress)
  const histogram = useSelector(selectRietveldHistogram)
  const refinedHistogram = useSelector(selectRietveldRefinedHistogram)

  const [chartData, setChartData] = useState<any>([]);
  const [chartShapes, setChartShapes] = useState<any>([]);

  const handleClick = (event: any) => {
    if (event.points.length > 0) {
      dispatch({ type: SET_RIETVELD_RANGE, payload: event.points[0].x });
      toast.success("Updated Range")
    }
  };

  useEffect(() => {
    const histogramGraphData = HistogramGraph(histogram);
    const refinedHistogramData = GraphData(refinedHistogram);

    if (refinedHistogramData.length) {
      histogramGraphData.histogramData.shift();
    }

    setChartData([ ...histogramGraphData.histogramData, ...refinedHistogramData] );
    setChartShapes([ ...histogramGraphData.phasesShapes ]);
  }, [histogram, refinedHistogram]);

  return (
    <div className="relative">
      {(inInlineOperationInProgress || inInInlineOperationInProgress) &&
        <div className={"absolute top-2 right-2"}>
          <Loader type="Oval" color="#68d391" height={25} width={25} />
        </div>
      }
      <div className={"flex"}>
      </div>
      <div className={"flex justify-around"}>
        <Plot
          data={chartData}
          layout={{
            ...GraphLayout,
            shapes: chartShapes,
          }}
          config={GraphConfig}
          useResizeHandler={true}
          style={GraphStyling}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default RietveldGraph;
