export const getChartSeriesDataPlotly = (
  obj: any,
  extraX?: string,
  extraY?: {
    label: string;
    dynamo_db_name: string;
    inner_name?: string;
    table_dynamo_db_name?: string;
  }[]
) => {
  const dataSets = [];
  const propNames = Object.getOwnPropertyNames(obj);

  let finalDataSets = [];
  if (extraY && extraX !== "q") {
    if (extraY && extraX === "data_file_name") {
      extraY.forEach((x) => {
        if (
          x.inner_name &&
          x.table_dynamo_db_name &&
          Array.isArray(obj[x.table_dynamo_db_name])
        ) {
          let counter = 1;
          for (const tableRow of obj[x.table_dynamo_db_name]) {
            finalDataSets.push({
              x: [obj.fileKey],
              y: [tableRow[x.inner_name]],
              isReportExtra: true,
              name:
                (obj.fileKey || obj.fullFileKey) +
                "_" +
                x.label +
                "_" +
                counter,
              type: "scatter",
              mode: "markers",
              marker: {
                size: [10],
              },
            });
            counter++;
          }
        } else {
          finalDataSets.push({
            x: [obj.fileKey],
            y: [obj[x.dynamo_db_name]],
            isReportExtra: true,
            name: (obj.fileKey || obj.fullFileKey) + "_" + x.label,
            type: "scatter",
            mode: "markers",
            marker: {
              size: [10],
            },
          });
        }
      });
      return finalDataSets;
    } else {
      extraY.forEach((x) => {
        if (
          x.inner_name &&
          x.table_dynamo_db_name &&
          Array.isArray(obj[x.table_dynamo_db_name])
        ) {
          let counter = 1;
          for (const tableRow of obj[x.table_dynamo_db_name]) {
            finalDataSets.push({
              x: [obj[extraX]],
              y: [tableRow[x.inner_name]],
              isReportExtra: true,
              name:
                (obj.fileKey || obj.fullFileKey) +
                "_" +
                x.label +
                "_" +
                counter,
              type: "scatter",
              mode: "markers",
              marker: {
                size: [10],
              },
            });
            counter++;
          }
        } else {
          finalDataSets.push({
            x: [obj[extraX]],
            y: [obj[x.dynamo_db_name]],
            isReportExtra: true,
            name: (obj.fileKey || obj.fullFileKey) + "_" + x.label,
            type: "scatter",
            mode: "markers",
            marker: {
              size: [10],
            },
          });
        }
      });
      return finalDataSets;
    }
  } else {
    for (const propName of propNames) {
      const propVal = obj[propName];

      if (propVal && Array.isArray(propVal)) {
        const propNameNoSuffix = propName.substring(0, propName.length - 2);
        if (propName.endsWith("_x")) {
          if (Array.isArray(propVal[0])) {
            for (let i = 0; i < propVal.length; i++) {
              dataSets.push({
                name: propNameNoSuffix + "_" + i,
                value: propVal[i],
                isX: true,
              });
            }
          } else {
            dataSets.push({
              name: propNameNoSuffix,
              value: propVal,
              isX: true,
            });
          }
        } else if (propName.endsWith("_y")) {
          if (Array.isArray(propVal[0])) {
            for (let i = 0; i < propVal.length; i++) {
              dataSets.push({
                name: propNameNoSuffix + "_" + i,
                value: propVal[i],
                isY: true,
              });
            }
          } else {
            dataSets.push({
              name: propNameNoSuffix,
              value: propVal,
              isY: true,
            });
          }
        } else {
          // table outputs
          propVal.forEach((tRow, i) => {
            const tRowPropNames = Object.getOwnPropertyNames(tRow);
            if (tRowPropNames.length === 2) {
              // only rows with 2 elements can be plot
              dataSets.push({
                name: `${propName}_${i + 1}`,
                value: [tRow[tRowPropNames[0]]],
                isX: true,
              });
              dataSets.push({
                name: `${propName}_${i + 1}`,
                value: [tRow[tRowPropNames[1]]],
                isY: true,
              });
            }
          });
        }
      }
    }

    for (const axisDataSet of dataSets) {
      if (axisDataSet.isX) {
        const ySet = dataSets.find((x) => x.name === axisDataSet.name && x.isY);
        if (ySet) {
          if (finalDataSets.some((x) => x.name === ySet.name)) {
            continue;
          }
          finalDataSets.push({
            x: axisDataSet.value,
            y: ySet.value,
            name: axisDataSet.name,
            type: "scatter",
            mode: "lines+markers",
          });
        }
      }
    }
  }
  return finalDataSets;
};

export const transformChartDataForExport = (
  chartData: any,
  selectedX?: string
) => {
  let parsedChartData = [];
  let titles = [];
  let row = [];

  if (selectedX !== "q") {
    if (selectedX === "data_file_name") {
      titles.push("X-data_file_name", "Y-value");
      parsedChartData.push(titles);

      for (let i = 0; i < chartData.length; i++) {
        row.push(chartData[i].name);
        row.push(chartData[i].y);
        parsedChartData.push(row);
        row = [];
      }
    } else {
      titles.push(" ", "X-" + selectedX, "Y-value");
      parsedChartData.push(titles);

      for (let i = 0; i < chartData.length; i++) {
        row.push(chartData[i].name);
        row.push(chartData[i].x);
        row.push(chartData[i].y);
        parsedChartData.push(row);
        row = [];
      }
    }
  } else {
    for (let i = 0; i < chartData.length; i++) {
      titles.push("x_" + chartData[i].name);
      titles.push("y_" + chartData[i].name);
    }
    parsedChartData.push(titles);
    if (chartData && chartData.length > 0) {
      for (let i = 0; i < chartData[0].x.length; i++) {
        row = [];
        for (let j = 0; j < chartData.length; j++) {
          row.push(chartData[j].x[i]);
          row.push(chartData[j].y[i]);
          continue;
        }
        parsedChartData.push(row);
      }
    }
  }
  return parsedChartData;
};
