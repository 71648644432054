export const colorHistogram = [
  "#ff7f0e",
  "#2ca02c",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
  "#1f77b4",
  "#d62728",
];

export const colorsGraph = [
  "#FFFFFF", // White
  "#FF0000", // Red
  "#00FF00", // Lime Green
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#FF00FF", // Magenta
  "#00FFFF", // Cyan
  "#FFA500", // Orange
  "#800080", // Purple
  "#008000", // Dark Green
  "#000080", // Navy Blue
  "#FFC0CB", // Pink
  "#FFD700", // Gold
  "#FF1493", // Deep Pink
  "#00CED1", // Dark Turquoise
  "#800000", // Maroon
  "#8A2BE2", // Blue Violet
  "#DC143C", // Crimson
  "#008080", // Teal
  "#FF4500",  // Orange Red
  "#FF69B4", // Hot Pink
  "#00BFFF", // Deep Sky Blue
  "#FF6347", // Tomato
  "#FF00FF", // Fuchsia
  "#FF8C00", // Dark Orange
  "#FFA07A", // Light Salmon
  "#FFDAB9", // Peach Puff
  "#FFB6C1", // Light Pink
  "#FF69B4", // Hot Pink
  "#FFC0CB", // Pink
  "#FFA07A", // Light Salmon
  "#FFDAB9", // Peach Puff
  "#7CFC00", // Lawn Green
  "#40E0D0", // Turquoise
  "#BDB76B", // Dark Khaki
  "#808000", // Olive
  "#00FF7F", // Spring Green
  "#00FA9A", // Medium Spring Green
  "#ADFF2F", // Green Yellow
  "#FF8C69", // Salmon
  "#CD853F", // Peru
  "#BA55D3", // Medium Orchid
  "#4B0082", // Indigo
  "#6A5ACD", // Slate Blue
  "#7B68EE", // Medium Slate Blue
  "#9ACD32", // Yellow Green
  "#20B2AA", // Light Sea Green
  "#8B4513", // Saddle Brown
  "#556B2F", // Dark Olive Green
  "#FF4500", // Orange Red
  "#C71585", // Medium Violet Red
  "#800080", // Purple
  "#FF1493", // Deep Pink
  "#1E90FF", // Dodger Blue
];

export const colorsGraphMix = [
  '#00FFFF', // Cyan
  '#FF00FF', // Magenta
  '#32CD32', // Lime Green
  '#007FFF', // Electric Blue
  '#FFA500', // Bright Orange
  '#FF69B4', // Neon Pink
  '#DFFF00', // Chartreuse
  '#00FFFF', // Aqua
  '#FFFF00', // Vivid Yellow
  '#FF69B4', // Hot Pink
  '#7FFF00', // Spring Green
  '#7D3C98', // Plum
  '#F08080', // Light Coral
  '#00BFFF', // Deep Sky Blue
  '#FF6347', // Tomato
  '#FFD700', // Gold
  '#40E0D0', // Turquoise
  '#C71585', // Medium Violet Red
  '#FF4500', // Orange Red
  '#98FB98', // Pale Green
  '#4682B4', // Steel Blue
  '#DA70D6', // Orchid
  '#B0C4DE', // Light Steel Blue
  '#4169E1', // Royal Blue
  '#6A5ACD', // Slate Blue
  '#20B2AA', // Light Sea Green
  '#87CEFA', // Light Sky Blue
  '#778899', // Light Slate Gray
  '#BDB76B', // Dark Khaki
  '#FFDAB9'  // Peach Puff
];
