import { Dispatch } from "redux";
import { SET_REPORT_SCREEN_HEATMAP_X, SET_REPORT_SCREEN_HEATMAP_Y, SET_REPORT_SCREEN_SWIPE_MODE, SET_REPORT_SCREEN_SWIPE_ROW_EVEN, SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_HEATMAP, SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_LINE } from "../../../redux/project/projectActions";
import DropDown from "../../analysis-settings/shared/drop-down";
import { useSelector } from "react-redux";
import { selectReportScreen } from "../../../redux/project/projectSelectors";
import { getLabelsForValues } from "../../../helpers/name";

export const DislocationDensityReportScreenInput = ({ dispatch, graphType, peakDropDown, processLineChart }: { dispatch: Dispatch; graphType: string, peakDropDown: any, processLineChart: any }) => {
	const report = useSelector(selectReportScreen)

  return (
    <>
      <div className={"flex items-center flex-col mb-2 mt-5"}>
        <h1 className={"font-bold text-white text-base mb-2"}>
          Choose Y Axis Type
        </h1>
        {graphType === "Line" && (
          <DropDown
            DropDownOptions={peakDropDown}
            setSelected={(value) =>
              dispatch({
                type: SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_LINE,
                payload: processLineChart(value),
              })
            }
            selected={report.dislocationDensityReport.yAxis.lineChart}
            multiple={true}
            title={
              report.dislocationDensityReport.yAxis.lineChart.length
                ? getLabelsForValues(
                    peakDropDown,
                    report.dislocationDensityReport.yAxis.lineChart
                  )
                : "Select Y Axis"
            }
            height={"400px"}
            truncateText={true}
          />
        )}
        {graphType === "Heatmap" && (
          <DropDown
            DropDownOptions={peakDropDown}
            setSelected={(value) =>
              dispatch({
                type: SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_HEATMAP,
                payload: value,
              })
            }
            selected={report.dislocationDensityReport.yAxis.heatMap}
            multiple={false}
            title={
              report.dislocationDensityReport.yAxis.heatMap
                ? getLabelsForValues(
                    peakDropDown,
                    report.dislocationDensityReport.yAxis.heatMap
                  )
                : "Select Y Axis"
            }
            height={"400px"}
            truncateText={true}
          />
        )}
      </div>
      <h1 className={"font-bold text-white text-base mb-2 mt-4"}>
        HeatMap Size
      </h1>
      <div className={"flex flex-row items-center justify-center"}>
        <div
          className={
            "flex flex-col items-center justify-center w-3/4 ml-5 mr-5"
          }
        >
          <label className={"text-white"}>X Axis</label>
          <div className="flex flex-row">
            <input
              className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
              type={"number"}
              step="1"
              value={report.heatMap.x ? report.heatMap.x.toString() : ""}
              onChange={(event) => {
                dispatch({
                  type: SET_REPORT_SCREEN_HEATMAP_X,
                  payload: parseInt(event.target.value),
                });
              }}
            />
          </div>
        </div>
        <div
          className={
            "flex flex-col items-center justify-center w-3/4 ml-5 mr-5"
          }
        >
          <label className={"text-white"}>Y Axis</label>
          <div className="flex flex-row">
            <input
              className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
              type={"number"}
              step="1"
              value={report.heatMap.y ? report.heatMap.y.toString() : ""}
              onChange={(event) => {
                dispatch({
                  type: SET_REPORT_SCREEN_HEATMAP_Y,
                  payload: parseInt(event.target.value),
                });
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}
      >
        <h1 className={"font-bold text-white text-base mb-2 mt-5"}>
          Swipe Mode
        </h1>
        <div className={"flex flex-row items-center justify-center mb-3"}>
          <div className="flex items-center justify-start w-3/4">
            <input
              type="checkbox"
              id="checkbox"
              className="w-4 h-4 bg-white border border-white-300 rounded mr-2"
              onChange={() => {
                dispatch({
                  type: SET_REPORT_SCREEN_SWIPE_MODE,
                  payload: !report.swipeMode.swipe,
                });
              }}
              checked={report.swipeMode.swipe}
            />
            <label
              htmlFor="checkbox"
              className="flex items-center cursor-pointer text-white"
            >
              <span>Enable Swipe Mode</span>
            </label>
          </div>
        </div>
        <div className={"flex flex-row items-center justify-center"}>
          <div className="flex items-center justify-start w-3/4">
            <input
              type="checkbox"
              id="checkboxE"
              className="w-4 h-4 bg-white border border-white-300 rounded mr-2"
              onChange={() => {
                dispatch({
                  type: SET_REPORT_SCREEN_SWIPE_ROW_EVEN,
                  payload: !report.swipeMode.swipeRowEven,
                });
              }}
              checked={report.swipeMode.swipeRowEven}
            />
            <label
              htmlFor="checkboxE"
              className="flex items-center cursor-pointer text-white"
            >
              <span>Even Rows</span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
