import { useEffect, useState } from "react";
import { GET_INSTRUMENTAL_FILE_WAVELENGTH } from "../../../redux/project/projectActions";
import { Dispatch } from "redux";


interface Props {
  dispatch: Dispatch;
  fileWaveLength: number;
  wavelength: number;
  setWavelength: (value: number) => void;
  method: string;
  instrumentalFile: string;
  instrumentalTemplateFile: string;
}


const WavelengthInput = ({ dispatch, fileWaveLength, wavelength, setWavelength, method, instrumentalFile, instrumentalTemplateFile }: Props) => {
  const [valueChanged, setValueChanged] = useState(false);

  useEffect(() => {
		if (!instrumentalFile && !instrumentalTemplateFile) return;

		dispatch({ type: GET_INSTRUMENTAL_FILE_WAVELENGTH, payload: { type: method} });
	}, [dispatch, instrumentalFile, instrumentalTemplateFile, method]);
  
  return (
    <div className="flex flex-row items-center justify-center mt-1">
      <div className="flex flex-col w-1/2 ml-5 mr-5">
        <label className="text-white text-center">
          Enter Wavelength [Å]
        </label>
        <p className="text-white text-xs text-left mt-1">
          {!fileWaveLength ? `` : `File Wavelength [Å]: ${fileWaveLength.toFixed(6)}`}
        </p>
        <input
          className={`${false ? "border-red-500" : "border-gray-500"}
            bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
          type="number"
          step="0.0001"
          onChange={e => {
            const value = parseFloat(e.target.value)
            setWavelength(value || value === 0 ? value : 0)
            setValueChanged(value || value === 0 ? true : false)
          }}
          value={valueChanged || wavelength !== 0 ? wavelength : ""}
        />
      </div>
    </div>
  );
};

export default WavelengthInput;
