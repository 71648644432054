import toast from "react-hot-toast";
import { Histogram, Partition, PartitionFitting } from "../../../../models/analysis.general.model";
import { colorHistogram, colorsGraph, colorsGraphMix } from "../../../../data/colors";
import { getName } from "../../../../helpers/name";

export const PartitionSelectionHistogramRange = (histogramX: number[], histogramY: number[], partitions: Partition[], selectPeaks: boolean, selectedPartitionIndex: number) => {
  if (selectPeaks) {
    const rangeLowerX = partitions[selectedPartitionIndex].rangeLower.x;
    const rangeHigherX = partitions[selectedPartitionIndex].rangeHigher.x;
    const range = rangeHigherX - rangeLowerX;
    const xLower = rangeLowerX - (range * 0.1);
    const xHigher = rangeHigherX + (range * 0.1);

    let yMax = 0;
    let yMin = 0;

    if (histogramX && histogramY) {
      const indexLower = histogramX.indexOf(rangeLowerX);
      const indexHigher = histogramX.indexOf(rangeHigherX);

      yMax = Math.max(...histogramY.slice(indexLower, indexHigher));
      yMin = Math.min(...histogramY.slice(indexLower, indexHigher));

      yMin -= (yMax - yMin) * 0.05;
      yMax += (yMax - yMin) * 0.05;
    }

    return {
      xLower,
      xHigher,
      yMax,
      yMin,
    };
  } else {
    return {
      xLower: undefined,
      xHigher: undefined,
      yMax: undefined,
      yMin: undefined,
    };
  }
}


export const HandleHistogramClick = (event: { points: { x: number, y: number}[] }, selectPeaks: boolean, partitions: Partition[], selectedPartitionIndex: number) => {
  partitions = [...partitions];

  if (event.points.length > 0) {
    const data = event.points[0];

    if (selectPeaks) {
      if (!partitions[selectedPartitionIndex].rangeLower.x || !partitions[selectedPartitionIndex].rangeHigher.x) {
        toast.error("Please select a range first")
        return;
      }

      if (data.x < partitions[selectedPartitionIndex].rangeLower.x || data.x > partitions[selectedPartitionIndex].rangeHigher.x) {
        toast.error("Please select a point within the range")
        return;
      }

      partitions[selectedPartitionIndex].peaks.push({
        x: data.x,
        y: data.y,
        function: "",
        includeInAnalysis: false,
        constraint: {
          centerMin: undefined,
          centerMax: undefined,
          sigmaMin: undefined,
          sigmaMax: undefined,
          heightMin: undefined,
          heightMax: undefined,
        }
      })

      toast.success("Selected a Peak")
    } else {
      const range = partitions[selectedPartitionIndex].rangeHistory
      range[1] = range[0];
      range[0] = data.x;

      partitions[selectedPartitionIndex] = {
        ...partitions[selectedPartitionIndex],
        rangeHistory: range,
        rangeLower: range[0] <= range[1] ? {
          x: range[0],
          y: 0
        } : {
          x: range[1],
          y: 0
        },
        rangeHigher: range[0] > range[1] ? {
          x: range[0],
          y: 0
        } : {
          x: range[1],
          y: 0
        }
      }
      
      toast.success("Updated Partition Range")
    }
  }

  return partitions;
}


export const HistogramGraph = (histogram: Histogram, partitions?: Partition[], selectedPartitionIndex?: number) => {
  let histogramData = []
  let phasesShapes = []
  let traces = [];

  if (histogram.data.x && histogram.data.y) {
    let new_x, new_y;

    
    if (partitions && partitions[selectedPartitionIndex].rangeLower && partitions[selectedPartitionIndex].rangeLower.x && partitions[selectedPartitionIndex].rangeHigher && partitions[selectedPartitionIndex].rangeHigher.x) {
      new_x = histogram.data.x.filter((x) => x >= partitions[selectedPartitionIndex].rangeLower.x && x <= partitions[selectedPartitionIndex].rangeHigher.x);
      new_y = histogram.data.y.slice(histogram.data.x.indexOf(new_x[0]), histogram.data.x.indexOf(new_x[new_x.length - 1]) + 1);
    } else {
      new_x = histogram.data.x;
      new_y = histogram.data.y;
    }
    let yMax = Math.max(...histogram.data.y);
    let yMin = Math.min(...histogram.data.y);

    // Both a gray line and a white line representing the data file
    histogramData = [
      {
        x: histogram.data.x,
        y: histogram.data.y,
        type: "linear",
        mode: "lines",
        showlegend: false,
        line: {
          color: "#898989",
          width: 1,
        },
      },
      {
        x: new_x,
        y: new_y,
        type: "linear",
        name: "Data",
        mode: "lines",
        line: {
          color: colorsGraph[0],
          width: 2,
        },
      }
    ];

    // Phases lines
    phasesShapes = Object.entries(histogram.phases).flatMap(([cifFile, data], index) => {
      return data.map((entry) => {
        if (entry.double) {
          return ({
            type: "line",
            x0: entry.tth,
            y0: yMin,
            x1: entry.tth,
            y1: yMax,
            opacity: 1,
            line: {
              dash: "dashdot",
              color: colorHistogram[index % colorHistogram.length],
              width: 3,
            },
          });
        } else {
          return ({
            type: "line",
            x0: entry.tth,
            y0: yMin,
            x1: entry.tth,
            y1: yMax,
            opacity: 0.8,
            line: {
              dash: "dash",
              color: colorHistogram[index % colorHistogram.length],
              width: 1.5,
            },
          });
        }
      })
    });

    // Phases lines legend
    histogramData = [...histogramData, ...Object.entries(histogram.phases).flatMap(([cifFile, data], index) => {
      return {
        x: [null],
        y: [null],
        mode: 'lines',
        line: {
          dash: 'solid',
          color: colorHistogram[index % colorHistogram.length],
          width: 2,
        },
        showlegend: true,
        name: getName(cifFile),
      }
    })];

    if (partitions) {
      traces = partitions.map((partition, index) => {
        const trace = {
          x: [],
          y: [],
          mode: 'markers',
          marker: {
            color: colorHistogram[index],
            size: 10,
          },
          name: `Partition ${partition.name} Peak`,
        };

        partition.peaks.forEach((position) => {
          trace.x.push(position.x);
          trace.y.push(position.y);
        });

        return trace;
      });
    }

    return {
      histogramData,
      phasesShapes,
      traces,
    };
  }
}

export const PartitionFittingGraph = (partitionFitting: { [dataFile: string]: { [partitionName: string]: PartitionFitting } }, dataViewFile: string) => {
  let partitionFittingRes = partitionFitting && Object.keys(partitionFitting).length ? partitionFitting[dataViewFile] : undefined;
  let currentChartShapes = {}

  if (partitionFittingRes) {
    for (const key in partitionFittingRes) {
      let colorIndex = 0;

      const currentPartitionFitting = partitionFittingRes[key];

      const data = [
        {
          x: currentPartitionFitting.originalData.x,
          y: currentPartitionFitting.originalData.y,
          type: "linear",
          name: "Data",
          mode: "lines",
          line: {
            color: colorsGraphMix[colorIndex],
            width: 2,
            dash: 'dot',
          },
        },
        {
          x: currentPartitionFitting.bestFit.x,
          y: currentPartitionFitting.bestFit.y,
          type: "linear",
          name: "Best Fit",
          mode: "lines",
          line: {
            color: colorsGraphMix[colorIndex + 1],
            width: 2,
          },
        },
        {
          x: currentPartitionFitting.background.x,
          y: currentPartitionFitting.background.y,
          type: "linear",
          name: "Background",
          mode: "lines",
          line: {
            color: colorsGraphMix[colorIndex + 2],
            width: 2,
          },
        },
      ];

      colorIndex += 3

      if (currentPartitionFitting.components.gaussian.length) {
        currentPartitionFitting.components.gaussian.forEach((gaussian) => {
          data.push({
            x: gaussian.x,
            y: gaussian.y,
            type: "linear",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 2,
            },
            name: "Gaussian",
          })
        })
        colorIndex++;
      }

      if (currentPartitionFitting.components.lorentzian.length) {
        currentPartitionFitting.components.lorentzian.forEach(lorentzian => {
          data.push({
            x: lorentzian.x,
            y: lorentzian.y,
            type: "linear",
            name: "Lorentzian",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 1,
            },
          })
        })
        colorIndex++;
      }

      if (currentPartitionFitting.components.pseudoVoigt.length) {
        currentPartitionFitting.components.pseudoVoigt.forEach(pseudoVoigt => {
          data.push({
            x: pseudoVoigt.x,
            y: pseudoVoigt.y,
            type: "linear",
            name: "Pseudo Voigt",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 1,
            },
          })
        })
        colorIndex++;
      }

      if (currentPartitionFitting.components.pearson7.length) {
        currentPartitionFitting.components.pearson7.forEach(pearson7 => {
          data.push({
            x: pearson7.x,
            y: pearson7.y,
            type: "linear",
            name: "Pearson 7",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 1,
            },
          })
        })
        colorIndex++;
      }

      currentChartShapes[key] = data;
    }
  }

  return currentChartShapes;
}
