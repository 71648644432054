import { all, put, select, takeLatest } from "redux-saga/effects";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import toast from "react-hot-toast";
import { waitFiveSeconds } from "../../helpers/redux";
import { selectCurrentProject, selectUserToken } from "../project/projectSelectors";
import { RietveldModel } from "../../models/analysis.rietveld.model";
import { selectRietveld } from "./rietveldSelectors";
import { getCallback, getCaller, preProcessObject } from "./rietveldHelpers";
import { SET_RIETVELD_REFINED_HISTOGRAM, GET_RIETVELD_REFINED_HISTOGRAM, GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK, SET_RIETVELD_PARAMETERS } from "./rietveldActions";

function* get_callback() {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let rietveld = yield select(selectRietveld);

    rietveld = preProcessObject(rietveld);

    const res = yield getCallback(
      token,
      {
        ...rietveld,
        userId: currentProject.userId,
        projectId: currentProject.project
      }
    )

    yield put({ type: SET_RIETVELD_REFINED_HISTOGRAM, payload: res.data.data });
    yield put({ type: SET_RIETVELD_PARAMETERS, payload: res.data.parameters });
  } catch (error) {
    console.error(error)
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });

    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

function* get_fitting() {
  try {
    let rietveld: RietveldModel = yield select(selectRietveld);
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    toast.promise(waitFiveSeconds(), {
      loading: "Starting Refinement",
      success: "Refinement Running",
      error: "Refinement Running",
    })

    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    rietveld = preProcessObject(rietveld);

    const res = yield getCaller(
      token,
      {
        ...rietveld,
        userId: currentProject.userId,
        projectId: currentProject.project
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to load Refined Histogram check inputs")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

export default function* rietveldSaga() {
  yield all([
    takeLatest(GET_RIETVELD_REFINED_HISTOGRAM, get_fitting),
    takeLatest(GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK, get_callback),
  ]);
}
