import { compareKeys } from "../../../../helpers/general";

class File {
  name: string;
  path: string;
  isSelected: boolean;

  constructor(name: string, path: string, isSelected = false) {
    this.name = name;
    this.path = path;
    this.isSelected = isSelected;
  }
}

class Directory {
  name: string;
  path: string;
  files: File[];
  subdirectories: Directory[];

  constructor(name: string, path: string) {
    this.name = name;
    this.path = path;
    this.files = [];
    this.subdirectories = [];
  }

  addFile(file: File) {
    /**
     * In short we insert the file sorted using the compareKeys function. The code below is the same as:
     *    this.files.push(file);
     *    this.files.sort((a, b) => compareKeys(a.name, b.name));
     */
    let low = 0, high = this.files.length;
    while (low < high) {
      let mid = Math.floor((low + high) / 2);
      if (compareKeys(file.name, this.files[mid].name) > 0) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }

    this.files.splice(low, 0, file);
  }

  addSubdirectory(dir: Directory) {
    this.subdirectories.push(dir);
  }

  isAllFilesSelected(selectedFiles: Set<string>, allFiles: string[], extensions: Set<string>): boolean {
    const extensionArray = Array.from(extensions);
    const filteredFiles = allFiles.filter(file => {
      if (!file.startsWith(this.path)) return false;
      if (extensions.size === 0) return true;

      return extensionArray.some(extension => file.endsWith(extension));
    });

    if (filteredFiles.length === 0) return false;

    return filteredFiles.every(file => selectedFiles.has(file));
  }

  getAllFiles(): File[] {
    let files: File[] = [...this.files];

    this.subdirectories.forEach(subdirectory => {
      const subdirectoryFiles = subdirectory.getAllFiles();
      files = [...files, ...subdirectoryFiles];
    });

    return files;
  }
}

export class FileSystemS {
  root: Directory;
  currentPath: string;
  selectedFiles: Set<string> = new Set();

  constructor() {
    this.root = new Directory("root", "/");
    this.currentPath = "/";
  }

  addFile(path: string) {
    let parts = path.split("/").filter(p => p.length > 0);
    let currentDir = this.root;

    for (let i = 0; i < parts.length - 1; i++) {
      let foundDir = currentDir.subdirectories.find(dir => dir.name === parts[i]);
      if (!foundDir) {
        foundDir = new Directory(parts[i], parts.slice(0, i + 1).join("/"));
        currentDir.addSubdirectory(foundDir);
      }
      currentDir = foundDir;
    }

    const fileName = parts[parts.length - 1].split("-").slice(1).join("-");
    currentDir.addFile(new File(fileName, path));
  }

  selectFileHelper(path: string) {
    const parts = path.split("/").filter(p => p.length > 0);
    let currentDir = this.root;

    for (let i = 0; i < parts.length - 1; i++) {
      let foundDir = currentDir.subdirectories.find(dir => dir.name === parts[i]);
      if (foundDir) {
        currentDir = foundDir;
      }
    }

    const fileIndex = currentDir.files.findIndex(file => file.path === path);
    currentDir.files[fileIndex].isSelected = true;
    this.selectedFiles.add(path);
  }

  deSelectFileHelper(path: string) {
    const parts = path.split("/").filter(p => p.length > 0);
    let currentDir = this.root;

    for (let i = 0; i < parts.length - 1; i++) {
      let foundDir = currentDir.subdirectories.find(dir => dir.name === parts[i]);
      if (foundDir) {
        currentDir = foundDir;
      }
    }

    const fileIndex = currentDir.files.findIndex(file => file.path === path);
    currentDir.files[fileIndex].isSelected = false;
    this.selectedFiles.delete(path);
  }

  selectSingleFile(path: string) {
    if (this.selectedFiles.has(path)) {
      this.deSelectFileHelper(path);
      return;
    }

    const selectedFile = this.selectedFiles.values().next().value;
    if (selectedFile) {
      this.deSelectFileHelper(selectedFile);
    }

    this.selectFileHelper(path);
  }

  selectFile(path: string) {
    if (this.selectedFiles.has(path)) {
      this.deSelectFileHelper(path);
      return;
    }

    this.selectFileHelper(path);
  }

  selectFolder(files: string[], isAllSelected: boolean) {
    files.forEach(file => {
      if (isAllSelected) {
        this.deSelectFileHelper(file);
      } else {
        this.selectFileHelper(file);
      }
    })
  }

  navigateTo(path: string): Directory | null {
    const parts = path.split("/").filter(p => p.length > 0);
    let currentDir = this.root;

    for (const part of parts) {
      let foundDir = currentDir.subdirectories.find(dir => dir.name === part);
      if (!foundDir) {
        return null;
      }
      currentDir = foundDir;
    }

    this.currentPath = path;
    return currentDir;
  }
}
