import { Dispatch } from "redux";
import DropDown from "../analysis-settings/shared/drop-down";
import { useEffect, useState } from "react";
import { DropDownOptions } from "../../data/models";
import { selectReportScreen } from "../../redux/project/projectSelectors";
import { useSelector } from "react-redux";
import { GET_REPORT_SCREEN_ADDITIONAL_DATA, SET_REPORT_SCREEN_CLEAR, SET_REPORT_SCREEN_SELECTED_ANALYSIS_TYPE } from "../../redux/project/projectActions";
import { Divider } from "../shared/divider";
import { getLabelsForValues } from "../../helpers/name";
import { defaultDropDown, dislocationDensityDropDown, leBailDropDown, peakFittingDropDown, phaseQuantificationDropDown, stressStrainDropDown } from "./analysis-dropdown";
import { LeBailReportScreenInput } from "./report-screen-analysis-input/report-screen-le-bail-input";
import { PhaseQuantificationReportScreenInput } from "./report-screen-analysis-input/report-screen-phase-quantification-input";
import { DislocationDensityReportScreenInput } from "./report-screen-analysis-input/report-screen-dislocation-density-input";
import { PeakFittingReportScreenInput } from "./report-screen-analysis-input/report-screen-peak-fitting-input";
import { StressStrainReportScreenInput } from "./report-screen-analysis-input/report-screen-stress-strain-input";

const ReportScreenInput = ({ dispatch, graphType }: { dispatch: Dispatch, graphType: string }) => {
	const report = useSelector(selectReportScreen)

	const [visibleDiagrams, setVisibleDiagrams] = useState({
    id: "analysisTypes",
    label: "Analysis Types",
    options: [],
  } as DropDownOptions)
	const [cifFilesDropDown, setCifFilesDropDown] = useState({
		id: "cifFiles",
		label: "Cif Files",
		options: [],
	} as DropDownOptions)
	const [peakDropDown, setPeakDropDown] = useState({
		id: "peakDropDown",
		label: "Peaks",
		options: [],
	} as DropDownOptions)

	useEffect(() => {
		setVisibleDiagrams(defaultDropDown(report))

		switch (report.selectedAnalysisType) {
			case "leBail":
				setCifFilesDropDown(leBailDropDown(report.leBailReport.availableCifFiles))
				break;
			case "phaseQuantification":
				setPeakDropDown(phaseQuantificationDropDown(report))
				break;
			case "dislocationDensity":
				setPeakDropDown(dislocationDensityDropDown(report))
				break;
			case "peakFitting":
				setPeakDropDown(peakFittingDropDown(report))
				break;
			case "stressStrain":
				setPeakDropDown(stressStrainDropDown(report))
				break;
			default:
				break;
		}
	}, [report])

	useEffect(() => {
		if (!report.selectedAnalysisType) return

		dispatch({ type: SET_REPORT_SCREEN_CLEAR })
		dispatch({ type: GET_REPORT_SCREEN_ADDITIONAL_DATA, payload: {type: report.selectedAnalysisType } })
	}, [report.selectedAnalysisType, dispatch])

	function processLineChart(lineChart: string[]) {
		if (lineChart.length > 1 && report.phaseQuantificationReport.yAxis.lineChart.includes("volumeFraction")) {
			return lineChart.filter((value) => value !== "volumeFraction");
		} else {
			if (lineChart.length > 1 && lineChart.includes("volumeFraction")) {
				return ["volumeFraction"];
			}
		}

		return lineChart;
	}

	const extraInput = () => {
		switch (report.selectedAnalysisType) {
			case "leBail":
				return <LeBailReportScreenInput dispatch={dispatch} graphType={graphType} cifFilesDropDown={cifFilesDropDown} />
			case "phaseQuantification":
				return <PhaseQuantificationReportScreenInput dispatch={dispatch} graphType={graphType} peakDropDown={peakDropDown} processLineChart={processLineChart} />
			case "dislocationDensity":
				return <DislocationDensityReportScreenInput dispatch={dispatch} graphType={graphType} peakDropDown={peakDropDown} processLineChart={processLineChart} />
			case "peakFitting":
				return <PeakFittingReportScreenInput dispatch={dispatch} graphType={graphType} peakDropDown={peakDropDown} processLineChart={processLineChart} />
			case "stressStrain":
				return <StressStrainReportScreenInput dispatch={dispatch} graphType={graphType} peakDropDown={peakDropDown} processLineChart={processLineChart} />
			default:
				return null;
		}
	}

	return (
		<>
		<div className={"flex flex-col flex-1 bg-primary-800 rounded-md h-full"}>
			<h1 className={"font-bold text-white text-xl mb-2 text-center mt-2"}>
				Report Input
			</h1>
			<div className={"p-2 flex flex-col bg-primary-600 rounded-md w-full h-full"}>
				<div className={"flex items-center flex-col mb-5 mt-3"}>
					<h1 className={"font-bold text-white text-base mb-2"}>
						Choose Analysis Type
					</h1>
					<DropDown
						DropDownOptions={visibleDiagrams}
						setSelected={(value) => dispatch({ type: SET_REPORT_SCREEN_SELECTED_ANALYSIS_TYPE, payload: value })}
						selected={report.selectedAnalysisType}
						multiple={false}
						title={report.selectedAnalysisType ? getLabelsForValues(visibleDiagrams, report.selectedAnalysisType) : "Select Analysis Type"}
						closeOnClick={true}
					/>
				</div>
				<Divider color="gray" />
				{extraInput()}
			</div>
		</div>
	</>
	);
};

export default ReportScreenInput;
