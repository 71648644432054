import { InputFieldModel, PanelModel, ToggleGroupModel } from "../../../../data/models";
import { InputField } from "./input-field";
import { ToggleGroupInput } from "./toggle-group-input";

export interface PanelInputProps {
  panelModel: PanelModel;
  values: any;
  validate: boolean;
  onValueChange: (
    dynamodb_name: string,
    value: string | number | boolean
  ) => void;
}
export const PanelInput = ({
  panelModel,
  values,
  validate,
  onValueChange,
}: PanelInputProps) => {
  const usePanelOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(panelModel.dynamodb_value, e.target.checked);
  };
  const onChange = (
    dynamodb_name: string,
    value: string | number | boolean
  ) => {
    onValueChange(dynamodb_name, value);
  };
  const isUsed = values[panelModel.dynamodb_value] === true;
  return (
    <div>
      {panelModel.conditional && (
        <div
          className={
            "flex items-center border-t border-primary-900 border-solid"
          }
        >
          <input
            id={panelModel.id}
            type="checkbox"
            checked={isUsed}
            onChange={usePanelOnChange}
            className={"mr-2 bg-white"}
          />
          <label
            htmlFor={panelModel.id}
            className={"flex text-white text-base p-2 pl-0 w-full"}
          >
            {panelModel.condition_label}
          </label>
        </div>
      )}
      {(isUsed || !panelModel.conditional) && (
        <div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md"}>
          <h1 className={"font-bold text-white text-base mb-2"}>
            {panelModel.header}
          </h1>
          {panelModel.panels && (
            <div>
              {panelModel.panels.map((innerPanel: PanelModel) => (
                <PanelInput
                  values={values}
                  onValueChange={onChange}
                  validate={validate}
                  key={innerPanel.id}
                  panelModel={innerPanel}
                />
              ))}
            </div>
          )}
          {panelModel.toggleGroups && (
            <div>
              {panelModel.toggleGroups.map((toggleGroup: ToggleGroupModel) => (
                <ToggleGroupInput
                  validate={validate}
                  onValueChange={onChange}
                  key={toggleGroup.id}
                  toggleGroupModel={toggleGroup}
                  values={values}
                />
              ))}
            </div>
          )}
          {panelModel.inputFields && (
            <div>
              {panelModel.inputFields.map((inputField: InputFieldModel) => (
                <InputField
                  key={inputField.id}
                  inputFieldModel={inputField}
                  onValueChange={onChange}
                  validate={validate}
                  values={values}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
