import { compareKeysV2 } from "../../../helpers/general";
import { numberShortener } from "../../../helpers/name";
import { colorsGraph } from "../../../data/colors";
import { DropDownOptions } from "../../../data/models";
import { SET_DISLOCATION_DENSITY_L0_PICKER_RANGE, SET_DISLOCATION_DENSITY_PARTITIONS, SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE } from "../../../redux/dislocationDensity/dislocationDensityActions";
import toast from "react-hot-toast";


export function dislocationDensityFileInformation(dislocationDensityDataFiles, whParams, mwhParams, fitting): {headers: any[], filesMapping: {}} {
  let filesMapping = []
  let filesMappingShortened = []
  let headers = []
  let parametersHeaders = []

  for (const file of dislocationDensityDataFiles) {
    filesMapping.push({ fileName: file })
    
    filesMappingShortened.push(file.split('/').pop().split('.')[0]);
  }

  filesMapping = filesMapping.sort((a, b) => compareKeysV2(a.fileName, b.fileName))
  filesMappingShortened = filesMappingShortened.sort((a, b) => compareKeysV2(a, b))
  
  for (const key in whParams) {
    const currData = whParams[key];
    const index = filesMappingShortened.findIndex((file) => file === key)

    if (index !== -1) {
      let newItems = {}
      for (const key in currData) {
        let displayKey = ""
        switch (key) {
          case "rho":
            displayKey = "DD_WH [m^-2]"
            break;
          case "crystallite_size":
            displayKey = "Crystallite Size [Å]"
            break;
          case "intercept":
            displayKey = "Intercept"
            break;
          case "slope":
            displayKey = "Slope"
            break;
          case "strain":
            displayKey = "Strain"
            break;
          default:
            continue;
        }

        newItems[`${displayKey}`] = numberShortener(currData[key].nominal_value)
        newItems[`${displayKey}: ${"uncertainty"}`] = numberShortener(currData[key].std_dev)

        if (!headers.includes(`${displayKey}`)) headers.push(`${displayKey}`)
        if (!headers.includes(`${displayKey}: ${"uncertainty"}`)) headers.push(`${displayKey}: ${"uncertainty"}`)
      }

      filesMapping[index] = { ...filesMapping[index], ...newItems };
    }
  }

  for (const key in mwhParams) {
    const currData = mwhParams[key];
    const index = filesMappingShortened.findIndex((file) => file === key)

    if (index !== -1) {
      let newItems = {}
      for (const key in currData) {
        if (!["alpha", "q", "rho"].includes(key)) continue;

        let displayKey = ""
        switch (key) {
          case "rho":
            displayKey = "DD_mWA [m^-2]"
            break;
          case "alpha":
            displayKey = "Alpha"
            break;
          case "q":
            displayKey = "Q"
            break;
          default:
            continue;
        }

        newItems[`${displayKey}`] = numberShortener(currData[key].nominal_value)
        newItems[`${displayKey}: ${"uncertainty"}`] = numberShortener(currData[key].std_dev)

        if (!headers.includes(`${displayKey}`)) headers.push(`${displayKey}`)
        if (!headers.includes(`${displayKey}: ${"uncertainty"}`)) headers.push(`${displayKey}: ${"uncertainty"}`)
      }

      filesMapping[index] = { ...filesMapping[index], ...newItems };
    }
  }

  for (const key in fitting) {
    const currentFitting = fitting[key]
    const index = filesMapping.findIndex((file) => file.fileName === key)
    if (index !== -1) {
      let newItems = {}
      for (const key in currentFitting) {
        for (const innerKey in currentFitting[key]) {
          newItems[`${key}: ${innerKey}`] = numberShortener(currentFitting[key][innerKey])
        }
      }

      const sortedKeys = Object.keys(newItems).sort((a, b) => {
        const matchA = a.match(/^(\D+)(\d+)$/);
        const matchB = b.match(/^(\D+)(\d+)$/);
        if (matchA && matchB) {
          const letterCompare = matchA[1].localeCompare(matchB[1]);
          if (letterCompare !== 0) return letterCompare;
          return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
        }
        return 0;
      });

      const sortedNewItems = {};
      for (const sortedKey of sortedKeys) {
        sortedNewItems[sortedKey] = newItems[sortedKey];
      if (!parametersHeaders.includes(sortedKey)) parametersHeaders.push(sortedKey)
      }

      filesMapping[index] = { ...filesMapping[index], ...sortedNewItems };
    }
  }

  let sortedKeysParams = parametersHeaders.sort((a, b) => {
    const matchA = a.match(/^(\D+)(\d+):/);
    const matchB = b.match(/^(\D+)(\d+):/);
    if (matchA && matchB) {
        const letterCompare = matchA[1].localeCompare(matchB[1]);
        if (letterCompare !== 0) return letterCompare;
        return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
    }
    return 0;
  });

  return {
    headers: [...headers, ...sortedKeysParams],
    filesMapping: filesMapping
  }
}

export function getDislocationDensityGraph(wh, k2_cdk, k2c_lna, l0Picker, rhoPicker, l0Analysis, rhoAnalysis) {
  let currentChartShapes = {}

  if (wh?.x?.length) {
    const whData = [
      {
        x: wh.x,
        y: wh.y,
        type: "scatter",
        name: "Data",
        mode: "markers+lines",
        line: {
          color: colorsGraph[0],
          width: 2,
        },
        marker: {
          size: 12,
        },
      },
      {
        x: wh.x,
        y: wh.fit,
        type: "scatter",
        name: "Fit",
        mode: "lines",
        line: {
          color: colorsGraph[1],
          width: 2,
        },
      },
    ];

    currentChartShapes["wh"] = whData;
  }

  if (k2_cdk.length) {
    const cSteps = Array.from({length: k2_cdk.length}, (_, i) => 100 + (155 * i) / (k2_cdk.length - 1));

    const plotData = k2_cdk.map((value, index) => {

      return {
        x: value.k2c,
        y: value.delta_k,
        type: 'scatter',
        mode: 'markers+lines',
        name: `Data Point ${index + 1}`,
        line: {
          color: `rgb(${cSteps[index]},${cSteps[index]},255)`, // This simulates the color change
          width: 2,
        },
        marker: {
          size: 12,
        }
    }});

    currentChartShapes["k2cdk"] = plotData;
  }

  if (k2c_lna.length) {
    const cSteps = Array.from({length: k2c_lna.length}, (_, i) => 100 + (155 * i) / (k2c_lna.length - 1));

    const plotData = k2c_lna.map((value, index) => {

      return {
        x: value.k2c,
        y: value.ln_a,
        type: 'scatter',
        mode: 'markers',
        name: `Data Point ${index + 1}`,
        line: {
          color: `rgb(${cSteps[index]},${cSteps[index]},255)`, // This simulates the color change
          width: 2,
        },
        marker: {
          size: 12,
        }
    }});

    const plotDataMes = k2c_lna.map((value, index) => {
      return {
        x: value.x,
        y: value.y,
        type: 'scatter',
        mode: 'lines',
        name: `Data Point ${index + 1}`,
        line: {
          color: `rgb(${cSteps[index]},${cSteps[index]},255)`, // This simulates the color change
          width: 2,
        },
        marker: {
          size: 12,
        }
    }});

    currentChartShapes["k2clna"] = [...plotData, ...plotDataMes];
  }

  if (l0Picker.x.length && l0Picker.y.length) {
    const l0PickerData = [
      {
        x: l0Picker.x,
        y: l0Picker.y,
        type: "scatter",
        name: "Data",
        mode: "markers+lines",
        line: {
          color: colorsGraph[0],
          width: 2,
        },
        marker: {
          size: 12,
        },
      },
    ];

    currentChartShapes["l0"] = l0PickerData;
  }

  if (rhoPicker.x.length && rhoPicker.y.length) {
    const rhoPickerData = [
      {
        x: rhoPicker.x,
        y: rhoPicker.y,
        type: "scatter",
        name: "Data",
        mode: "markers+lines",
        line: {
          color: colorsGraph[0],
          width: 2,
        },
        marker: {
          size: 12,
        },
      },
    ];

    currentChartShapes["rho"] = rhoPickerData;
  }

  if (l0Analysis.x.length && l0Analysis.y.length) {
    const l0AnalysisData = [
      {
        x: l0Analysis.x,
        y: l0Analysis.y,
        type: "scatter",
        name: "Data",
        mode: "markers+lines",
        line: {
          color: colorsGraph[0],
          width: 2,
        },
        marker: {
          size: 12,
        },
      },
      {
        x: l0Analysis.ablineX,
        y: l0Analysis.ablineY,
        type: "scatter",
        name: "Abline",
        mode: "lines",
        line: {
          color: 'red',
          width: 2,
          dash: 'dot',
        },
      },
    ];

    currentChartShapes["l0Analysis"] = l0AnalysisData;
  }

  if (rhoAnalysis.x.length && rhoAnalysis.y.length) {
    const rhoAnalysisData = [
      {
        x: rhoAnalysis.x,
        y: rhoAnalysis.y,
        type: "scatter",
        name: "Data",
        mode: "markers+lines",
        line: {
          color: colorsGraph[0],
          width: 2,
        },
        marker: {
          size: 12,
        },
      },
      {
        x: rhoAnalysis.ablineX,
        y: rhoAnalysis.ablineY,
        type: "scatter",
        name: "Abline",
        mode: "lines",
        line: {
          color: 'red',
          width: 2,
          dash: 'dot',
        },
      },
    ];

    currentChartShapes["rhoAnalysis"] = rhoAnalysisData;
  }

  return currentChartShapes
}

export function getDislocationDensityGraphLabels(maxCoordinate, activeTab, axis) {
  let base;

  if (axis === "x") {
    base = {
      color: "white",
      gridcolor: "grey",
      title: {
        text: "2θ",
        font: {
          family: "sans-serif",
          size: 16,
          color: "white",
        },
      },
      range: activeTab === "histogram" ? [maxCoordinate.xLower, maxCoordinate.xHigher] : undefined,
    }

    if (activeTab === "wh") {
      base["title"]["text"] = "4 sin(θ)";
    }

    if (activeTab === "k2cdk") {
      base["title"]["text"] = "K^2C";
    }

    if (activeTab === "k2clna") {
      base["title"]["text"] = "K^2C";
    }

    if (activeTab === "l0") {
      base["title"]["text"] = "L [nm]";
    }

  } else {
    base = {
      color: "white",
      gridcolor: "grey",
      title: {
        text: "intensity",
        font: {
          family: "sans-serif",
          size: 16,
          color: "white",
        },
      },
      range: activeTab === "histogram" ? [maxCoordinate.yMin, maxCoordinate.yMax] : undefined,
    }

    if (activeTab === "wh") {
      base["title"]["text"] = "β cos(θ)";
    }

    if (activeTab === "k2cdk") {
      base["title"]["text"] = "ΔK";
    }

    if (activeTab === "k2clna") {
      base["title"]["text"] = "ln(A)";
    }

    if (activeTab === "l0") {
      base["title"]["text"] = "A^S(L)";
    }
  }

  return base;
}

export const GraphDropDowns = (partitionFitting, dataViewFile, wh, k2_cdk, l0Picker, rhoPicker, l0Analysis, rhoAnalysis) => {
  let dropdownOptions = {
    id: "diagrams",
    label: "Diagrams",
    options: [
      {
        id: "histogram",
        label: "Histogram",
        values: [
          { label: "Histogram", value: "histogram" },
        ],
      },
    ],
  } as DropDownOptions

  let partitionFittingRes = partitionFitting && Object.keys(partitionFitting).length ? partitionFitting[dataViewFile] : undefined;

  if (partitionFittingRes) {
    dropdownOptions.options.push({
      id: "partitions",
      label: "Partitions",
      values: Object.keys(partitionFittingRes).filter(value => value !== "histogram").map(value => {
        return { label: `Partition ${parseInt(value) + 1}`, value: value }
      })
    })
  }

  if (wh?.x?.length) {
    dropdownOptions.options.push({
      id: "wh",
      label: "Williamson-Hall",
      values: [
        { label: "Williamson-Hall", value: "wh" },
      ],
    })
  }

  if (k2_cdk.length) {
    dropdownOptions.options.push({
      id: "k2cdk",
      label: "Modified Williamson-Hall",
      values: [
        { label: "K^2C vs ΔK", value: "k2cdk" },
        { label: "K^2C vs Ln(A)", value: "k2clna" },
      ],
    })
  }

  if (l0Picker.x.length && l0Picker.y.length) {
    const values = [{ label: "Subgrain Size Picker", value: "l0" }];

    if (l0Analysis.x.length && l0Analysis.y.length) {
      values.push({ label: "Subgrain Size Fit", value: "l0Analysis" });
    }

    dropdownOptions.options.push({
      id: "subgrainSize",
      label: "Subgrain Size Estimation",
      values: values,
    })
  }

  if (rhoPicker.x.length && rhoPicker.y.length) {
    const values = [{ label: "Density Picker", value: "rho" }];

    if (rhoAnalysis.x.length && rhoAnalysis.y.length) {
      values.push({ label: "Density Fit", value: "rhoAnalysis" });
    }

    dropdownOptions.options.push({
      id: "Density Estimation",
      label: "Density Estimation",
      values: values,
    })
  }

  return dropdownOptions;
}

export const HandleClick = (event: any, selectPeaks, selectedPartitionIndex, dispatch, rhoSelectionActive, l0SelectionActive) => {
  let partitions;

  if (event.points.length > 0) {
    const data = event.points[0];

    if (selectPeaks) {
      if (!partitions[selectedPartitionIndex].rangeLower.x || !partitions[selectedPartitionIndex].rangeHigher.x) {
        toast.error("Please select a range first")
        return;
      }

      if (data.x < partitions[selectedPartitionIndex].rangeLower || data.x > partitions[selectedPartitionIndex].rangeHigher) {
        toast.error("Please select a point within the range")
        return;
      }

      partitions[selectedPartitionIndex].peaks.push({
        x: data.x,
        y: data.y,
        function: "",
        includeInAnalysis: false,
        constraint: {
          centerMin: undefined,
          centerMax: undefined,
          sigmaMin: undefined,
          sigmaMax: undefined,
          heightMin: undefined,
          heightMax: undefined,
        }
      })

      dispatch({ type: SET_DISLOCATION_DENSITY_PARTITIONS, payload: partitions });

      toast.success("Selected a Peak")
    } else if (rhoSelectionActive) {
      dispatch({ type: SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE, payload: parseFloat(data.x.toFixed(4)) });

      toast.success("Updated Rho Range")
    } else if (l0SelectionActive) {
      dispatch({ type: SET_DISLOCATION_DENSITY_L0_PICKER_RANGE, payload: parseFloat(data.x.toFixed(4)) });

      toast.success("Updated L0 Range")
    } else {
      const range = partitions[selectedPartitionIndex].rangeHistory
      range[1] = range[0];
      range[0] = data.x;

      partitions[selectedPartitionIndex] = {
        ...partitions[selectedPartitionIndex],
        rangeHistory: range,
        rangeLower: range[0] <= range[1] ? {
          x: range[0],
          y: 0
        } : {
          x: range[1],
          y: 0
        },
        rangeHigher: range[0] > range[1] ? {
          x: range[0],
          y: 0
        } : {
          x: range[1],
          y: 0
        }
      }
      
      dispatch({ type: SET_DISLOCATION_DENSITY_PARTITIONS, payload: partitions });
      toast.success("Updated Partition Range")

    }
  }
}