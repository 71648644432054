import { Dispatch } from "redux";
import { useEffect } from "react";
import { GET_CIF_FILE_ABC_VALUE } from "../../../redux/project/projectActions";
import { FormatName } from "../../shared/utils";
import { CifFilesABC } from "../../../models/analysis.general.model";

interface Props {
  dispatch: Dispatch;
  cifFilesABC: CifFilesABC;
  cifFiles: string[] | string;
  cifTemplateFiles: string[] | string;
  analysisMethod: string;
  setABCValues: (abcValues: { [cifFile: string]: { abcValues: { a: number, b: number, c:number }, sameValues: { ab: boolean, ac: boolean, bc: boolean } } }) => void;
}

export const CifFileABC = ({ dispatch, cifFilesABC, cifFiles, cifTemplateFiles, analysisMethod, setABCValues }: Props) => {
  useEffect(() => {
    const normalizedCifFiles = Array.isArray(cifFiles) ? cifFiles : cifFiles ? [cifFiles] : [];
    const normalizedCifTemplateFiles = Array.isArray(cifTemplateFiles) ? cifTemplateFiles : cifTemplateFiles ? [cifTemplateFiles] : [];
    
    const newCifFilesABC = { ...cifFilesABC };
    const combinedCif = [...normalizedCifFiles, ...normalizedCifTemplateFiles];
    let newValues = false;

    combinedCif.forEach((cifFile) => {
      if (!newCifFilesABC[cifFile]) {
        dispatch({ type: GET_CIF_FILE_ABC_VALUE, payload: { cifFile: cifFile, type: analysisMethod } });
        newValues = true;
      }
    });
    
    Object.keys(newCifFilesABC).forEach((cifFile) => {
      if (!combinedCif.includes(cifFile)) {
        delete newCifFilesABC[cifFile];
        newValues = true;
      }
    });

    if (newValues) setABCValues(newCifFilesABC);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cifFiles, cifTemplateFiles, dispatch]);

  const handleInputChange = (cifFile: string, keys: ('a' | 'b' | 'c')[], value: number) => {
    const updatedValues = { ...cifFilesABC[cifFile] };
    keys.forEach(key => {
      updatedValues.abcValues[key] = value;
    });
    setABCValues({ ...cifFilesABC, [cifFile]: updatedValues });
  };

  const renderInputs = (cifFile: string, values: { abcValues: { a: number; b: number; c: number; }, sameValues: { ab: boolean; ac: boolean; bc: boolean; }}) => {
    const { ab, ac, bc } = values.sameValues;
    const inputsToRender = [];
    let renderedKeys = new Set<string>();

    if (ab && ac && bc) {
      inputsToRender.push(
        <div key="abc" className={"p-2 flex flex-col items-center w-1/2"}>
          <p>Values for (A, B, C)</p>
          <input
            className={"bg-primary-700 p-2 rounded-md border border-solid border-gray-500 text-white w-full"}
            type={"number"}
            step="0.01"
            onChange={(event) => handleInputChange(cifFile, ["a", "b", "c"], parseFloat(event.target.value))}
            value={values.abcValues.a}
          />
        </div>
      );
      renderedKeys.add("a");
      renderedKeys.add("b");
      renderedKeys.add("c");
    } else {
      if (ab) {
        inputsToRender.push(
          <div key="ab" className={"p-2 flex flex-col items-center w-1/2"}>
            <p>Values for (A, B)</p>
            <input
              className={"bg-primary-700 p-2 rounded-md border border-solid border-gray-500 text-white w-full"}
              type={"number"}
              step="0.01"
              onChange={(event) => handleInputChange(cifFile, ["a", "b"], parseFloat(event.target.value))}
              value={values.abcValues.a}
            />
          </div>
        );
        renderedKeys.add("a");
        renderedKeys.add("b");
      }
      if (ac && !renderedKeys.has("a") && !renderedKeys.has("c")) {
        inputsToRender.push(
          <div key="ac" className={"p-2 flex flex-col items-center w-1/2"}>
            <p>Values for (A, C)</p>
            <input
              className={"bg-primary-700 p-2 rounded-md border border-solid border-gray-500 text-white w-full"}
              type={"number"}
              step="0.01"
              onChange={(event) => handleInputChange(cifFile, ["a", "c"], parseFloat(event.target.value))}
              value={values.abcValues.a}
            />
          </div>
        );
        renderedKeys.add("a");
        renderedKeys.add("c");
      }
      if (bc && !renderedKeys.has("b") && !renderedKeys.has("c")) {
        inputsToRender.push(
          <div key="bc" className={"p-2 flex flex-col items-center w-1/2"}>
            <p>Values for (B, C)</p>
            <input
              className={"bg-primary-700 p-2 rounded-md border border-solid border-gray-500 text-white w-full"}
              type={"number"}
              step="0.01"
              onChange={(event) => handleInputChange(cifFile, ["b", "c"], parseFloat(event.target.value))}
              value={values.abcValues.b}
            />
          </div>
        );
        renderedKeys.add("b");
        renderedKeys.add("c");
      }
    }

    ["a", "b", "c"].forEach(key => {
      if (!renderedKeys.has(key)) {
        inputsToRender.push(
          <div className={"p-2 flex flex-col items-center w-1/3"} key={key}>
            <p>{key.toUpperCase()}</p>
            <input
              className={"bg-primary-700 p-2 rounded-md border border-solid border-gray-500 text-white w-full"}
              type={"number"}
              step="0.01"
              onChange={(event) => handleInputChange(cifFile, [key as "a" | "b" | "c"], parseFloat(event.target.value))}
              value={values.abcValues[key as "a" | "b" | "c"]}
            />
          </div>
        );
      }
    });

    return inputsToRender;
  };

  return (
    <>
      <h1 className={"font-bold text-white text-base mt-4"}>Cif Files ABC Values</h1>
      {Object.entries(cifFilesABC).map(([cifFile, values]) => (
        <div
          key={cifFile}
          className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1 text-white"}
        >
          <h2 className={"text-white text-base"}>
            {FormatName(cifFile, false).length ? FormatName(cifFile, false) : cifFile}
          </h2>
          <div className={"flex flex-row items-center space-x-4"}>
            {renderInputs(cifFile, values)}
          </div>
        </div>
      ))}
    </>
  );
};
