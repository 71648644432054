import { apiDislocationDensity, requestApi } from "../../api";
import { DislocationDensityModel } from "../../models/analysis.dislocationDensity.model";

export const preProcessObject = (obj: DislocationDensityModel) => {
  let newObj = JSON.parse(JSON.stringify(obj));

  delete newObj.histogram;
  delete newObj.fitting;
  delete newObj.partitionFitting;
  delete newObj.partition;
  delete newObj.wh;
  delete newObj.k2cdk;
  delete newObj.k2clna;
  delete newObj.l0Analysis;
  delete newObj.rhoAnalysis;
  delete newObj.whParams;
  delete newObj.mwhParams;

  newObj.cifFiles = newObj.cifFiles ? [newObj.cifFiles] : [];
  newObj.templateCifFiles = newObj.templateCifFiles ? [newObj.templateCifFiles] : [];

  return newObj;
}

export const getDislocationDensityCallback = async (token, req) => {
  return await requestApi(`callback`, "POST", req, token, {}, apiDislocationDensity);
};

export const getDislocationDensity = async (token, req) => {
  return await requestApi(`caller`, "POST", req, token, {}, apiDislocationDensity);
};
