import { GlobalState } from "../../store/models";

export const selectDislocationDensity = (state: GlobalState) => state.dislocationDensityReducer;

export const selectDislocationDensityDataFiles = (state: GlobalState) => state.dislocationDensityReducer.dataFiles;
export const selectDislocationDensityDataViewFile = (state: GlobalState) => state.dislocationDensityReducer.dataViewFile;
export const selectDislocationDensityDataActiveFiles = (state: GlobalState) => state.dislocationDensityReducer.dataActiveFiles;

export const selectDislocationDensityCifFiles = (state: GlobalState) => state.dislocationDensityReducer.cifFiles;
export const selectDislocationDensityCifTemplateFiles = (state: GlobalState) => state.dislocationDensityReducer.templateCifFiles;
export const selectDislocationDensityCifFilesABC = (state: GlobalState) => state.dislocationDensityReducer.cifFilesABC;

export const selectDislocationDensityInstrumentalFile = (state: GlobalState) => state.dislocationDensityReducer.instrumentalFile;
export const selectDislocationDensityInstrumentalTemplateFile = (state: GlobalState) => state.dislocationDensityReducer.templateInstrumentalFile;
export const selectDislocationDensityInstrumentalFileWaveLength = (state: GlobalState) => state.dislocationDensityReducer.instrumentalFileWaveLength;

export const selectDislocationDensityPartitions = (state: GlobalState) => state.dislocationDensityReducer.partitions;
export const selectDislocationDensityMovingPeaks = (state: GlobalState) => state.dislocationDensityReducer.movingPeaks;

export const selectDislocationDensityWavelength = (state: GlobalState) => state.dislocationDensityReducer.wavelength;

export const selectDislocationDensityFitting = (state: GlobalState) => state.dislocationDensityReducer.fitting;

export const selectDislocationDensityPartitionActiveIndex = (state: GlobalState) => state.dislocationDensityReducer.partition.activeIndex;
export const selectDislocationDensityPartitionSelectingPeaks = (state: GlobalState) => state.dislocationDensityReducer.partition.selectingPeaks;

export const selectDislocationDensityHistogram = (state: GlobalState) => state.dislocationDensityReducer.histogram;

export const selectDislocationDensityPartitionFitting = (state: GlobalState) => state.dislocationDensityReducer.partitionFitting;

export const selectDislocationDensityCH00 = (state: GlobalState) => state.dislocationDensityReducer.ch00;
export const selectDislocationDensityTTH1 = (state: GlobalState) => state.dislocationDensityReducer.tth1;
export const selectDislocationDensityTTH2 = (state: GlobalState) => state.dislocationDensityReducer.tth2;
export const selectDislocationDensityLN = (state: GlobalState) => state.dislocationDensityReducer.ln;
export const selectDislocationDensityL0 = (state: GlobalState) => state.dislocationDensityReducer.l0;
export const selectDislocationDensityRHO = (state: GlobalState) => state.dislocationDensityReducer.rho;

export const selectDislocationDensityL0_rangeHistory = (state: GlobalState) => state.dislocationDensityReducer.l0Picker.rangeHistory;
export const selectDislocationDensityRHO_rangeHistory = (state: GlobalState) => state.dislocationDensityReducer.rhoPicker.rangeHistory;
export const selectDislocationDensityL0_selectionActive = (state: GlobalState) => state.dislocationDensityReducer.l0Picker.selectionActive;
export const selectDislocationDensityRHO_selectionActive = (state: GlobalState) => state.dislocationDensityReducer.rhoPicker.selectionActive;

export const selectDislocationDensityWH = (state: GlobalState) => state.dislocationDensityReducer.wh;
export const selectDislocationDensityK2CDK = (state: GlobalState) => state.dislocationDensityReducer.k2cdk;
export const selectDislocationDensityK2CLNA = (state: GlobalState) => state.dislocationDensityReducer.k2clna;
export const selectDislocationDensityL0Analysis = (state: GlobalState) => state.dislocationDensityReducer.l0Analysis;
export const selectDislocationDensityRhoAnalysis = (state: GlobalState) => state.dislocationDensityReducer.rhoAnalysis;
export const selectDislocationDensityL0PickerData = (state: GlobalState) => state.dislocationDensityReducer.l0PickerData;
export const selectDislocationDensityRhoPickerData = (state: GlobalState) => state.dislocationDensityReducer.rhoPickerData;

export const selectDislocationDensityWhParams = (state: GlobalState) => state.dislocationDensityReducer.whParams;
export const selectDislocationDensityMwhParams = (state: GlobalState) => state.dislocationDensityReducer.mwhParams;
