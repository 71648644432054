import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { GET_DOWNLOAD } from "../../../redux/project/projectActions";
import { InstrumentalFileCreationModel } from "../../../models/analysis.instrumentalFileCreation.model";
import { selectInstrumentalFileCreation, selectInstrumentalFileCreationAnalysis, selectInstrumentalFileCreationCifFile, selectInstrumentalFileCreationCifFilesABC, selectInstrumentalFileCreationCifTemplateFile, selectInstrumentalFileCreationCrystalliteSize, selectInstrumentalFileCreationDataFiles, selectInstrumentalFileCreationInstrumentalFileWaveLength, selectInstrumentalFileCreationInstrumentalTemplateFile, selectInstrumentalFileCreationMicrostrain, selectInstrumentalFileCreationRangeInitial, selectInstrumentalFileCreationRefinementRange, selectInstrumentalFileCreationWavelength } from "../../../redux/instrumentalFileCreation/instrumentalFileCreationSelectors";
import { GET_INSTRUMENTAL_FILE_CREATION, SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES, SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC, SET_INSTRUMENTAL_FILE_CREATION_CIF_TEMPLATE_FILES, SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, SET_INSTRUMENTAL_FILE_CREATION_DATA_FILES, SET_INSTRUMENTAL_FILE_CREATION_INSTRUMENTAL_TEMPLATE_FILE, SET_INSTRUMENTAL_FILE_CREATION_MICROSTRAIN, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER, SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH } from "../../../redux/instrumentalFileCreation/instrumentalFileCreationActions";
import { cifFilesInstrumentalFileCreationDirectories, instrumentalFileCreationCifMapping, instrumentalFileCreationInstrumentalFiles } from "../../../data/instrumental_file_creation";
import { FileSelection } from "../shared/file-selection/file-selection";
import DropDown from "../shared/drop-down";
import WavelengthInput from "../shared/wavelength";
import { CifFileABC } from "../shared/cifFileABC";
import { useEffect } from "react";

const InstrumentalFileCreationInput = ({ dispatch  }: { dispatch: Dispatch }) => {
	const InstrumentalFileCreation: InstrumentalFileCreationModel = useSelector(selectInstrumentalFileCreation);
	const cifTemplateFile = useSelector(selectInstrumentalFileCreationCifTemplateFile);
	const instrumentalTemplateFile = useSelector(selectInstrumentalFileCreationInstrumentalTemplateFile);
	const range = useSelector(selectInstrumentalFileCreationRefinementRange);
	const wavelength = useSelector(selectInstrumentalFileCreationWavelength);
	const fileWavelength = useSelector(selectInstrumentalFileCreationInstrumentalFileWaveLength)
	const initialRange = useSelector(selectInstrumentalFileCreationRangeInitial);
	const crystalliteSize = useSelector(selectInstrumentalFileCreationCrystalliteSize);
	const microstrain = useSelector(selectInstrumentalFileCreationMicrostrain);
	const cifFile = useSelector(selectInstrumentalFileCreationCifFile)
	const analysis = useSelector(selectInstrumentalFileCreationAnalysis)
	const dataFiles = useSelector(selectInstrumentalFileCreationDataFiles);
	const cifFilesABC = useSelector(selectInstrumentalFileCreationCifFilesABC);

	useEffect(() => {
		if (!cifTemplateFile) {
			dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, payload: 0})
		} else {
			dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, payload: instrumentalFileCreationCifMapping[cifTemplateFile]})
		}
	}, [dispatch, cifTemplateFile]);

	return (
		<>
		<div className={"flex flex-col flex-1 bg-primary-800 rounded-md"}>
			<h1 className={"text-white font-bold mb-2"}>Instrumental File Creation</h1>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Select Files
				</h1>
				<FileSelection
					dispatch={dispatch}
					selected={dataFiles}
					setSelected={files => dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_DATA_FILES, payload: files })}
					title="Select Data File(s)"
					extensions={[".dat"]}
					defaultExtension={".dat"}
					multiple={true}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={cifFile}
					selectedTemplates={cifTemplateFile}
					setSelected={files => dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES, payload: files })}
					setSelectedTemplates={files => dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_CIF_TEMPLATE_FILES, payload: files })}
					title="Select Cif File"
					extensions={[".cif"]}
					defaultExtension={".cif"}
					multiple={false}
					templates={cifFilesInstrumentalFileCreationDirectories}
				/>
				<br />
				<h1 className={"font-bold text-white text-base mb-2"}>
					Choose Instrumental Parameter File
				</h1>
				<div className={"flex items-center flex-col mb-2 text-center"}>
					<DropDown
						DropDownOptions={instrumentalFileCreationInstrumentalFiles}
						multiple={false}
						setSelected={(file) => dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_INSTRUMENTAL_TEMPLATE_FILE, payload: file })}
						selected={instrumentalTemplateFile}
						title={"Select From Template"}
					/>
				</div>
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Inputs
				</h1>
				<WavelengthInput
					dispatch={dispatch}
					fileWaveLength={fileWavelength}
					wavelength={wavelength}
					setWavelength={(wavelength: number) => dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH, payload: wavelength })}
					method={"instrumentalFileCreation"}
					instrumentalFile={instrumentalTemplateFile}
					instrumentalTemplateFile={instrumentalTemplateFile}
				/>
				<CifFileABC
					dispatch={dispatch}
					cifFilesABC={cifFilesABC}
					cifFiles={cifFile}
					cifTemplateFiles={cifTemplateFile}
					analysisMethod={"instrumentalFileCreation"}
					setABCValues={(abcValues) => dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC, payload: abcValues })}
				/>
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Microstructural Parameters Range
				</h1>
				<div className={"flex flex-row items-center justify-center"}>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Crystallite size[μm]
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={crystalliteSize.toString()}
								onChange={(event) => {dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, payload: parseFloat(event.target.value)})}}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {
									dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, payload: cifTemplateFile ? instrumentalFileCreationCifMapping[cifTemplateFile] : 0})
								}}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Microstrain [x10^6]
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={microstrain.toString()}
								onChange={(event) => {dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_MICROSTRAIN, payload: parseFloat(event.target.value)}); }}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_MICROSTRAIN, payload: 0}); }}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
				</div>
				<h1 className={"font-bold text-white text-base mb-2 mt-6"}>
					Refinement Range
				</h1>
				<div className={"flex flex-row items-center justify-center"}>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Range Lower
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={range.lower.toString()}
								onChange={(event) => {dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, payload: parseFloat(event.target.value)})}}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, payload: initialRange[0]})}}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Range Upper
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={range.upper.toString()}
								onChange={(event) => {dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER, payload: parseFloat(event.target.value)}); }}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER, payload: initialRange[1]}); }}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
				</div>
			</div>
			<button
				className={"cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded-md"}
				onClick={() => {
					if(!InstrumentalFileCreation.dataFiles.length) {
						toast.error("Please Select Data Files");
						return;
					}

					if (!range.lower || !range.upper) {
						toast.error("Please Enter Range Lower and Range Higher");
						return;
					}

					if (!InstrumentalFileCreation.instrumentalTemplateFile && !InstrumentalFileCreation.wavelength) {
						toast.error("Please Select Instrumental File or Enter Wavelength");
						return;
					}

					if (!InstrumentalFileCreation.cifFile && !InstrumentalFileCreation.cifTemplateFile) {
						toast.error("Please Select CIF Files");
						return;
					}
			
					dispatch({ type: GET_INSTRUMENTAL_FILE_CREATION })
			}}
			>
				Save and calculate
			</button>
			<button
				className={`cursor-pointer ${ analysis?.backgroundX?.length ?  "text-white bg-primary-600" : "text-gray-600 bg-primary-700"} font-bold m-4 p-4 rounded-md`}
				onClick={() => {
					dispatch({ type: GET_DOWNLOAD, payload: { type: "instrumentalFileCreation" } })
				}}
			>
				Download Data
			</button>
		</div>
	</>
	);
};

export default InstrumentalFileCreationInput;

