import { DropDownOptions } from "./models";

export const instrumentalFileCreationCifFiles: DropDownOptions = {
	id: "cif_templates",
	label: "CIF Templates",
	options: [
		{
			id: "agbh",
			label: "AgBh",
			values: [
				{ label: "AgBh", value: "agbh" },
			],
		},
		{
			id: "al203",
			label: "Al203",
			values: [
				{ label: "Alumina SRM 676", value: "alumina_srm_676" },
			],
		},
		{
			id: "ceo2",
			label: "CeO2",
			values: [
				{ label: "CeO2 SRM 674B", value: "ceO2_srm_674b" },
			],
		},
		{
			id: "lab6",
			label: "LaB6",
			values: [
				{ label: "LaB6 SRM 660a", value: "lab6_srm_660a" },
				{ label: "LaB6 SRM 660b", value: "lab6_srm_660b" },
				{ label: "LaB6 SRM 660c", value: "lab6_srm_660c" },
			],
		},
	],
};


export const instrumentalFileCreationInstrumentalFiles: DropDownOptions = {
	id: "instrumental_templates",
	label: "Instrumental Templates",
	options: [
		{
			id: "xray",
			label: "Xray",
			values: [
				{ label: "Xray Constant Wavelength", value: "xray_constant_wavelength_start_template" },
			],
		},
	],
};


export const instrumentalFileCreationCifMapping = {
	agbh: 0.09,
	ceO2_srm_674b: 0.3806,
	alumina_srm_676: 0.5,
	lab6_srm_660a: 2,
	lab6_srm_660b: 0.7,
	lab6_srm_660c: 0.5,
};

export const cifFilesInstrumentalFileCreationDirectories = [
	{ label: "AgBh", value: "agbh", directory: "AgBh" },
	{ label: "Alumina SRM 676", value: "alumina_srm_676", directory: "Al203" },
	{ label: "CeO2 SRM 674B", value: "ceO2_srm_674b", directory: "CeO2" },
	{ label: "LaB6 SRM 660a", value: "lab6_srm_660a", directory: "LaB6" },
	{ label: "LaB6 SRM 660b", value: "lab6_srm_660b", directory: "LaB6" },
	{ label: "LaB6 SRM 660c", value: "lab6_srm_660c", directory: "LaB6" },
]