import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <div>
        <h1 className={"text-primary-600 text-5xl items-center p-24"}>
          404 Page not found
        </h1>
      </div>
    </div>
  );
};

export default PageNotFound;
