import { DropDownOptions } from "./models";

export const instrumentalFiles: DropDownOptions = {
	id: "instrumental_templates",
	label: "Instrumental Templates",
	options: [
		{
			id: "xray",
			label: "Xray",
			values: [
				{ label: "Xray Constant Wavelength", value: "xray_constant_wavelength" },
			],
		},
		{
			id: "neutron",
			label: "Neutron",
			values: [
				{ label: "Neutron Constant Wavelength", value: "neutron_constant_wavelength" },
			],
		},
	],
};

export const instrumentalFilesDirectories = [
	{
		value: "xray_constant_wavelength",
		label: "Xray Constant Wavelength",
		directory: "Xray",
	},
	{
		value: "neutron_constant_wavelength",
		label: "Neutron Constant Wavelength",
		directory: "Neutron",
	},
];