import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { GET_DOWNLOAD } from "../../../redux/project/projectActions";
import { selectPeakFittingCifFiles, selectPeakFittingCifFilesABC, selectPeakFittingCifTemplateFiles, selectPeakFittingDataActiveFiles, selectPeakFittingDataFiles, selectPeakFittingFitting, selectPeakFittingInstrumentalFile, selectPeakFittingInstrumentalFileWaveLength, selectPeakFittingInstrumentalTemplateFile, selectPeakFittingPartitionActiveIndex, selectPeakFittingPartitionSelectingPeaks, selectPeakFittingPartitions, selectPeakFittingWavelength } from "../../../redux/peakFitting/peakFittingSelectors";
import { FileSelection } from "../shared/file-selection/file-selection";
import { GET_PEAK_FITTING, SET_PEAK_FITTING_ACTIVE_INDEX, SET_PEAK_FITTING_CIF_FILES, SET_PEAK_FITTING_CIF_FILES_ABC, SET_PEAK_FITTING_CIF_TEMPLATE_FILES, SET_PEAK_FITTING_DATA_FILES, SET_PEAK_FITTING_INSTRUMENTAL_FILE, SET_PEAK_FITTING_INSTRUMENTAL_TEMPLATE_FILE, SET_PEAK_FITTING_PARTITIONS, SET_PEAK_FITTING_SELECTING_PEAKS, SET_PEAK_FITTING_WAVELENGTH } from "../../../redux/peakFitting/peakFittingActions";
import { cifFilesDirectories } from "../../../data/cif_files";
import { instrumentalFilesDirectories } from "../../../data/instrumental_files";
import WavelengthInput from "../shared/wavelength";
import PeakPicking from "../shared/peak-picking";
import { CifFileABC } from "../shared/cifFileABC";


const PeakFittingInput = ({ dispatch  }: { dispatch: Dispatch }) => {
	const dataFiles = useSelector(selectPeakFittingDataFiles);
	const dataActiveFiles = useSelector(selectPeakFittingDataActiveFiles);
	const cifFiles = useSelector(selectPeakFittingCifFiles);
	const cifTemplateFiles = useSelector(selectPeakFittingCifTemplateFiles);
	const instrumentalFile = useSelector(selectPeakFittingInstrumentalFile);
	const instrumentalFileTemplate = useSelector(selectPeakFittingInstrumentalTemplateFile);
	const fileWaveLength = useSelector(selectPeakFittingInstrumentalFileWaveLength);
	const wavelength = useSelector(selectPeakFittingWavelength);
	const fitting = useSelector(selectPeakFittingFitting);
	const partitions = useSelector(selectPeakFittingPartitions)
	const selectedPartition = useSelector(selectPeakFittingPartitionActiveIndex)
	const selectingPeaks = useSelector(selectPeakFittingPartitionSelectingPeaks)
	const cifFilesABC = useSelector(selectPeakFittingCifFilesABC);

	return (
		<div className={"flex flex-col flex-1 bg-primary-800 rounded-md"}>
			<h1 className={"text-white mb-2 text-lg font-bold"}>Peak Fitting</h1>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Select Files
				</h1>
				<FileSelection
					dispatch={dispatch}
					selected={dataFiles}
					setSelected={files => dispatch({ type: SET_PEAK_FITTING_DATA_FILES, payload: files })}
					title="Select Data File(s)"
					extensions={[".dat"]}
					defaultExtension={".dat"}
					multiple={true}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={cifFiles}
					selectedTemplates={cifTemplateFiles}
					setSelected={files => dispatch({ type: SET_PEAK_FITTING_CIF_FILES, payload: files })}
					setSelectedTemplates={files => dispatch({ type: SET_PEAK_FITTING_CIF_TEMPLATE_FILES, payload: files })}
					title="Select Cif File(s)"
					extensions={[".cif"]}
					defaultExtension={".cif"}
					multiple={true}
					templates={cifFilesDirectories}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={instrumentalFile}
					selectedTemplates={instrumentalFileTemplate}
					setSelected={file => dispatch({ type: SET_PEAK_FITTING_INSTRUMENTAL_FILE, payload: file })}
					setSelectedTemplates={file => dispatch({ type: SET_PEAK_FITTING_INSTRUMENTAL_TEMPLATE_FILE, payload: file })}
					title="Select Instrumental File"
					extensions={[".instprm"]}
					defaultExtension=".instprm"
					multiple={false}
					templates={instrumentalFilesDirectories}
				/>
				<br />
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Inputs
				</h1>
				<WavelengthInput
					dispatch={dispatch}
					fileWaveLength={fileWaveLength}
					wavelength={wavelength}
					setWavelength={(wavelength: number) => dispatch({ type: SET_PEAK_FITTING_WAVELENGTH, payload: wavelength })}
					method={"peakFitting"}
					instrumentalFile={instrumentalFile}
					instrumentalTemplateFile={instrumentalFileTemplate}
				/>
				<CifFileABC
					dispatch={dispatch}
					cifFilesABC={cifFilesABC}
					cifFiles={cifFiles}
					cifTemplateFiles={cifTemplateFiles}
					analysisMethod={"peakFitting"}
					setABCValues={(abcValues) => dispatch({ type: SET_PEAK_FITTING_CIF_FILES_ABC, payload: abcValues })}
				/>
			</div>
			<PeakPicking
				setPartitions={(partitions) => dispatch({ type: SET_PEAK_FITTING_PARTITIONS, payload: partitions })}
				partitions={partitions}
				setActiveIndex={(index) => dispatch({ type: SET_PEAK_FITTING_ACTIVE_INDEX, payload: index })}
				selectedPartition={selectedPartition}
				setSelectingPeaks={(value) => dispatch({ type: SET_PEAK_FITTING_SELECTING_PEAKS, payload: value })}
				selectPeaks={selectingPeaks}
			/>
			<button
				className={"cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded"}
				onClick={() => {
					if (!cifFiles.length && !cifTemplateFiles.length) {
						toast.error("Please add at least one cif file");
						return;
					}

					if (!instrumentalFile && !instrumentalFileTemplate) {
						toast.error("Please add a instrumental file");
						return;
					}

					if (!dataActiveFiles.length) {
						toast.error("Please add at least one data file");
						return;
					}

					dispatch({ type: GET_PEAK_FITTING })
				}}
			>
				Save and calculate
			</button>
			<button
				className={`cursor-pointer ${Object.keys(fitting).length ? "text-white" : "text-gray-500 cursor-not-allowed"} font-bold bg-primary-600 m-4 p-4 rounded-md`}
				onClick={() => {
					dispatch({ type: GET_DOWNLOAD, payload: { type: "peakFitting" } })
				}}
			>
				Download Data
			</button>
		</div>
	);
};

export default PeakFittingInput;
