import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import AnalysisFilesTable from "./analysis-files-table";
import { GlobalState } from "../../../store/models";
import AnalysisChart from "./chart/analysis-chart";

const selectCurrentProjectFiles = (state: GlobalState) =>
  state.projectReducer.currentProjectFiles;
const selectCurrentChartData = (state: GlobalState) =>
  state.projectReducer.currentChartData;

export default function AnalysisResults({ dispatch }: { dispatch: Dispatch }) {
  const currentProjectFiles = useSelector(selectCurrentProjectFiles);

  const currentChartData = useSelector(selectCurrentChartData);

  return (
    <div
      className={
        "flex flex-col flex-1 bg-primary-800 rounded-md w-2/3 max-h-80"
      }
    >
      <div>
        <AnalysisChart chartData={currentChartData} />
      </div>
      <div
        className={"border-b border-primary-900 border-solid xl:h-screen"}
      ></div>
      <AnalysisFilesTable
        dispatch={dispatch}
        files={currentProjectFiles}
      ></AnalysisFilesTable>
    </div>
  );
}
