import ReportInput from "./report-input";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import ReportFilesTable from "./report-files-table";
import { GlobalState } from "../../../store/models";
import LineChart from "./chart/chart-plotly";

const selectCurrentProjectFiles = (state: GlobalState) =>
  state.projectReducer.currentProjectFiles;

const PrecipitationReport = ({ dispatch }: { dispatch: Dispatch }) => {
  const selectCurrentReportChart = (state: GlobalState) =>
    state.projectReducer.currentReportChartData;
  const currentProjectFiles = useSelector(selectCurrentProjectFiles);

  const currentReportChartData = useSelector(selectCurrentReportChart);
  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col"}>
      <ReportInput dispatch={dispatch} chartData={currentReportChartData} />
      <div
        className={
          "flex flex-col flex-1 bg-primary-800 rounded-md w-2/3 max-h-80"
        }
      >
        <div className={"flex-1 bg-primary-800 rounded-md"}>
          <LineChart chartData={currentReportChartData}></LineChart>
        </div>
        <div
          className={"border-b border-primary-900 border-solid xl:h-screen"}
        ></div>
        <ReportFilesTable files={currentProjectFiles}></ReportFilesTable>
      </div>
    </div>
  );
};

export default PrecipitationReport;
