import { all, put, takeEvery } from "redux-saga/effects";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUBMIT, GET_CURRENT_USER, GET_CURRENT_USER_TOKEN, LOG_IN, LOG_OUT, SET_CURRENT_USER, SET_OPERATION_IN_PROGRESS, SET_USER_LOADED, SET_USER_TOKEN, SIGN_UP } from "./generalActions";
import { Auth } from "aws-amplify";
import toast from "react-hot-toast";

function* logInUser(action: any) {
  try {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true })
    yield Auth.signIn({
      username: action.payload.email,
      password: action.payload.password,
    });
    yield put({ type: GET_CURRENT_USER });
    toast.success("Logged in successfully");
  } catch (e) {
    toast.error(e.message);
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false })
  }
}

function* logOutUser() {
  try {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true })
    yield Auth.signOut();
    toast.success("Logged out successfully");
    yield put({ type: SET_CURRENT_USER, payload: { userId: "", userName: "" } });
  } catch (e) {
    toast.error(e.message);
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false })
  }
}

function* signUpUser(action: any) {
  try {
    yield Auth.signUp({
      username: action.payload.email,
      password: action.payload.password,
      attributes: {
        given_name: action.payload.username,
        "custom:userId": action.payload.username.toLowerCase().split(" ").join("-") + "-" + Date.now(),
      },
    });
    toast.success("Signed up successfully, we will approve your account soon");
  } catch (e) {
    toast.error(e.message);
  }
}

function* getUserToken() {
  try {
    const session = yield Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    yield put({ type: SET_USER_TOKEN, payload: token });
  } catch (e) {
    toast.error(e.message);
  }
}

function* getLoggedUser() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    yield put({ type: SET_CURRENT_USER, payload: {
      userId: user.attributes["custom:userId"],
      userName: user.attributes.given_name,
    } });
    const token = yield user.signInUserSession.getIdToken().getJwtToken();
    yield put({ type: SET_USER_TOKEN, payload: token});
  } catch (e) {
  } finally {
    yield put({ type: SET_USER_LOADED, payload: true });
  }
}

function* forgotPassword(action: any) {
  try {
    yield Auth.forgotPassword(action.payload.email);
    toast.success("Password reset link sent to your email");
  } catch (e) {
    toast.error(e.message);
  }
}

function* forgotPasswordSubmit(action: any) {
  try {
    yield Auth.forgotPasswordSubmit(action.payload.email, action.payload.verificationCode, action.payload.password);
    toast.success("Password reset successfully");
  } catch (e) {
    toast.error(e.message);
  }
}

export default function* generalSaga() {
  yield all([
    takeEvery(SIGN_UP, signUpUser),
    takeEvery(LOG_IN, logInUser),
    takeEvery(LOG_OUT, logOutUser),
    takeEvery(GET_CURRENT_USER, getLoggedUser),
    takeEvery(GET_CURRENT_USER_TOKEN, getUserToken),
    takeEvery(FORGOT_PASSWORD, forgotPassword),
    takeEvery(FORGOT_PASSWORD_SUBMIT, forgotPasswordSubmit),
  ]);
}
