import { GlobalState } from "../../store/models";

export const selectStressStrain = (state: GlobalState) => state.stressStrainReducer;

export const selectStressStrainDataFiles = (state: GlobalState) => state.stressStrainReducer.dataFiles;
export const selectStressStrainDataViewFile = (state: GlobalState) => state.stressStrainReducer.dataViewFile;
export const selectStressStrainDataActiveFiles = (state: GlobalState) => state.stressStrainReducer.dataActiveFiles;

export const selectStressStrainCifFiles = (state: GlobalState) => state.stressStrainReducer.cifFiles;
export const selectStressStrainCifTemplateFiles = (state: GlobalState) => state.stressStrainReducer.templateCifFiles;
export const selectStressStrainCifFilesABC = (state: GlobalState) => state.stressStrainReducer.cifFilesABC;

export const selectStressStrainInstrumentalFile = (state: GlobalState) => state.stressStrainReducer.instrumentalFile;
export const selectStressStrainInstrumentalTemplateFile = (state: GlobalState) => state.stressStrainReducer.templateInstrumentalFile;
export const selectStressStrainInstrumentalFileWaveLength = (state: GlobalState) => state.stressStrainReducer.instrumentalFileWaveLength;

export const selectStressStrainPartitions = (state: GlobalState) => state.stressStrainReducer.partitions;
export const selectStressStrainMovingPeaks = (state: GlobalState) => state.stressStrainReducer.movingPeaks;

export const selectStressStrainWavelength = (state: GlobalState) => state.stressStrainReducer.wavelength;

export const selectStressStrainFitting = (state: GlobalState) => state.stressStrainReducer.fitting;

export const selectStressStrainPartitionActiveIndex = (state: GlobalState) => state.stressStrainReducer.partition.activeIndex;
export const selectStressStrainPartitionSelectingPeaks = (state: GlobalState) => state.stressStrainReducer.partition.selectingPeaks;

export const selectStressStrainHistogram = (state: GlobalState) => state.stressStrainReducer.histogram;

export const selectStressStrainPartitionFitting = (state: GlobalState) => state.stressStrainReducer.partitionFitting;

export const selectStressStrainRotation = (state: GlobalState) => state.stressStrainReducer.rotation;

export const selectStressStrainStressPeaks = (state: GlobalState) => state.stressStrainReducer.stressPeaks;

export const selectStressStrainPlaneStressCondition = (state: GlobalState) => state.stressStrainReducer.planeStressCondition;

export const selectStressStrainStressFitting = (state: GlobalState) => state.stressStrainReducer.stressFitting;
export const selectStressStrainStressResult = (state: GlobalState) => state.stressStrainReducer.stressResult;
export const selectStressStrainStressPipelinePeakParameters = (state: GlobalState) => state.stressStrainReducer.stressPipelinePeakParameters;
