import { put, select, call, delay } from "redux-saga/effects";
import { SET_UPLOAD_STATUS, SET_UPLOAD_FILES_PROGRESS, GET_PROJECT_FILES } from "./projectActions";
import { getUploadPreAssignedUrls, getUploadSuccess } from "./projectHelpers";
import { selectCurrentProject, selectUserToken } from "./projectSelectors";
import { GET_CURRENT_USER_TOKEN } from "../general/generalActions";

export function* uploadFiles(payload) {
  if (payload.cancelUpload) {
    yield put({ type: SET_UPLOAD_STATUS, payload: 0 });
    return;
  }

  yield put({ type: SET_UPLOAD_STATUS, payload: 1 });

  const files = payload.payload.files;
  const fileStatusDict = initializeFileStatus(files);
  const fileProgressDict = initializeFileProgress(files);
  const failedFiles = {};
  const presignedUrls = {};
  let uploads = new Map();
  
  const currentProject = yield select(selectCurrentProject);
  const BUCKETS = 10;

  while (hasIncompleteFiles(fileStatusDict)) {
    const userToken = yield select(selectUserToken);

    if (canRequestMoreUrls(fileStatusDict, BUCKETS)) {
      const filePaths = getPendingFiles(fileStatusDict, BUCKETS);
      if (filePaths.length > 0) {
        yield put({ type: SET_UPLOAD_FILES_PROGRESS, payload: fileProgressDict });
        const filesInfo = yield getUploadPreAssignedUrls(userToken, currentProject.projectId, payload.payload.pipelineData, filePaths);
        assignPreSignedUrls(filesInfo, presignedUrls, fileStatusDict, fileProgressDict);
      }
    }

    startFileUploads(files, presignedUrls, fileStatusDict, fileProgressDict, failedFiles, uploads, BUCKETS);

    yield handleUploadPromises(uploads, fileStatusDict, fileProgressDict, failedFiles);

    yield finalizeUploads(fileStatusDict, fileProgressDict, presignedUrls, failedFiles, currentProject, userToken, BUCKETS);
  }

  yield finalizeProgress(fileProgressDict);
  yield completeUpload();
}

function uploadFile(url, file, onProgress, onFail, retries = 3) {
  return new Promise((resolve, reject) => {
    const attemptUpload = (attemptsLeft) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url);

      xhr.upload.onprogress = function(event) {
        if (event.lengthComputable) {
          const percentComplete = Math.round(10 + ((event.loaded / event.total) * 80));
          onProgress(percentComplete);
        }
      };

      xhr.onload = function() {
        if (xhr.status === 200) {
          resolve(file.fullPath || file.name);
        } else {
          console.error('Error during file upload:', xhr.statusText);
          if (attemptsLeft > 0) {
              attemptUpload(attemptsLeft - 1);
          } else {
              onFail();
          }
        }
      };

      xhr.onerror = function() {
        console.error('Error during file upload:', xhr.statusText);
        if (attemptsLeft > 0) {
          attemptUpload(attemptsLeft - 1);
        } else {
          onFail();
        }
      };

      xhr.send(file.file);
    };

    attemptUpload(retries);
  });
}


function* apiWrapper(fn, args, retries = 3) {
  for (let i = 0; i < retries; i++) {
    try {
      return yield call(fn, ...args);
    } catch (error) {
      if (i === 0) {
        yield put({ type: GET_CURRENT_USER_TOKEN });
        args[0] = yield select(selectUserToken);;
      }
      if (i >= retries) {
        console.error('All retry attempts failed:', error);
        throw error;
      }
    }
  }
}

function isPromiseSettled(promise) {
  return Promise.race([promise, new Promise(resolve => setTimeout(() => resolve('pending'), 1))]);
}

function initializeFileStatus(files) {
  const fileStatusDict = {};
  for (const file of files) {
    const fileKey = file.fullPath || file.file.name;
    fileStatusDict[fileKey] = 0;
  }
  return fileStatusDict;
}

function initializeFileProgress(files) {
  const fileProgressDict = {};
  for (const file of files) {
    const fileKey = file.fullPath || file.file.name;
    fileProgressDict[fileKey] = 0;
  }
  return fileProgressDict;
}

function hasIncompleteFiles(fileStatusDict: Record<string, number>): boolean {
  return Object.values(fileStatusDict).some(status => status < 4);
}

function canRequestMoreUrls(fileStatusDict, BUCKETS) {
  return Object.values(fileStatusDict).filter(status => status === 1).length <= BUCKETS + 2;
}

function getPendingFiles(fileStatusDict, BUCKETS) {
  return Object.keys(fileStatusDict).filter(key => fileStatusDict[key] === 0).slice(0, BUCKETS);
}

function assignPreSignedUrls(filesInfo, presignedUrls, fileStatusDict, fileProgressDict) {
  for (const [filePath, info] of Object.entries(filesInfo.data)) {
    presignedUrls[filePath] = info;
    fileStatusDict[filePath] = 1;
    fileProgressDict[filePath] = 10;
  }
}

function startFileUploads(files, presignedUrls, fileStatusDict, fileProgressDict, failedFiles, uploads, BUCKETS) {
  while (Object.values(fileStatusDict).filter(status => status === 2).length < BUCKETS) {
    const filePath = Object.keys(fileStatusDict).find(key => fileStatusDict[key] === 1 && !(key in failedFiles));
    if (filePath) {
      const file = files.find(file => file.fullPath ? file.fullPath === filePath : file.name === filePath);
      const info = presignedUrls[filePath];
      const onProgress = (progress) => {
        fileProgressDict[filePath] = progress;
      };
      const onFail = () => {
        failedFiles[filePath] = "";
      };
      uploads.set(filePath, uploadFile(info.uploadUrl, file, onProgress, onFail));
      fileStatusDict[filePath] = 2;
    } else {
      break;
    }
  }
}

function* handleUploadPromises(uploads, fileStatusDict, fileProgressDict, failedFiles) {
  while (uploads.size > 0) {
    let anySettled = false;
    for (const [filePath, uploadPromise] of uploads.entries()) {
      const status = yield isPromiseSettled(uploadPromise);
      if (status !== 'pending') {
        anySettled = true;
        const response = yield uploadPromise;
        if (!(response in failedFiles)) {
          fileStatusDict[response] = 3;
          fileProgressDict[response] = 90;
        }
        uploads.delete(filePath);
      }
    }
    if (!anySettled) {
      yield put({ type: SET_UPLOAD_FILES_PROGRESS, payload: { ...fileProgressDict } });
      yield delay(50);
    }
  }
}

function* finalizeUploads(fileStatusDict, fileProgressDict, presignedUrls, failedFiles, currentProject, userToken, BUCKETS) {
  if (shouldFinalizeUploads(fileStatusDict, BUCKETS)) {
    const fileIdentifiers = Object.keys(fileStatusDict).filter(key => fileStatusDict[key] === 3 && !(key in failedFiles));
    const fileInfos = fileIdentifiers.map(fileIdentifier => presignedUrls[fileIdentifier]);
    if (fileInfos.length !== 0) {
      yield apiWrapper(getUploadSuccess, [userToken, currentProject.projectId, fileInfos, currentProject.userId]);
      fileIdentifiers.forEach(fileIdentifier => {
        fileStatusDict[fileIdentifier] = 4;
        fileProgressDict[fileIdentifier] = 100;
      });
    }
  }

  if (shouldDelayUploads(fileStatusDict, BUCKETS)) {
    yield delay(10);
  }

  markFailedFiles(fileStatusDict, fileProgressDict, failedFiles);
}

function shouldFinalizeUploads(fileStatusDict: Record<string, number>, BUCKETS: number): boolean {
  return Object.values(fileStatusDict).filter(status => status === 3).length >= BUCKETS ||
    Object.values(fileStatusDict).filter(status => status < 3).length === 0;
}

function shouldDelayUploads(fileStatusDict: Record<string, number>, BUCKETS: number): boolean {
  return Object.values(fileStatusDict).filter(status => status === 2).length >= BUCKETS ||
    Object.values(fileStatusDict).filter(status => status < 2).length === 0;
}

function markFailedFiles(fileStatusDict, fileProgressDict, failedFiles) {
  for (const filePath of Object.keys(failedFiles)) {
    fileStatusDict[filePath] = 4;
    fileProgressDict[filePath] = -1;
  }
}

function* finalizeProgress(fileProgressDict) {
  yield put({ type: SET_UPLOAD_FILES_PROGRESS, payload: fileProgressDict });
}

function* completeUpload() {
  yield put({ type: SET_UPLOAD_STATUS, payload: 2 });
  yield put({ type: GET_PROJECT_FILES });
}
