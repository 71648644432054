import { ToggleInputFieldModel } from "../../../../data/models";

export interface ToggleInputListProps {
  toggleInputsModel: ToggleInputFieldModel[];
  disabled: boolean;
  values: any;
  validate: boolean;
  onValueChange: (dynamodb_name: string, value: string | number) => void;
}

const getInputType = (type: string) => {
  switch (type) {
    case "float":
      return "number";
    case "boolean":
      return "checkbox";
    default:
      return "text";
  }
};

export const ToggleInputList = ({
  toggleInputsModel,
  disabled,
  values,
  validate,
  onValueChange,
}: ToggleInputListProps) => {
  const onChange = (
    dynamodb_name: string,
    value: string,
    controlType: string
  ) => {
    if (controlType === "float") {
      onValueChange(dynamodb_name, parseFloat(value));
    } else {
      onValueChange(dynamodb_name, value);
    }
  };

  const inputs = toggleInputsModel.map((control) => {
    const isInvalid =
      control.required &&
      control.type === "float" &&
      validate &&
      (values[control.dynamodb_name] === undefined ||
        values[control.dynamodb_name] === "");
    return (
      <div key={control.id} className={"mb-2 flex flex-col flex-1 max-w-4/5"}>
        <input
          id={control.id}
          disabled={disabled}
          key={control.id}
          className={`${isInvalid ? "border-red-500" : "border-white"}
          bg-primary-850 p-2 rounded-md border border-solid text-white`}
          placeholder={!disabled ? control.label : ""}
          required={control.required}
          type={getInputType(control.type)}
          // defaultValue={!disabled ? control.default_value : undefined}
          step="0.0001"
          value={values[control.dynamodb_name]}
          onChange={(e) => {
            onChange(control.dynamodb_name, e.target.value, control.type);
          }}
        />
      </div>
    );
  });
  return <>{inputs}</>;
};
