import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { SET_CURRENT_PROJECT, DELETE_PROJECT, RENAME_PROJECT } from "../../../redux/project/projectActions";
import { ProjectModel } from "../../../models/project.model";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import { selectCurrentProjectId, selectProjectsList } from "../../../redux/project/projectSelectors";

const ProjectsList = ({ dispatch }: { dispatch: Dispatch }) => {
  const projects = useSelector(selectProjectsList).sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1);
  const currentProjectId = useSelector(selectCurrentProjectId);

  const [displayProjectSettings, setDisplayProjectSettings] = useState("" as string);
  const [editProjectName, setEditProjectName] = useState("" as string);
  const [newProjectName, setNewProjectName] = useState("" as string);

  const loadProject = (project: ProjectModel) => {
    dispatch({ type: SET_CURRENT_PROJECT, payload: project });
  };

  const setProjectName = (name: string, projectId: string) => {
    setNewProjectName(name);
  };

  const newProject = () => {
    const def = {
      projectId: "",
      userId: "",
      projectName: "",
      createdAt: 0,
    }
    dispatch({ type: SET_CURRENT_PROJECT, payload: def });
  };

  const handleDeleteProject = (project: ProjectModel) => {
    if (project.projectId === currentProjectId) {
      const def = {
        projectId: "",
        userId: "",
        projectName: "",
        createdAt: 0,
      }
      dispatch({ type: SET_CURRENT_PROJECT, payload: def });
    }

    dispatch({ type: DELETE_PROJECT, payload: { projectId: project.projectId } });
  };

  const handleNameChange = (projectID: string) => {
    dispatch({ type: RENAME_PROJECT, payload: { projectName: newProjectName, projectId: projectID } });
    setEditProjectName("");
  };

  return (
    <div className={"flex rounded-md bg-primary-800 flex-1 xl:flex-col"}>
      <div
        className={
          "flex-1 flex flex-col p-4 border-r border-primary-900 border-solid xl:border-b xl:border-r-0 overflow-y-auto"
        }
      >
        <div className={"flex justify-between mb-4"}>
          <h1 className={"p-1 font-bold text-white text-base"}>Projects</h1>
          <h1
            className={
              "p-1 text-blue-400 text-base cursor-pointer hover:bg-primary-600 rounded-md"
            }
            onClick={newProject}
          >
            + New project
          </h1>
        </div>
        <div className={"flex flex-col mb-4"}>
          <div className={"grid grid-cols-3"}>
            <label className={"text-primary-500 font-bold"}>Project name</label>
            <label className={"text-primary-500 font-bold text-center"}>Created At</label>
            <label className={"text-primary-500 font-bold text-right"}></label>
          </div>
          {projects.map((project) => (
            <div className="flex hover:bg-primary-600" key={project.projectId}>
              <div
                key={project.projectId}
                className={`border-b border-primary-900 border-solid grid-cols-2 grid w-8/12
                ${project.projectId === currentProjectId
                  ? ""
                  : "cursor-pointer"
                }
                `}
                onClick={() => !editProjectName ? loadProject(project) : null}
              >
              <h1
                className={`${"items-center py-2"} ${
                  project.projectId === currentProjectId
                    ? "text-white"
                    : "text-primary-500 cursor-pointer"
                }`}
              >
                {editProjectName === project.projectId ? (
                  <input
                    type="text"
                    className="bg-primary-800 text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent"
                    onChange={(e) => {
                      setProjectName(e.target.value, project.projectId);
                    }}
                    defaultValue={
                      projects.find((p) => p.projectId === project.projectId).projectName || ""
                    }
                    onBlur={() => setEditProjectName("")}
                    autoFocus={true}
                    onKeyDown={(e) => e.key === 'Enter' && handleNameChange(
                      project.projectId
                    )}
                  />
                ) : (
                  <p>
                    {projects.find((p) => p.projectId === project.projectId)?.projectName || ""}
                  </p>
                )}
              </h1>
              <h1
                className={`${"items-center py-2 text-center"} ${
                  project.projectId === currentProjectId
                    ? "text-white"
                    : "text-primary-500 cursor-pointer"
                }`}
              >
                {new Date(project.createdAt).toLocaleDateString()}
              </h1>
              </div>
              <div className="w-4/12 border-b border-primary-900 border-solid text-right pr-2 py-2"
                onMouseLeave={() => setDisplayProjectSettings("")}
              >
                <FontAwesomeIcon icon={faEllipsisVertical} color={project.projectId === currentProjectId ? "white" : "gray"} className="cursor-pointer project-settings-icon"
                  onMouseEnter={() => setDisplayProjectSettings(project.projectId)}
                />
                {displayProjectSettings === project.projectId && (
                  <div className="flex flex-col absolute rounded-md bg-primary-600 right-12 ">
                    <div className="text-white p-3 cursor-pointer rounded-t-md hover:bg-primary-500"
                      onClick={() => {
                          setEditProjectName(project.projectId);
                        }
                      }
                    >
                      <p>Rename</p>
                    </div>
                    <div className="text-white p-3 cursor-pointer rounded-b-md border-t-2 border-gray-900 hover:bg-primary-500"
                      onClick={() => handleDeleteProject(project)}
                    >
                      <p>Remove</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProjectsList;
