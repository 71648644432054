import { ProjectFileModel } from "../../../models/project.model";

export default function ReportFilesTable({
  files,
}: {
  files: ProjectFileModel[];
}) {
  return (
    <div className={"w-full overflow-x-auto flex-1"}>
      <table className={"table-auto text-xs w-full files-table"}>
        <tbody>
          <tr
            className={
              "flex-2 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis text-primary-400 text-left border-b border-gray-300"
            }
          >
            <th className={"px-2 py-2"}>Data title</th>
            <th className={"px-2 py-2"}>Composition</th>
            <th className={"px-2 py-2"}>Temperature [C]</th>
            <th className={"px-2 py-2"}>Duration [min]</th>
            <th className={"px-2 py-2"}>Instrument</th>
            <th className={"px-2 py-2"}>Date added</th>
            <th className={"px-2 py-2"}>Experiment date</th>
          </tr>
          {files.map((fileItem, index) => (
            <tr
              key={index}
              className={
                "text-primary-500 flex-1 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis"
              }
            >
              <td className={"px-4 py-1"}>
                {`${index + 1}. ${fileItem.fileRef || fileItem.fileRef}`}
              </td>
              <td className={"px-4 py-1"}>
                {(fileItem as any).chemical_composition}
              </td>
              <td className={"px-4 py-1"}>{(fileItem as any).temperature}</td>
              <td className={"px-4 py-1"}>
                {(fileItem as any).measurement_duration}
              </td>
              <td className={"px-4 py-1"}>{(fileItem as any).instrument}</td>
              <td className={"px-4 py-1"}>
                {new Date(
                  parseInt((fileItem as any).createdAt)
                ).toLocaleDateString()}
              </td>
              <td className={"px-4 py-1"}>
                {(fileItem as any).experiment_date}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
