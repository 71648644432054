import { useEffect, useRef, useState } from "react";
import { Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PhaseQuantificationAnalysis from "../phase-quantification/phase-quantification";
import AzimuthalIntegrationAnalysis from "../azimuthal-integration/azimuthal-integration";
import PipelineStatus from "./status";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PIPELINE_FILES, GET_PIPELINE_STATUS } from "../../../redux/project/projectActions";
import { selectPhaseQuantification } from "../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { useSelector } from "react-redux";
import { GET_PHASE_QUANTIFICATION_CALLBACK_FITTED, GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS } from "../../../redux/phaseQuantification/phaseQuantificationActions";
import StressStrainAnalysis from "../stress-strain/stress-strain";
import { GET_STRESS_STRAIN_CALLBACK, GET_STRESS_STRAIN_CALLBACK_FITTING, GET_STRESS_STRAIN_CALLBACK_RESULT } from "../../../redux/stressStrain/stressStrainActions";
import { selectStressStrain } from "../../../redux/stressStrain/stressStrainSelectors";
import PhaseQuantificationReport from "./report/phase-quantification";
import StressStrainReport from "./report/stress-strain";

const pipelineConfigurations = {
  "azimuthalIntegration-phaseQuantification": ["status", "azimuthal_integration", "phase_quantification", "report"],
  "azimuthalIntegration-stressStrain": ["status", "azimuthal_integration", "stress_strain", "report"],
};

const TIME_BETWEEN_STATUS_CHECKS = 30000;

const Pipeline = ({ dispatch, pipelineType }: { dispatch: Dispatch; pipelineType: string }) => {
  const phaseQuantification = useSelector(selectPhaseQuantification);
  const stressStrain = useSelector(selectStressStrain);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("left");
  const [pipelineStatusInterval, setPipelineStatusInterval] = useState<NodeJS.Timeout | undefined>(undefined);
  const [pipelineProgressInterval, setPipelineProgressInterval] = useState<NodeJS.Timeout | undefined>(undefined);
  const [phaseQuantificationProgressInterval, setPhaseQuantificationProgressInterval] = useState<NodeJS.Timeout | undefined>(undefined);

  const nodeRef = useRef(null);

  const components = pipelineConfigurations[pipelineType];

  const getCurrentComponent = () => {
    switch (components[currentIndex]) {
      case "phase_quantification":
        return <PhaseQuantificationAnalysis dispatch={dispatch} pipeline={true} />;
      case "azimuthal_integration":
        return <AzimuthalIntegrationAnalysis dispatch={dispatch} pipeline={true} />;
      case "stress_strain":
        return <StressStrainAnalysis dispatch={dispatch} pipeline={true} />;
      case "status":
        return <PipelineStatus dispatch={dispatch} pipelineType={pipelineType} />;
      case "report":
        if (pipelineType === "azimuthalIntegration-phaseQuantification") {
          return <PhaseQuantificationReport dispatch={dispatch} />;
        } else {
          return <StressStrainReport dispatch={dispatch} />;
        }
      default:
        return <></>;
    }
  };

  useEffect(() => {
		if (pipelineStatusInterval !== undefined) clearInterval(pipelineStatusInterval);

    dispatch({ type: GET_PIPELINE_STATUS, payload: { pipelineType} });

		const newInterval = setInterval(async () => {
			dispatch({ type: GET_PIPELINE_STATUS, payload: { pipelineType} });
		}, TIME_BETWEEN_STATUS_CHECKS);

		setPipelineStatusInterval(newInterval);

    return () => {
      clearInterval(newInterval);
    };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

  useEffect(() => {
		if (pipelineProgressInterval !== undefined) clearInterval(pipelineProgressInterval);

    dispatch({ type: GET_PIPELINE_FILES, payload: { pipelineType } });

		const newInterval = setInterval(async () => {
			dispatch({ type: GET_PIPELINE_FILES, payload: { pipelineType } });
		}, TIME_BETWEEN_STATUS_CHECKS);

		setPipelineProgressInterval(newInterval);

    return () => {
      clearInterval(newInterval);
    };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

  useEffect(() => {
		if (phaseQuantificationProgressInterval !== undefined) clearInterval(phaseQuantificationProgressInterval);
    
    let action;

    if (pipelineType === "azimuthalIntegration-phaseQuantification") {
      action = GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS;
    } else {
      action = GET_STRESS_STRAIN_CALLBACK;
    }

		const newInterval = setInterval(async () => {
      if (pipelineType === "azimuthalIntegration-phaseQuantification") {
        dispatch({ type: action });
      } else {
			  dispatch({ type: action });
        dispatch({ type: GET_STRESS_STRAIN_CALLBACK_RESULT })
      }
		}, TIME_BETWEEN_STATUS_CHECKS);

		setPhaseQuantificationProgressInterval(newInterval);

    return () => {
      clearInterval(newInterval);
    };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, pipelineType]);

  useEffect(() => {
    if (pipelineType === "azimuthalIntegration-phaseQuantification") {
      dispatch({ type: GET_METHOD_DATA, payload: {type: "phaseQuantification"} })
      dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "phaseQuantification"} })
    }
    if (pipelineType === "azimuthalIntegration-stressStrain") {
      dispatch({ type: GET_METHOD_DATA, payload: {type: "stressStrain"} })
      dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "stressStrain"} })
    }

	  dispatch({ type: GET_METHOD_DATA, payload: {type: "azimuthalIntegration"} });
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "azimuthalIntegration"} })
	}, [dispatch, pipelineType]);

  useEffect(() => {
    if (!phaseQuantification.dataViewFile && !stressStrain.dataViewFile) return;

    let action;
    if (pipelineType === "azimuthalIntegration-phaseQuantification") {
      action = GET_PHASE_QUANTIFICATION_CALLBACK_FITTED;
    } else {
      action = GET_STRESS_STRAIN_CALLBACK_FITTING;
    }

    dispatch({ type: action });
  }, [dispatch, phaseQuantification.dataViewFile, stressStrain.dataViewFile, pipelineType])

  const handleLeftClick = () => {
    if (currentIndex === 1 || currentIndex === 0) {
      setDirection("left");
    } else {
      setDirection("right");
    }
    if (currentIndex !== 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      
    }
  };

  const handleRightClick = () => {
    if (currentIndex === components.length - 1) {
      setDirection("right");
    } else {
      setDirection("left");
    }
    if (currentIndex !== components.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow relative overflow-hidden"}>
      <div
        className="absolute top-0 left-0 p-4 cursor-pointer transform transition-transform active:scale-95 z-10 bg-primary-500 hover:bg-primary-700 rounded-r-full flex items-center justify-center"
        onClick={handleLeftClick}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="text-white" />
      </div>
      <div
        className="absolute top-0 right-0 p-4 cursor-pointer transform transition-transform active:scale-95 hover:bg-primary-700 z-10 bg-primary-500 rounded-l-full flex items-center justify-center"
        onClick={handleRightClick}
      >
        <FontAwesomeIcon icon={faArrowRight} size="2x" className="text-white" />
      </div>
      <TransitionGroup className="flex-1">
        <CSSTransition
          nodeRef={nodeRef}
          key={currentIndex}
          timeout={300}
          classNames={direction === "right" ? "slide-right" : "slide-left"}
        >
          <div ref={nodeRef} className="flex-1" style={{ height: "100%" }}>{getCurrentComponent()}</div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Pipeline;
