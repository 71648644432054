import { Partition, StressStrainPeaks } from "../../../models/analysis.general.model";


interface Props {
  setStressStrainPeaks: (stressStrainPeaks: StressStrainPeaks[][]) => void,
  stressStrainPeaks: StressStrainPeaks[][],
  partitions: Partition[],
  setActiveIndex: (index: number) => void,
  selectedPartition: number,
}

const StressStrainPeaksPicker = ({ setStressStrainPeaks, stressStrainPeaks, partitions, setActiveIndex, selectedPartition }: Props) => {
	const handlePeakInputs = (peakIndex: number, analysisType: "youngsModulus" | "poissonRatio" | "tthRef", value: number) => {
		const partitionsMod: StressStrainPeaks[][] = stressStrainPeaks;

		partitionsMod[selectedPartition][peakIndex] = {
			...partitionsMod[selectedPartition][peakIndex],
			[analysisType]: value
		}

    setStressStrainPeaks(partitionsMod)
	};

	return (
    <div className={"flex flex-col bg-primary-600 mb-2 rounded-md text-white mx-1"}>
      <ul className="flex w-full border-2 border-primary-500 rounded-lg">
        {partitions.map((partition, index) => (
          <li
            key={partition.name}
            className={`flex-1 py-2 text-center cursor-pointer ${
              index !== selectedPartition ? 'border-r-2 border-primary-500' : 'bg-primary-500'
            } ${index === 0 ? 'rounded-l' : ''} ${index === partitions.length - 1 ? 'rounded-r' : ''}`}
            onClick={() => setActiveIndex(index)}
          >
            {partitions.length > 4
              ? `${partition.name}`
              : partitions.length > 2
              ? `Part ${partition.name}`
              : `Partition ${partition.name}`}
          </li>        
        ))}
      </ul>
      <div className="p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full">
        <div className={"flex flex-col items-center justify-center mt-5 w-full scroll-light relative"}>
          <div className={"w-full overflow-x-scroll overflow-y-hidden scroll-light flex flex-row"}>
            <div className={"flex flex-col items-center text-left gap-1 mr-2 font-bold mb-4"}>
              <label className={"h-8 w-36 flex items-center text-center"}>
                Peak
              </label>
              <label className={"h-10 w-36 flex items-center"}>
                Youngs Modulues
              </label>
              <label className={"h-10 w-36 flex items-center"}>
                Poisson ratio
              </label>
              <label className={"h-10 w-36 flex items-center"}>
                Stress Free Ref Position
              </label>
            </div>
            {partitions[selectedPartition] && stressStrainPeaks[selectedPartition] && stressStrainPeaks[selectedPartition].map((partition, index) => {
              return (
              <div
                className={"flex flex-col items-center w-max gap-1 mr-2"}
                key={`3-${index}`}
              >
                <div className="h-8 whitespace-nowrap">
                  <p>Peak {index + 1}</p>
                </div>
                <div className="h-10">
                  <input
                    className={"border-gray-500 bg-primary-700 p-2 rounded-md border border-solid text-white w-32"}
                    type={"number"}
                    step="0.001"
                    value={partition.youngsModulus !== 0 ? partition.youngsModulus : ""}

                    onChange={(e) => handlePeakInputs(index, "youngsModulus", parseFloat(e.target.value))}
                  />
                </div>
                <div className="h-10">
                  <input
                    className={"border-gray-500 bg-primary-700 p-2 rounded-md border border-solid text-white w-32"}
                    type={"number"}
                    step="0.001"
                    value={partition.poissonRatio !== 0 ? partition.poissonRatio : ""}
                    onChange={(e) => handlePeakInputs(index, "poissonRatio", parseFloat(e.target.value))}
                  />
                </div>
                <div className="h-10">
                  <input
                    className={"border-gray-500 bg-primary-700 p-2 rounded-md border border-solid text-white w-32"}
                    type={"number"}
                    step="0.001"
                    value={partition.tthRef !== 0 ? partition.tthRef : ""}
                    onChange={(e) => handlePeakInputs(index, "tthRef", parseFloat(e.target.value))}
                  />
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
    </div>
	);
};

export default StressStrainPeaksPicker;
