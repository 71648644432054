// SETTERS
export const SET_DISLOCATION_DENSITY_DATA_FILES = "SET_DISLOCATION_DENSITY_DATA_FILES";
export const SET_DISLOCATION_DENSITY_DATA_VIEW_FILE = "SET_DISLOCATION_DENSITY_DATA_VIEW_FILE"
export const SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES = "SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES";

export const SET_DISLOCATION_DENSITY_CIF_FILES = "SET_DISLOCATION_DENSITY_CIF_FILES";
export const SET_DISLOCATION_DENSITY_CIF_TEMPLATE_FILES = "SET_DISLOCATION_DENSITY_CIF_TEMPLATE_FILES";
export const SET_DISLOCATION_DENSITY_CIF_FILES_ABC = "SET_DISLOCATION_DENSITY_CIF_FILES_ABC";

export const SET_DISLOCATION_DENSITY_INSTRUMENTAL_FILE = "SET_DISLOCATION_DENSITY_INSTRUMENTAL_FILE";
export const SET_DISLOCATION_DENSITY_INSTRUMENTAL_WAVELENGTH = "SET_DISLOCATION_DENSITY_INSTRUMENTAL_WAVELENGTH";
export const SET_DISLOCATION_DENSITY_INSTRUMENTAL_TEMPLATE_FILE = "SET_DISLOCATION_DENSITY_INSTRUMENTAL_TEMPLATE_FILE";

export const SET_DISLOCATION_DENSITY_WAVELENGTH = "SET_DISLOCATION_DENSITY_WAVELENGTH";

export const SET_DISLOCATION_DENSITY = "SET_DISLOCATION_DENSITY"
export const SET_DISLOCATION_DENSITY_RESET = "SET_DISLOCATION_DENSITY_RESET"

export const SET_DISLOCATION_DENSITY_PARTITIONS = "SET_DISLOCATION_DENSITY_PARTITIONS"

export const SET_DISLOCATION_DENSITY_RESULT = "SET_PHASE_QUANTIFICATION_RESULT"

export const SET_DISLOCATION_DENSITY_ACTIVE_INDEX = "SET_DISLOCATION_DENSITY_ACTIVE_INDEX"
export const SET_DISLOCATION_DENSITY_SELECTING_PEAKS = "SET_DISLOCATION_DENSITY_SELECTING_PEAKS"

export const SET_DISLOCATION_DENSITY_HISTOGRAM = "SET_DISLOCATION_DENSITY_HISTOGRAM"

export const SET_DISLOCATION_DENSITY_PARTITION_FITTING = "SET_DISLOCATION_DENSITY_PARTITION_FITTING"

export const SET_DISLOCATION_DENSITY_PARAMETERS = "SET_DISLOCATION_DENSITY_PARAMETERS"
export const SET_DISLOCATION_DENSITY_FITTING = "SET_DISLOCATION_DENSITY_FITTING"

export const SET_DISLOCATION_DENSITY_CH00 = "SET_DISLOCATION_DENSITY_CH00"
export const SET_DISLOCATION_DENSITY_TTH1 = "SET_DISLOCATION_DENSITY_TTH1"
export const SET_DISLOCATION_DENSITY_TTH2 = "SET_DISLOCATION_DENSITY_TTH2"
export const SET_DISLOCATION_DENSITY_LN = "SET_DISLOCATION_DENSITY_LN"
export const SET_DISLOCATION_DENSITY_L0 = "SET_DISLOCATION_DENSITY_L0"
export const SET_DISLOCATION_DENSITY_RHO = "SET_DISLOCATION_DENSITY_RHO"

export const SET_DISLOCATION_DENSITY_L0_PICKER_SELECTION = "SET_DISLOCATION_DENSITY_L0_PICKER_SELECTION"
export const SET_DISLOCATION_DENSITY_L0_PICKER_RANGE = "SET_DISLOCATION_DENSITY_L0_PICKER_RANGE"

export const SET_DISLOCATION_DENSITY_RHO_PICKER_SELECTION = "SET_DISLOCATION_DENSITY_RHO_PICKER_SELECTION"
export const SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE = "SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE"

export const SET_DISLOCATION_DENSITY_WH = "SET_DISLOCATION_DENSITY_WH"
export const SET_DISLOCATION_DENSITY_K2CDK = "SET_DISLOCATION_DENSITY_K2CDK"
export const SET_DISLOCATION_DENSITY_K2CLNA = "SET_DISLOCATION_DENSITY_K2CLNA"
export const SET_DISLOCATION_DENSITY_L0_ANALYSIS = "SET_DISLOCATION_DENSITY_L0_ANALYSIS"
export const SET_DISLOCATION_DENSITY_RHO_ANALYSIS = "SET_DISLOCATION_DENSITY_RHO_ANALYSIS"

export const SET_DISLOCATION_DENSITY_L0_PICKER_DATA = "SET_DISLOCATION_DENSITY_L0_PICKER_DATA"
export const SET_DISLOCATION_DENSITY_RHO_PICKER_DATA = "SET_DISLOCATION_DENSITY_RHO_PICKER_DATA"

export const SET_DISLOCATION_DENSITY_WH_PARAMS = "SET_DISLOCATION_DENSITY_WH_PARAMS"
export const SET_DISLOCATION_DENSITY_MWH_PARAMS = "SET_DISLOCATION_DENSITY_MWH_PARAMS"


// GETTERS
export const GET_DISLOCATION_DENSITY_FITTING = "GET_DISLOCATION_DENSITY_FITTING";
export const GET_DISLOCATION_DENSITY_CALLBACK = "GET_DISLOCATION_DENSITY_CALLBACK"
export const GET_DISLOCATION_DENSITY_MWH = "GET_DISLOCATION_DENSITY_MWH"
export const GET_DISLOCATION_DENSITY_SUBGRAIN_SIZE = "GET_DISLOCATION_DENSITY_SUBGRAIN_SIZE"
export const GET_DISLOCATION_DENSITY_DENSITY = "GET_DISLOCATION_DENSITY_DENSITY"
