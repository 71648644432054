import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getInInInlineOperationInProgress, getInInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { useEffect, useState } from "react";
import { DropDownOptions } from "../../../data/models";
import { Dispatch } from "redux";
import DropDown from "../shared/drop-down";
import { getTitle } from "../../../helpers/name";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { selectAzimuthalIntegrationCakingResult, selectAzimuthalIntegrationDataViewFileRow, selectAzimuthalIntegrationImage, selectAzimuthalIntegrationImageCombined, selectAzimuthalIntegrationImageOptions, selectAzimuthalIntegrationImageVideo, selectAzimuthalIntegrationIntegrationResult, selectAzimuthalIntegrationXAxisUnit } from "../../../redux/azimuthalIntegration/azimuthalIntegrationSelectors";
import { GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES, GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION, SET_AZIMUTHAL_INTEGRATION_IMAGE_COLOR_MAP, SET_AZIMUTHAL_INTEGRATION_IMAGE_LOG } from "../../../redux/azimuthalIntegration/azimuthalIntegrationActions";
import ImagePlayer from "./components/video-player";
import Plot from "react-plotly.js";
import { AzimuthalIntegrationImageColorMap, AzimuthalIntegrationXAxis } from "../../../data/azimuthal_integrations";
import { waitFiveSeconds } from "../../../helpers/redux";
import toast from "react-hot-toast";
import { GraphConfig, GraphLayout, GraphStyling } from "../../../constants/graphs";


const AzimuthalIntegrationGraph = ({ dispatch, pipeline=false }: { dispatch: Dispatch, pipeline: boolean }) => {
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress)
  const inInInlineOperationInProgress = useSelector(getInInInlineOperationInProgress)
  const azimuthalIntegrationActiveImagePath = useSelector(selectAzimuthalIntegrationImage)
  const azimuthalIntegrationActiveImageCombinedPath = useSelector(selectAzimuthalIntegrationImageCombined)
  const azimuthalIntegrationActiveImageVideo = useSelector(selectAzimuthalIntegrationImageVideo)
  const azimuthalIntegrationXLabel = useSelector(selectAzimuthalIntegrationXAxisUnit)
  const azimuthalDataViewFileRow = useSelector(selectAzimuthalIntegrationDataViewFileRow)
  const azimuthalIntegrationImageOptions = useSelector(selectAzimuthalIntegrationImageOptions)
  const azimuthalIntegrationIntegrationResult = useSelector(selectAzimuthalIntegrationIntegrationResult)
  const azimuthalIntegrationCakingResult = useSelector(selectAzimuthalIntegrationCakingResult)

  const [activeTab, setActiveTab] = useState("detector_image")
  const [chartDataCaking, setChartDataCaking] = useState<any>([]);
  const [chartDataIntegration, setChartDataIntegration] = useState<any>([]);
  const [integrationText, setIntegrationText] = useState<string>("")
  const [visibleDiagrams, setVisibleDiagrams] = useState({
    id: "diagrams",
    label: "Diagrams",
    options: [],
  } as DropDownOptions)

  useEffect(() => {
    let dropdownOptions = {
      id: "diagrams",
      label: "Diagrams",
      options: [
        {
          id: "detector_image",
          label: "Detector Image(s)",
          values: [
            { label: "Detector Image", value: "detector_image" },
            { label: "Detector Image Video", value: "detector_image_video" }
          ],
        },
      ],
    } as DropDownOptions

    dropdownOptions.options.push({
      label: `Caking Graph`,
      id: `caking_graph`,
      values: [
        { label: `Caking Graph`, value: `caking_graph` },
      ],
    })

    dropdownOptions.options.push({
      label: `Integration Graph`,
      id: `integration_graph`,
      values: [
        { label: `Integration Graph`, value: `integration_graph`, subOptions:
          { label: `Azimuthal Integration`, id: `azimuthal_integration`, values: [
            <div className="flex items-center justify-center">
              <input
                className="border border-gray-400 rounded-l-md px-3 py-2 text-xs"
                type="text"
                placeholder="e.g. 1-5, 8, start:stop:step"
                onChange={(e) => setIntegrationText(e.target.value)}
              />
              <button
                className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded-r-md text-xs"
                style={{ height: "34px" }}
                onClick={() => {
                  dispatch({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION, payload: integrationText})
                  toast.promise(waitFiveSeconds(3), {
                    loading: "Fetching integration...",
                    success: "Integration loaded!",
                    error: "",
                  })
                }}>
                Submit
              </button>
            </div>,
          ] },
        },
      ],
    })

    setVisibleDiagrams(dropdownOptions)
  }, [azimuthalIntegrationCakingResult, azimuthalIntegrationIntegrationResult, dispatch, integrationText])

  useEffect(() => {
    let integrationData = []

    try {
      integrationData = Object.keys(azimuthalIntegrationIntegrationResult).map((fileRow) => ({
        x: azimuthalIntegrationIntegrationResult[fileRow].radial,
        y: azimuthalIntegrationIntegrationResult[fileRow].intensity,
        name: `Integration - ${fileRow}`,
        type: 'scatter',
      }));
    } catch (e) {
      console.error(e)
    }

    setChartDataIntegration(integrationData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [azimuthalIntegrationIntegrationResult]);

  useEffect(() => {
    let cakingData = []

    try {
      cakingData = Object.keys(azimuthalIntegrationCakingResult).map((fileRow) => ({
        x: azimuthalIntegrationCakingResult[fileRow].radial,
        y: azimuthalIntegrationCakingResult[fileRow].intensity,
        name: `Caking Bin - ${fileRow}`,
        type: 'scatter',
      }));
    } catch (e) {
      console.error(e)
    }

    setChartDataCaking(cakingData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [azimuthalIntegrationCakingResult]);

  useEffect(() => {
    if (!pipeline) return;

    if (activeTab === "detector_image_video") dispatch({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES })
  }, [activeTab, dispatch, pipeline])

  return (
    <div className="flex flex-col items-center w-full relative h-3/5">
      {(inInlineOperationInProgress || inInInlineOperationInProgress) &&
        <div className={"absolute top-2 right-2"}>
          <Loader type="Oval" color="#68d391" height={30} width={30} />
        </div>
      }
      <div className={"flex justify-center items-center w-96 z-10 relative"} style={{marginBottom: "-50px"}}>
        <DropDown
          DropDownOptions={visibleDiagrams}
          title={getTitle(activeTab)}
          setSelected={(value: string) => setActiveTab(value)}
          selected={activeTab}
          multiple={false}
          padding={"p-2"}
          closeOnClick={true}
        />
      </div>
      {activeTab === "detector_image" &&
        <div className={"flex justify-center items-center w-full h-full mt-20 cursor-pointer"}>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <div className="tools absolute top-2 left-2 text-xs">
                  <button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1"
                    onClick={() => zoomIn()}
                  >
                    <span className="mr-2">+</span>
                    Zoom In
                  </button>
                  <button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1"
                    onClick={() => zoomOut()}
                  >
                    <span className="mr-2">-</span>
                    Zoom Out
                  </button>
                  <button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1"
                    onClick={() => resetTransform()}
                  >
                    <span className="mr-2">x</span>
                    Reset Transform
                  </button>
                </div>
                  <TransformComponent>
                    {azimuthalIntegrationActiveImagePath && (
                      <div className="flex justify-center items-center h-full w-full">
                        <img src={azimuthalIntegrationActiveImagePath} alt="Detector" className="object-cover" style={{ maxHeight: "46vh" }} />
                      </div>
                    )

                    }
                  </TransformComponent>
              </>
            )}
          </TransformWrapper>
          <div className="tools absolute top-10 left-2 text-xs">
            <DropDown
              DropDownOptions={AzimuthalIntegrationImageColorMap}
              title={"Color Map"}
              setSelected={(value: string) => dispatch({ type: SET_AZIMUTHAL_INTEGRATION_IMAGE_COLOR_MAP, payload: value })}
              selected={azimuthalIntegrationImageOptions.colorMap}
              multiple={false}
              padding={"p-2"}
              height="450px"
              width="w-48"
            />
          </div>
          <div className="tools absolute top-10 left-52 text-xs">
            <button
              className={`${azimuthalIntegrationImageOptions.log ? "bg-primary-600 hover:bg-primary-700" : "bg-primary-500 hover:bg-primary-600"} text-white font-bold py-1 px-2 rounded-full mr-1`}
              onClick={() => dispatch({ type: SET_AZIMUTHAL_INTEGRATION_IMAGE_LOG, payload: !azimuthalIntegrationImageOptions.log })}
            >
              Logarithmic
            </button>
          </div>
        </div>
      }
      {activeTab === "detector_image_combined" &&
        <div className={"flex justify-center items-center w-full h-full mt-20 cursor-pointer"}>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <div className="tools absolute top-2 left-2 text-xs">
                  <button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1"
                    onClick={() => zoomIn()}
                  >
                    <span className="mr-2">+</span>
                    Zoom In
                  </button>
                  <button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1"
                    onClick={() => zoomOut()}
                  >
                    <span className="mr-2">-</span>
                    Zoom Out
                  </button>
                  <button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1"
                    onClick={() => resetTransform()}
                  >
                    <span className="mr-2">x</span>
                    Reset Transform
                  </button>
                </div>
                  <TransformComponent>
                    {azimuthalIntegrationActiveImageVideo.length > 0 && azimuthalDataViewFileRow in azimuthalIntegrationActiveImageCombinedPath && (
                      <div className="flex justify-center items-center h-full w-full">
                        <img src={azimuthalIntegrationActiveImageCombinedPath[azimuthalDataViewFileRow]} alt="Detector Combined" className="object-cover" style={{ maxHeight: "46vh "}}/>
                      </div>
                    )
                    }
                  </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      }
      {activeTab === "detector_image_video" &&
        <div className={"flex flex-col justify-center items-center w-full relative"}>
          <div className={"w-full mt-14"}>
            {(azimuthalIntegrationActiveImageVideo.length > 0 || pipeline) && <ImagePlayer images={azimuthalIntegrationActiveImageVideo} dispatch={dispatch} pipeline={pipeline} />}
          </div>
        </div>
      }
      {activeTab === "caking_graph" && 
        <div className={"flex justify-center items-center h-full w-full mb-10"}>
          <Plot
            data={chartDataCaking}
            layout={{
              ...GraphLayout,
              xaxis: {
                ...GraphLayout.xaxis,
                title: {
                  ...GraphLayout.xaxis.title,
                  text: AzimuthalIntegrationXAxis.options[0].values.find((value) => value.value === azimuthalIntegrationXLabel)?.label,
                },
              },
            }}
            config={GraphConfig}
            useResizeHandler={true}
            style={GraphStyling}
          />
        </div>
      }
      {activeTab === "integration_graph" && 
        <div className={"flex justify-center items-center h-full w-full mb-10"}>
          <Plot
            data={chartDataIntegration}
            layout={{
              ...GraphLayout,
              xaxis: {
                ...GraphLayout.xaxis,
                title: {
                  ...GraphLayout.xaxis.title,
                  text: AzimuthalIntegrationXAxis.options[0].values.find((value) => value.value === azimuthalIntegrationXLabel)?.label,
                },
              },
            }}
            config={GraphConfig}
            useResizeHandler={true}
            style={GraphStyling}
          />
        </div>
      }
    </div>
  );
};

export default AzimuthalIntegrationGraph;
