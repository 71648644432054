import { GlobalState } from "../../store/models";

export const selectRietveldCifFiles = (state: GlobalState) => state.rietveldReducer.cifFiles;
export const selectRietveldCifTemplateFiles = (state: GlobalState) => state.rietveldReducer.cifTemplateFiles;
export const selectRietveldCifFilesABC = (state: GlobalState) => state.rietveldReducer.cifFilesABC;

export const selectRietveldInstrumentalFile = (state: GlobalState) => state.rietveldReducer.instrumentalFile;
export const selectRietveldInstrumentalTemplateFile = (state: GlobalState) => state.rietveldReducer.instrumentalTemplateFile;

export const selectRietveldInstrumentalFileWaveLength = (state: GlobalState) => state.rietveldReducer.instrumentalFileWaveLength;
export const selectRietveldWavelength = (state: GlobalState) => state.rietveldReducer.wavelength;

export const selectRietveldDataFiles = (state: GlobalState) => state.rietveldReducer.dataFiles;
export const selectRietveldDataViewFile = (state: GlobalState) => state.rietveldReducer.dataViewFile;
export const selectRietveldDataActiveFiles = (state: GlobalState) => state.rietveldReducer.dataActiveFiles;

export const selectRietveldRangeLower = (state: GlobalState) => state.rietveldReducer.rangeLower;
export const selectRietveldRangeHigher = (state: GlobalState) => state.rietveldReducer.rangeHigher;
export const selectRietveldRangeInitial = (state: GlobalState) => state.rietveldReducer.rangeInitial;

export const selectRietveldCrystalliteSize = (state: GlobalState) => state.rietveldReducer.crystalliteSize;
export const selectRietveldMustrains = (state: GlobalState) => state.rietveldReducer.mustrains;
export const selectRietveldParameters = (state: GlobalState) => state.rietveldReducer.parameters;

export const selectRietveld = (state: GlobalState) => state.rietveldReducer;

export const selectRietveldHistogram = (state: GlobalState) => state.rietveldReducer.histogram;
export const selectRietveldRefinedHistogram = (state: GlobalState) => state.rietveldReducer.refinedHistogram;
