import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectCurrentProjectFiles, selectReportScreen } from "../../redux/project/projectSelectors";
import ReportScreenGraph from "./report-screen-graph";
import { GET_PROJECT_FILES, GET_REPORT_SCREEN_ANALYSIS_TYPES, SET_REPORT_SCREEN_DATA_VIEW_FILES, SET_REPORT_SCREEN_STRESS_STRAIN_Y_AXIS_LINE } from "../../redux/project/projectActions";
import ReportScreenInput from "./report-screen-input";
import AnalysisTable from "../analysis-settings/shared/analysis-table";
import { compareKeysV2 } from "../../helpers/general";

const ReportScreen = ({ dispatch }: {dispatch: Dispatch}) => {
  const reportScreen = useSelector(selectReportScreen)
  const projectFiles = useSelector(selectCurrentProjectFiles);

  const [filesInformation, setFilesInformation] = useState<any>([]);
  const [graphType, setGraphType] = useState("Line" as "Line" | "Heatmap");
  const [lastFileType, setLastFileType] = useState<string>("dataFiles");
  const [latestSelected, setLatestSelected] = useState([]);

  useEffect(() => {
    dispatch({ type: GET_REPORT_SCREEN_ANALYSIS_TYPES });
    dispatch({ type: GET_PROJECT_FILES });
  }, [dispatch]);

  useEffect(() => {
    if (lastFileType === "dataFiles") {
      const currentFiles = projectFiles.filter((file) => file.fileRef.endsWith(".dat"));
      let filesMapping = []

      for (const file of currentFiles) {
        filesMapping.push({ fileName: file.fileRef })
      }

      filesMapping = filesMapping.sort((a, b) => compareKeysV2(a.fileName, b.fileName))
      setFilesInformation(filesMapping)
    } else if (lastFileType === "other") {
      const showFileGroupings = reportScreen.stressStrainReport.stressStrainParameters;
      let filesMapping = []

      for (const file of Object.keys(showFileGroupings)) {
        filesMapping.push({ fileName: file })
      }

      filesMapping = filesMapping.sort((a, b) => compareKeysV2(a.fileName, b.fileName))
      setFilesInformation(filesMapping)
    }
  }, [lastFileType, projectFiles, reportScreen.stressStrainReport.stressStrainParameters])

  useEffect(() => {
    if (reportScreen.selectedAnalysisType === "stressStrain" && graphType === "Line") {
      let newSelected = [];
      let reports = [...reportScreen.stressStrainReport.yAxis.lineChart];
      let newElements = []
      
      for (const report of reports) {
        if (!newSelected.includes(report)) {
          newElements.push(report)
        }
      }

      if (newElements.length > 0 && newElements[0].startsWith("stress_")) {
        newSelected = reports.filter((report) => report.startsWith("stress_"));
      } else {
        newSelected = newElements.filter((report) => !report.startsWith("stress_"));
      }

      if (newSelected.length !== latestSelected.length || !newSelected.every((value, index) => value === latestSelected[index])) {
        if (newSelected.length > 0 && newSelected[0].startsWith("stress_")) {
          setLastFileType("other");
        } else {
          setLastFileType("dataFiles");
        }
        dispatch({ type: SET_REPORT_SCREEN_STRESS_STRAIN_Y_AXIS_LINE, payload: newSelected });
      }

      setLatestSelected(newSelected);
    } else if (reportScreen.selectedAnalysisType === "stressStrain" && graphType === "Heatmap") {
      if (reportScreen.stressStrainReport.yAxis.heatMap.startsWith("stress_")) {
        setLastFileType("other");
      } else {
        setLastFileType("dataFiles");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reportScreen.selectedAnalysisType, reportScreen.stressStrainReport.yAxis.heatMap, reportScreen.stressStrainReport.yAxis.lineChart])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"} style={{ height: "80%" }}>
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6"}>
        <div className={"flex flex-col border-b border-primary-900 border-solid overflow-y-auto h-full"}>
          <ReportScreenInput dispatch={dispatch} graphType={graphType} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <ReportScreenGraph dispatch={dispatch} graphType={graphType} setGraphType={(graphType) => setGraphType(graphType)} />
        <AnalysisTable
          data={filesInformation}
          setDataActiveFiles={(files) => {
            dispatch({ type: SET_REPORT_SCREEN_DATA_VIEW_FILES, payload: files });
          }}
          dataActiveFiles={reportScreen.dataViewFiles}
        />
        </div>
    </div>
  );
};

export default ReportScreen;
