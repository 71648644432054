import { getName, truncate } from "../../../helpers/name";
import { LabelsMappingPeakFitting, ReportScreenModel } from "../../../models/report-screen.model";

export const getPeakFittingLineData = (report: ReportScreenModel) => {
  const newPlotData = [];

  report.peakFittingReport.yAxis.lineChart.forEach((chartTypeSelected) => {
    const xValues: string[] = [];
    const yValues = [];

    report.dataViewFiles.forEach((dataViewFile) => {
      const dataFileRef = dataViewFile;
      if (report.peakFittingReport.analysis[dataFileRef]) {
        const analysisData = report.peakFittingReport.analysis[dataFileRef];
        Object.entries(analysisData).forEach(([peak, peakValues]) => {
          Object.entries(peakValues).forEach(([key, value]) => {
            const chartType = `${peak}_${key}`;
            if (!key.endsWith('_std_dev') && chartTypeSelected === chartType) {
              if (LabelsMappingPeakFitting[key]) {
                xValues.push(getName(dataFileRef));
                yValues.push(value);
              }
            }
          });
        });
      }
    });

    newPlotData.push({
      x: xValues,
      y: yValues,
      mode: 'lines',
      name: truncate(chartTypeSelected, 20),
    });
  });

  return newPlotData;
}

export const getPeakFittingHeatMapData = (report: ReportScreenModel) => {
  const newPlotData = [];

  const heatMapWidth = report.heatMap.x;
  const heatMapHeight = report.heatMap.y;

  if (!heatMapWidth || !heatMapHeight) return [];

  const z: number[][] | string[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(undefined));
  const hoverText: string[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(''));
  const maxItems = heatMapWidth * heatMapHeight;

  report.dataViewFiles.slice(0, maxItems).forEach((dataViewFile, xIndex) => {
    const dataFileRef = dataViewFile;
    if (report.peakFittingReport.analysis[dataFileRef]) {
      const analysisData = report.peakFittingReport.analysis[dataFileRef];
      Object.entries(analysisData).forEach(([peak, peakValues]) => {
        Object.entries(peakValues).forEach(([key, value]) => {
          const chartType = `${peak}_${key}`;
          if (key.endsWith('_std_dev') || report.peakFittingReport.yAxis.heatMap !== chartType) return;
          const x = xIndex % heatMapWidth;
          const y = Math.floor(xIndex / heatMapWidth);
          const newY = (heatMapHeight - 1) - y;
          let reversedX = x;

          if (report.swipeMode.swipe) {
            if (report.swipeMode.swipeRowEven) {
              if (newY % 2 === 0) {
                reversedX = (heatMapWidth - 1) - x;
              }
            } else {
              if (newY % 2 !== 0) {
                reversedX = (heatMapWidth - 1) - x;
              }
            }
          }

          z[newY][reversedX] = value;
          hoverText[newY][reversedX] = getName(dataFileRef);
        })
      })
    }
  });

  newPlotData.push({
    z: z,
    type: 'heatmap',
    text: hoverText,
    hoverinfo: 'z+text',
    colorbar: {
      tickfont: {
        color: 'white'
      }
    },
    colorscale: report.heatMap.colorMap
  });

  return newPlotData;
}
