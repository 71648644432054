import { compareKeys } from "../../../helpers/general"

export const FileHandling = (dataFiles) => {
  let filesMapping = []

  for (const file of dataFiles) {
    filesMapping.push({ fileName: file })
  }

  filesMapping = filesMapping.sort((a, b) => compareKeys(a.fileName, b.fileName))

  return filesMapping;
}


export const GraphData = (analysis) => {
  let analysisDiagram = []

  if (analysis.backgroundX && analysis.backgroundX.length) {
    analysisDiagram = [
      {
        x: analysis.calculationsX,
        y: analysis.calculationsY,
        name: "Calculations",
        type: "linear",
        mode: "lines",
        line: {
          color: "orange",
          width: 2,
        },
      },
      {
        x: analysis.backgroundX,
        y: analysis.backgroundY,
        name: "Background",
        type: "linear",
        mode: "lines",
        line: {
          color: "green",
          width: 2,
        },
      },
    ];
  }

  return analysisDiagram;
}
