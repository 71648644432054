import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { componentFunction } from "../../../data/phase_quantification";
import toast from "react-hot-toast";
import { Partition } from "../../../models/analysis.general.model";
import { numberShortener } from "../../../helpers/name";
import DropDownConstraints from "./drop-down-constraints";
import DropDownSen from "./drop-down-sen";


interface Props {
  setPartitions: (partitions: Partition[]) => void,
  partitions: Partition[],
  setActiveIndex: (index: number) => void,
  selectedPartition: number,
  setSelectingPeaks: (selectingPeaks: boolean) => void,
  selectPeaks: boolean
}

const PeakPicking = ({ setPartitions, partitions, setActiveIndex, selectedPartition, setSelectingPeaks, selectPeaks }: Props) => {
	const addPartition = () => {
    const partitionsModified = [
			...partitions, {
				name: (partitions.length + 1).toString(),
				rangeHistory: [0, 0] as [number, number],
				rangeLower: {
          x: 0,
          y: 0
        },
				rangeHigher: {
          x: 0,
          y: 0
        },
				peaks: [],
			}
    ];

    setPartitions(partitionsModified);
    setActiveIndex(partitionsModified.length - 1);
    setSelectingPeaks(false);

    toast.success("Added Partition")
	};

	const removePartition = () => {
		if (partitions.length === 1) {
			return
		}

		if (partitions.length - 1 === selectedPartition) {
      setActiveIndex(partitions.length - 2)
		}

    setPartitions(partitions.slice(0, partitions.length - 1))
    setSelectingPeaks(false)

		toast.success("Removed Partition")
	};

	const handlePartitionRangeLower = (event: any) => {
		let newPartitions: Partition[] = [...partitions]

		newPartitions[selectedPartition] = {
			...partitions[selectedPartition],
      rangeLower: {
        x: parseFloat(event.target.value),
        y: 0
      }
    }

    setPartitions(newPartitions)
	}

	const handlePartitionRangeHigher = (event: any) => {
		let newPartitions: Partition[] = [...partitions]

		newPartitions[selectedPartition] = {
			...partitions[selectedPartition],
      rangeHigher: {
        x: parseFloat(event.target.value),
        y: 0
      }
		}

    setPartitions(newPartitions)
	}

	const handleRemovePeak = (peakIndex: number) => {
		const partitionsMod: Partition[] = partitions;

		partitionsMod[selectedPartition] = {
			...partitionsMod[selectedPartition],
			...partitionsMod[selectedPartition].peaks.splice(peakIndex, 1)
		}

    setPartitions(partitionsMod)
		toast.success("Removed Peak Point")
	}

	const handlePositionInclude = (peakIndex: number) => {
		const partitionsMod: Partition[] = partitions;

		partitionsMod[selectedPartition].peaks[peakIndex] = {
			...partitionsMod[selectedPartition].peaks[peakIndex],
			includeInAnalysis: !partitionsMod[selectedPartition].peaks[peakIndex].includeInAnalysis
		}

    setPartitions(partitionsMod)
	}

	const handlePositionFunction = (peakIndex: number, item) => {
		const partitionsMod: Partition[] = partitions;

		partitionsMod[selectedPartition].peaks[peakIndex] = {
			...partitionsMod[selectedPartition].peaks[peakIndex],
			function: item
		}

    setPartitions(partitionsMod)
	}

	const handleSelectPeak = () => {
    setSelectingPeaks(!selectPeaks)
	}

	const handleConstraint = (peakIndex: number, item) => {
		const partitionsMod: Partition[] = partitions;

		partitionsMod[selectedPartition].peaks[peakIndex] = {
			...partitionsMod[selectedPartition].peaks[peakIndex],
			constraint: item
		}

    setPartitions(partitionsMod)
	}

	return (
    <div className={"flex flex-col bg-primary-600 mb-2 rounded-md text-white mx-1"}>
      <ul className="flex w-full">
        {partitions.map((partition, index) => (
          <li
            key={partition.name}
            className={`flex-1 py-2 text-center cursor-pointer ${
              index !== selectedPartition ? 'bg-primary-500' : ''
            } ${index === 0 ? 'rounded-tl' : ''}`}
            onClick={() => setActiveIndex(index)}
          >
            {partitions.length > 4 ? `${partition.name}` : partitions.length > 2 ? `Part ${partition.name}` : `Partition ${partition.name}`}
          </li>
        ))}
        <li
          className="flex-1 py-2 text-center cursor-pointer bg-primary-500"
          onClick={addPartition}
        >
          +
        </li>
        <li
          className="flex-1 py-2 text-center cursor-pointer bg-primary-500 rounded-tr"
          onClick={removePartition}
        >
          -
        </li>
      </ul>
      <div className="p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full">
        <h1 className={"font-bold text-white text-base mb-2"}>
          Partition
        </h1>
        <div className={"flex flex-row items-center justify-center"}>
          <div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
            <label className={"text-white"}>
              Range Lower
            </label>
            <input
              className={`${false ? "border-red-500" : "border-gray-500"}
                bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
              type={"number"}
              step="0.001"
              value={
                partitions[selectedPartition] ? partitions[selectedPartition].rangeLower.x ? partitions[selectedPartition].rangeLower.x : "" : ""
              }
              onChange={handlePartitionRangeLower}
            />
          </div>
          <div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
            <label className={"text-white"}>
              Range Upper
            </label>
            <input
              className={`${false ? "border-red-500" : "border-gray-500"}
                bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
              type={"number"}
              step="0.01"
              value={
                partitions[selectedPartition] ? partitions[selectedPartition].rangeHigher.x ? partitions[selectedPartition].rangeHigher.x : "" : ""
              }
              onChange={handlePartitionRangeHigher}
            />
          </div>
        </div>
        <div className={"flex flex-row items-center justify-center mt-5"}>
          <div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
            <label className={"text-white"}>
              Select Peaks
            </label>
            <button
              className={`cursor-pointer text-white font-bold bg-primary-500 p-2 rounded-md w-full ${selectPeaks ? "border-b-2 border-green-600" : ""}`}
              onClick={handleSelectPeak}
            >
              {selectPeaks ? "Stop Selecting Peaks" : "Start Selecting Peaks"}
            </button>
          </div>
        </div>
        <div className={"flex flex-col items-center justify-center mt-5 w-full scroll-light relative"}>
          <div className={"w-full overflow-x-scroll overflow-y-hidden scroll-light flex flex-row"}>
            <div className={"flex flex-col text-left gap-1 mr-2 font-bold"}>
              <label className={"h-5"}>
                Peak
              </label>
              <label className={"h-5"}>
                Remove
              </label>
              <label className={"h-5"}>
                Position
              </label>
              <label className={"h-7"}>
                Include
              </label>
              <label className={"h-10"}>
                Function
              </label>
              <label className={"h-10"}>
                Constrains
              </label>
            </div>
            {partitions[selectedPartition] && partitions[selectedPartition].peaks.map((part, index) => (
              <div
                className={"flex flex-col items-center w-max gap-1 mr-2"}
                key={`3-${index}`}
              >
                <div className="h-5 whitespace-nowrap">
                  <p>Peak {index + 1}</p>
                </div>
                <div className="h-5">
                  <FontAwesomeIcon
                    icon={faTrash}
                    color={"red"}
                    style={{cursor: "pointer"}}
                    onClick={() => handleRemovePeak(index)}
                  />
                </div>
                <div className="h-5 whitespace-nowrap">
                  {`(${part.x.toFixed(1)}, ${numberShortener(part.y)})`}
                </div>
                <div className="h-7">
                  <input
                    type="checkbox"
                    id="checkbox"
                    className="w-5 h-5 bg-white border border-white-300 rounded mt-1 mr-1 center"
                    onChange={() => handlePositionInclude(index)}
                    checked={partitions[selectedPartition].peaks[index].includeInAnalysis}
                  />
                </div>
                <div className="h-10">
                  <DropDownSen
                    DropDownOptions={componentFunction}
                    multiple={false}
                    setSelected={(item) => handlePositionFunction(index, item)}
                    selected={partitions[selectedPartition].peaks[index].function}
                    title={partitions[selectedPartition].peaks[index].function ? partitions[selectedPartition].peaks[index].function.charAt(0).toUpperCase() + partitions[selectedPartition].peaks[index].function.slice(1) : "Choose"}
                    width={"w-24"}
                    padding="p-1"
                    childWidth="w-60"
                  />
                </div>
                <div className="h-10">
                  <DropDownConstraints
                    handleChange={(item) => handleConstraint(index, item)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
	);
};

export default PeakPicking;
