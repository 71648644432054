export const SAVE_PROJECT = "SAVE PROJECT";
export const SAVE_PROJECT_FAIL = "SAVE PROJECT FAIL";

export const SET_SELECTED_FILES_FOR_UPLOAD = "SET_SELECTED_FILES_FOR_UPLOAD";
export const SET_SELECTED_FILES_FOR_ADDING = "SET_SELECTED_FILES_FOR_ADDING";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FILE = "DELETE_PROJECT_FILE";
export const SETUP_NEW_PROJECT = "SETUP_NEW_PROJECT";
export const SET_DELETE_PROJECT = "SET_DELETE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const SET_ANALYSIS_TYPE = "SET_ANALYSIS_TYPE";
export const SET_ACTIVE_PHASE = "SET_ACTIVE_PHASE";
export const SET_ACTIVE_LENGTH = "SET_ACTIVE_LENGTH";
export const SET_ACTIVE_GEOMETRY = "SET_ACTIVE_GEOMETRY";
export const RENAME_PROJECT_SUCCESS = "RENAME_PROJECT_SUCCESS";
export const RENAME_PROJECT_FAIL = "RENAME_PROJECT_FAIL";
export const RENAME_PROJECT = "RENAME_PROJECT";
export const UPDATE_CURRENT_PROJECT_ANALYSIS_TYPE = "UPDATE_CURRENT_PROJECT_ANALYSIS_TYPE";
export const ADD_FILES_IN_CURRENT_PROJECT = "ADD_FILES_IN_CURRENT_PROJECT";
export const UPDATE_CURRENT_PROJECT_ADD_FILES = "UPDATE_CURRENT_PROJECT_ADD_FILES";
export const SET_CURRENT_PROJECT_FILES = "SET_CURRENT_PROJECT_FILES";
export const SET_PROJECT_NAME = "SET_PROJECT_NAME";
export const SET_NEW_PROJECT_NAME = "SET_NEW_PROJECT_NAME";
export const SET_TEMP_FROM = "SET_TEMP_FROM";
export const SET_TEMP_TO = "SET_TEMP_TO";
export const SET_TIME_FROM = "SET_TIME_FROM";
export const SET_TIME_TO = "SET_TIME_TO";
export const APPEND_NEW_PROJECT = "APPEND_NEW_PROJECT";
export const SET_RENAME_PROJECT = "SET_RENAME_PROJECT";

export const SET_INSTRUMENT = "SET_INSTRUMENT";
export const SET_DATABASE = "SET_DATABASE";
export const GET_PROJECTS_FOR_USER = "GET_PROJECTS_FOR_USER";
export const ADD_PROJECTS_FOR_USER = "ADD_PROJECTS_FOR_USER";
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";
export const SET_FILES_FOR_CURRENT_PROJECT = "SET_FILES_FOR_CURRENT_PROJECT";
export const SET_FILES_FOR_USER = "SET_FILES_FOR_USER";
export const GET_USER_FILES = "GET_USER_FILES";
export const SET_CURRENT_SELECTED_FILE = "SET_CURRENT_SELECTED_FILE";
export const SET_CURRENT_CHART_DATA = "SET_CURRENT_CHART_DATA";
export const SAVE_AND_CALCULATE = "SAVE_AND_CALCULATE";
export const UPDATE_PROJECT_FILE = "UPDATE_PROJECT_FILE";
export const SET_SELECTED_X = "SET_SELECTED_X";
export const SET_SELECTED_Y = "SET_SELECTED_Y";

export const UPDATE_PROJECT = "UPDATE PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE PROJECT SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE PROJECT FAIL";
export const SET_REPORT_CHART_DATA = "SET_REPORT_CHART_DATA";
export const GET_INSTANCE_RUNNING_STATUS = "GET_INSTANCE_RUNNING_STATUS";
export const GET_INSTRUMENTAL_WAVELENGTH = "GET_INSTRUMENTAL_WAVELENGTHS";
export const SET_INSTRUMENTAL_WAVELENGTH = "SET_INSTRUMENTAL_WAVELENGTHS";
export const GET_INSTRUMENTAL_FILE_WAVELENGTH = "GET_INSTRUMENTAL_FILE_WAVELENGTHS";
export const GET_METHOD_DATA = "GET_METHOD_DATA";

export const GET_DOWNLOAD = "GET_DOWNLOAD";
export const GET_DOWNLOAD_CHECKER = "GET_DOWNLOAD_CHECKER";

export const SET_REPORT_SCREEN_DATA_VIEW_FILES = "SET_REPORT_SCREEN_DATA_VIEW_FILES";
export const GET_REPORT_SCREEN_ANALYSIS_TYPES = "GET_REPORT_SCREEN_ANALYSIS_TYPES";
export const GET_REPORT_SCREEN_ADDITIONAL_DATA = "GET_REPORT_SCREEN_ADDITIONAL_DATA";
export const SET_REPORT_SCREEN_ANALYSIS_TYPES = "SET_REPORT_SCREEN_ANALYSIS_TYPES";
export const SET_REPORT_SCREEN_SELECTED_ANALYSIS_TYPE = "SET_REPORT_SCREEN_SELECTED_ANALYSIS_TYPE";

export const SET_REPORT_SCREEN_LE_BAIL = "SET_REPORT_SCREEN_LE_BAIL";
export const SET_REPORT_SCREEN_LE_BAIL_Y_AXIS_HEATMAP = "SET_REPORT_SCREEN_LE_BAIL_Y_AXIS_HEATMAP";
export const SET_REPORT_SCREEN_LE_BAIL_Y_AXIS_LINE = "SET_REPORT_SCREEN_LE_BAIL_Y_AXIS_LINE";
export const SET_REPORT_SCREEN_LE_BAIL_CIF_FILES_HEATMAP = "SET_REPORT_SCREEN_LE_BAIL_CIF_FILES_HEATMAP";
export const SET_REPORT_SCREEN_LE_BAIL_CIF_FILES_LINE = "SET_REPORT_SCREEN_LE_BAIL_CIF_FILES_LINE";
export const SET_REPORT_SCREEN_LE_BAIL_AVAILABLE_CIF_FILES = "SET_REPORT_SCREEN_LE_BAIL_AVAILABLE_CIF_FILES";
export const SET_REPORT_SCREEN_LE_BAIL_ANALYSIS = "SET_REPORT_SCREEN_LE_BAIL_ANALYSIS";
export const SET_REPORT_SCREEN_LE_BAIL_ANALYSIS_SPECIFIC = "SET_REPORT_SCREEN_LE_BAIL_ANALYSIS_SPECIFIC";

export const SET_REPORT_SCREEN = "SET_REPORT_SCREEN";
export const SET_REPORT_SCREEN_CLEAR = "SET_REPORT_SCREEN_CLEAR";
export const SET_REPORT_SCREEN_HEATMAP_X = "SET_REPORT_SCREEN_HEATMAP_X";
export const SET_REPORT_SCREEN_HEATMAP_Y = "SET_REPORT_SCREEN_HEATMAP_Y";
export const SET_REPORT_SCREEN_HEATMAP_COLOR_MAP = "SET_REPORT_SCREEN_HEATMAP_COLOR_MAP";
export const SET_REPORT_SCREEN_SWIPE_MODE = "SET_REPORT_SCREEN_SWIPE_MODE";
export const SET_REPORT_SCREEN_SWIPE_ROW_EVEN = "SET_REPORT_SCREEN_SWIPE_ROW_EVEN";

export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_AVAILABLE_CIF_FILES = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_AVAILABLE_CIF_FILES";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS_PARAMETERS = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS_PARAMETERS";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_NUMBER_PEAKS = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_NUMBER_PEAKS";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_Y_AXIS_HEATMAP = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_Y_AXIS_HEATMAP";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_Y_AXIS_LINE = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_Y_AXIS_LINE";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_CIF_FILES_HEATMAP = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_CIF_FILES_HEATMAP";
export const SET_REPORT_SCREEN_PHASE_QUANTIFICATION_CIF_FILES_LINE = "SET_REPORT_SCREEN_PHASE_QUANTIFICATION_CIF_FILES_LINE";

export const SET_REPORT_SCREEN_DISLOCATION_DENSITY = "SET_REPORT_SCREEN_DISLOCATION_DENSITY";
export const SET_REPORT_SCREEN_DISLOCATION_DENSITY_ANALYSIS = "SET_REPORT_SCREEN_DISLOCATION_DENSITY_ANALYSIS";
export const SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_HEATMAP = "SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_HEATMAP";
export const SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_LINE = "SET_REPORT_SCREEN_DISLOCATION_DENSITY_Y_AXIS_LINE";
export const SET_REPORT_SCREEN_DISLOCATION_DENSITY_WH_PARAMS = "SET_REPORT_SCREEN_DISLOCATION_DENSITY_WH_PARAMS";
export const SET_REPORT_SCREEN_DISLOCATION_DENSITY_MWH_PARAMS = "SET_REPORT_SCREEN_DISLOCATION_DENSITY_MWH_PARAMS";


export const SET_REPORT_SCREEN_PEAK_FITTING = "SET_REPORT_SCREEN_PEAK_FITTING";
export const SET_REPORT_SCREEN_PEAK_FITTING_ANALYSIS = "SET_REPORT_SCREEN_PEAK_FITTING_ANALYSIS";
export const SET_REPORT_SCREEN_PEAK_FITTING_Y_AXIS_HEATMAP = "SET_REPORT_SCREEN_PEAK_FITTING_Y_AXIS_HEATMAP";
export const SET_REPORT_SCREEN_PEAK_FITTING_Y_AXIS_LINE = "SET_REPORT_SCREEN_PEAK_FITTING_Y_AXIS_LINE";


export const SET_REPORT_SCREEN_STRESS_STRAIN = "SET_REPORT_SCREEN_STRESS_STRAIN ";
export const SET_REPORT_SCREEN_STRESS_STRAIN_ANALYSIS = "SET_REPORT_SCREEN_STRESS_STRAIN_ANALYSIS";
export const SET_REPORT_SCREEN_STRESS_STRAIN_Y_AXIS_HEATMAP = "SET_REPORT_SCREEN_STRESS_STRAIN_Y_AXIS_HEATMAP";
export const SET_REPORT_SCREEN_STRESS_STRAIN_Y_AXIS_LINE = "SET_REPORT_SCREEN_STRESS_STRAIN_Y_AXIS_LINE";
export const SET_REPORT_SCREEN_STRESS_STRAIN_BIN_PARAMETERS = "SET_REPORT_SCREEN_STRESS_STRAIN_BIN_PARAMETERS";
export const SET_REPORT_SCREEN_STRESS_STRAIN_PARAMETERS = "SET_REPORT_SCREEN_STRESS_STRAIN_PARAMETERS";


export const GET_LAMBDA_CACHE = "GET_LAMBDA_CACHE";

export const UPLOAD_FILES = "UPLOAD_FILES";
export const SET_UPLOAD_FILES_PROGRESS = "SET_UPLOAD_FILES_PROGRESS";
export const SET_UPLOAD_STATUS = "SET_UPLOAD_STATUS";

export const GET_PROJECT_FILES = "GET_PROJECT_FILES";

export const SET_BROWSING_PROJECT = "SET_BROWSING_PROJECT";
export const GET_BROWSING_PROJECT = "GET_BROWSING_PROJECT";

export const GET_ECS_RUNNING_INSTANCE = "GET_ECS_RUNNING_INSTANCE"
export const GET_PHASES = "GET_PHASES"

export const GET_PIPELINE_STATUS = "GET_PIPELINE_STATUS"
export const START_PIPELINE = "START_PIPELINE"
export const STOP_PIPELINE = "STOP_PIPELINE"
export const SET_PIPELINE_STATUS = "SET_PIPELINE_STATUS"

export const SAVE_PROJECT_INFO = "SAVE_PROJECT_INFO"

export const GET_PIPELINE_FILES = "GET_PIPELINE_FILES"

export const GET_CIF_FILE_ABC_VALUE = "GET_CIF_FILE_ABC_VALUE"

export const START_ON_DEMAND = "START_ON_DEMAND"
export const GET_ON_DEMAND_STATUS = "GET_ON_DEMAND"
export const SET_ON_DEMAND_STATUS = "SET_ON_DEMAND_STATUS"
export const GET_ON_DEMAND_PROGRESS = "GET_ON_DEMAND_PROGRESS"
export const ADD_ON_DEMAND_TASK = "ADD_ON_DEMAND_TASK"
