import { Dispatch } from "redux";
import RietveldGraph from "./rietveld-graph";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import RietveldInput from "./rietveld-input";
import AnalysisFilesTable from "../shared/analysis-files-table";
import ProgressBar from "../shared/progress-bar";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import { GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK, SET_RIETVELD_DATA_ACTIVE_FILES, SET_RIETVELD_DATA_VIEW_FILE } from "../../../redux/rietveld/rietveldActions";
import { selectRietveldCifFiles, selectRietveldCifTemplateFiles, selectRietveldDataActiveFiles, selectRietveldDataFiles, selectRietveldDataViewFile, selectRietveldInstrumentalFile, selectRietveldInstrumentalTemplateFile, selectRietveldParameters, selectRietveldWavelength } from "../../../redux/rietveld/rietveldSelectors";
import { compareKeysV2 } from "../../../helpers/general";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import { useInstanceStatusPolling } from "../shared/instanceTrigger";
import { FileHanding } from "./helpers";


const RietveldAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const parameters = useSelector(selectRietveldParameters)
  const dataActiveFiles = useSelector(selectRietveldDataActiveFiles)
  const cifTemplateFiles = useSelector(selectRietveldCifTemplateFiles);
	const instrumentalTemplateFile = useSelector(selectRietveldInstrumentalTemplateFile);
	const wavelength = useSelector(selectRietveldWavelength);
	const instanceId = useSelector(selectInstanceId);
	const dataFiles = useSelector(selectRietveldDataFiles);
	const cifFiles = useSelector(selectRietveldCifFiles);
	const instrumentalFile = useSelector(selectRietveldInstrumentalFile);
	const dataViewFile = useSelector(selectRietveldDataViewFile);
  
  const [filesInformation, setFilesInformation] = useState<any>([]);

  useInstanceStatusPolling(instanceId, "rietveld", dispatch);

  useEffect(() => {
    dispatch({ type: GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK })
  }, [dispatch, dataViewFile]);

  useEffect(() => {
		dispatch({ type: GET_METHOD_DATA, payload: {type: "rietveld"} });
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "rietveld"} })
	}, [dispatch]);

	useEffect(() => {
		dispatch({ type: GET_PHASES, payload: { type: "rietveld"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile]);

  useEffect(() => {
    setFilesInformation(FileHanding(parameters, dataFiles))
  }, [dataFiles, dispatch, parameters])

  useEffect(() => {
    if (dataFiles.length > 0 && !dataViewFile) {
      const dataFilesObj = dataFiles.sort((a, b) => compareKeysV2(a, b))
      dispatch({ type: SET_RIETVELD_DATA_VIEW_FILE, payload: dataFilesObj[0] });
    }
  }, [dataFiles, dataViewFile, dispatch])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Refinement"}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6 xl:w-full overflow-y-auto resize-x hover:resize min-w-min"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid"}>
          <RietveldInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <RietveldGraph dispatch={dispatch} />
        <AnalysisFilesTable
          data={filesInformation}
          dataViewFile={dataViewFile}
          setDataViewFile={(file) => dispatch({type: SET_RIETVELD_DATA_VIEW_FILE, payload: file})}
          setDataActiveFiles={(files) => dispatch({type: SET_RIETVELD_DATA_ACTIVE_FILES, payload: files})}
          dataActiveFiles={dataActiveFiles}
        />
        </div>
    </div>
  );
};

export default RietveldAnalysis;
