import { InstrumentalFileCreationModel } from "../../models/analysis.instrumentalFileCreation.model";
import { RESET_INSTRUMENTAL_FILE_CREATION, SET_INSTRUMENTAL_FILE_CREATION, SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS, SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES, SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC, SET_INSTRUMENTAL_FILE_CREATION_CIF_TEMPLATE_FILES, SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, SET_INSTRUMENTAL_FILE_CREATION_DATA_ACTIVE_FILES, SET_INSTRUMENTAL_FILE_CREATION_DATA_FILES, SET_INSTRUMENTAL_FILE_CREATION_DATA_VIEW_FILE, SET_INSTRUMENTAL_FILE_CREATION_FILE_WAVELENGTH, SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM, SET_INSTRUMENTAL_FILE_CREATION_INSTRUMENTAL_TEMPLATE_FILE, SET_INSTRUMENTAL_FILE_CREATION_MICROSTRAIN, SET_INSTRUMENTAL_FILE_CREATION_RANGE, SET_INSTRUMENTAL_FILE_CREATION_RANGE_HISTORY, SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER, SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH } from "./instrumentalFileCreationActions";

const initialState: InstrumentalFileCreationModel = {
  cifFile: "",
  cifTemplateFile: "",
  cifFilesABC: {},
  instrumentalTemplateFile: "",
  dataFiles: [],
  dataActiveFiles: [],
  wavelength: 0,
  crystalliteSize: 0,
  microstrain: 0,
  refinementRange: {
    lower: 0,
    upper: 0,
  },
  histogram: {
    data: {
      x: [],
      y: [],
    },
    phases: {}
  },
  dataViewFile: "",
  rangeHistory: [0, 0],
  rangeInitial: [0, 0],
  instrumentalFileWaveLength: 0,
  analysis: {
    backgroundX: [],
    backgroundY: [],
    calculationsX: [],
    calculationsY: [],
  }
};

export default function instrumentalFileCreationReducer(state = initialState, { type, payload }: { type: string; payload: any }): InstrumentalFileCreationModel {
  switch (type) {
    case SET_INSTRUMENTAL_FILE_CREATION:
      return {
        ...state,
        ...payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES:
      return {
        ...state,
        cifFile: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_CIF_TEMPLATE_FILES:
      return {
        ...state,
        cifTemplateFile: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_INSTRUMENTAL_TEMPLATE_FILE:
      return {
        ...state,
        instrumentalTemplateFile: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_DATA_FILES:
      return {
        ...state,
        dataFiles: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH:
      return {
        ...state,
        wavelength: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE:
      return {
        ...state,
        crystalliteSize: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_MICROSTRAIN:
      return {
        ...state,
        microstrain: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER:
      return {
        ...state,
        refinementRange: {
          ...state.refinementRange,
          lower: payload,
        },
      };
    case SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER:
      return {
        ...state,
        refinementRange: {
          ...state.refinementRange,
          upper: payload,
        },
      };
    case SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM:
      return {
        ...state,
        histogram: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_RANGE_HISTORY:
      return {
        ...state,
        rangeHistory: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL:
      return {
        ...state,
        rangeInitial: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_FILE_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_RANGE:
      const range = state.rangeHistory;
      range[1] = range[0];
      range[0] = payload;

      return {
        ...state,
        rangeHistory: range,
        refinementRange: {
          lower: range[0] <= range[1] ? range[0] : range[1],
          upper: range[0] > range[1] ? range[0] : range[1],
        }
      }
    case SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS:
      return {
        ...state,
        analysis: payload,
      }
    case RESET_INSTRUMENTAL_FILE_CREATION:
      return {
        ...initialState,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload,
      };
    case SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload,
        }
      };
    default:
      return state;
  }
}
