import { Dispatch } from "redux";
import { selectAzimuthalIntegrationDataViewFile, selectAzimuthalIntegrationDataViewFileRow, selectAzimuthalIntegrationPartitions } from "../../../redux/azimuthalIntegration/azimuthalIntegrationSelectors";
import { useSelector } from "react-redux";
import { getName } from "../../../helpers/name";
import { GET_AZIMUTHAL_INTEGRATION_CALLBACK_CAKING, SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE, SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE_ROW } from "../../../redux/azimuthalIntegration/azimuthalIntegrationActions";
import { useState } from "react";

const AzimuthalIntegrationTable = ({dispatch}: {dispatch: Dispatch}) => {
  const partitions = useSelector(selectAzimuthalIntegrationPartitions)
  const dataViewFile = useSelector(selectAzimuthalIntegrationDataViewFile)
  const dateViewFileRow = useSelector(selectAzimuthalIntegrationDataViewFileRow)

  const [inputText, setInputText] = useState([] as string[])

  return (
    <div className={"w-full overflow-x-auto flex-1"}>
      <table className={"table-auto text-xs w-full files-table overflow-x-auto border-collapse border-spacing-x-1"}>
        <thead>
          <tr className={"flex-2 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis text-white text-center border-b border-gray-300"}>
            <th>
              <h1>
                View
              </h1>
            </th>
            <th>
              <h1>
                Display Azimuthal Bins
              </h1>
            </th>
            {partitions.map((part, index) => (
              <th key={index}>
                <h1 className={"py-2"}>
                  {`Detector ${index + 1}`}
                </h1>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: partitions.reduce((maxLength, part) => Math.max(maxLength, part.dataFiles.length), 0) }).map((_, rowIndex) => (
            <tr
              key={rowIndex}
              className={`flex-1 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer text-center mt-1`}
            >
              <td className={"px-2 py-1 w-12 m-2"}>
                <button
                  className={`${rowIndex.toString() === dateViewFileRow ? "bg-primary-700" : "bg-primary-500"} w-12   p-1 rounded-md text-white cursor-pointer`}
                  onClick={() => {
                    dispatch({ type: SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE_ROW, payload: rowIndex.toString() })
                    dispatch({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_CAKING, payload: {
                      index: String(rowIndex + 1),
                      cakingRange: inputText[rowIndex],
                    }})
                  }}
                >
                  View
                </button>
              </td>
              <td className="py-2 h-full px-2">
                <div className="flex items-center justify-center h-full">
                  <input
                    className="w-40 h-6 text-center border-2 border-gray-300 rounded-sm"
                    type="text"
                    onChange={(e) => {
                      // Reset it so that user has too click the view button again if the result has changed
                      dispatch({ type: SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE_ROW, payload: "" })
                      inputText[rowIndex] = e.target.value
                      setInputText([...inputText])
                    }}
                    placeholder="e.g. 1-5, 8, start:stop:step"
                  />
                </div>
              </td>
              {partitions.map((part, columnIndex) => (
                <td key={columnIndex} className="py-2 px-2">
                  {part.dataFiles[rowIndex] && (
                    <button
                      className={`${dataViewFile === part.dataFiles[rowIndex] ? "bg-primary-700 " : "bg-primary-500"} text-white py-2 px-2 rounded-md cursor-pointer`}
                      onClick={() => {
                        dispatch({type: SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE, payload: part.dataFiles[rowIndex]})
                      }}
                    >
                      <p>{getName(part.dataFiles[rowIndex])}</p>
                    </button>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AzimuthalIntegrationTable;
