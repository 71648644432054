import { compareKeys } from "../../../helpers/general"
import { numberShortener } from "../../../helpers/name";
import { Histogram } from "../../../models/analysis.general.model";
import { LeBailRefinedHistogramPhase } from "../../../models/analysis.leBail.model";
import { FormatName } from "../../shared/utils";


export const FilesMapping = (leBailDataFiles: string[], phases: { name: string; data: LeBailRefinedHistogramPhase[] }[]) => {
  let filesMapping = []

  for (const file of leBailDataFiles) {
    filesMapping.push({ fileName: file })
  }

  filesMapping = filesMapping.sort((a, b) => compareKeys(a.fileName, b.fileName))

  if (phases) {
    for (const fileObject of filesMapping) {
      for (const phase of phases) {
        if (phase.name !== fileObject.fileName) continue
        for (const phase_d of phase.data) {
          let cifName = FormatName(phase_d.cifName, false);

          fileObject[`${cifName} LengthA`] = numberShortener(phase_d.lengthA);
          fileObject[`${cifName} LengthB`] = numberShortener(phase_d.lengthB);
          fileObject[`${cifName} LengthC`] = numberShortener(phase_d.lengthC);
        }
      }
    }
  }

  return filesMapping;
}


export const GraphData = (dataViewFile: string, histogram: Histogram, dataFiles: string[], refinedHistogram: any) => {
  let refinedHistogramData = []
  let dataViewFileModify = dataViewFile.split("/").pop();

  if (dataFiles.length && refinedHistogram && refinedHistogram.calculations) {
    const index = refinedHistogram.calculations.findIndex((d) => d.name === dataViewFileModify);

    if (index !== -1 && refinedHistogram.calculations[index].y) {
      refinedHistogramData = [
        {
          x: refinedHistogram.calculations[index].x,
          y: refinedHistogram.calculations[index].y,
          name: "Calculations",
          type: "linear",
          mode: "lines",
          line: {
            color: "orange",
            width: 2,
          },
        },
        {
          x: refinedHistogram.background[index].x,
          y: refinedHistogram.background[index].y,
          name: "Background",
          type: "linear",
          mode: "lines",
          line: {
            color: "green",
            width: 2,
          },
        },
        {
          x: refinedHistogram.residual[index].x,
          y: refinedHistogram.residual[index].y,
          name: "Residual",
          type: "linear",
          mode: "lines",
          line: {
            color: "purple",
            width: 2,
          },
        },
      ];
    }
  }

  return refinedHistogramData
}