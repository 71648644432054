import { all, put, select, takeLatest } from "redux-saga/effects";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { waitFiveSeconds } from "../../helpers/redux";
import toast from "react-hot-toast";
import { selectUserToken, selectCurrentProject } from "../project/projectSelectors";
import { GET_DISLOCATION_DENSITY_CALLBACK, GET_DISLOCATION_DENSITY_DENSITY, GET_DISLOCATION_DENSITY_FITTING, GET_DISLOCATION_DENSITY_MWH, GET_DISLOCATION_DENSITY_SUBGRAIN_SIZE, SET_DISLOCATION_DENSITY_FITTING, SET_DISLOCATION_DENSITY_K2CDK, SET_DISLOCATION_DENSITY_K2CLNA, SET_DISLOCATION_DENSITY_L0_ANALYSIS, SET_DISLOCATION_DENSITY_L0_PICKER_DATA, SET_DISLOCATION_DENSITY_MWH_PARAMS, SET_DISLOCATION_DENSITY_PARAMETERS, SET_DISLOCATION_DENSITY_RHO_ANALYSIS, SET_DISLOCATION_DENSITY_RHO_PICKER_DATA, SET_DISLOCATION_DENSITY_WH, SET_DISLOCATION_DENSITY_WH_PARAMS } from "./dislocationDensityActions";
import { selectDislocationDensity } from "./dislocationDensitySelectors";
import { DislocationDensityModel } from "../../models/analysis.dislocationDensity.model";
import { getDislocationDensity, getDislocationDensityCallback, preProcessObject } from "./dislocationDensityHelpers";


function* get_dislocation_density_fitting() {
  try {
    toast.promise(waitFiveSeconds(), {
      loading: "Starting Computing Process",
      success: "Running Peak Fitting...",
      error: "Running Peak Fitting...",
    })

    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
    dislocationDensity = preProcessObject(dislocationDensity);
    
    const res = yield getDislocationDensity(
      token,
      {
        ...dislocationDensity,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
        type: "wh"
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to perform peak fitting. Please try again.")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_dislocation_density_mwh() {
  try {
    toast.promise(waitFiveSeconds(), {
      loading: "Starting Computing Process",
      success: "Running Fitting...",
      error: "Running Fitting...",
    })

    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
    dislocationDensity = preProcessObject(dislocationDensity);
    
    const res = yield getDislocationDensity(
      token,
      {
        ...dislocationDensity,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
        type: "mwh",
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to perform peak fitting. Please try again.")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_dislocation_density_subgrain_size() {
  try {
    toast.promise(waitFiveSeconds(), {
      loading: "Starting Computing Process",
      success: "Running Fitting...",
      error: "Running Fitting...",
    })

    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
    dislocationDensity = preProcessObject(dislocationDensity);
    
    const res = yield getDislocationDensity(
      token,
      {
        ...dislocationDensity,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
        type: "subgrainSize",
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to perform peak fitting. Please try again.")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_dislocation_density_density() {
  try {
    toast.promise(waitFiveSeconds(), {
      loading: "Starting Computing Process",
      success: "Running Fitting...",
      error: "Running Fitting...",
    })

    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
    dislocationDensity = preProcessObject(dislocationDensity);
    
    const res = yield getDislocationDensity(
      token,
      {
        ...dislocationDensity,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
        type: "density",
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to perform peak fitting. Please try again.")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_dislocation_density_callback() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
    dislocationDensity = preProcessObject(dislocationDensity);

    const res = yield getDislocationDensityCallback(
      token,
      {
        ...dislocationDensity,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_DISLOCATION_DENSITY_PARAMETERS, payload: res.data.parametersData });
    yield put({ type: SET_DISLOCATION_DENSITY_FITTING, payload: { data: res.data.fittedData, file: dislocationDensity.dataViewFile }});
    yield put({ type: SET_DISLOCATION_DENSITY_WH, payload: res.data.whData });
    yield put({ type: SET_DISLOCATION_DENSITY_K2CDK, payload: res.data.k2cDkPlot });
    yield put({ type: SET_DISLOCATION_DENSITY_K2CLNA, payload: res.data.mwhData });
    yield put({ type: SET_DISLOCATION_DENSITY_L0_ANALYSIS, payload: res.data.estimateSubgrainSizeData });
    yield put({ type: SET_DISLOCATION_DENSITY_RHO_ANALYSIS, payload: res.data.densityData });
    yield put({ type: SET_DISLOCATION_DENSITY_RHO_PICKER_DATA, payload: res.data.densityPickerData });
    yield put({ type: SET_DISLOCATION_DENSITY_L0_PICKER_DATA, payload: res.data.estimateSubgrainSizePickerData });
    yield put({ type: SET_DISLOCATION_DENSITY_WH_PARAMS, payload: res.data.whParams });
    yield put({ type: SET_DISLOCATION_DENSITY_MWH_PARAMS, payload: res.data.mwhParams });
  } catch (error) {
    console.error(error)
    toast.error("Failed to fetch peak fitting results. Please try again.")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

export default function* dislocationDensitySaga() {
  yield all([
    takeLatest(GET_DISLOCATION_DENSITY_CALLBACK, get_dislocation_density_callback),
    takeLatest(GET_DISLOCATION_DENSITY_FITTING, get_dislocation_density_fitting),
    takeLatest(GET_DISLOCATION_DENSITY_MWH, get_dislocation_density_mwh),
    takeLatest(GET_DISLOCATION_DENSITY_SUBGRAIN_SIZE, get_dislocation_density_subgrain_size),
    takeLatest(GET_DISLOCATION_DENSITY_DENSITY, get_dislocation_density_density),
  ]);
}
