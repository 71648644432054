export const SET_OPERATION_IN_PROGRESS = "SET_OPERATION_IN_PROGRESS";
export const SET_OPERATION_IN_PROGRESS_DICT = "SET_OPERATION_IN_PROGRESS_DICT";
export const SET_INLINE_OPERATION_IN_PROGRESS = "SET_INLINE_OPERATION_IN_PROGRESS";
export const SET_IN_INLINE_OPERATION_IN_PROGRESS = "SET_IN_INLINE_OPERATION_IN_PROGRESS"
export const SET_IN_IN_INLINE_OPERATION_IN_PROGRESS = "SET_IN_IN_INLINE_OPERATION_IN_PROGRESS"
export const SET_FILE_FETCHING_IN_PROGRESS = "SET_FILE_FETCHING_IN_PROGRESS";
export const SET_ERROR_DETAILS = "SET_ERROR_DETAILS";

export const SET_INSTANCE_ID = "SET_INSTANCE_ID";
export const SET_DOWNLOAD_INSTANCE_ID = "SET_DOWNLOAD_INSTANCE_ID";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_PROGRESS_MESSAGE = "SET_PROGRESS_MESSAGE";
export const SET_PROGRESS_TITLE = "SET_PROGRESS_TITLE";
export const SET_RELOAD_FILE_SYSTEM = "SET_RELOAD_FILE_SYSTEM";
export const SET_COMPUTING_INSTANCE = "SET_COMPUTING_INSTANCE";

export const SET_PIPELINE_PROGRESS = "SET_PIPELINE_PROGRESS";

export const SIGN_UP = "SIGN_UP";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_TOKEN = "GET_CURRENT_USER_TOKEN";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_LOADED = "SET_USER_LOADED";
export const FORGOT_PASSWORD_SUBMIT = "FORGOT_PASSWORD_SUBMIT";