import { Dispatch } from "redux";
import AnalysisInput from "./analysis-input";
import AnalysisResults from "./analysis-results";

const Analysis = ({ type, dispatch }: { type: string; dispatch: Dispatch }) => {

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col"}>
      <AnalysisInput type={type} dispatch={dispatch} />
      <AnalysisResults dispatch={dispatch} />
    </div>
  );
};

export default Analysis;
