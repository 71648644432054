import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";
import { GET_DATA_CALIBRATION_CALLBACK, GET_DATA_CALIBRATION_DETECTOR_SIZE, GET_DATA_CALIBRATION_DOWNLOAD_MASK, GET_DATA_CALIBRATION_IMAGE, GET_DATA_CALIBRATION_INTEGRATION, GET_DATA_CALIBRATION_MASKING, GET_DATA_CALIBRATION_META_FILE_INFO, GET_DATA_CALIBRATION_RING_PICKING, GET_DATA_CALIBRATION_RING_PICKING_COMPILE, SET_DATA_CALIBRATION, SET_DATA_CALIBRATION_ACTIVE_IMAGE_PATH, SET_DATA_CALIBRATION_ANALYSIS, SET_DATA_CALIBRATION_CALIBRATION_FILE, SET_DATA_CALIBRATION_DETECTOR_SIZE, SET_DATA_CALIBRATION_IMAGE_GEOMETRY, SET_DATA_CALIBRATION_IMAGE_MASK, SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS } from "./dataCalibrationActions";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_OPERATION_IN_PROGRESS } from "../general/generalActions";
import { selectCurrentProject, selectCurrentProjectFiles, selectUserToken } from "../project/projectSelectors";
import { ProjectFileModel } from "../../models/project.model";
import { getDataCalibrationCallback, getDataCalibrationDetectorSize, getDataCalibrationDownloadMask, getDataCalibrationImage, getDataCalibrationIntegration, getDataCalibrationMasking, getDataCalibrationMetaFileInfo, getDataCalibrationRingPicking, getDataCalibrationRingPickingCompile } from "./dataCalibrationHelpers";
import { DataCalibrationModel } from "../../models/analysis.dataCalibration.model";
import { selectDataCalibration } from "./dataCalibrationSelectors";
import { initialStateDataCalibration } from "./dataCalibrationReducer";

function* get_data_calibration_meta_file_info() {
  try {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const projectFiles: ProjectFileModel[] = yield select(selectCurrentProjectFiles);

    const metaFile = projectFiles.filter((x) => x.fileRef.includes(".meta"));

    if (metaFile.length === 0) {
      return;
    }

    const res = yield getDataCalibrationMetaFileInfo(
      token,
      currentProject.userId,
      currentProject.projectId,
      metaFile[0].fileRef
    )

    const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    const obj: DataCalibrationModel = {
      ...dataCalibration,
      calibrantType: initialStateDataCalibration.calibrantType === dataCalibration.calibrantType ? res.data.Psnap === "AgBeh" ? "AgBh" : res.data.Psnap : dataCalibration.calibrantType,
      wavelength: initialStateDataCalibration.wavelength === dataCalibration.wavelength ? parseFloat(res.data["Pilatus energy setting"]) / 1000 : dataCalibration.wavelength,
      peakThreshold: initialStateDataCalibration.peakThreshold === dataCalibration.peakThreshold ? parseFloat(res.data.Threshold) : dataCalibration.peakThreshold,
    }

    yield put({ type: SET_DATA_CALIBRATION, payload: obj });

  } catch (error) {
    console.error(error)
    toast.error("Failed to get data calibration meta file info")
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_data_calibration_image() {
  try {
    const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    if (dataCalibration.activeImagePath === "") {
      yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    } else {
      yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    }

    if (dataCalibration.dataFile === "") {
      return
    }

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getDataCalibrationImage(
      token,
      currentProject.projectId,
      dataCalibration.dataFile,
      dataCalibration.imageOptions.colorMap,
      dataCalibration.imageOptions.log
    );

    yield put({ type: SET_DATA_CALIBRATION_ACTIVE_IMAGE_PATH, payload: res.data});
  } catch (error) {
    console.error(error)
    toast.error("Failed to load Detector Image")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_data_calibration_ring_picking(payload: any) {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    let dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    if (dataCalibration.dataFile === "") {
      return;
    }

    if (!dataCalibration.customDetector && dataCalibration.detectorType === "") {
      return;
    }

    const res = yield getDataCalibrationRingPicking(
      token,
      currentProject.projectId,
      dataCalibration.dataFile,
      dataCalibration.calibrantType,
      !dataCalibration.customDetector ? dataCalibration.detectorType : "",
      dataCalibration.wavelength,
      payload.payload,
      dataCalibration.detectorSize,
      dataCalibration.detectorPixelSize,
    );

    dataCalibration = yield select(selectDataCalibration);

    if (res.data.length === 0) {
      if (Object.keys(dataCalibration.ringPicking).includes((parseInt(payload.payload.index)).toString())) {
        yield put({
          type: SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS,
          payload: { index: (parseInt(payload.payload.index)).toString(), numberOfPeaks: 9999, peaks: [] },
        });
      }
      return;
    } else {
      if (Object.keys(dataCalibration.ringPicking).includes((parseInt(payload.payload.index)).toString())) {
        yield put({
          type: SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS,
          payload: { index: (parseInt(payload.payload.index)).toString(), numberOfPeaks: res.data.length, peaks: res.data },
        });
      }
    }
  } catch (error) {
    let dataCalibration = yield select(selectDataCalibration);

    if (Object.keys(dataCalibration.ringPicking).includes((parseInt(payload.payload.index)).toString())) {
      yield put({
        type: SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS,
        payload: { index: (parseInt(payload.payload.index)).toString(), numberOfPeaks: 9999, peaks: [] },
      });
    }
    console.error(error)
  }
}


function* get_data_calibration_ring_picking_compile() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    if (dataCalibration.dataFile === "") {
      toast.error("Please select a data file")
      return;
    }

    if (!dataCalibration.customDetector && dataCalibration.detectorType === "") {
      toast.error("Please select a detector type")
      return;
    }

    const res = yield getDataCalibrationRingPickingCompile(
      token,
      currentProject.projectId,
      currentProject.userId,
      dataCalibration.dataFile,
      dataCalibration.calibrantType,
      !dataCalibration.customDetector ? dataCalibration.detectorType : "",
      dataCalibration.wavelength,
      dataCalibration.ringPicking,
      dataCalibration.drawings,
      dataCalibration.mask.below === 0 || dataCalibration.mask.below ? dataCalibration.mask.below : "",
      dataCalibration.mask.above === 0 || dataCalibration.mask.above ? dataCalibration.mask.above : "",
      dataCalibration.imageOptions.colorMap,
      dataCalibration.imageOptions.log,
      dataCalibration.detectorSize,
      dataCalibration.detectorPixelSize,
      dataCalibration.maskingFile,
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });

  } catch (error) {
    console.error(error)
    toast.error("Failed to geometry calibration")
  }
}

function* get_data_calibration_masking() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    if (dataCalibration.dataFile === "") {
      toast.error("Please select a data file")
      return;
    }

    const res = yield getDataCalibrationMasking(
      token,
      currentProject.projectId,
      dataCalibration.dataFile,
      dataCalibration.drawings,
      dataCalibration.mask.above === 0 || dataCalibration.mask.above ? dataCalibration.mask.above : "",
      dataCalibration.mask.below === 0 || dataCalibration.mask.below ? dataCalibration.mask.below : "",
      dataCalibration.imageOptions.colorMap,
      dataCalibration.imageOptions.log,
      dataCalibration.maskingFile,
      currentProject.userId,
    );

    yield put({ type: SET_DATA_CALIBRATION_IMAGE_MASK, payload: res.data});
  } catch (error) {
    console.error(error)
    toast.error("Failed to mask image")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_data_calibration_integration() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    if (dataCalibration.dataFile === "") {
      toast.error("Please select a data file")
      return;
    }

    if (!dataCalibration.customDetector && dataCalibration.detectorType === "") {
      toast.error("Please select a detector type")
      return;
    }

    const res = yield getDataCalibrationIntegration(
      token,
      currentProject.projectId,
      currentProject.userId,
      dataCalibration.dataFile,
      dataCalibration.drawings,
      dataCalibration.mask.above === 0 || dataCalibration.mask.above ? dataCalibration.mask.above : "",
      dataCalibration.mask.below === 0 || dataCalibration.mask.below ? dataCalibration.mask.below : "",
      dataCalibration.calibrantType,
      !dataCalibration.customDetector ? dataCalibration.detectorType : "",
      dataCalibration.wavelength,
      dataCalibration.ringPicking,
      dataCalibration.numberOfDataPoints,
      dataCalibration.polarizationFactor,
      dataCalibration.radialRange.lower,
      dataCalibration.radialRange.upper,
      dataCalibration.unit,
      dataCalibration.imageOptions.log,
      dataCalibration.imageOptions.colorMap,
      dataCalibration.detectorSize,
      dataCalibration.detectorPixelSize,
      dataCalibration.maskingFile,
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });

  } catch (error) {
    console.error(error)
    toast.error("Failed to integrate")
  }
}

function* get_data_calibration_callback() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getDataCalibrationCallback(
      token,
      currentProject.projectId,
    );

    // check if the key "poni" exists in res.data
    if (Object.keys(res.data).includes("poni")) {
      yield put({ type: SET_DATA_CALIBRATION_CALIBRATION_FILE, payload: res.data.poni});
    }

    if (Object.keys(res.data).includes("x")) {
      yield put({ type: SET_DATA_CALIBRATION_ANALYSIS, payload: {
        x: res.data.x,
        y: res.data.y,
        lines: res.data.linesX,
      }});
    }

    if (Object.keys(res.data).includes("ringUrl")) {
      yield put({ type: SET_DATA_CALIBRATION_IMAGE_GEOMETRY, payload: res.data.ringUrl});
    }

  } catch (error) {
    console.error(error)
    toast.error("Failed to load integrated data")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
  }
}

function* get_data_calibration_detector_size() {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    if (dataCalibration.dataFile === "") {
      return
    }

    const res = yield getDataCalibrationDetectorSize(
      token,
      currentProject.projectId,
      dataCalibration.dataFile
    )

    yield put({ type: SET_DATA_CALIBRATION_DETECTOR_SIZE, payload: {
      "x": res.data.x,
      "y": res.data.y,
      "originalX": res.data.x,
      "originalY": res.data.y,
    } });

  } catch (error) {
    console.error(error)
    toast.error("Failed to get detector size")
  } finally {
  }
}

function* get_data_calibration_download_mask() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration);

    if (dataCalibration.dataFile === "") {
      toast.error("Please select a data file")
      return;
    }

    const res = yield getDataCalibrationDownloadMask(
      token,
      currentProject.projectId,
      dataCalibration.dataFile,
      dataCalibration.drawings,
      dataCalibration.mask.above === 0 || dataCalibration.mask.above ? dataCalibration.mask.above : "",
      dataCalibration.mask.below === 0 || dataCalibration.mask.below ? dataCalibration.mask.below : "",
      dataCalibration.imageOptions.colorMap,
      dataCalibration.imageOptions.log,
      dataCalibration.maskingFile,
    );

    const link = document.createElement("a");
    link.href = res.data;
    link.setAttribute("download", "mask.tif");
    document.body.appendChild(link);
    link.click();
    link.remove();

  } catch (error) {
    console.error(error)
    toast.error("Failed to integrate")
  }
}

export default function* dataCalibrationSaga() {
  yield all([
    takeLatest(GET_DATA_CALIBRATION_META_FILE_INFO, get_data_calibration_meta_file_info),
    takeLatest(GET_DATA_CALIBRATION_IMAGE, get_data_calibration_image),
    takeEvery(GET_DATA_CALIBRATION_RING_PICKING, get_data_calibration_ring_picking),
    takeLatest(GET_DATA_CALIBRATION_RING_PICKING_COMPILE, get_data_calibration_ring_picking_compile),
    takeLatest(GET_DATA_CALIBRATION_MASKING, get_data_calibration_masking),
    takeLatest(GET_DATA_CALIBRATION_INTEGRATION, get_data_calibration_integration),
    takeLatest(GET_DATA_CALIBRATION_CALLBACK, get_data_calibration_callback),
    takeLatest(GET_DATA_CALIBRATION_DETECTOR_SIZE, get_data_calibration_detector_size),
    takeLatest(GET_DATA_CALIBRATION_DOWNLOAD_MASK, get_data_calibration_download_mask),
  ]);
}