import { createBrowserHistory } from "history";
import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import mySaga from "./sagas";
import { composeWithDevTools } from 'redux-devtools-extension';
import { isLocalhost } from "../api";

export const history = createBrowserHistory();

export default function configureStore(preloadedState: {}) {
  const sagaMiddleware = createSagaMiddleware();
  let store;

  if (isLocalhost) {
    store = createStore(
      createRootReducer(history),
      preloadedState,
      composeWithDevTools(
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
      )
    );
  } else {
    store = createStore(
      createRootReducer(history),
      preloadedState,
      compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    );
  }

  sagaMiddleware.run(mySaga);

  return store;
}
