// eslint-disable-next-line no-restricted-globals
export const isLocalhost = Boolean(location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "scatterin-website-dev.s3-website.eu-north-1.amazonaws.com")

export const apiUrl = isLocalhost ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL;
export const apiLeBail = isLocalhost ? process.env.REACT_APP_API_URL_LE_BAIL_DEV : process.env.REACT_APP_API_URL_LE_BAIL;
export const apiGeneral = isLocalhost ? process.env.REACT_APP_API_URL_GENERAL_DEV : process.env.REACT_APP_API_URL_GENERAL;
export const apiPhaseQuantification = isLocalhost ? process.env.REACT_APP_API_URL_PHASE_QUANTIFICATION_DEV : process.env.REACT_APP_API_URL_PHASE_QUANTIFICATION;
export const apiDislocationDensity = isLocalhost ? process.env.REACT_APP_API_URL_DISLOCATION_DENSITY_DEV : process.env.REACT_APP_API_URL_DISLOCATION_DENSITY;
export const apiAzimuthalIntegration = isLocalhost ? process.env.REACT_APP_API_URL_AZIMUTHAL_INTEGRATION_DEV : process.env.REACT_APP_API_URL_AZIMUTHAL_INTEGRATION;
export const apiInstrumentalFileCreation = isLocalhost ? process.env.REACT_APP_API_URL_INSTRUMENTAL_FILE_CREATION_DEV : process.env.REACT_APP_API_URL_INSTRUMENTAL_FILE_CREATION;
export const apiDataCalibration = isLocalhost ? process.env.REACT_APP_API_URL_DATA_CALIBRATION_DEV : process.env.REACT_APP_API_URL_DATA_CALIBRATION;
export const apiRietveld = isLocalhost ? process.env.REACT_APP_API_URL_RIETVELD_DEV : process.env.REACT_APP_API_URL_RIETVELD;
export const apiPeakFitting = isLocalhost ? process.env.REACT_APP_API_URL_PEAK_FITTING_DEV : process.env.REACT_APP_API_URL_PEAK_FITTING;
export const apiStressStrain = isLocalhost ? process.env.REACT_APP_API_URL_STRESS_STRAIN_DEV : process.env.REACT_APP_API_URL_STRESS_STRAIN;

export const requestApi = async (path = "", method = "GET", data = null, token = "", headers = {}, endPointUrl = "", retry = false) => {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }

  let url = "";

  if (endPointUrl) {
    url = `${endPointUrl}${path}`;
  } else {
    url = `${apiUrl}${path}`;
  }

  let headerObj = { "Content-Type": "application/json" };

  if (token) {
    headerObj["Authorization"] = `Bearer ${token}`;
  }

  headers = Object.assign(headerObj, headers);

  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: "cors",
    cache: "no-cache",
    headers,
    body: data && method.toUpperCase() !== "GET" ? JSON.stringify(data) : null,
  });

  try {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(JSON.stringify(response));
    }
  } catch (error) {
    console.error(error);
  }

  return await response.json();
};