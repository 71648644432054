import { InputFieldModel } from "../../../../data/models";
import "./table-input-field.css";

export interface TableInputProps {
  model: InputFieldModel;
  values: any;
}
export const TableInputField = ({ model, values }: TableInputProps) => {
  const arr = values[model.dynamodb_name];
  if (arr && Array.isArray(arr) && arr.length > 0) {
    const headers = Object.getOwnPropertyNames(arr[0]);
    const columnsControl = headers.map((h) => (
      <div className="flex flex-col flex-1 table-input-column">
        <h1 className="font-bold text-white text-base mb-2">{h}</h1>
        {arr.map((tupple, index) => {
          return (
            <div className="mb-2 flex flex-col flex-1 max-w-4/5">
              <input
                id={`${model.id}_${h}_${index}`}
                key={`${model.id}_${h}_${index}`}
                className={`border-white bg-primary-850 p-2 rounded-md border border-solid text-white`}
                type="number"
                disabled={true}
                value={tupple[h]}
                step="0.0001"
              />
            </div>
          );
        })}
      </div>
    ));
    return <div className="flex gap-4 max-w-80">{columnsControl}</div>;
  } else {
    return <></>;
  }
};
