import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import ProjectsList from "./child-components/projects-list";
import { selectAnalysisType, selectCurrentProject } from "../../redux/project/projectSelectors";
import SelectAnalysis from "./child-components/select-analysis";
import CreateProject from "./child-components/create-project";
import { GET_PROJECTS_FOR_USER, SET_ANALYSIS_TYPE } from "../../redux/project/projectActions";

export default function ProjectSettings({ dispatch }: { dispatch: Dispatch }) {
  const currentProject = useSelector(selectCurrentProject);
  const analysisType = useSelector(selectAnalysisType);

  useEffect(() => {
    dispatch({ type: GET_PROJECTS_FOR_USER });
  }, [dispatch]);

  return (
    <>
    <div className={"flex rounded-md bg-primary-800 flex-1 xl:flex-col"}>
      <div className={"w-4/6 border-r border-primary-900 border-solid xl:border-b xl:border-r-0"}>
        {currentProject.projectId && (
          <>
          <div className={"flex flex-col text-center"}>
            <h1 className={"font-bold text-white text-4xl mb-4 pl-4 pt-4"}>
              {currentProject.projectName}
            </h1>
          </div>
          <SelectAnalysis
            setAnalysis={(analysis: string) => dispatch({ type: SET_ANALYSIS_TYPE, payload: analysis })}
            analysisSelected={analysisType}
          />
          </>
        )}
        {!currentProject.projectId && (
          <CreateProject dispatch={dispatch} />
        )}
      </div>
      <ProjectsList dispatch={dispatch} />
    </div>
    </>
  );
}
