import "./styles/App.css";
import "./styles/output.css";
import "./styles/other.css";
import Header from "./components/header";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import AuthForm from "./components/authForm";
import AnalysisStart from "./components/analysis-start";
import LoadProgress from "./components/analysis-settings/old/load-progress";
import PageNotFound from "./components/page-not-found";
import { Toaster } from 'react-hot-toast';
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { GET_CURRENT_USER } from "./redux/general/generalActions";
import { useEffect } from "react";
import { selectUserLoaded } from "./redux/general/generalSelectors";
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import { selectUserName } from "./redux/project/projectSelectors";

const App = ({ dispatch }: { dispatch: Dispatch; }) => {
  const history = useHistory();
  const userLoaded = useSelector(selectUserLoaded);
  const currentUser = useSelector(selectUserName);

  useEffect(() => {
    dispatch({ type: GET_CURRENT_USER });
  }, [dispatch]);

  useEffect(() => {
    if (userLoaded && currentUser === '') {
      history.push(ROUTES.LOGIN);
    }
  }, [userLoaded, currentUser, history]);

  useEffect(() => {
    if (currentUser && history.location.pathname === ROUTES.LOGIN) {
      history.push(ROUTES.HOME);
    }
  }, [currentUser, history]);

  if (!userLoaded) {
    return (
      <div className="progress-overlay z-50">
        <Loader type="Oval" color="#68d391" height={200} width={200} />
      </div>
    );
  }

  return (
    <div className="bg-primary-900 h-screen flex items-start flex-col">
      <Toaster />
      <LoadProgress />
      <Header />
      <Switch>
        <Route exact path={ROUTES.HOME} component={AnalysisStart}></Route>
        <Route exact path={ROUTES.LOGIN} component={AuthForm}></Route>
        <Route component={PageNotFound}></Route>
      </Switch>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps)(App);
