import { apiRietveld, requestApi } from "../../api";
import { RietveldModel } from "../../models/analysis.rietveld.model";

export const preProcessObject = (obj: RietveldModel) => {
  let newObj: RietveldModel = JSON.parse(JSON.stringify(obj));

  delete newObj.histogram;
  delete newObj.refinedHistogram;
  delete newObj.parameters;

  return newObj;
}

export const getCaller = async (token, req) => {
  return await requestApi(`caller`, "POST", req, token, {}, apiRietveld);
};

export const getCallback = async (token, req) => {
  return await requestApi(`callback`, "POST", req, token, {}, apiRietveld);
};

export const getDownload = async (token, req) => {
  return await requestApi(`download`, "POST", req, token, {}, apiRietveld);
};
