import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FormatLongFileName } from "../../shared/utils";
import { useEffect, useState } from "react";

interface Props {
  data: any[];
  dataViewFile: string;
  setDataViewFile: (file: string) => void;
  dataActiveFiles: string[];
  setDataActiveFiles: (files: string[]) => void;
  headersOrdered?: string[];
  pipeline?: boolean;
}

export default function AnalysisFilesTable({data, dataViewFile, setDataViewFile, dataActiveFiles, setDataActiveFiles, headersOrdered, pipeline=false}: Props) {
  const [headers, setHeaders] = useState<string[]>([]);
  
  useEffect(() => {
    const getUniqueHeaders = (data: Array<{ [key: string]: any }>): string[] => {
      const headers = new Set<string>();
      data.forEach((item) => {
        Object.keys(item).forEach((key) => headers.add(key));
      });
      headers.delete("fileName");
    
      return Array.from(headers).sort((a, b) => {
        const regex = /^(\D+)(\d+)?$/;
        const matchA = a.match(regex);
        const matchB = b.match(regex);
    
        if (matchA && matchB) {
          const letterCompare = matchA[1].localeCompare(matchB[1]);
          if (letterCompare !== 0) return letterCompare;
    
          if (matchA[2] && matchB[2]) {
            return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
          }
    
          if (matchA[2]) return -1;
          if (matchB[2]) return 1;
        }
    
        return a.localeCompare(b);
      });
    };
    
    if (headersOrdered) {
      setHeaders(headersOrdered);
    } else {
      setHeaders(getUniqueHeaders(data));
    }
  }, [data, headersOrdered]);

  const handleCheckboxChange = (fileName: string) => {
    if (dataActiveFiles.includes(fileName)) {
      setDataActiveFiles(dataActiveFiles.filter((file) => file !== fileName));
    } else {
      setDataActiveFiles([...dataActiveFiles, fileName]);
    }
  };

  return (
    <div className={"w-full overflow-x-auto flex-1"}>
      <table className={"table-auto text-xs w-full files-table overflow-x-auto border-collapse border-spacing-x-1"}>
        <thead>
          <tr className={"flex-2 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis text-white text-center border-b border-gray-300"}>
            {!pipeline && (
              <th className={"px-4 py-2 w-12"}>
                <FontAwesomeIcon
                  icon={dataActiveFiles.length === data.length ? faCheckSquare : faSquare}
                  onClick={() => {
                    if (dataActiveFiles.length === data.length) {
                      setDataActiveFiles([]);
                    } else {
                      setDataActiveFiles(data.map((file) => file["fileName"]));
                    }
                  }}
                  className="cursor-pointer"
                  size={"lg"}
                />
              </th>
            )}
            <th className={"px-4 py-2 w-12"}>View File</th>
            <th className={"px-2 py-2"}>Data File Name</th>
            {headers.map((header) => (
              <th key={header} className={"px-2 py-2"}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((file, index) => (
            <tr
              key={index}
              className={"text-white flex-1 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer text-center"}
            >
              {!pipeline && (
                <td className={"px-2 py-1 w-12 m-2"} key={`${index}-select`}>
                  <FontAwesomeIcon
                    icon={dataActiveFiles.includes(file["fileName"]) ? faCheckSquare : faSquare}
                    onClick={() => handleCheckboxChange(file["fileName"])}
                    className="cursor-pointer"
                    size={"lg"}
                  />
                </td>
              )}
              <td className={"px-2 py-1 w-12 m-2"} key={`${index}-view`}>
                <button
                  className={`${dataViewFile === file["fileName"] ? "bg-primary-700" : "bg-primary-500"} w-12 p-1 rounded-md text-white cursor-pointer`}
                  onClick={() => setDataViewFile(file["fileName"])}
                >
                  View
                </button>
              </td>
              <td className={"px-6 py-1"} key={`${index}-fileName`}>
                {FormatLongFileName(file["fileName"])}
              </td>
              {headers.map((header) => (
                <td className={"px-6 py-1"} key={`${index}-${header}`}>
                  {file.hasOwnProperty(header) ? file[header] : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
