import { requestApi, apiDataCalibration, apiGeneral } from "../../api";
import { isNumber } from "../../helpers/redux";

export const getDataCalibrationMetaFileInfo = async (token, userId, projectId, metaFile) => {
  const req = {
    projectId,
    userId,
    metaFile
  };

  return await requestApi(`read-meta`, "POST", req, token, {}, apiDataCalibration);
};

export const getDataCalibrationImage = async (token, projectId, detectorFile, colorMap, log) => {
  const req = {
    token,
    projectId,
    detectorFile,
    colorMap,
    log,
  };

  return await requestApi(`detector`, "POST", req, token, {}, apiGeneral);
};

export const getDataCalibrationRingPicking = async (token, projectId, dataFile, calibrantType, detectorName, wavelength, coordinate, detectorSize, detectorPixelSize) => {
  const req = {
    token,
    projectId,
    dataFile,
    calibrantType,
    detectorName,
    wavelength,
    coordinate,
    detectorSize,
    detectorPixelSize,
  };

  return await requestApi(`ring-picking`, "POST", req, token, {}, apiDataCalibration);
}

export const getDataCalibrationRingPickingCompile = async (token, projectId, userId, dataFile, calibrantType, detectorName, wavelength, coordinates, drawings, maskingBelow, maskingAbove, colorMap, log, detectorSize, detectorPixelSize, maskingFile) => {
  const req = {
    token,
    projectId,
    userId,
    dataFile,
    calibrantType,
    detectorName,
    wavelength,
    coordinates,
    drawings,
    maskingBelow: isNumber(maskingBelow) ? maskingBelow : "",
    maskingAbove: isNumber(maskingAbove) ? maskingAbove : "",
    colorMap,
    log,
    callerType: "ringPickingCalculation",
    detectorSize,
    detectorPixelSize,
    maskingFile
  };

  return await requestApi(`caller`, "POST", req, token, {}, apiDataCalibration);
}

export const getDataCalibrationMasking = async (token, projectId, dataFile, drawings, maskingAbove, maskingBelow, colorMap, log, maskingFile, userId) => {
  const req = {
    token,
    projectId,
    dataFile,
    drawings,
    maskingAbove: isNumber(maskingAbove) ? maskingAbove : "",
    maskingBelow: isNumber(maskingBelow) ? maskingBelow : "",
    colorMap,
    log,
    maskingFile,
    userId
  };

  return await requestApi(`masking`, "POST", req, token, {}, apiDataCalibration);
}

export const getDataCalibrationIntegration = async (token, projectId, userId, dataFile, drawings, maskingAbove, maskingBelow, calibrantType, detectorName, wavelength, coordinates, numberOfDataPoints, polarizationFactor, radialRangeLower, radialRangeUpper, unit, log, colorMap, detectorSize, detectorPixelSize, maskingFile) => {
  const req = {
    token,
    projectId,
    userId,
    dataFile,
    drawings,
    maskingBelow: isNumber(maskingBelow) ? maskingBelow : "",
    maskingAbove: isNumber(maskingAbove) ? maskingAbove : "",
    calibrantType,
    detectorName,
    wavelength,
    coordinates,
    numberOfDataPoints,
    polarizationFactor: isNumber(polarizationFactor) ? polarizationFactor : 0,
    radialRangeLower: isNumber(radialRangeLower) ? radialRangeLower : 0,
    radialRangeUpper: isNumber(radialRangeUpper) ? radialRangeUpper : 0,
    unit,
    log,
    colorMap,
    callerType: "integration",
    detectorSize,
    detectorPixelSize,
    maskingFile
  };

  return await requestApi(`caller`, "POST", req, token, {}, apiDataCalibration);
}

export const getDataCalibrationCallback = async (token, projectId) => {
  const req = {
    token,
    projectId
  };

  return await requestApi(`callback`, "POST", req, token, {}, apiDataCalibration);
}

export const getDataCalibrationDetectorSize = async (token, projectId, file) => {
  const req = {
    token,
    projectId,
    file
  };

  return await requestApi(`detector-size`, "POST", req, token, {}, apiDataCalibration);
}

export const getDataCalibrationDownloadMask = async (token, projectId, dataFile, drawings, maskingAbove, maskingBelow, colorMap, log, maskingFile) => {
  const req = {
    token,
    projectId,
    dataFile,
    drawings,
    maskingAbove: isNumber(maskingAbove) ? maskingAbove : "",
    maskingBelow: isNumber(maskingBelow) ? maskingBelow : "",
    colorMap,
    log,
    maskingFile
  };

  return await requestApi(`masking-download`, "POST", req, token, {}, apiDataCalibration);
}
