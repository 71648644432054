import { GeneralState } from "../../models/general.model";
import { SET_UPLOAD_FILES_PROGRESS, SET_UPLOAD_STATUS } from "../project/projectActions";
import { SET_COMPUTING_INSTANCE, SET_CURRENT_USER, SET_DOWNLOAD_INSTANCE_ID, SET_ERROR_DETAILS, SET_FILE_FETCHING_IN_PROGRESS, SET_INLINE_OPERATION_IN_PROGRESS, SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_OPERATION_IN_PROGRESS, SET_OPERATION_IN_PROGRESS_DICT, SET_PIPELINE_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE, SET_PROGRESS_TITLE, SET_RELOAD_FILE_SYSTEM, SET_USER_LOADED, SET_USER_TOKEN } from "./generalActions";

const initialState: GeneralState = {
  operationInProgress: false,
  inlineOperationInProgress: false,
  inInlineOperationInProgress: false,
  inInInlineOperationInProgress: false,
  fileFetchingInProgress: false,
  errorDetails: undefined,
  instanceId: "",
  progress: 0,
  progressMessage: "Starting Instance",
  progressTitle: "Running Operation",
  downloadInstance: "",
  fileUploadProgress: {},
  uploadStatus: 0,
  reloadFileSystem: false,
  operationInProgressDict: [],
  pipeline: {
    progress: {
      azimuthalIntegration: 0,
      phaseQuantification: 0,
      processed: 0,
    },
  },
  user: {
    userToken: "",
    userId: "",
    userName: "",
  },
  userLoaded: false,
};

export default function generalReducer(state: GeneralState = initialState,{ type, payload }: { type: string; payload: any }) {
  switch (type) {
    case SET_OPERATION_IN_PROGRESS: {
      return {
        ...state,
        operationInProgress: payload,
      };
    }
    case SET_INLINE_OPERATION_IN_PROGRESS:
      return {
        ...state,
        inlineOperationInProgress: payload,
      };
    case SET_IN_INLINE_OPERATION_IN_PROGRESS:
      return {
        ...state,
        inInlineOperationInProgress: payload
      };
    case SET_IN_IN_INLINE_OPERATION_IN_PROGRESS:
      return {
        ...state,
        inInInlineOperationInProgress: payload
      }
    case SET_ERROR_DETAILS: {
      return {
        ...state,
        errorDetails: payload,
      };
    }
    case SET_INSTANCE_ID: {
      return {
        ...state,
        instanceId: payload,
      };
    }
    case SET_PROGRESS: {
      return {
        ...state,
        progress: payload,
      };
    }
    case SET_PROGRESS_MESSAGE: {
      return {
        ...state,
        progressMessage: payload,
      };
    }
    case SET_PROGRESS_TITLE:
      return {
        ...state,
        progressTitle: payload,
      };
    case SET_DOWNLOAD_INSTANCE_ID:
      return {
        ...state,
        downloadInstance: payload,
      };
    case SET_UPLOAD_FILES_PROGRESS:
      return {
        ...state,
        fileUploadProgress: payload,
      };
    case SET_UPLOAD_STATUS:
      return {
        ...state,
        uploadStatus: payload,
      };
    case SET_RELOAD_FILE_SYSTEM:
      return {
        ...state,
        reloadFileSystem: payload,
      };
    case SET_COMPUTING_INSTANCE:
      return {
        ...state,
        computingRunning: payload,
      };
    case SET_FILE_FETCHING_IN_PROGRESS:
      return {
        ...state,
        fileFetchingInProgress: payload,
      };
    case SET_OPERATION_IN_PROGRESS_DICT:
      if (payload.operationInProgress) {
        return {
          ...state,
          operationInProgressDict: [...state.operationInProgressDict, payload.operation],
        };
      } else {
        return {
          ...state,
          operationInProgressDict: [...state.operationInProgressDict.filter((operation) => operation !== payload.operation)],
        };
      }
    case SET_PIPELINE_PROGRESS:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          progress: payload,
        },
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          userId: payload.userId,
          userName: payload.userName,
        },
      };
    case SET_USER_LOADED:
      return {
        ...state,
        userLoaded: payload,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          userToken: payload,
        },
      };
    default:
      return state;
  }
}
