import { DropDownOptions } from "../../data/models";
import { getName, toReadableLabel } from "../../helpers/name";
import { LabelsMappingPeakFitting, LabelsMappingStressStrain, LabelsMappingStressStrainBin, ReportScreenModel } from "../../models/report-screen.model";


export function defaultDropDown(report) {
  let dropdownOptions = {
    id: "analysisTypes",
    label: "Analysis Types",
    options: [
      {
        id: "analysisTypes",
        label: "Analysis Types",
        values: [],
      },
    ],
  } as DropDownOptions

  if (report.analysisTypes.length > 0) {
    dropdownOptions.options[0].values = report.analysisTypes.map((analysisType) => ({
      value: analysisType,
      label: toReadableLabel(analysisType),
    }));
  }

  return dropdownOptions;
}

export function phaseQuantificationDropDown(report) {
  let dropdownOptions = {
    id: "peakDropDown",
    label: "Peaks",
    options: [
      {
        id: "volumeFraction",
        label: "Volume Fraction",
        values: [
        ]
      }
    ],
  } as DropDownOptions;

  const uniqueCifKeys = new Set();

  Object.values(report.phaseQuantificationReport.parametersAnalysis).forEach((fileAnalysis) => {
    Object.entries(fileAnalysis).forEach(([peakKey, peakValues]) => {
      uniqueCifKeys.add(peakKey);
    });
  });

  const cifKeysArray = Array.from(uniqueCifKeys);

  cifKeysArray.forEach((cif_key, index) => {
    dropdownOptions.options[0].values.push({
      value: cif_key as string,
      label: cif_key as string,
    });
  });

  const uniquePeakKeys = new Set();

  Object.values(report.phaseQuantificationReport.analysis).forEach((fileAnalysis) => {
    Object.entries(fileAnalysis).forEach(([peakKey, peakValues]) => {
      uniquePeakKeys.add(peakKey);
    });
  });

  const peakKeysArray = Array.from(uniquePeakKeys);

  peakKeysArray.sort((a, b) => {
    const peakNumberA = parseInt((a as string).match(/\d+/)[0]);
    const peakNumberB = parseInt((b as string).match(/\d+/)[0]);

    return peakNumberA - peakNumberB;
  });

  peakKeysArray.forEach((peak_key, index) => {
    const peakIndex = index + 1;
    dropdownOptions.options.push({
      id: `peak_${peakIndex}`,
      label: `Peak ${peakIndex}`,
      values: Object.entries(LabelsMappingPeakFitting).map(([peakKey, peakValues]) => ({
        value: `${peak_key}_${peakKey}`,
        label: LabelsMappingPeakFitting[peakKey],
      })),
    });
  });

  return dropdownOptions;
}

export function dislocationDensityDropDown(report) {
  let dropdownOptions = {
    id: "peakDropDown",
    label: "Peaks",
    options: [
      {
        id: "mwh",
        label: "Modified Williamson-Hall",
        values: [
          { value: "alpha", label: "Alpha" },
          { value: "q", label: "Q" },
          { value: "rhoWH", label: "DD_WH [m^-2]" },
          { value: "rhoMWH", label: "DD_mWA [m^-2]" },
          { value: "slope", label: "Slope" },
          { value: "strain", label: "Strain" },
          { value: "intercept", label: "Intercept" },
          { value: "crystallite_size", label: "Crystallite Size [Å]" },
        ]
      }
    ],
  } as DropDownOptions;

  const uniquePeakKeys = new Set();

  Object.values(report.dislocationDensityReport.analysis).forEach((fileAnalysis) => {
    Object.entries(fileAnalysis).forEach(([peakKey, _]) => {
      uniquePeakKeys.add(peakKey);
    });
  });

  const peakKeysArray = Array.from(uniquePeakKeys);

  peakKeysArray.sort((a, b) => {
    const peakNumberA = parseInt((a as string).match(/\d+/)[0]);
    const peakNumberB = parseInt((b as string).match(/\d+/)[0]);

    return peakNumberA - peakNumberB;
  });

  peakKeysArray.forEach((peak_key, index) => {
    const peakIndex = index + 1;
    dropdownOptions.options.push({
      id: `peak_${peakIndex}`,
      label: `Peak ${peakIndex}`,
      values: Object.entries(LabelsMappingPeakFitting).map(([peakKey, peakValues]) => ({
        value: `${peak_key}_${peakKey}`,
        label: LabelsMappingPeakFitting[peakKey],
      })),
    });
  });

  return dropdownOptions;
}

export function peakFittingDropDown(report) {
  let dropdownOptions = {
    id: "peakDropDown",
    label: "Peaks",
    options: [],
  } as DropDownOptions;

  const uniquePeakKeys = new Set();

  Object.values(report.peakFittingReport.analysis).forEach((fileAnalysis) => {
    Object.entries(fileAnalysis).forEach(([peakKey, peakValues]) => {
      uniquePeakKeys.add(peakKey);
    });
  });

  const peakKeysArray = Array.from(uniquePeakKeys);

  peakKeysArray.sort((a, b) => {
    const peakNumberA = parseInt((a as string).match(/\d+/)[0]);
    const peakNumberB = parseInt((b as string).match(/\d+/)[0]);

    return peakNumberA - peakNumberB;
  });

  peakKeysArray.forEach((peak_key, index) => {
    const peakIndex = index + 1;
    dropdownOptions.options.push({
      id: `peak_${peakIndex}`,
      label: `Peak ${peakIndex}`,
      values: Object.entries(LabelsMappingPeakFitting).map(([peakKey, peakValues]) => ({
        value: `${peak_key}_${peakKey}`,
        label: LabelsMappingPeakFitting[peakKey],
      })),
    });
  });

  return dropdownOptions;
}


export function stressStrainDropDown(report: ReportScreenModel) {
  let dropdownOptions = {
    id: "peakDropDown",
    label: "Peaks",
    options: [],
  } as DropDownOptions;

  // Stress Parameters Bin
  const uniquePeakKeysStressBin = new Set();

  Object.values(report.stressStrainReport.stressStrainBinParameters).forEach((fileAnalysis) => {
    Object.entries(fileAnalysis).forEach(([peakKey, peakValues]) => {
      uniquePeakKeysStressBin.add(peakKey);
    });
  });

  const peakKeysArrayStressBin = Array.from(uniquePeakKeysStressBin);

  peakKeysArrayStressBin.sort((a, b) => {
    const peakNumberA = parseInt((a as string).match(/\d+/)[0]);
    const peakNumberB = parseInt((b as string).match(/\d+/)[0]);

    return peakNumberA - peakNumberB;
  });

  peakKeysArrayStressBin.forEach((peak_key, index) => {
    const peakIndex = index + 1;
    dropdownOptions.options.push({
      id: `peak_bin_${peakIndex}`,
      label: `Stress Strain Bin Parameters Peak ${peakIndex}`,
      values: Object.entries(LabelsMappingStressStrainBin).map(([peakKey, peakValues]) => ({
        value: `bin_${peak_key}_${peakKey}`,
        label: LabelsMappingStressStrainBin[peakKey],
      })),
    });
  });

  // Stress Parameters
  const uniquePeakKeysStress = new Set();

  Object.values(report.stressStrainReport.stressStrainParameters).forEach((fileAnalysis) => {
    Object.entries(fileAnalysis).forEach(([peakKey, peakValues]) => {
      uniquePeakKeysStress.add(peakKey);
    });
  });

  const peakKeysArrayStress = Array.from(uniquePeakKeysStress);

  peakKeysArrayStressBin.sort((a, b) => {
    const peakNumberA = parseInt((a as string).match(/\d+/)[0]);
    const peakNumberB = parseInt((b as string).match(/\d+/)[0]);

    return peakNumberA - peakNumberB;
  });

  peakKeysArrayStress.forEach((peak_key, index) => {
    const peakIndex = index + 1;
    dropdownOptions.options.push({
      id: `peak_stress_${peakIndex}`,
      label: `Stress Strain Parameters Peak ${peakIndex}`,
      values: Object.entries(LabelsMappingStressStrain).map(([peakKey, peakValues]) => ({
        value: `stress_${peak_key}_${peakKey}`,
        label: LabelsMappingStressStrain[peakKey],
      })),
    });
  });

  // Peak Fitting
  const uniquePeakKeys = new Set();

  Object.values(report.stressStrainReport.analysis).forEach((fileAnalysis) => {
    Object.entries(fileAnalysis).forEach(([peakKey, peakValues]) => {
      uniquePeakKeys.add(peakKey);
    });
  });

  const peakKeysArray = Array.from(uniquePeakKeys);

  peakKeysArray.sort((a, b) => {
    const peakNumberA = parseInt((a as string).match(/\d+/)[0]);
    const peakNumberB = parseInt((b as string).match(/\d+/)[0]);

    return peakNumberA - peakNumberB;
  });

  peakKeysArray.forEach((peak_key, index) => {
    const peakIndex = index + 1;
    dropdownOptions.options.push({
      id: `peak_${peakIndex}`,
      label: `Peak Fitting Peak ${peakIndex}`,
      values: Object.entries(LabelsMappingPeakFitting).map(([peakKey, peakValues]) => ({
        value: `peak_${peak_key}_${peakKey}`,
        label: LabelsMappingPeakFitting[peakKey],
      })),
    });
  });

  return dropdownOptions;
}

export function leBailDropDown(availableCifFiles) {
  let dropdownOptions = {
    id: "cifFiles",
    label: "Cif Files",
    options: [
      {
        id: "cifFiles",
        label: "Cif Files",
        values: [],
      },
    ],
  } as DropDownOptions;

  if (availableCifFiles.length > 0) {
    dropdownOptions.options[0].values = availableCifFiles.map((cifFile) => ({
      value: cifFile,
      label: getName(cifFile),
    }));
  }

  return dropdownOptions;
}
