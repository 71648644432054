import Loader from "react-loader-spinner";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getInInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { selectPhaseQuantificationDataViewFile, selectPhaseQuantificationPartitionActiveIndex, selectPhaseQuantificationPartitionSelectingPeaks, selectPhaseQuantificationPartitions, selectPhaseQuantificationHistogram, selectPhaseQuantificationPartitionFitting } from "../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { SET_PHASE_QUANTIFICATION_PARTITIONS } from "../../../redux/phaseQuantification/phaseQuantificationActions";
import { HandleHistogramClick, HistogramGraph, PartitionFittingGraph, PartitionSelectionHistogramRange } from "../shared/graphs/histogram";
import { GraphConfig, GraphLayout, GraphStyling } from "../../../constants/graphs";


const PhaseQuantificationGraph = ({dispatch }: { dispatch: Dispatch }) => {
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress)
  const dataViewFile = useSelector(selectPhaseQuantificationDataViewFile);
  const selectedPartitionIndex = useSelector(selectPhaseQuantificationPartitionActiveIndex);
  const selectPeaks = useSelector(selectPhaseQuantificationPartitionSelectingPeaks);
  const partitions = useSelector(selectPhaseQuantificationPartitions);
  const histogram = useSelector(selectPhaseQuantificationHistogram);
  const partitionFitting = useSelector(selectPhaseQuantificationPartitionFitting);

  const [activeTab, setActiveTab] = useState("histogram")
  const [chartFitting, setChartFitting] = useState<{ [key: string]: any[] }>({});
  const [chartShapes, setChartShapes] = useState<any>([]);
  const [reload, setReload] = useState(false);
  const [maxCoordinate, setMaxCoordinate] = useState<any>({ xLower: undefined, xHigher: undefined, yMax: undefined, yMin: undefined });

  useEffect(() => {
    setMaxCoordinate(PartitionSelectionHistogramRange(histogram.data.x, histogram.data.y, partitions, selectPeaks, selectedPartitionIndex));
  }, [selectPeaks, partitions, selectedPartitionIndex, reload, histogram]);

  const handleClick = (event: any) => {
    dispatch({ type: SET_PHASE_QUANTIFICATION_PARTITIONS, payload:  HandleHistogramClick(event, selectPeaks, partitions, selectedPartitionIndex) });
    setReload(!reload);
  };

  useEffect(() => {
    let currentChartShapes = PartitionFittingGraph(partitionFitting, dataViewFile);
    let histogramGraphData = HistogramGraph(histogram, partitions, selectedPartitionIndex);

    currentChartShapes["histogram"] = [...histogramGraphData.histogramData, ...histogramGraphData.traces];

    setChartFitting({ ...currentChartShapes });
    setChartShapes([ ...histogramGraphData.phasesShapes ]);
  }, [dataViewFile, histogram, partitionFitting, partitions, reload, selectedPartitionIndex]);

  return (
    <div className="relative flex flex-col justify-center items-center">
      {(inInlineOperationInProgress || inInlineOperationInProgress) &&
        <div className={"absolute top-2 right-2"}>
          <Loader type="Oval" color="#68d391" height={25} width={25} />
        </div>
      }
      <div className={"flex justify-center items-center z-10"}>
        <div className={"flex flex-row justify-center items-center z-10"}>
          <button
            className={`${activeTab === "histogram" ? "bg-primary-700" : "bg-primary-600"} w-24  pt-1 pb-1 pr-2 pl-2 ${Object.keys(chartFitting).length !== 1 ? "rounded-l-md" : "rounded-md"} text-white cursor-pointer`}
            onClick={() => setActiveTab("histogram")}
          >
            Histogram
          </button>
          {chartFitting &&
            Object.keys(chartFitting).map((value, index) => {
              if (value === "histogram") return null;

              return (
                <button
                key={index}
                className={`w-24 ${activeTab === value ? "bg-primary-700" : "bg-primary-600"} pt-1 pb-1 pr-2 pl-2 text-white cursor-pointer ${
                  index === Object.keys(chartFitting).length - 2 ? 'rounded-r-md' : ''
                }`}
                onClick={() => setActiveTab(value)}
              >
                Partition {parseInt(value) + 1}
              </button>
              )
            })
          }
        </div>
      </div>
      <div className={"flex justify-around w-full"}>
        <Plot
          data={
            chartFitting[activeTab] ? chartFitting[activeTab] : []
          }
          layout={{
            ...GraphLayout,
            xaxis: {
              ...GraphLayout.xaxis,
              range: activeTab === "histogram" ? [maxCoordinate.xLower, maxCoordinate.xHigher] : undefined,
            },
            yaxis: {
              ...GraphLayout.yaxis,
              range: activeTab === "histogram" ? [maxCoordinate.yMin, maxCoordinate.yMax] : undefined,
            },
            shapes: activeTab === "histogram" ? chartShapes : [],
          }}
          config={GraphConfig}
          useResizeHandler={true}
          style={GraphStyling}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default PhaseQuantificationGraph;
