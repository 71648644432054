import { CalibrationFile, DataCalibrationModel } from "../../models/analysis.dataCalibration.model";
import { SET_DATA_CALIBRATION, SET_DATA_CALIBRATION_DATA_FILE, SET_DATA_CALIBRATION_META_FILE, SET_DATA_CALIBRATION_THRESHOLD_MASK, SET_DATA_CALIBRATION_CALIBRANT_TYPE, SET_DATA_CALIBRATION_WAVELENGTH, SET_DATA_CALIBRATION_RING_PICKING, SET_DATA_CALIBRATION_NUMBER_OF_DATA_POINTS, SET_DATA_CALIBRATION_RADIAL_RANGE, SET_DATA_CALIBRATION_AZIMUTHAL_RANGE, SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_LOWER, SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_UPPER, SET_DATA_CALIBRATION_RADIAL_RANGE_LOWER, SET_DATA_CALIBRATION_RADIAL_RANGE_UPPER, SET_DATA_CALIBRATION_POLARIZATION_FACTOR, SET_DATA_CALIBRATION_UNIT, SET_DATA_CALIBRATION_DETECTOR_TYPE, SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE, SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_X, SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_Y, SET_DATA_CALIBRATION_DETECTOR_SIZE, SET_DATA_CALIBRATION_DETECTOR_SIZE_X, SET_DATA_CALIBRATION_DETECTOR_SIZE_Y, SET_DATA_CALIBRATION_MASK, SET_DATA_CALIBRATION_MASK_ABOVE, SET_DATA_CALIBRATION_MASK_BELOW, SET_DATA_CALIBRATION_DRAWING_MODE, SET_DATA_CALIBRATION_DRAWINGS, APPEND_DATA_CALIBRATION_DRAWINGS, REMOVE_DATA_CALIBRATION_DRAWINGS, SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX, SET_DATA_CALIBRATION_DRAWING_VISIBLE, SET_DATA_CALIBRATION_PEAK_THRESHOLD, SET_DATA_CALIBRATION_RING_PICKING_RING, SET_DATA_CALIBRATION_RING_PICKING_RING_MODE, UPDATE_DATA_CALIBRATION_RING_PICKING, REMOVE_DATA_CALIBRATION_RING_PICKING, SET_DATA_CALIBRATION_IMAGE_OPTIONS_COLOR_MAP, SET_DATA_CALIBRATION_IMAGE_OPTIONS_LOG, SET_DATA_CALIBRATION_IMAGE_OPTIONS, UPDATE_DATA_CALIBRATION_DRAWINGS, SET_DATA_CALIBRATION_ACTIVE_IMAGE_PATH, SET_DATA_CALIBRATION_DRAWINGS_OBJECT, SET_DATA_CALIBRATION_RING_PICKING_OBJECT, SET_DATA_CALIBRATION_IMAGE_MASK, SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS, SET_DATA_CALIBRATION_CALIBRATION_FILE, SET_DATA_CALIBRATION_IMAGE_GEOMETRY, SET_DATA_CALIBRATION_ANALYSIS, SET_DATA_CALIBRATION_CUSTOM_DETECTOR, SET_DATA_CALIBRATION_MASKING_FILE } from "./dataCalibrationActions";

export const initialStateDataCalibration:  DataCalibrationModel = {
  dataFile: "",
  metaFile: "",
  maskingFile: "",
  thresholdMask: undefined,
  calibrantType: "",
  wavelength: undefined,
  ringPicking: {},
  numberOfDataPoints: 3500,
  radialRange: {
    lower: 0,
    upper: 20,
  },
  azimuthalRange: {
    lower: 0,
    upper: 360,
  },
  polarizationFactor: 0.9,
  unit: "2th_deg",
  detectorType: "",
  detectorSize: {
    x: undefined,
    y: undefined,
    originalX: undefined,
    originalY: undefined,
  },
  detectorPixelSize: {
    x: undefined,
    y: undefined,
  },
  mask: {
    below: undefined,
    above: undefined,
  },
  drawingMode: undefined,
  drawings: {},
  drawingActiveIndex: undefined,
  peakThreshold: undefined,
  ringPickingRing: 1,
  ringPickingMode: undefined,
  imageOptions: {
    colorMap: 'VIRIDIS',
    log: true,
  },
  activeImagePath: "",
  imageMask: "",
  imageGeometry: "",
  calibrationFile: {} as CalibrationFile,
  analysis: {
    x: [],
    y: [],
    lines: [],
  },
  customDetector: false,
}

export default function dataCalibrationReducer(state = initialStateDataCalibration, { type, payload }: { type: string; payload: any }): DataCalibrationModel {
  if (type.includes("DATA_CALIBRATION")) {
    console.debug(`[DATA_CALIBRATION REDUCER] ${type}`, payload);
  }
  switch (type) {
    case SET_DATA_CALIBRATION:
      return {
        ...state,
        ...payload,
      };
    case SET_DATA_CALIBRATION_DATA_FILE:
      return {
        ...state,
        dataFile: payload,
      };
    case SET_DATA_CALIBRATION_META_FILE:
      return {
        ...state,
        metaFile: payload,
      };
    case SET_DATA_CALIBRATION_THRESHOLD_MASK:
      return {
        ...state,
        thresholdMask: payload,
      };
    case SET_DATA_CALIBRATION_CALIBRANT_TYPE:
      return {
        ...state,
        calibrantType: payload,
      };
    case SET_DATA_CALIBRATION_WAVELENGTH:
      return {
        ...state,
        wavelength: payload,
      };
    case SET_DATA_CALIBRATION_NUMBER_OF_DATA_POINTS:
      return {
        ...state,
        numberOfDataPoints: payload,
      };
    case SET_DATA_CALIBRATION_RADIAL_RANGE:
      return {
        ...state,
        radialRange: payload,
      };
    case SET_DATA_CALIBRATION_AZIMUTHAL_RANGE:
      return {
        ...state,
        azimuthalRange: payload,
      };
    case SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_LOWER:
      return {
        ...state,
        azimuthalRange: {
          ...state.azimuthalRange,
          lower: payload,
        }
      };
    case SET_DATA_CALIBRATION_AZIMUTHAL_RANGE_UPPER:
      return {
        ...state,
        azimuthalRange: {
          ...state.azimuthalRange,
          upper: payload,
        }
      };
    case SET_DATA_CALIBRATION_RADIAL_RANGE_LOWER:
      return {
        ...state,
        radialRange: {
          ...state.radialRange,
          lower: payload,
        }
      };
    case SET_DATA_CALIBRATION_RADIAL_RANGE_UPPER:
      return {
        ...state,
        radialRange: {
          ...state.radialRange,
          upper: payload,
        }
      };
    case SET_DATA_CALIBRATION_POLARIZATION_FACTOR:
      return {
        ...state,
        polarizationFactor: payload,
      };
    case SET_DATA_CALIBRATION_UNIT:
      return {
        ...state,
        unit: payload,
      };
    case SET_DATA_CALIBRATION_DETECTOR_TYPE:
      return {
        ...state,
        detectorType: payload,
      };
    case SET_DATA_CALIBRATION_DETECTOR_SIZE:
      return {
        ...state,
        detectorSize: payload,
      };
    case SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE:
      return {
        ...state,
        detectorPixelSize: payload,
      };
    case SET_DATA_CALIBRATION_DETECTOR_SIZE_X:
      return {
        ...state,
        detectorSize: {
          ...state.detectorSize,
          x: payload,
        }
      };
    case SET_DATA_CALIBRATION_DETECTOR_SIZE_Y:
      return {
        ...state,
        detectorSize: {
          ...state.detectorSize,
          y: payload,
        }
      };
    case SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_X:
      return {
        ...state,
        detectorPixelSize: {
          ...state.detectorPixelSize,
          x: payload,
        }
      };
    case SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_Y:
      return {
        ...state,
        detectorPixelSize: {
          ...state.detectorPixelSize,
          y: payload,
        }
      };
    case SET_DATA_CALIBRATION_MASK:
      return {
        ...state,
        mask: payload,
      };
    case SET_DATA_CALIBRATION_MASK_BELOW:
      return {
        ...state,
        mask: {
          ...state.mask,
          below: payload,
        }
      };
    case SET_DATA_CALIBRATION_MASK_ABOVE:
      return {
        ...state,
        mask: {
          ...state.mask,
          above: payload,
        }
      };
    case SET_DATA_CALIBRATION_DRAWING_MODE:
      return {
        ...state,
        drawingMode: payload,
        ringPickingMode: undefined,
      };
    case SET_DATA_CALIBRATION_DRAWINGS_OBJECT:
      return {
        ...state,
        drawings: payload,
      };
    case SET_DATA_CALIBRATION_RING_PICKING_OBJECT:
      return {
        ...state,
        ringPicking: payload,
      };
    case SET_DATA_CALIBRATION_DRAWINGS:
      const lastDrawing = state.drawings[Object.keys(state.drawings).length - 1];
      let index = Object.keys(state.drawings).length;
      if (lastDrawing && lastDrawing.coordinates.length === 0) {
        index = Object.keys(state.drawings).length - 1;
      }

      return {
        ...state,
        drawingActiveIndex: index,
        ringPickingMode: undefined,
        drawings: {
          ...state.drawings,
          [index]: {
            type: payload,
            visible: true,
            coordinates: [],
          },
        }
      };
    case SET_DATA_CALIBRATION_RING_PICKING:
      let indexRing = Object.keys(state.ringPicking).length;

      return {
        ...state,
        drawingActiveIndex: undefined,
        drawingMode: undefined,
        ringPicking: {
          ...state.ringPicking,
          [indexRing]: {
            type: state.ringPickingMode ? state.ringPickingMode : "group",
            clickedCoordinate: payload,
            numberOfPeaks: state.ringPickingMode === "group" ? 0 : 1,
            ring: state.ringPickingRing,
            peaks: [],
          },
        }
      };
    case SET_DATA_CALIBRATION_RING_PICKING_NUMBER_OF_PEAKS:
      return {
        ...state,
        ringPicking: {
          ...state.ringPicking,
          [payload.index]: {
            ...state.ringPicking[payload.index],
            numberOfPeaks: payload.numberOfPeaks,
            peaks: payload.peaks,
          },
        },
      };
    case APPEND_DATA_CALIBRATION_DRAWINGS:
      return {
        ...state,
        drawings: {
          ...state.drawings,
          [state.drawingActiveIndex]: {
            ...state.drawings[state.drawingActiveIndex],
            coordinates: [...state.drawings[state.drawingActiveIndex].coordinates, payload],
          },
        },
      };
    case UPDATE_DATA_CALIBRATION_DRAWINGS:
      return {
        ...state,
        drawings: {
          ...state.drawings,
          [payload.index]: {
            ...state.drawings[payload.index],
            coordinates: payload.coordinates,
          },
        },
      };
    case UPDATE_DATA_CALIBRATION_RING_PICKING:
      return {
        ...state,
        ringPicking: {
          ...state.ringPicking,
          [payload.index]: {
            ...state.ringPicking[payload.index],
            numberOfPeaks: payload.numberOfPeaks,
            peaks: payload.peaks,
          },
        },
      };
    case REMOVE_DATA_CALIBRATION_DRAWINGS:
      const updatedDrawings = {};
      Object.keys(state.drawings).forEach((key) => {
        if (parseInt(key) > payload) {
          updatedDrawings[parseInt(key) - 1] = state.drawings[key];
        } else if (parseInt(key) < payload) {
          updatedDrawings[key] = state.drawings[key];
        }
      });

      return {
        ...state,
        drawings: updatedDrawings,
      };
    case REMOVE_DATA_CALIBRATION_RING_PICKING:
      const updatedRingPicking = {};
      Object.keys(state.ringPicking).forEach((key) => {
        if (parseInt(key) > payload) {
          updatedRingPicking[parseInt(key) - 1] = state.ringPicking[key];
        } else if (parseInt(key) < payload) {
          updatedRingPicking[key] = state.ringPicking[key];
        }
      });

      return {
        ...state,
        ringPicking: updatedRingPicking,
      };
    case SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX:
      return {
        ...state,
        drawingActiveIndex: payload,
        ringPickingMode: undefined,
      };
    case SET_DATA_CALIBRATION_DRAWING_VISIBLE:
      return {
        ...state,
        drawings: {
          ...state.drawings,
          [payload.index]: {
            ...state.drawings[payload.index],
            visible: payload.visible,
          },
        },
      };
    case SET_DATA_CALIBRATION_PEAK_THRESHOLD:
      return {
        ...state,
        peakThreshold: payload,
      };
    case SET_DATA_CALIBRATION_RING_PICKING_RING:
      return {
        ...state,
        ringPickingRing: payload,
        drawingMode: undefined,
        drawingActiveIndex: undefined,
      };
    case SET_DATA_CALIBRATION_RING_PICKING_RING_MODE:
      return {
        ...state,
        ringPickingMode: payload,
        drawingMode: undefined,
        drawingActiveIndex: undefined,
      };
    case SET_DATA_CALIBRATION_IMAGE_OPTIONS_COLOR_MAP:
      return {
        ...state,
        imageOptions: {
          ...state.imageOptions,
          colorMap: payload,
        },
      };
    case SET_DATA_CALIBRATION_IMAGE_OPTIONS_LOG:
      return {
        ...state,
        imageOptions: {
          ...state.imageOptions,
          log: payload,
        },
      };
    case SET_DATA_CALIBRATION_IMAGE_OPTIONS:
      return {
        ...state,
        imageOptions: payload,
      };
    case SET_DATA_CALIBRATION_ACTIVE_IMAGE_PATH:
      return {
        ...state,
        activeImagePath: payload,
      };
    case SET_DATA_CALIBRATION_IMAGE_MASK:
      return {
        ...state,
        imageMask: payload,
      };
    case SET_DATA_CALIBRATION_IMAGE_GEOMETRY:
      return {
        ...state,
        imageGeometry: payload,
      };
    case SET_DATA_CALIBRATION_CALIBRATION_FILE:
      return {
        ...state,
        calibrationFile: payload,
      };
    case SET_DATA_CALIBRATION_ANALYSIS:
      return {
        ...state,
        analysis: payload,
      };
    case SET_DATA_CALIBRATION_CUSTOM_DETECTOR:
      return {
        ...state,
        customDetector: payload,
      };
    case SET_DATA_CALIBRATION_MASKING_FILE:
      return {
        ...state,
        maskingFile: payload,
      };
    default:
      return state;
  }
}