import { ProjectModel } from "./../../models/project.model";
import { ADD_PROJECTS_FOR_USER, CREATE_PROJECT, GET_PROJECTS_FOR_USER, SET_FILES_FOR_CURRENT_PROJECT, DELETE_PROJECT, RENAME_PROJECT, GET_INSTANCE_RUNNING_STATUS, GET_INSTRUMENTAL_FILE_WAVELENGTH, GET_METHOD_DATA, GET_REPORT_SCREEN_ANALYSIS_TYPES, SET_REPORT_SCREEN_ANALYSIS_TYPES, GET_REPORT_SCREEN_ADDITIONAL_DATA, SET_REPORT_SCREEN_LE_BAIL_AVAILABLE_CIF_FILES, SET_REPORT_SCREEN_LE_BAIL_ANALYSIS, SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS, SET_REPORT_SCREEN_DISLOCATION_DENSITY_MWH_PARAMS, SET_REPORT_SCREEN_DISLOCATION_DENSITY_ANALYSIS, SET_REPORT_SCREEN, GET_DOWNLOAD, GET_DOWNLOAD_CHECKER, GET_LAMBDA_CACHE, UPLOAD_FILES, GET_PROJECT_FILES, SET_BROWSING_PROJECT, GET_BROWSING_PROJECT, GET_ECS_RUNNING_INSTANCE, GET_PHASES, SET_REPORT_SCREEN_PEAK_FITTING_ANALYSIS, SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS_PARAMETERS, SET_REPORT_SCREEN_DISLOCATION_DENSITY_WH_PARAMS, APPEND_NEW_PROJECT, SET_CURRENT_PROJECT, SET_DELETE_PROJECT, SET_RENAME_PROJECT, GET_PIPELINE_STATUS, START_PIPELINE, STOP_PIPELINE, SET_PIPELINE_STATUS, SAVE_PROJECT_INFO, GET_PIPELINE_FILES, DELETE_PROJECT_FILE, START_ON_DEMAND, SET_ON_DEMAND_STATUS, GET_ON_DEMAND_STATUS, GET_ON_DEMAND_PROGRESS, ADD_ON_DEMAND_TASK, GET_CIF_FILE_ABC_VALUE, SET_REPORT_SCREEN_STRESS_STRAIN_ANALYSIS, SET_REPORT_SCREEN_STRESS_STRAIN_BIN_PARAMETERS, SET_REPORT_SCREEN_STRESS_STRAIN_PARAMETERS } from "./projectActions";
import { all, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import { ProjectFileModel } from "../../models/project.model";
import { SET_COMPUTING_INSTANCE, SET_DOWNLOAD_INSTANCE_ID, SET_FILE_FETCHING_IN_PROGRESS, SET_INLINE_OPERATION_IN_PROGRESS, SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_OPERATION_IN_PROGRESS, SET_OPERATION_IN_PROGRESS_DICT, SET_PIPELINE_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE, SET_PROGRESS_TITLE, SET_RELOAD_FILE_SYSTEM } from "../general/generalActions";
import toast from "react-hot-toast";
import { LeBailModel } from "../../models/analysis.leBail.model";
import { PhaseQuantificationModel } from "../../models/analysis.phaseQuantification.model";
import { DislocationDensityModel } from "../../models/analysis.dislocationDensity.model";
import { GET_DISLOCATION_DENSITY_CALLBACK, SET_DISLOCATION_DENSITY, SET_DISLOCATION_DENSITY_CIF_FILES_ABC, SET_DISLOCATION_DENSITY_HISTOGRAM, SET_DISLOCATION_DENSITY_INSTRUMENTAL_WAVELENGTH } from "../dislocationDensity/dislocationDensityActions";
import { GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK, SET_LE_BAIL, SET_LE_BAIL_CIF_FILES_ABC, SET_LE_BAIL_HISTOGRAM, SET_LE_BAIL_INSTRUMENTAL_WAVELENGTH, SET_LE_BAIL_RANGE_HIGHER, SET_LE_BAIL_RANGE_INITIAL, SET_LE_BAIL_RANGE_LOWER } from "../leBail/leBailActions";
import { selectCurrentProject, selectCurrentProjectFiles, selectOnDemandStatus, selectUserId, selectUserToken } from "./projectSelectors";
import { selectLeBail } from "../leBail/leBailSelectors";
import { selectPhaseQuantification } from "../phaseQuantification/phaseQuantificationSelectors";
import { selectDislocationDensity } from "../dislocationDensity/dislocationDensitySelectors";
import { GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES, GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION, SET_AZIMUTHAL_INTEGRATION, SET_AZIMUTHAL_INTEGRATION_PARTITIONS } from "../azimuthalIntegration/azimuthalIntegrationActions";
import { AzimuthalIntegrationModel } from "../../models/analysis.azimuthalIntegration.model";
import { selectAzimuthalIntegration, selectAzimuthalIntegrationPartitions } from "../azimuthalIntegration/azimuthalIntegrationSelectors";
import { InstrumentalFileCreationModel } from "../../models/analysis.instrumentalFileCreation.model";
import { selectInstrumentalFileCreation } from "../instrumentalFileCreation/instrumentalFileCreationSelectors";
import { GET_INSTRUMENTAL_FILE_CREATION_CALLBACK, SET_INSTRUMENTAL_FILE_CREATION, SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC, SET_INSTRUMENTAL_FILE_CREATION_FILE_WAVELENGTH, SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM, SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER, SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH } from "../instrumentalFileCreation/instrumentalFileCreationActions";
import { getInstrumentalFileWavelength, getMethodData, getInstanceRunningStatus, getReportScreenAnalysisTypes, getReportScreenAdditionalData, getDownloadInstanceRunningStatus, getDownload, getLambdaCache, getECSRunningStatus, getPhases, getUserProjects, createProjectCaller, deleteProjectCaller, projectFilesCaller, renameProjectCaller, getPipelineStatus, startPipeline, stopPipeline, saveProjectInfo, pipelineFiles, deleteProjectFileCaller, getOnDemandStatus, startOnDemand, onDemandAddTask, getCifFileABCValue } from "./projectHelpers";
import { GET_DATA_CALIBRATION_CALLBACK, SET_DATA_CALIBRATION } from "../dataCalibration/dataCalibrationActions";
import { DataCalibrationModel } from "../../models/analysis.dataCalibration.model";
import { selectDataCalibration } from "../dataCalibration/dataCalibrationSelectors";
import { defaultReportScreen } from "./projectReducer";
import { RietveldModel } from "../../models/analysis.rietveld.model";
import { selectRietveld } from "../rietveld/rietveldSelectors";
import { GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK, SET_RIETVELD, SET_RIETVELD_CIF_FILES_ABC, SET_RIETVELD_HISTOGRAM, SET_RIETVELD_INSTRUMENTAL_WAVELENGTH } from "../rietveld/rietveldActions";
import { downloadZipGroup, fetchData, waitFiveSeconds } from "../../helpers/redux";
import { selectGeneral, selectProgressMessage, selectReloadFileSystem } from "../general/generalSelectors";
import { selectPeakFitting } from "../peakFitting/peakFittingSelectors";
import { PeakFittingModel } from "../../models/analysis.peakFitting.model";
import { GET_PEAK_FITTING_CALLBACK, SET_PEAK_FITTING, SET_PEAK_FITTING_CIF_FILES_ABC, SET_PEAK_FITTING_HISTOGRAM, SET_PEAK_FITTING_INSTRUMENTAL_WAVELENGTH } from "../peakFitting/peakFittingActions";
import { GET_PHASE_QUANTIFICATION_CALLBACK_FITTED, GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS, SET_PHASE_QUANTIFICATION, SET_PHASE_QUANTIFICATION_CIF_FILES_ABC, SET_PHASE_QUANTIFICATION_DATA_FILES, SET_PHASE_QUANTIFICATION_HISTOGRAM, SET_PHASE_QUANTIFICATION_INSTRUMENTAL_WAVELENGTH } from "../phaseQuantification/phaseQuantificationActions";
import { parseJwt } from "../../helpers/general";
import { uploadFiles } from "./upload";
import { GET_STRESS_STRAIN_CALLBACK, GET_STRESS_STRAIN_CALLBACK_FITTING, SET_STRESS_STRAIN, SET_STRESS_STRAIN_CIF_FILES_ABC, SET_STRESS_STRAIN_DATA_ACTIVE_FILES, SET_STRESS_STRAIN_DATA_FILES, SET_STRESS_STRAIN_HISTOGRAM, SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH } from "../stressStrain/stressStrainActions";
import { selectStressStrain } from "../stressStrain/stressStrainSelectors";
import { StressStrainModel } from "../../models/analysis.stressStrain.model";
import { remapObject, remapObjectParams } from "../stressStrain/stressStrainHelpers";


function* createProject(action: any) {
  yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });

  const token = yield select(selectUserToken);
  const projectName = action.payload.projectName;

  const decodedToken: any = parseJwt(token);
  const userId = decodedToken['custom:userId'];

  try {
    const response = yield createProjectCaller(
      token,
      userId,
      projectName,
    );

    yield put({ type: APPEND_NEW_PROJECT, payload: response.data });
    yield put({ type: SET_CURRENT_PROJECT, payload: response.data });
    toast.success("Project Created")
  } catch (e) {
    toast.error("Failed to create project");
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}


function* renameProject(action: any) {
  yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });

  const token = yield select(selectUserToken);
  const projectName = action.payload.projectName;
  const projectId = action.payload.projectId;

  const decodedToken: any = parseJwt(token);
  const userId = decodedToken['custom:userId'];

  try {
    yield renameProjectCaller(token, projectId, userId, projectName);

    yield put({ type: SET_RENAME_PROJECT, payload: { projectId, projectName } });
    toast.success("Project Created")
  } catch (e) {
    toast.error("Failed to create project");
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}


function* deleteProject(action: any) {
  yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });

  const userToken = yield select(selectUserToken);
  const decodedToken: any = parseJwt(userToken);
  const userId = decodedToken['custom:userId'];

  try {
    yield deleteProjectCaller(userToken, userId, action.payload.projectId);

    yield put({ type: SET_DELETE_PROJECT, payload: action.payload.projectId });
    toast.success("Project deleted successfully");
  } catch (e) {
    toast.error("Project delete failed");
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* getProjectsForUser() {
  try {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });
    let token = yield select(selectUserToken);

    const decodedToken: any = parseJwt(token);
    const projectsRes = yield getUserProjects(token, decodedToken['custom:userId']);
    yield put({ type: ADD_PROJECTS_FOR_USER, payload: projectsRes.data });

  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_instrumental_file_wavelength(payload) {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const leBail: LeBailModel = yield select(selectLeBail);
    const phaseQuantification: PhaseQuantificationModel = yield select(selectPhaseQuantification);
    const dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
    const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation)
    const rietveld: RietveldModel = yield select(selectRietveld)
    const peakFitting: PeakFittingModel = yield select(selectPeakFitting)
    const stressStrain: StressStrainModel = yield select(selectStressStrain)

    let instrumentalFile = undefined
    let instrumentalTemplateFile = undefined

    switch (payload.payload.type) {
      case "leBail":
        instrumentalFile = leBail.instrumentalFile
        instrumentalTemplateFile = leBail.instrumentalTemplateFile
        break;
      case "phaseQuantification":
        instrumentalFile = phaseQuantification.instrumentalFile
        instrumentalTemplateFile = phaseQuantification.templateInstrumentalFile
        break;
      case "dislocationDensity":
        instrumentalFile = dislocationDensity.instrumentalFile
        instrumentalTemplateFile = dislocationDensity.templateInstrumentalFile;
        break;
      case "rietveld":
        instrumentalFile = rietveld.instrumentalFile;
        instrumentalTemplateFile = rietveld.instrumentalTemplateFile;
        break
      case "peakFitting":
        instrumentalFile = peakFitting.instrumentalFile;
        instrumentalTemplateFile = peakFitting.templateInstrumentalFile;
        break
      case "instrumentalFileCreation":
        instrumentalFile = "";
        instrumentalTemplateFile = instrumentalFileCreation.instrumentalTemplateFile;
        break
      case "stressStrain":
        instrumentalFile = stressStrain.instrumentalFile;
        instrumentalTemplateFile = stressStrain.templateInstrumentalFile
        break
      default:
        break;
    };

    const res = yield getInstrumentalFileWavelength(
      token,
      currentProject.userId,
      currentProject.projectId,
      instrumentalTemplateFile,
      instrumentalFile
    )

    if (res.data.wavelength) {
      switch (payload.payload.type) {
        case "leBail":
          yield put({ type: SET_LE_BAIL_INSTRUMENTAL_WAVELENGTH, payload: res.data.wavelength })
          break;
        case "phaseQuantification":
          yield put({ type: SET_PHASE_QUANTIFICATION_INSTRUMENTAL_WAVELENGTH, payload: res.data.wavelength })
          break;
        case "dislocationDensity":
          yield put({ type: SET_DISLOCATION_DENSITY_INSTRUMENTAL_WAVELENGTH, payload: res.data.wavelength })
          break;
        case "instrumentalFileCreation":
          yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_FILE_WAVELENGTH, payload: res.data.wavelength })
          if (!instrumentalFileCreation.wavelength) {
            yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH, payload: res.data.wavelength})
          }
          break
        case "rietveld":
          yield put({ type: SET_RIETVELD_INSTRUMENTAL_WAVELENGTH, payload: res.data.wavelength })
          break
        case "peakFitting":
          yield put({ type: SET_PEAK_FITTING_INSTRUMENTAL_WAVELENGTH, payload: res.data.wavelength })
          break
        case "stressStrain":
          yield put({ type: SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH, payload: res.data.wavelength })
          break
        default:
          break;
      };
    }
  } catch (error) {
    console.error(error)
    toast.error("Failed to load Instrumental File Wavelength")
  }
}

function* get_cif_file_abc_value(payload) {
  try {
    const token = yield select(selectUserToken);
    const res = yield getCifFileABCValue(
      token,
      payload.payload.cifFile
    )

    switch (payload.payload.type) {
      case "leBail":
        const leBail: LeBailModel = yield select(selectLeBail)
        const leBailABC = {
          ...leBail.cifFilesABC,
          [payload.payload.cifFile]: res.data
        }

        yield put({ type: SET_LE_BAIL_CIF_FILES_ABC, payload: leBailABC })
        break;
      case "phaseQuantification":
        const phaseQuantification: PhaseQuantificationModel = yield select(selectPhaseQuantification)
        const phaseQuantificationABC = {
          ...phaseQuantification.cifFilesABC,
          [payload.payload.cifFile]: res.data
        }
        yield put({ type: SET_PHASE_QUANTIFICATION_CIF_FILES_ABC, payload: phaseQuantificationABC })
        break;
      case "dislocationDensity":
        const dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity)
        const dislocationDensityABC = {
          ...dislocationDensity.cifFilesABC,
          [payload.payload.cifFile]: res.data
        }
        yield put({ type: SET_DISLOCATION_DENSITY_CIF_FILES_ABC, payload: dislocationDensityABC })
        break;
      case "instrumentalFileCreation":
        const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation)
        const instrumentalFileCreationABC = {
          ...instrumentalFileCreation.cifFilesABC,
          [payload.payload.cifFile]: res.data
        }
        yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC, payload: instrumentalFileCreationABC })
        break
      case "rietveld":
        const rietveld: RietveldModel = yield select(selectRietveld)
        const rietveldABC = {
          ...rietveld.cifFilesABC,
          [payload.payload.cifFile]: res.data
        }
        yield put({ type: SET_RIETVELD_CIF_FILES_ABC, payload: rietveldABC })
        break
      case "peakFitting":
        const peakFitting: PeakFittingModel = yield select(selectPeakFitting)
        const peakFittingABC = {
          ...peakFitting.cifFilesABC,
          [payload.payload.cifFile]: res.data
        }
        yield put({ type: SET_PEAK_FITTING_CIF_FILES_ABC, payload: peakFittingABC })
        break;
      case "stressStrain":
        const stressStrain: StressStrainModel = yield select(selectStressStrain)
        const stressStrainABC = {
          ...stressStrain.cifFilesABC,
          [payload.payload.cifFile]: res.data
        }
        yield put({ type: SET_STRESS_STRAIN_CIF_FILES_ABC, payload: stressStrainABC })
        break;
      default:
        break;
    }
  } catch (error) {
    console.error(error)
  }
}

function* get_phases(payload) {
  let request = {}
  let token = ""
  try {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const phaseQuantification: PhaseQuantificationModel = yield select(selectPhaseQuantification);
    const dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
    const peakFitting: PeakFittingModel = yield select(selectPeakFitting);
    const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation)
    let leBail: LeBailModel = yield select(selectLeBail);

    request = {
      userId: currentProject.userId,
      projectId: currentProject.projectId,
    }

    switch (payload.payload.type) {
      case "peakFitting":
        request["dataFiles"] = peakFitting.dataViewFile
        request["instrumentalFile"] = peakFitting.instrumentalFile
        request["templateInstrumentalFile"] = peakFitting.templateInstrumentalFile
        request["wavelength"] = peakFitting.wavelength
        request["cifFiles"] = peakFitting.cifFiles
        request["templateCifFiles"] = peakFitting.templateCifFiles
        request["cifFilesABC"] = peakFitting.cifFilesABC
        break;
      case "leBail":
        request["dataFiles"] = leBail.dataViewFile
        request["instrumentalFile"] = leBail.instrumentalFile
        request["templateInstrumentalFile"] = leBail.instrumentalTemplateFile
        request["wavelength"] = leBail.wavelength
        request["cifFiles"] = leBail.cifFiles
        request["templateCifFiles"] = leBail.cifTemplateFiles
        request["cifFilesABC"] = leBail.cifFilesABC
        break;
      case "phaseQuantification":
        request["dataFiles"] = phaseQuantification.dataViewFile
        request["instrumentalFile"] = phaseQuantification.instrumentalFile
        request["templateInstrumentalFile"] = phaseQuantification.templateInstrumentalFile
        request["wavelength"] = phaseQuantification.wavelength
        request["cifFiles"] = phaseQuantification.cifFiles
        request["templateCifFiles"] = phaseQuantification.templateCifFiles
        request["cifFilesABC"] = phaseQuantification.cifFilesABC
        break;
      case "dislocationDensity":
        request["dataFiles"] = dislocationDensity.dataViewFile
        request["instrumentalFile"] = dislocationDensity.instrumentalFile
        request["templateInstrumentalFile"] = dislocationDensity.templateInstrumentalFile
        request["wavelength"] = dislocationDensity.wavelength
        request["cifFiles"] = dislocationDensity.cifFiles ? [dislocationDensity.cifFiles] : []
        request["templateCifFiles"] = dislocationDensity.templateCifFiles ? [dislocationDensity.templateCifFiles] : []
        request["cifFilesABC"] = dislocationDensity.cifFilesABC
        break;
      case "pipelinePhaseQuantification":
        request["dataFiles"] = phaseQuantification.dataViewFile
        request["instrumentalFile"] = phaseQuantification.instrumentalFile
        request["templateInstrumentalFile"] = phaseQuantification.templateInstrumentalFile
        request["wavelength"] = phaseQuantification.wavelength
        request["cifFiles"] = phaseQuantification.cifFiles
        request["templateCifFiles"] = phaseQuantification.templateCifFiles
        request["cifFilesABC"] = phaseQuantification.cifFilesABC
        break;
      case "instrumentalFileCreation":
        request["dataFiles"] = instrumentalFileCreation.dataFiles
        request["instrumentalFile"] = ""
        request["templateInstrumentalFile"] = instrumentalFileCreation.instrumentalTemplateFile
        request["wavelength"] = instrumentalFileCreation.wavelength
        request["cifFiles"] = instrumentalFileCreation.cifFile ? [instrumentalFileCreation.cifFile] : []
        request["templateCifFiles"] = instrumentalFileCreation.cifTemplateFile ? [instrumentalFileCreation.cifTemplateFile] : []
        request["cifFilesABC"] = instrumentalFileCreation.cifFilesABC
        break;
      case "rietveld":
        const rietveld: RietveldModel = yield select(selectRietveld);
        request["dataFiles"] = rietveld.dataFiles
        request["instrumentalFile"] = rietveld.instrumentalFile
        request["templateInstrumentalFile"] = rietveld.instrumentalTemplateFile
        request["wavelength"] = rietveld.wavelength
        request["cifFiles"] = rietveld.cifFiles
        request["templateCifFiles"] = rietveld.cifTemplateFiles
        request["cifFilesABC"] = rietveld.cifFilesABC
        break;
      case "stressStrain":
        const stressStrain: StressStrainModel = yield select(selectStressStrain);
        request["dataFiles"] = stressStrain.dataViewFile
        request["instrumentalFile"] = stressStrain.instrumentalFile
        request["templateInstrumentalFile"] = stressStrain.templateInstrumentalFile
        request["wavelength"] = stressStrain.wavelength
        request["cifFiles"] = stressStrain.cifFiles
        request["templateCifFiles"] = stressStrain.templateCifFiles
        request["cifFilesABC"] = stressStrain.cifFilesABC
        break;
      default:
        break;
    };

    if (!request["dataFiles"] || (!request["instrumentalFile"] && !request["templateInstrumentalFile"]) || (!request["cifFiles"] && !request["templateCifFiles"])) {
      throw new Error("Invalid data")
    }

    const res = yield getPhases(
      token,
      request
    )

    switch (payload.payload.type) {
      case "peakFitting":
        yield put({ type: SET_PEAK_FITTING_HISTOGRAM, payload: res.data })
        break
      case "leBail":
        let leBail: LeBailModel = yield select(selectLeBail);
        yield put({ type: SET_LE_BAIL_HISTOGRAM, payload: res.data })
        if (!leBail.rangeLower) yield put({ type: SET_LE_BAIL_RANGE_LOWER, payload: Math.min(...res.data.data.x) });
        if (!leBail.rangeHigher) yield put({ type: SET_LE_BAIL_RANGE_HIGHER, payload: Math.max(...res.data.data.x) });
        yield put({ type: SET_LE_BAIL_RANGE_INITIAL, payload: [Math.min(...res.data.data.x), Math.max(...res.data.data.x)] });
        break
      case "phaseQuantification":
        yield put({ type: SET_PHASE_QUANTIFICATION_HISTOGRAM, payload: res.data })
        break
      case "pipelinePhaseQuantification":
        yield put({ type: SET_PHASE_QUANTIFICATION_HISTOGRAM, payload: res.data })
        break
      case "dislocationDensity":
        yield put({ type: SET_DISLOCATION_DENSITY_HISTOGRAM, payload: res.data })
        break;
      case "instrumentalFileCreation":
        let instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation)
        if (!instrumentalFileCreation.refinementRange.lower) {
          yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, payload: Math.min(...res.data.data.x) });
        }
        if (!instrumentalFileCreation.refinementRange.upper) {
          yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER, payload: Math.max(...res.data.data.x) });
        }
        yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL, payload: [Math.min(...res.data.data.x), Math.max(...res.data.data.x)] });
        yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM, payload: res.data })
        break;
      case "rietveld":
        yield put({ type: SET_RIETVELD_HISTOGRAM, payload: res.data })
        break;
      case "stressStrain":
        yield put({ type: SET_STRESS_STRAIN_HISTOGRAM, payload: res.data })
        break;
      default:
        break;
    };
  } catch (error) {
    try {
      if (payload.payload.type === "pipelinePhaseQuantification") {
        const res2 = yield getPhases(
          token,
          request
        )

        yield put({ type: SET_PHASE_QUANTIFICATION_HISTOGRAM, payload: res2.data })
      }
    } catch (error) {
      console.error(error)
      toast.error("Failed to load Phases")
    }
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_method_data(payload) {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const currentProjectFiles = yield select(selectCurrentProjectFiles);
    const reloadFileSystem = yield select(selectReloadFileSystem);

    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });

    const res = yield getMethodData(
      token,
      currentProject.userId,
      currentProject.projectId,
      currentProjectFiles.map((x) => x.fileRef),
      payload.payload.type
    )

    const projectFiles = yield select(selectCurrentProjectFiles);

    let dataFiles = []

    if (res.data.dataFiles) {
      projectFiles.forEach(element => {
        if (res.data.dataFiles.includes(element.fileRef)) {
          dataFiles.push(element)
        }
      });
    }

    yield put({type: SET_INSTANCE_ID, payload: res.data.runningInstances ? res.data.runningInstances : ""})

    switch (payload.payload.type) {
      case "leBail":
        const leBail: LeBailModel = yield select(selectLeBail);
        const obj = {
          ...leBail,
          cifFiles: res.data.cifFiles ? res.data.cifFiles : leBail.cifFiles,
          cifTemplateFiles: res.data.cifTemplateFiles ? res.data.cifTemplateFiles : leBail.cifTemplateFiles,
          dataFiles: res.data.dataFiles ? res.data.dataFiles : leBail.dataFiles,
          dataActiveFiles: res.data.dataActiveFiles ? res.data.dataActiveFiles : leBail.dataActiveFiles,
          instrumentalFile: res.data.instrumentalFile ? res.data.instrumentalFile : leBail.instrumentalFile,
          instrumentalTemplateFile: res.data.instrumentalTemplateFile ? res.data.instrumentalTemplateFile : leBail.instrumentalTemplateFile,
          rangeHigher: res.data.rangeHigher ? res.data.rangeHigher : leBail.rangeHigher,
          rangeLower: res.data.rangeLower ? res.data.rangeLower : leBail.rangeLower,
          wavelength: res.data.wavelength ? res.data.wavelength : leBail.wavelength,
          instrumentalFileWaveLength: leBail.instrumentalFileWaveLength ? leBail.instrumentalFileWaveLength : leBail.wavelength,
        }

        yield put({ type: SET_LE_BAIL, payload: obj })
        break;

      case "phaseQuantification":
        const phaseQuantification: PhaseQuantificationModel = yield select(selectPhaseQuantification);
        const obj2 = {
          ...phaseQuantification,
          dataActiveFiles: res.data.dataActiveFiles && res.data.dataActiveFiles.length ? res.data.dataActiveFiles : phaseQuantification.dataActiveFiles,
          dataFiles: res.data.dataFiles && res.data.dataFiles.length ? res.data.dataFiles : phaseQuantification.dataFiles,
          partitions: res.data.partitions.length ? res.data.partitions : phaseQuantification.partitions,
          movingPeaks: res.data.movingPeaks ? res.data.movingPeaks : phaseQuantification.movingPeaks,
          templateInstrumentalFile: res.data.instrumentalTemplateFile ? res.data.instrumentalTemplateFile : phaseQuantification.templateInstrumentalFile,
          instrumentalFile: res.data.instrumentalFile ? res.data.instrumentalFile : phaseQuantification.instrumentalFile,
          templateCifFiles: res.data.cifTemplateFiles ? res.data.cifTemplateFiles : phaseQuantification.templateCifFiles,
          cifFiles: res.data.cifFiles ? res.data.cifFiles : phaseQuantification.cifFiles,
          bFactor: res.data.bFactor ? res.data.bFactor : phaseQuantification.bFactor,
        } as PhaseQuantificationModel

        yield put({ type: SET_PHASE_QUANTIFICATION, payload: obj2 })
        break;

        case "dislocationDensity":
          const dislocationDensity: DislocationDensityModel = yield select(selectDislocationDensity);
          const obj3 = {
            ...dislocationDensity,
            dataActiveFiles: res.data.dataActiveFiles ? res.data.dataActiveFiles : dislocationDensity.dataActiveFiles,
            dataFiles: res.data.dataFiles ? res.data.dataFiles : dislocationDensity.dataFiles,
            partitions: res.data.partitions.length ? res.data.partitions : dislocationDensity.partitions,
            movingPeaks: res.data.movingPeaks ? res.data.movingPeaks : dislocationDensity.movingPeaks,
            templateInstrumentalFile: res.data.instrumentalTemplateFile ? res.data.instrumentalTemplateFile : dislocationDensity.templateInstrumentalFile,
            instrumentalFile: res.data.instrumentalFile ? res.data.instrumentalFile : dislocationDensity.instrumentalFile,
            templateCifFiles: res.data.cifTemplateFiles?.length ? res.data.cifTemplateFiles[0] : dislocationDensity.templateCifFiles,
            cifFiles: res.data.cifFiles?.length ? res.data.cifFiles[0] : dislocationDensity.cifFiles,
            ch00: res.data.ch00 ? res.data.ch00 : dislocationDensity.ch00,
            tth1: res.data.tth1 ? res.data.tth1 : dislocationDensity.tth1,
            tth2: res.data.tth2 ? res.data.tth2 : dislocationDensity.tth2,
            ln: res.data.ln ? res.data.ln : dislocationDensity.ln,
            l0: res.data.l0 ? res.data.l0 : dislocationDensity.l0,
            rho: res.data.rho ? res.data.rho : dislocationDensity.rho
          } as DislocationDensityModel

          yield put({ type: SET_DISLOCATION_DENSITY, payload: obj3 })
          break;

      case "azimuthalIntegration":
        const azimuthalIntegration: AzimuthalIntegrationModel = yield select(selectAzimuthalIntegration);
        const obj4 = {
          ...azimuthalIntegration,
          azimuthalBinning: res.data.azimuthalBinning ? res.data.azimuthalBinning : azimuthalIntegration.azimuthalBinning,
          azimuthalRange: res.data.azimuthalRange ? res.data.azimuthalRange : azimuthalIntegration.azimuthalRange,
          numberOfAzimuthalBins: res.data.numberOfAzimuthalBins ? res.data.numberOfAzimuthalBins : azimuthalIntegration.numberOfAzimuthalBins,
          numberOfPoints: res.data.numberOfPoints ? res.data.numberOfPoints : azimuthalIntegration.numberOfPoints,
          partitions: res.data.partitions && res.data.partitions.length ? res.data.partitions : azimuthalIntegration.partitions,
          range: res.data.range ? res.data.range : azimuthalIntegration.range,
          xAxisUnit: res.data.xAxisUnit ? res.data.xAxisUnit : azimuthalIntegration.xAxisUnit,
          numberOfPartitions: res.data.partitions.length ? res.data.partitions.length : azimuthalIntegration.numberOfPartitions,
          integration: res.data.integration ? res.data.integration : azimuthalIntegration.integration,
        } as AzimuthalIntegrationModel

        yield put({ type: SET_AZIMUTHAL_INTEGRATION, payload: obj4 })
        break;

      case "instrumentalFileCreation":
        const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation);
        const obj5 = {
          ...instrumentalFileCreation,
          cifTemplateFile: res.data.cifTemplateFile ? res.data.cifTemplateFile : instrumentalFileCreation.cifTemplateFile,
          cifFile: res.data.cifFile ? res.data.cifFile : instrumentalFileCreation.cifFile,
          dataFiles: res.data.dataFiles ? res.data.dataFiles : instrumentalFileCreation.dataFiles,
          dataActiveFiles: res.data.dataActiveFiles ? res.data.dataActiveFiles : instrumentalFileCreation.dataActiveFiles,
          instrumentalTemplateFile: res.data.instrumentalTemplateFile ? res.data.instrumentalTemplateFile : instrumentalFileCreation.instrumentalTemplateFile,
          wavelength: res.data.wavelength ? res.data.wavelength : instrumentalFileCreation.wavelength,
          crystalliteSize: res.data.crystalliteSize ? res.data.crystalliteSize : instrumentalFileCreation.crystalliteSize,
          microstrain: res.data.microstrain ? res.data.microstrain : instrumentalFileCreation.microstrain,
          refinementRange: res.data.refinementRange ? res.data.refinementRange : instrumentalFileCreation.refinementRange,
        } as InstrumentalFileCreationModel

        yield put({ type: SET_INSTRUMENTAL_FILE_CREATION, payload: obj5 })
        break;

      case "dataCalibration":
        const dataCalibration: DataCalibrationModel = yield select(selectDataCalibration)
        const obj6 = {
          ...dataCalibration,
          calibrantType: res.data.calibrantType ? res.data.calibrantType : dataCalibration.calibrantType,
          ringPicking: res.data.coordinates ? res.data.coordinates : dataCalibration.ringPicking,
          detectorType: res.data.detectorName ? res.data.detectorName : dataCalibration.detectorType,
          drawings: res.data.drawings ? res.data.drawings : dataCalibration.drawings,
          mask: {
            above: res.data.maskingAbove ? res.data.maskingAbove : dataCalibration.mask.above,
            below: res.data.maskingBelow ? res.data.maskingBelow : dataCalibration.mask.below,
          },
          numberOfDataPoints: res.data.numberOfDataPoints ? res.data.numberOfDataPoints : dataCalibration.numberOfDataPoints,
          polarizationFactor: res.data.polarizationFactor ? res.data.polarizationFactor : dataCalibration.polarizationFactor,
          radialRange: {
            lower: res.data.radialRangeLower ? res.data.radialRangeLower : dataCalibration.radialRange.lower,
            upper: res.data.radialRangeUpper ? res.data.radialRangeUpper : dataCalibration.radialRange.upper,
          },
          unit: res.data.unit ? res.data.unit : dataCalibration.unit,
          wavelength: res.data.wavelength ? res.data.wavelength : dataCalibration.wavelength,
          dataFile: res.data.dataFile ? res.data.dataFile : dataCalibration.dataFile,
          customDetector: res.data.customDetector ? res.data.customDetector : dataCalibration.customDetector,
        } as DataCalibrationModel

        yield put({ type: SET_DATA_CALIBRATION, payload: obj6 })
        break

      case "rietveld":
        const rietveld: RietveldModel = yield select(selectRietveld);
        const obj7 = {
          ...rietveld,
          cifFiles: res.data.cifFiles ? res.data.cifFiles : rietveld.cifFiles,
          cifTemplateFiles: res.data.cifTemplateFiles ? res.data.cifTemplateFiles : rietveld.cifTemplateFiles,
          dataFiles: res.data.dataFiles ? res.data.dataFiles : rietveld.dataFiles,
          dataActiveFiles: res.data.dataActiveFiles ? res.data.dataActiveFiles : rietveld.dataActiveFiles,
          instrumentalFile: res.data.instrumentalFile ? res.data.instrumentalFile : rietveld.instrumentalFile,
          instrumentalTemplateFile: res.data.instrumentalTemplateFile ? res.data.instrumentalTemplateFile : rietveld.instrumentalTemplateFile,
          rangeHigher: res.data.rangeHigher ? res.data.rangeHigher : rietveld.rangeHigher,
          rangeLower: res.data.rangeLower ? res.data.rangeLower : rietveld.rangeLower,
          wavelength: res.data.wavelength ? res.data.wavelength : rietveld.wavelength,
          mustrains: res.data.mustrains ? res.data.mustrains : rietveld.mustrains,
          crystalliteSize: res.data.crystalliteSize ? res.data.crystalliteSize : rietveld.crystalliteSize,
        }

        yield put({ type: SET_RIETVELD, payload: obj7 })
        break;
      
      case "peakFitting":
        const peakFitting: PeakFittingModel = yield select(selectPeakFitting);
        const obj8: PeakFittingModel = {
          ...peakFitting,
          cifFiles: res.data.cifFiles ? res.data.cifFiles : peakFitting.cifFiles,
          dataFiles: res.data.dataFiles ? res.data.dataFiles : peakFitting.dataFiles,
          dataActiveFiles: res.data.dataActiveFiles ? res.data.dataActiveFiles : peakFitting.dataActiveFiles,
          instrumentalFile: res.data.instrumentalFile ? res.data.instrumentalFile : peakFitting.instrumentalFile,
          templateInstrumentalFile: res.data.templateInstrumentalFile ? res.data.templateInstrumentalFile : peakFitting.templateInstrumentalFile,
          templateCifFiles: res.data.templateCifFiles ? res.data.templateCifFiles : peakFitting.templateCifFiles,
          movingPeaks: res.data.movingPeaks ? res.data.movingPeaks : peakFitting.movingPeaks,
          partitions: res.data.partitions.length ? res.data.partitions : peakFitting.partitions,
        }

        yield put({ type: SET_PEAK_FITTING, payload: obj8 })
        break;
      
      case "stressStrain":
        const stressStrain: StressStrainModel = yield select(selectStressStrain);
        const obj9: StressStrainModel = {
          ...stressStrain,
          cifFiles: res.data.cifFiles ? res.data.cifFiles : stressStrain.cifFiles,
          dataFiles: res.data.dataFiles ? res.data.dataFiles : stressStrain.dataFiles,
          dataActiveFiles: res.data.dataActiveFiles ? res.data.dataActiveFiles : stressStrain.dataActiveFiles,
          instrumentalFile: res.data.instrumentalFile ? res.data.instrumentalFile : stressStrain.instrumentalFile,
          templateInstrumentalFile: res.data.templateInstrumentalFile ? res.data.templateInstrumentalFile : stressStrain.templateInstrumentalFile,
          templateCifFiles: res.data.templateCifFiles ? res.data.templateCifFiles : stressStrain.templateCifFiles,
          movingPeaks: res.data.movingPeaks ? res.data.movingPeaks : stressStrain.movingPeaks,
          partitions: res.data.partitions.length ? res.data.partitions : stressStrain.partitions,
          rotation: res.data.rotation ? res.data.rotation : stressStrain.rotation,
          stressPeaks: res.data.stressPeaks ? res.data.stressPeaks : stressStrain.stressPeaks,
          planeStressCondition: res.data.planeStressCondition ? res.data.planeStressCondition : stressStrain.planeStressCondition,
        }

        yield put({ type: SET_STRESS_STRAIN, payload: obj9 })
        break;
      
      default:
        break;
      }

    yield put({ type: SET_RELOAD_FILE_SYSTEM, payload: !reloadFileSystem })
  } catch (error) {
    console.error(error)
    toast.error("Failed to load Init Data")
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_on_demand_progress(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  const statusMessage = yield select(selectProgressMessage);

  try {
    const res = yield getInstanceRunningStatus(
      token,
      currentProject.projectId,
      payload.payload.analysisType
    )

    let progress = res.data.item.progress ? res.data.item.progress : 0
    yield put({ type: SET_PROGRESS, payload: progress })

    if (statusMessage !== "Loading Task Details..." || progress !== 0) {
      yield put({ type: SET_PROGRESS_MESSAGE, payload: res.data.item["progressMessage"] ? res.data.item["progressMessage"] : "Starting Instance" })
    }

    if (progress === 100) {
      yield put({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES })
    }

  } catch(error) {
    console.error(error)
  }
}

function* get_instance_running_status(payload) {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getInstanceRunningStatus(
      token,
      currentProject.projectId,
      payload.payload.instanceId
    )

    if (res.data.error) {
      switch (payload.payload.projectType) {
        case "dataCalibration":
          toast.error("The operation failed, try adding more rings")
          break;
        default:
          toast.error("The operation failed")
          break;
      }
      yield put({ type: SET_INSTANCE_ID, payload: "" })
      yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
      yield put({ type: SET_PROGRESS, payload: 0 });
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "" });

      console.error(res.data.item.errorMessage)
    } else if (res.data.item && res.data.item.hasOwnProperty("running") && !res.data.item.running) {
      yield put({ type: SET_PROGRESS, payload: 100 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Finishing" })
      switch (payload.payload.projectType) {
        case "leBail":
          yield put({ type: GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK })
          break;
        case "phaseQuantification":
          yield put({ type: GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS })
          yield put({ type: GET_PHASE_QUANTIFICATION_CALLBACK_FITTED })
          break;
        case "dislocationDensity":
          yield put({ type: GET_DISLOCATION_DENSITY_CALLBACK })
          break;
        case 'azimuthalIntegration':
          yield put({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION })
          yield put({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES })
          break;
        case "azimuthalIntegrationImage":
          yield put({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES })
          break;
        case "instrumentalFileCreation":
          yield put({ type: GET_INSTRUMENTAL_FILE_CREATION_CALLBACK })
          break
        case "dataCalibration":
          yield put({ type: GET_DATA_CALIBRATION_CALLBACK })
          break
        case "rietveld":
          yield put({ type: GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK })
          break
        case "peakFitting":
          yield put({ type: GET_PEAK_FITTING_CALLBACK })
          break
        case "stressStrain":
          yield put({ type: GET_STRESS_STRAIN_CALLBACK })
          yield put({ type: GET_STRESS_STRAIN_CALLBACK_FITTING })
          break
        default:
          break;
      }
    } else if (res.data.item && res.data.item.hasOwnProperty("running") && res.data.item.running) {
      let progress = res.data.item.progress ? res.data.item.progress : 0
      if (progress === 100) {
        progress = 99
      }
      yield put({ type: SET_PROGRESS, payload: progress })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: res.data.item["progressMessage"] ? res.data.item["progressMessage"] : "Starting Instance" })
    }
  } catch(error) {
    console.error(error)
  }
}

function* get_ecs_running_instance(payload) {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const general = yield select(selectGeneral);

    let orderArgs = {}
    let order = ""

    if (payload.payload?.order) {
      orderArgs = payload.payload.orderArgs
      order = payload.payload.order

      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Loading Task Details..." });
      yield put({ type: SET_PROGRESS_TITLE, payload: "Computing Integration"});
    } else {
      order = "keepAlive"
    }

    const res = yield getECSRunningStatus(
      token,
      currentProject.projectId,
      payload.payload.projectType,
      currentProject.userId,
      order,
      orderArgs
    )

    if (res.data.progress?.error) {
      yield put({ type: SET_INSTANCE_ID, payload: "" })
      yield put({ type: SET_PROGRESS, payload: 0 });
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "" });

      if (general.computingRunning) {
        toast.error("The operation failed")
      }

      yield put({ type: SET_COMPUTING_INSTANCE, payload: false });
    
    } else if (res.data.progress.item && res.data.progress.item?.running) {
      yield put({ type: SET_COMPUTING_INSTANCE, payload: true });
      yield put({ type: SET_PROGRESS, payload: res.data.progress.item.progress ? res.data.progress.item.progress : 0 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: res.data.progress.item.progressMessage ? res.data.progress.item.progressMessage : "Starting Computing Instance..." })
    
    } else if (res.data.progress.item && !res.data.progress.item?.running && general.computingRunning) {
      yield put({ type: SET_PROGRESS, payload: 100 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Finishing" })
      yield put({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION })
      yield put({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES})
      yield put({ type: SET_COMPUTING_INSTANCE, payload: false });
  
    } else if (res.data.status && res.data.status === "running") {
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Waiting for tasks..." });
      yield put({ type: SET_PROGRESS_TITLE, payload: "Instance Running"});

    } else if (res.data.status && res.data.status === "starting") {
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance..." });
      yield put({ type: SET_PROGRESS_TITLE, payload: "Instance Starting"});
      yield put({ type: SET_COMPUTING_INSTANCE, payload: false });
    } 

  } catch(error) {
    console.error(error)
  }
}

function* get_report_screen_analysis_types() {
  try {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });
    yield put({ type: SET_REPORT_SCREEN, payload: defaultReportScreen })
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getReportScreenAnalysisTypes(
      token,
      currentProject.projectId,
    )

    if (res.data.includes("stressStrainParameters")) {
      res.data = res.data.filter((x) => x !== "stressStrainParameters" && x !== "stressStrainBinParameters")
      res.data.push("stressStrain")
    }

    yield put({ type: SET_REPORT_SCREEN_ANALYSIS_TYPES, payload: res.data })

  } catch (error) {
    console.error(error)
    toast.error("Failed to get report screen analysis types")
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_report_screen_additional_data(payload) {
  try {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const projectFiles: ProjectFileModel[] = yield select(selectCurrentProjectFiles);

    const res = yield getReportScreenAdditionalData(
      token,
      currentProject.projectId,
      payload.payload.type,
      projectFiles.map((x) => x.fileRef),
    )

    switch (payload.payload.type) {
      case "leBail":
        yield put({ type: SET_REPORT_SCREEN_LE_BAIL_AVAILABLE_CIF_FILES, payload: res.data.cifNames })
        let analysis = {};

        for (let fileRef in res.data.data) {
          if (!analysis[fileRef]) {
            analysis[fileRef] = {};
          }

          for (let cif of res.data.data[fileRef]) {
            analysis[fileRef][cif.cifName] = {
              lengthA: parseFloat(cif.lengthA),
              lengthB: parseFloat(cif.lengthB),
              lengthC: parseFloat(cif.lengthC),
              volume: parseFloat(cif.volume),
            };
          }
        }

        yield put({ type: SET_REPORT_SCREEN_LE_BAIL_ANALYSIS, payload: analysis });
        break;

      case "phaseQuantification":
        yield put({ type: SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS, payload: res.data.data})
        yield put({ type: SET_REPORT_SCREEN_PHASE_QUANTIFICATION_ANALYSIS_PARAMETERS, payload: res.data.debyeWallerParameters})
        break;

      case "dislocationDensity":
        yield put({ type: SET_REPORT_SCREEN_DISLOCATION_DENSITY_ANALYSIS, payload: res.data.data})
        yield put({ type: SET_REPORT_SCREEN_DISLOCATION_DENSITY_WH_PARAMS, payload: res.data.whParams })
        yield put({ type: SET_REPORT_SCREEN_DISLOCATION_DENSITY_MWH_PARAMS, payload: res.data.mwhParams })
        break;
      
      case "peakFitting":
        yield put({ type: SET_REPORT_SCREEN_PEAK_FITTING_ANALYSIS, payload: res.data.data})
        break;
      
      case "stressStrain":
        yield put({ type: SET_REPORT_SCREEN_STRESS_STRAIN_ANALYSIS, payload: res.data.data.peakFitting })
        yield put({ type: SET_REPORT_SCREEN_STRESS_STRAIN_BIN_PARAMETERS, payload: remapObject(res.data.data.stressStrainBinParameters) })
        yield put({ type: SET_REPORT_SCREEN_STRESS_STRAIN_PARAMETERS, payload: remapObjectParams(res.data.data.stressStrainParameters) })
        break;

      default:
        break;
    }
  } catch (error) {
    console.error(error)
    toast.error("Failed to get report screen additional data")
  } finally {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_download(payload) {
  try {
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const projectFiles: ProjectFileModel[] = yield select(selectCurrentProjectFiles);

    let parameters = {}

    switch (payload.payload.type) {
      case "leBail":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
          files: projectFiles.map((x) => x.fileRef).filter((x) => x.includes(".dat")),
        }
        break;
      case "phaseQuantification":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
          dataFiles: projectFiles.map((x) => x.fileRef).filter((x) => x.includes(".dat")),
        }
        break;
      case "dislocationDensity":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
          dataFiles: projectFiles.map((x) => x.fileRef).filter((x) => x.includes(".dat")),
        }
        break;
      case "stressStrain":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
          dataFiles: projectFiles.map((x) => x.fileRef).filter((x) => x.includes(".dat")),
        }
        break;
      case "instrumentalFileCreation":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
        }
        break;
      case "azimuthalIntegration":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
        }
        break;
      case "dataCalibration":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
        }
        break;
      case "rietveld":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
          files: projectFiles.map((x) => x.fileRef).filter((x) => x.includes(".dat")),
        }
        break;
      case "peakFitting":
        parameters = {
          token,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
          dataFiles: projectFiles.map((x) => x.fileRef).filter((x) => x.includes(".dat")),
        }
        break;
      default:
        break;
    }

    parameters["callerType"] = payload.payload.type

    const res = yield getDownload(
      parameters
    )

    yield put({ type: SET_DOWNLOAD_INSTANCE_ID, payload: res.data.instanceId })

  } catch (error) {
    console.error(error)
    toast.error("Failed to Download Data")
  }
}


function* lambda_cache(payload) {
  try {
    yield put({ type: SET_INLINE_OPERATION_IN_PROGRESS, payload: true });
    const token = yield select(selectUserToken);

    yield getLambdaCache(
      token,
      payload.payload.callerType
    )

  } catch (error) {
    console.error(error)
  } finally {
    yield put({ type: SET_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_download_checker(payload) {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getDownloadInstanceRunningStatus(
      token,
      currentProject.projectId,
      payload.payload.instanceId
    )

    if (res.data.error) {
      toast.error("The operation failed")
      yield put({ type: SET_DOWNLOAD_INSTANCE_ID, payload: "" })
      yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
      yield put({ type: SET_DOWNLOAD_INSTANCE_ID, payload: "" })
    } else if (res.data.item && res.data.item.hasOwnProperty("running") && !res.data.item.running) {
      yield put({ type: SET_DOWNLOAD_INSTANCE_ID, payload: "" })

      if (res.data.item.urlsS3Path) {
        const data = yield fetchData(res.data.item.urlsS3Path)
        yield downloadZipGroup(
          data,
          res.data.item.downloadType,
        )
      } else {
        yield downloadZipGroup(
          res.data.item.urls,
          res.data.item.downloadType,
        )
      }
      yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
    }
  } catch(error) {
    toast.error("The operation failed")
    console.error(error)
    yield put({ type: SET_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_DOWNLOAD_INSTANCE_ID, payload: "" })
  }
}

function* getProjectFiles() {
  yield put({ type: SET_FILE_FETCHING_IN_PROGRESS, payload: true });

  const currentProject: ProjectModel = yield select(selectCurrentProject);
  const token = yield select(selectUserToken);

  try {
    const res = yield projectFilesCaller(
      token,
      currentProject.projectId,
    );
    yield put({ type: SET_FILES_FOR_CURRENT_PROJECT, payload: res.data });
  } catch (err) {
    console.error(err);
  } finally {
    yield put({ type: SET_FILE_FETCHING_IN_PROGRESS, payload: false });
  }
}

function* getProjectFilesId(payload) {
  yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

  const token = yield select(selectUserToken);
  try {

    const res = yield projectFilesCaller(
      token,
      payload.payload.projectId
    );

    yield put({ type: SET_BROWSING_PROJECT, payload: {
      projectId: payload.payload.projectId,
      files: res.data,
    }});

  } catch (err) {
    console.error(err);
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_pipeline_status(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  try {
    const res = yield getPipelineStatus(token, currentProject.projectId, currentProject.userId, payload.payload.pipelineType);
    yield put({ type: SET_PIPELINE_STATUS, payload: res.status });
  } catch (err) {
    console.error(err);
  }
}

function* start_pipeline(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  yield put({ type: SET_OPERATION_IN_PROGRESS_DICT, payload: { operationInProgress: true, operation: "START_PIPELINE" } });
  try {
    const res = yield startPipeline(token, currentProject.projectId, currentProject.userId, payload.payload.pipelineType);
    yield put({ type: SET_PIPELINE_STATUS, payload: res.status });

    if (res.status === "STARTING") {
      toast.success("Pipeline starting successfully");
    } else {
      toast.error("Pipeline is already running");
    }
  } catch (err) {
    console.error(err);
  }
  yield put({ type: SET_OPERATION_IN_PROGRESS_DICT, payload: { operationInProgress: false, operation: "START_PIPELINE" } });
}

function* stop_pipeline(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  yield put({ type: SET_OPERATION_IN_PROGRESS_DICT, payload: { operationInProgress: true, operation: "STOP_PIPELINE" } });
  try {
    const res = yield stopPipeline(token, currentProject.projectId, currentProject.userId, payload.payload.pipelineType);
    yield put({ type: SET_PIPELINE_STATUS, payload: 'NO_INSTANCES' });

    if (res.status === "INSTANCES_STOPPED") {
      toast.success("Pipeline stopped successfully");
    } else {
      toast.error("Pipeline is already stopped");
    }
  } catch (err) {
    console.error(err);
  }
  yield put({ type: SET_OPERATION_IN_PROGRESS_DICT, payload: { operationInProgress: false, operation: "STOP_PIPELINE" } });
}

function* save_project_info(payload) {
  const token = yield select(selectUserToken);
  toast.promise(waitFiveSeconds(2), {
    loading: "Saving Project Information...",
    success: "Project information saved successfully",
    error: "",
  })

  try {
    yield saveProjectInfo(token, payload.payload);
  } catch (err) {
    console.error(err);
    toast.dismiss();
    toast.error("Failed to save project information");
  }
}

function* pipeline_files_progress(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  const stress: StressStrainModel = yield select(selectStressStrain);

  try {
    const res = yield pipelineFiles(token, currentProject.projectId, currentProject.userId, payload.payload.pipelineType);

    yield put({ type: SET_PIPELINE_PROGRESS, payload: {
      azimuthalIntegration: res.data.unprocessed.length,
      phaseQuantification: res.data.unprocessedPhaseQuantification.length,
      processed: res.data.phaseQuantification.length,
    } });

    const azimuthalIntegrationPartitions = yield select(selectAzimuthalIntegrationPartitions);
    const partitions = [...azimuthalIntegrationPartitions];
    partitions[0].dataFiles = res.data.azimuthalIntegration;

    yield put({ type: SET_AZIMUTHAL_INTEGRATION_PARTITIONS, payload: partitions });

    const phaseQuantificationData = [...res.data.phaseQuantification, ...res.data.unprocessedPhaseQuantification];

    if (payload.payload.pipelineType === "azimuthalIntegration-phaseQuantification") {
      yield put({ type: SET_PHASE_QUANTIFICATION_DATA_FILES, payload: phaseQuantificationData });
    }
    else {
      if (stress.dataFiles.length < 100) {
        if (phaseQuantificationData.length >= 100) {
          yield put({ type: SET_STRESS_STRAIN_DATA_FILES, payload: phaseQuantificationData.slice(0, 100) });
          yield put({ type: SET_STRESS_STRAIN_DATA_ACTIVE_FILES, payload: phaseQuantificationData.slice(0, 100) });
        } else {
          yield put({ type: SET_STRESS_STRAIN_DATA_FILES, payload: phaseQuantificationData });
          yield put({ type: SET_STRESS_STRAIN_DATA_ACTIVE_FILES, payload: phaseQuantificationData });
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* deleteProjectFile(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);

  try {
    yield deleteProjectFileCaller(token, currentProject.projectId, payload.payload);
    yield put({ type: GET_PROJECT_FILES });
  } catch (err) {
    console.error(err);
  }
}

function* get_on_demand(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  const userId = yield select(selectUserId);
  const statusMessage = yield select(selectProgressMessage);
  const onDemandStatus = yield select(selectOnDemandStatus);

  try {
    const res = yield getOnDemandStatus(token, currentProject.projectId, payload.payload.analysisType, userId);
    if (onDemandStatus !== res.status) {
      yield put({ type: SET_ON_DEMAND_STATUS, payload: res.status });
    }

    if (res.status === "STARTING") {
      yield put({ type: SET_PROGRESS, payload: 0 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
    } else if (res.status === "RUNNING" && statusMessage === "Starting Instance") {
      yield put({ type: SET_PROGRESS, payload: 0 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Waiting for task..." })
    } else if (res.status === "NO_INSTANCES") {
      yield put({ type: SET_PROGRESS, payload: 0 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Instance is stopping..." })
      yield put({ type: START_ON_DEMAND, payload: { analysisType: "azimuthalIntegration" } })
    }
  } catch (err) {
    console.error(err);
  }
}

function* start_on_demand(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  const userId = yield select(selectUserId);
  const statusMessage = yield select(selectProgressMessage);

  yield put({ type: SET_OPERATION_IN_PROGRESS_DICT, payload: { operationInProgress: true, operation: "START_ON_DEMAND" } });
  try {
    const res = yield startOnDemand(token, currentProject.projectId, payload.payload.analysisType, userId);
    yield put({ type: SET_ON_DEMAND_STATUS, payload: res.status });

    if (res.status === "STARTING") {
      yield put({ type: SET_PROGRESS, payload: 0 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
    } else if (res.status === "RUNNING" && statusMessage === "Starting Instance") {
      yield put({ type: SET_PROGRESS, payload: 0 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Waiting for task..." })
    } else if (res.status === "NO_INSTANCES") {
      yield put({ type: SET_PROGRESS, payload: 0 })
      yield put({ type: SET_PROGRESS_MESSAGE, payload: "Waiting for task..." })
    }

  } catch (err) {
    console.error(err);
  }
  yield put({ type: SET_OPERATION_IN_PROGRESS_DICT, payload: { operationInProgress: false, operation: "START_ON_DEMAND" } });
}

function* on_demand_add_task(payload) {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  const userId = yield select(selectUserId);

  try {
    yield onDemandAddTask(
      token,
      {
        projectId: currentProject.projectId,
        analysisType: payload.payload.analysisType,
        userId,
        ...payload.payload
      }
    );

    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Loading Task Details..." })
  } catch (err) {
    console.error(err);
    toast.error("Failed to add task")
  } finally {
  }
}


function* projectSaga() {
  yield all([
    takeLatest(CREATE_PROJECT, createProject),
    takeLatest(GET_PROJECTS_FOR_USER, getProjectsForUser),
    takeLatest(DELETE_PROJECT, deleteProject),
    takeLatest(RENAME_PROJECT, renameProject),
    takeLatest(GET_BROWSING_PROJECT, getProjectFilesId),
    takeLatest(SAVE_PROJECT_INFO, save_project_info),
    takeEvery(GET_METHOD_DATA, get_method_data),

    takeLatest(GET_INSTANCE_RUNNING_STATUS, get_instance_running_status),
    takeLatest(GET_ECS_RUNNING_INSTANCE, get_ecs_running_instance),

    takeLatest(GET_REPORT_SCREEN_ANALYSIS_TYPES, get_report_screen_analysis_types),
    takeLatest(GET_REPORT_SCREEN_ADDITIONAL_DATA, get_report_screen_additional_data),

    takeLatest(GET_DOWNLOAD, get_download),
    takeLatest(GET_DOWNLOAD_CHECKER, get_download_checker),

    takeEvery(GET_LAMBDA_CACHE, lambda_cache),

    takeLatest(UPLOAD_FILES, uploadFiles),
    takeLatest(GET_PROJECT_FILES, getProjectFiles),
    takeLatest(DELETE_PROJECT_FILE, deleteProjectFile),

    takeLatest(GET_PHASES, get_phases),
    takeEvery(GET_CIF_FILE_ABC_VALUE, get_cif_file_abc_value),
    takeLatest(GET_INSTRUMENTAL_FILE_WAVELENGTH, get_instrumental_file_wavelength),

    takeLatest(GET_PIPELINE_STATUS, get_pipeline_status),
    takeLatest(GET_PIPELINE_FILES, pipeline_files_progress),
    takeLatest(START_PIPELINE, start_pipeline),
    takeLatest(STOP_PIPELINE, stop_pipeline),

    takeLatest(START_ON_DEMAND, start_on_demand),
    takeLatest(GET_ON_DEMAND_STATUS, get_on_demand),
    takeLatest(GET_ON_DEMAND_PROGRESS, get_on_demand_progress),
    takeLatest(ADD_ON_DEMAND_TASK, on_demand_add_task),
  ]);
}

export default projectSaga;
