import { apiGeneral, requestApi } from "../../api";


export const getMethodData = async (token, userId, projectId, projectFiles, projectType) => {
  const req = {
    projectId,
    projectType,
    projectFiles,
    userId,
  };

  return await requestApi(`/project-data`, "POST", req, token, {}, apiGeneral);
};

export const getInstanceRunningStatus = async (token, projectId, instanceId) => {
  const req = {
    projectId,
    instanceId,
  };

  return await requestApi(`/instance-checker`, "POST", req, token, {}, apiGeneral, true);
};

export const getECSRunningStatus = async (token, projectId, callerType, userId, order, orderArgs) => {
  const req = {
    projectId,
    callerType,
    userId,
    order,
    ...orderArgs,
  };

  return await requestApi(`/ecs-on-demand`, "POST", req, token, {}, apiGeneral, true);
};

export const getDownloadInstanceRunningStatus = async (token, projectId, instanceId) => {
  const req = {
    projectId,
    instanceId,
  };

  return await requestApi(`/download-checker`, "POST", req, token, {}, apiGeneral, true);
}

export const getDownload = async (request) => {
  return await requestApi(`/download-caller`, "POST", request, request.token, {}, apiGeneral);
}

export const getLambdaCache = async (token, callerType ) => {
  const req = {
    callerType,
  };

  return await requestApi(`/lambda-cache`, "POST", req, token, {}, apiGeneral);
}

export const getInstrumentalFileWavelength = async (token, userId, projectId, templateInstrumentalFile, instrumentalFile) => {
  const req = {
    projectId,
    templateInstrumentalFile,
    instrumentalFile,
    userId,
  };

  return await requestApi(`/wavelength`, "POST", req, token, {}, apiGeneral);
}

export const getCifFileABCValue = async (token, cifFile) => {
  const req = {
    cifFile,
  };

  return await requestApi(`/cif-abc-value`, "POST", req, token, {}, apiGeneral);
}

export const getPhases = async (token, request) => {
  return await requestApi(`/phases`, "POST", request, token, {}, apiGeneral);
};

export const getReportScreenAnalysisTypes = async (token, projectId) => {
  const req = {
    projectId,
  };

  return await requestApi(`/report-types`, "POST", req, token, {}, apiGeneral);
}

export const getReportScreenAdditionalData = async (token, projectId, analysisType, files) => {
  const req = {
    projectId,
    analysisType,
    files,
  };

  return await requestApi(`/report-additional`, "POST", req, token, {}, apiGeneral);
}

export const getUploadPreAssignedUrls = async (token, projectId, pipelineData, files) => {
  const req = {
    projectId,
    files,
    pipelineData
  };

  return await requestApi(`/upload-url`, "POST", req, token, {}, apiGeneral);
}

export const getUploadSuccess = async (token, projectId, files, userId) => {
  const req = {
    projectId,
    files,
    userId,
  };

  return await requestApi(`/upload-success`, "POST", req, token, {}, apiGeneral);
}

export const getUserProjects = async (token: string, userId: string) => {
  const request = {
    userId,
  };

  return await requestApi(`/user-projects`, "POST", request, token, {}, apiGeneral);
}

export const createProjectCaller = async (token: string, userId: string, projectName: string) => {
  const request = {
    userId,
    projectName,
  };

  return await requestApi(`/create-project`, "POST", request, token, {}, apiGeneral);
}

export const deleteProjectCaller = async (token: string, userId: string, projectId: string) => {
  const request = {
    userId,
    projectId,
  };

  return await requestApi(`/delete-project`, "POST", request, token, {}, apiGeneral);
}

export const projectFilesCaller = async (token: string, projectId: string) => {
  const request = {
    projectId,
  };

  return await requestApi(`/project-files`, "POST", request, token, {}, apiGeneral);
}

export const renameProjectCaller = async (token: string, projectId: string, userId: string, projectName: string) => {
  const request = {
    projectId,
    projectName,
    userId,
  };

  return await requestApi(`/rename-project`, "POST", request, token, {}, apiGeneral);
}

export const getPipelineStatus = async (token: string, projectId: string, userId: string, pipelineType: string) => {
  const request = {
    projectId,
    userId,
    pipelineType,
  };

  return await requestApi(`/pipeline-status`, "POST", request, token, {}, apiGeneral);
}

export const startPipeline = async (token: string, projectId: string, userId: string, pipelineType: string) => {
  const request = {
    projectId,
    userId,
    pipelineType,
  };

  return await requestApi(`/pipeline-start`, "POST", request, token, {}, apiGeneral);
}


export const getOnDemandStatus = async (token: string, projectId: string, instanceType: string, userId: string) => {
  const request = {
    projectId,
    instanceType,
    userId
  };

  return await requestApi(`/on-demand-status`, "POST", request, token, {}, apiGeneral);
}

export const startOnDemand = async (token: string, projectId: string, instanceType: string, userId: string) => {
  const request = {
    projectId,
    instanceType,
    userId
  };

  return await requestApi(`/on-demand-start`, "POST", request, token, {}, apiGeneral);
}

export const onDemandAddTask = async (token: string, req: any) => {
  return await requestApi(`/on-demand-add-task`, "POST", req, token, {}, apiGeneral);
}

export const stopPipeline = async (token: string, projectId: string, userId: string, pipelineType: string) => {
  const request = {
    projectId,
    userId,
    pipelineType
  };

  return await requestApi(`/pipeline-stop`, "POST", request, token, {}, apiGeneral);
}

export const saveProjectInfo = async (token: string, projectInfo: any) => {
  return await requestApi(`/save-project-info`, "POST", projectInfo, token, {}, apiGeneral);
}

export const pipelineFiles = async (token: string, projectId: string, userId: string, pipelineType: string) => {
  const request = {
    projectId,
    userId,
    pipelineType,
  };

  return await requestApi(`/pipeline-files`, "POST", request, token, {}, apiGeneral);
}

export const deleteProjectFileCaller = async (token: string, projectId: string, item: string) => {
  const request = {
    projectId,
    item,
  };

  return await requestApi(`/delete-file`, "POST", request, token, {}, apiGeneral);
}
