import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUBMIT, LOG_IN, SIGN_UP } from "../redux/general/generalActions";
import { Dispatch } from "redux";
import { GlobalState } from "../store/models";
import { connect } from "react-redux";

const AuthForm = ({ dispatch }: { dispatch: Dispatch }) => {
  const [state, setState] = useState<"login" | "forgotPassword" | "signUp">("login");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verificationSent, setVerificationSent] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setEmail("");
      setPassword("");
      setUsername("");
      setVerificationCode("");
      setVerificationSent(false);
    };
  }, [dispatch]);

  const handleLogIn = (event: React.FormEvent) => {
    event.preventDefault();
    if (!email || !password) {
      return toast.error("Email and password are required");
    }
    dispatch({ type: LOG_IN, payload: { email, password } });
  };

  const handleSignUp = (event: React.FormEvent) => {
    event.preventDefault();
    if (!email || !password || !username) {
      return toast.error("Email, password, and username are required");
    }
    dispatch({ type: SIGN_UP, payload: { username, email, password } });
  };

  const handleForgotPassword = (event: React.FormEvent) => {
    event.preventDefault();
    if (!email) {
      return toast.error("Email is required");
    }
    if (!verificationSent) {
      dispatch({ type: FORGOT_PASSWORD, payload: { email } });
      setVerificationSent(true);
    } else {
      if (!verificationCode || !password) {
        return toast.error("Verification code and new password are required");
      }
      dispatch({ type: FORGOT_PASSWORD_SUBMIT, payload: { email, verificationCode, password } });
      setState("login");
      setEmail("");
      setPassword("");
      setUsername("");
      setVerificationCode("");
    }
  };

  return (
    <div className="m-auto w-4/6" style={{ maxWidth: "600px" }}>
      <div className="bg-primary-800 rounded-lg p-8">
        <form onSubmit={state === "login" ? handleLogIn : state === "signUp" ? handleSignUp : handleForgotPassword} id="auth-form">
          {state === "signUp" && (
            <input
              className="w-full mb-4 bg-primary-850 p-3 rounded-md border border-white border-solid text-white hover:border-blue-300"
              placeholder="Name"
              value={username}
              required
              onChange={(event) => setUsername(event.target.value)}
            />
          )}
          <input
            className="w-full mb-4 bg-primary-850 p-3 rounded-md border border-white border-solid text-white hover:border-blue-300"
            placeholder="Email"
            type="email"
            value={email}
            required
            onChange={(event) => setEmail(event.target.value)}
          />
          {(state === "login" || state === "signUp" || (state === "forgotPassword" && verificationSent)) && (
            <input
              className="w-full mb-4 bg-primary-850 p-3 rounded-md border border-white border-solid text-white hover:border-blue-300"
              placeholder="Password"
              type="password"
              value={password}
              required
              onChange={(event) => setPassword(event.target.value)}
            />
          )}
          {state === "forgotPassword" && verificationSent && (
            <input
              className="w-full mb-4 bg-primary-850 p-3 rounded-md border border-white border-solid text-white hover:border-blue-300"
              placeholder="Verification Code"
              value={verificationCode}
              required
              onChange={(event) => setVerificationCode(event.target.value)}
            />
          )}
          <button
            className="mb-2 w-full bg-primary-600 p-3 rounded-md text-primary-400 hover:bg-primary-500"
            type="submit"
          >
            {state === "login" ? "Login" : state === "signUp" ? "Sign up" : verificationSent ? "Reset Password" : "Send Verification Code"}
          </button>
        </form>
        <div className="flex justify-between">
          <button
            className="text-blue-500 underline hover:text-blue-700"
            onClick={() => setState(state === "login" ? "signUp" : "login")}
          >
            {state === "login" ? "Sign up here" : "Login here"}
          </button>
          <button
            className="text-blue-500 underline hover:text-blue-700"
            onClick={() => {
              setState("forgotPassword");
              setVerificationSent(false);
            }}
          >
            Forgot Password?
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  projectReducer: state.projectReducer,
});

export default connect(mapStateToProps)(AuthForm);
