import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { compareKeysV2 } from "../../../helpers/general";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { selectDislocationDensityDataFiles, selectDislocationDensityFitting, selectDislocationDensityDataViewFile, selectDislocationDensityDataActiveFiles, selectDislocationDensityMwhParams, selectDislocationDensityWhParams, selectDislocationDensityCifFiles, selectDislocationDensityCifFilesABC, selectDislocationDensityCifTemplateFiles, selectDislocationDensityInstrumentalTemplateFile, selectDislocationDensityWavelength, selectDislocationDensityInstrumentalFile } from "../../../redux/dislocationDensity/dislocationDensitySelectors";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import { GET_DISLOCATION_DENSITY_CALLBACK, SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES, SET_DISLOCATION_DENSITY_DATA_VIEW_FILE } from "../../../redux/dislocationDensity/dislocationDensityActions";
import DislocationDensityGraph from "./dislocation-density-graph";
import DislocationDensityInput from "./dislocation-density-input";
import { dislocationDensityFileInformation } from "./helpers";
import { useInstanceStatusPolling } from "../shared/instanceTrigger";

const DislocationDensityAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectDislocationDensityDataActiveFiles)
  const mwhParams = useSelector(selectDislocationDensityMwhParams);
  const whParams = useSelector(selectDislocationDensityWhParams);
  const fitting = useSelector(selectDislocationDensityFitting);
  const cifFiles = useSelector(selectDislocationDensityCifFiles);
  const cifTemplateFiles = useSelector(selectDislocationDensityCifTemplateFiles);
  const instrumentalTemplateFile = useSelector(selectDislocationDensityInstrumentalTemplateFile);
  const instrumentalFile = useSelector(selectDislocationDensityInstrumentalFile);
  const wavelength = useSelector(selectDislocationDensityWavelength);
  const dataFiles = useSelector(selectDislocationDensityDataFiles);
  const dataViewFile = useSelector(selectDislocationDensityDataViewFile);
  const cifFilesABC = useSelector(selectDislocationDensityCifFilesABC);

  const [filesInformation, setFilesInformation] = useState<any>([]);
  const [headersFileInformation, setHeadersFileInformation] = useState<any>([]);

  useInstanceStatusPolling(instanceId, "dislocationDensity", dispatch);

  useEffect(() => {
    dispatch({ type: GET_METHOD_DATA, payload: {type: "dislocationDensity"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "dislocationDensity"} })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: GET_PHASES, payload: { type: "dislocationDensity"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, cifFilesABC]);

  useEffect(() => {
    if (!dataViewFile) return;

    dispatch({ type: GET_DISLOCATION_DENSITY_CALLBACK })
  }, [dispatch, dataViewFile])

  useEffect(() => {
    const fileInformation = dislocationDensityFileInformation(dataFiles, whParams, mwhParams, fitting)

    setHeadersFileInformation(fileInformation.headers)
    setFilesInformation(fileInformation.filesMapping)
  }, [dataFiles, dispatch, fitting, whParams, mwhParams])

  useEffect(() => {
    if (dataFiles.length > 0 && !dataViewFile) {
      const dataFilesObj = dataFiles.sort((a, b) => compareKeysV2(a, b))
      dispatch({ type: SET_DISLOCATION_DENSITY_DATA_VIEW_FILE, payload: dataFilesObj[0] });
    }
  }, [dataFiles, dispatch, dataViewFile])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Dislocation Density..."}
        />
      }
      <div className={"flex flex-col bg-primary-800 h-full rounded-md w-2/6"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <DislocationDensityInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
          <DislocationDensityGraph dispatch={dispatch} />
          <AnalysisFilesTable
            data={filesInformation}
            dataViewFile={dataViewFile}
            setDataViewFile={(file) => dispatch({type: SET_DISLOCATION_DENSITY_DATA_VIEW_FILE, payload: file })}
            setDataActiveFiles={(files) => dispatch({type: SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES, payload: files})}
            dataActiveFiles={dataActiveFiles}
            headersOrdered={headersFileInformation}
          />
        </div>
    </div>
  );
};

export default DislocationDensityAnalysis;
