import { CifFilesModel, DropDownOptions } from "./models";

export const hardMetalCifFiles: CifFilesModel[] = [
	{   
		id: "cobalt_fcc",
		label: "Cobalt fcc",
		dynamodb_name: "cobalt_fcc",
	},
	{
		id: "wc_hcp",
		label: "WC hcp",
		dynamodb_name: "wc_hcp",
	},
];

export const niBasedSuperAlloyCifFiles: CifFilesModel[] = [
	{
		id: "delta_nb_ni3",
		label: "Delta Nb Ni3",
		dynamodb_name: "delta_nb_ni3",
	},
	{
		id: "gamma_ni24cr7fe036",
		label: "Gamma Ni24Cr7Fe036",
		dynamodb_name: "gamma_ni24cr7fe036",
	},
	{
		id: "laves_fe2nb",
		label: "Laves Fe2 Nb",
		dynamodb_name: "laves_fe2Nb",
	},
	{
		id: "mc_nbc",
		label: "MC NbC",
		dynamodb_name: "mc_nbc",
	},
];

export const steelCifFiles: CifFilesModel[] = [
	{
		id: "austenite_fcc",
		label: "Austenite fcc",
		dynamodb_name: "austenite_fcc",
	},
	{
		id: "cementite",
		label: "Cementite",
		dynamodb_name: "cementite",
	},
	{
		id: "ferrite_bcc",
		label: "Ferrite bcc",
		dynamodb_name: "ferrite_bcc",
	},
];

export const cifFiles: DropDownOptions = {
	id: "cif_templates",
	label: "CIF Templates",
	options: [
		{
			id: "steel",
			label: "Steel",
			values: [
				{ label: "Austenite fcc", value: "austenite_fcc" },
				{ label: "Cementite", value: "cementite" },
				{ label: "Ferrite bcc", value: "ferrite_bcc" },
			],
		},
		{
			id: "ni_based_super_alloy",
			label: "Ni-based super alloy",
			values: [
				{ label: "Delta Nb Ni3", value: "delta_nb_ni3" },
				{ label: "Gamma Ni24Cr7Fe036", value: "gamma_ni24cr7fe036" },
				{ label: "Laves Fe2 Nb", value: "laves_fe2Nb" },
				{ label: "MC NbC", value: "mc_nbc" },
			],
		},
		{
			id: "hard_metal",
			label: "Hard metal",
			values: [
				{ label: "Cobalt fcc", value: "cobalt_fcc" },
				{ label: "WC hcp", value: "wc_hcp" },
			],
		},
		{
			id: "ti_alloys",
			label: "Ti alloys",
			values: [
				{ label: "Ti bcc", value: "ti_bcc" },
				{ label: "Ti hcp", value: "ti_hcp" },
				{ label: "TiAIV bcc", value: "tiAIV_bcc" },
				{ label: "TiAl hcp", value: "tiAl_hcp" },
			],
		},
	],
};

export const cifFilesDirectories = [
	{ label: "Austenite fcc", value: "austenite_fcc", directory: "Steel" },
	{ label: "Cementite", value: "cementite", directory: "Steel" },
	{ label: "Ferrite bcc", value: "ferrite_bcc", directory: "Steel" },
	{ label: "Delta Nb Ni3", value: "delta_nb_ni3", directory: "Ni-based super alloy" },
	{ label: "Gamma Ni24Cr7Fe036", value: "gamma_ni24cr7fe036", directory: "Ni-based super alloy" },
	{ label: "Laves Fe2 Nb", value: "laves_fe2Nb", directory: "Ni-based super alloy" },
	{ label: "MC NbC", value: "mc_nbc", directory: "Ni-based super alloy" },
	{ label: "Cobalt fcc", value: "cobalt_fcc", directory: "Hard metal" },
	{ label: "WC hcp", value: "wc_hcp", directory: "Hard metal" },
	{ label: "Ti bcc", value: "ti_bcc", directory: "Ti alloys" },
	{ label: "Ti hcp", value: "ti_hcp", directory: "Ti alloys" },
	{ label: "TiAIV bcc", value: "tiAIV_bcc", directory: "Ti alloys" },
	{ label: "TiAl hcp", value: "tiAl_hcp", directory: "Ti alloys" },
]