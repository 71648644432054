import { all, put, select, takeLatest } from "redux-saga/effects";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { waitFiveSeconds } from "../../helpers/redux";
import toast from "react-hot-toast";
import { PhaseQuantificationModel } from "../../models/analysis.phaseQuantification.model";
import { selectPhaseQuantification } from "./phaseQuantificationSelectors";
import { selectUserToken, selectCurrentProject } from "../project/projectSelectors";
import { GET_PHASE_QUANTIFICATION_CALLBACK_FITTED, GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS, GET_PHASE_QUANTIFICATION_FITTING, SET_PHASE_QUANTIFICATION_DEBYE_WALLER_PARAMETER, SET_PHASE_QUANTIFICATION_FITTING, SET_PHASE_QUANTIFICATION_PARAMETERS } from "./phaseQuantificationActions";
import { getPhaseQuantificationCallbackFitted, getPhaseQuantificationCallbackParameters, getPhaseQuantificationCaller, preProcessObject } from "./phaseQuantificationHelpers";


function* get_phase_quantification_fitting() {
  try {
    toast.promise(waitFiveSeconds(), {
      loading: "Starting Computing Process",
      success: "Running Peak Fitting...",
      error: "Running Peak Fitting...",
    })

    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let phaseQuantification: PhaseQuantificationModel = yield select(selectPhaseQuantification);
    phaseQuantification = preProcessObject(phaseQuantification);
    
    const res = yield getPhaseQuantificationCaller(
      token,
      {
        ...phaseQuantification,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to perform phase quantification. Please try again.")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_phase_quantification_callback_fitted(payload) {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let phaseQuantification: PhaseQuantificationModel = yield select(selectPhaseQuantification);
    phaseQuantification = preProcessObject(phaseQuantification);

    const res = yield getPhaseQuantificationCallbackFitted(
      token,
      {
        ...phaseQuantification,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      },
    );

    yield put({ type: SET_PHASE_QUANTIFICATION_FITTING, payload: { data: res.data.fittedData, file: phaseQuantification.dataViewFile }});
  } catch (error) {
    console.error(error)
    toast.error("Failed to fetch peak fitting results. Please try again.")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

function* get_phase_quantification_callback_parameters(payload) {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let phaseQuantification: PhaseQuantificationModel = yield select(selectPhaseQuantification);
    phaseQuantification = preProcessObject(phaseQuantification);

    const res = yield getPhaseQuantificationCallbackParameters(
      token,
      {
        ...phaseQuantification,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      },
    );

    yield put({ type: SET_PHASE_QUANTIFICATION_PARAMETERS, payload: res.data.parametersData });
    yield put({ type: SET_PHASE_QUANTIFICATION_DEBYE_WALLER_PARAMETER, payload: res.data.debyeWallerParameters});
  } catch (error) {
    console.error(error)
    toast.error("Failed to fetch peak fitting results. Please try again.")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

export default function* phaseQuantificationSaga() {
  yield all([
    takeLatest(GET_PHASE_QUANTIFICATION_FITTING, get_phase_quantification_fitting),
    takeLatest(GET_PHASE_QUANTIFICATION_CALLBACK_FITTED, get_phase_quantification_callback_fitted),
    takeLatest(GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS, get_phase_quantification_callback_parameters),
  ]);
}
