import { DropDownOptions } from "../data/models";
import { StressFitting, StressParams } from "./analysis.general.model";

export interface ReportScreenModel {
  dataViewFiles: string[];
  analysisTypes: string[];
  selectedAnalysisType: string;
  leBailReport: LeBailReportModel;
  phaseQuantificationReport: PhaseQuantificationReportModel;
  dislocationDensityReport: DislocationDensityReportModel;
  peakFittingReport: PeakFittingReportModel;
  stressStrainReport: StressStrainReportModel;
  swipeMode: {
    swipe: boolean;
    swipeRowEven: boolean;
  },
  heatMap: {
    "x": number;
    "y": number;
    "colorMap": string;
  }
}

export interface LeBailReportModel {
  yAxis: {
    "lineChart": string[];
    "heatMap": string;
  }
  cifFiles: {
    "lineChart": string[];
    "heatMap": string;
  };
  availableCifFiles: string[];
  analysis: {
    [dataFile: string]: {
      [cifFile: string]: {
        lengthA: number;
        lengthB: number;
        lengthC: number;
        volume: number;
      };
    };
  }
}

export interface DislocationDensityReportModel {
  yAxis: {
    "lineChart": string[];
    "heatMap": string;
  };
  analysis: {
    [fileName: string]: {
      [peak: string]: {
        amplitude: number;
        amplitude_std_dev: number;
        center: number;
        center_std_dev: number;
        sigma: number;
        sigma_std_dev: number;
        fraction: number;
        fraction_std_dev: number;
        fwhm: number;
        fwhm_cauchy: number;
        fwhm_gaussian: number;
        fwhm_std_dev: number;
        height: number;
        height_std_dev: number;
      };
    };
  };
  whParams: {
    [fileKey: string]: {
      [key: string]: {
        nominal_value: number;
        std_dev: number;
      }
    }
  }
  mwhParams: {
    [fileKey: string]: {
      [key: string]: {
        nominal_value: number;
        std_dev: number;
      }
    }
  };
}

export interface PeakFittingReportModel {
  yAxis: {
    "lineChart": string[];
    "heatMap": string;
  };
  analysis: {
    [fileName: string]: {
      [peak: string]: {
        amplitude: number;
        amplitude_std_dev: number;
        center: number;
        center_std_dev: number;
        sigma: number;
        sigma_std_dev: number;
        fraction: number;
        fraction_std_dev: number;
        fwhm: number;
        fwhm_cauchy: number;
        fwhm_gaussian: number;
        fwhm_std_dev: number;
        height: number;
        height_std_dev: number;
      };
    };
  }
}

export interface StressStrainReportModel {
  yAxis: {
    "lineChart": string[];
    "heatMap": string;
  };
  analysis: {
    [fileName: string]: {
      [peak: string]: {
        amplitude: number;
        amplitude_std_dev: number;
        center: number;
        center_std_dev: number;
        sigma: number;
        sigma_std_dev: number;
        fraction: number;
        fraction_std_dev: number;
        fwhm: number;
        fwhm_cauchy: number;
        fwhm_gaussian: number;
        fwhm_std_dev: number;
        height: number;
        height_std_dev: number;
      };
    };
  },
  stressStrainBinParameters: StressFitting;
  stressStrainParameters: StressParams;
}

export interface PhaseQuantificationReportModel {
  yAxis: {
    "lineChart": string[];
    "heatMap": string;
  };
  cifFiles: {
    "lineChart": string[];
    "heatMap": string;
  };
  analysis: {
    [fileName: string]: {
      [peak: string]: {
        amplitude: number;
        amplitude_std_dev: number;
        center: number;
        center_std_dev: number;
        sigma: number;
        sigma_std_dev: number;
        fraction: number;
        fraction_std_dev: number;
        fwhm: number;
        fwhm_cauchy: number;
        fwhm_gaussian: number;
        fwhm_std_dev: number;
        height: number;
        height_std_dev: number;
      };
    };
  };
  parametersAnalysis: {
    [fileName: string]: {
      [cifFile: string]: number;
    };
  };
}

export const LabelsMappingPeakFitting = {
  amplitude: "Amplitude",
  center: "Center",
  sigma: "Sigma",
  fraction: "Fraction",
  height: "Height",
  fwhm: "Full width at half maximum",
};

export const LabelsMappingStressStrain = {
  strain_xx: "Strain XX",
  strain_xz: "Strain XZ",
  strain_zz: "Strain ZZ",
  "stress_xx [MPa]": "Stress XX [MPa]",
  "stress_xz [MPa]": "Stress XZ [MPa]",
  "stress_zz [MPa]": "Stress ZZ [MPa]",
  "von_mises [MPa]": "Von Mises [MPa]",
};


export const LabelsMappingStressStrainBin = {
  center: "Center",
  strain: "Strain",
  corr_angle: "Correlation Angle",
  rsquared: "R Squared",
}

export const ReportScreenLeBail: DropDownOptions = {
  id: "report_screen_lebail",
	label: "LeBail Y Axis",
	options: [
		{
			id: "lengths",
			label: "Lengths",
			values: [
				{ label: "Length A", value: "lengthA" },
        { label: "Length B", value: "lengthB" },
        { label: "Length C", value: "lengthC" },
			],
		},
    {
      id: "volume",
      label: "Volume",
      values: [
        { label: "Volume", value: "volume" },
      ],
    },
	],
}

export const ReportScreenDislocationDensity: DropDownOptions = {
  id: "report_screen_dislocation_density",
  label: "Dislocation Density Y Axis",
  options: [
    {
      id: "parameters",
      label: "Parameters",
      values: [
        { label: "Crystallite Size [Å]", value: "Crystallite Size" },
        { label: "Intercept", value: "Intercept" },
        { label: "Slope", value: "slope" },
        { label: "Strain", value: "strain" },
        { label: "Rho", value: "Rho" },
        { label: "L_0 [nm]", value: "L_0" },
        { label: "R_e [nm]", value: "R_e" },
        { label: "Rho [m^-2]", value: "rho [m^-2]" },
        { label: "M", value: "M" },
        { label: "q", value: "q" },
      ],
    },
  ],
}

export const ReportScreenColorMaps: DropDownOptions = {
  id: "report_screen_colormaps",
  label: "Colormaps",
  options: [
    {
      id: "perceptually_uniform_sequential",
      label: "Perceptually Uniform Sequential",
      values: [
        { label: "Viridis", value: "Viridis" },
        { label: "Greys", value: "Greys" },
        { label: "YlGnBu", value: "YlGnBu" },
        { label: "Greens", value: "Greens" },
        { label: "YlOrRd", value: "YlOrRd" },
        { label: "Bluered", value: "Bluered" },
        { label: "RdBu", value: "RdBu" },
        { label: "Reds", value: "Reds" },
        { label: "Blues", value: "Blues" },
        { label: "Picnic", value: "Picnic" },
        { label: "Rainbow", value: "Rainbow" },
        { label: "Portland", value: "Portland" },
        { label: "Jet", value: "Jet" },
        { label: "Hot", value: "Hot" },
        { label: "Blackbody", value: "Blackbody" },
        { label: "Earth", value: "Earth" },
        { label: "Electric", value: "Electric" },
        { label: "Cividis", value: "Cividis" },
      ],
    },
  ],
};
