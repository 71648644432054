export const Font = {
  family: "sans-serif",
  size: 16,
  color: "white",
};

export const GraphLayout = {
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  legend: {
    orientation: "v" as "v" | "h",
    font: Font
  },
  xaxis: {
    color: "white",
    gridcolor: "grey",
    title: {
      text: "2θ",
      font: Font
    },
  },
  yaxis: {
    color: "white",
    gridcolor: "grey",
    title: {
      text: "intensity",
      font: Font
    },
  },
  modebar: {
    orientation: "h" as "h" | "v",
    bgcolor: "#434446",
    color: "white",
  },
};

export const GraphConfig: Partial<any> = {
  modeBarButtonsToRemove: ["lasso2d"],
};

export const GraphStyling = {
  width: "100%",
  height: "500px",
  marginTop: "-40px",
  marginBottom: "-10px",
};
