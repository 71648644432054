import JSZip from "jszip";
import { DropDownOptions } from "../data/models";
import { DataCalibrationFactoriesName } from "../data/data_calibration";

export function waitFiveSeconds(seconds=5): Promise<void> {
	return new Promise<void>((resolve) => {
	  setTimeout(() => {
		resolve();
	  }, seconds * 1000);
	});
}

export function waitCustom(time: number): Promise<void> {
	return new Promise<void>((resolve) => {
	  setTimeout(() => {
		resolve();
	  }, time);
	});
}

export function downloadZip(files, name) {
	const zip = new JSZip();
  
	const downloadPromises = files.map((file) => downloadFile(file.url, file.name, zip));
  
	Promise.all(downloadPromises)
	  .then(() => zip.generateAsync({ type: 'blob' }))
	  .then((content) => {
		const link = document.createElement('a');
		link.href = URL.createObjectURL(content);
		link.download = `${name}.zip`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	});
}

async function downloadFile(url, filename, zip) {
	const response = await fetch(url);
	const data = await response.blob();
	zip.file(filename, data);
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

export async function downloadZipGroup(files, name) {
  const zip = new JSZip();

  const groupSize = 10;
  const numGroups = Math.ceil(files.length / groupSize);

  for (let i = 0; i < numGroups; i++) {
    const startIdx = i * groupSize;
    const endIdx = startIdx + groupSize;
    const groupFiles = files.slice(startIdx, endIdx);

    const downloadPromises = groupFiles.map((file) => downloadFileWithRetries(file.url, file.name, zip));
  	await Promise.all(downloadPromises);
  }

  const content = await zip.generateAsync({ type: 'blob' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(content);
  link.download = `${name}.zip`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function downloadFileWithRetries(url, filename, zip, retryCount = 0) {
  try {
    await downloadFileGroup(url, filename, zip);
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      console.error(`Download failed for ${filename}, retrying (${retryCount + 1} of ${MAX_RETRIES})...`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      await downloadFileWithRetries(url, filename, zip, retryCount + 1);
    } else {
      console.error(`Max retries reached for ${filename}. Download failed.`);
			console.error(error);			
    }
  }
}

async function downloadFileGroup(url, filename, zip) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch ${filename}. Status: ${response.status} ${response.statusText}`);
  }

  const data = await response.blob();
  zip.file(filename, data);
}

export function getLabelForValue(searchValue: string, dropDownOptions: DropDownOptions): string | undefined {
  for (let option of dropDownOptions.options) {
    for (let value of option.values) {
      if (value.value === searchValue) {
        return value.label;
      }
    }
  }
  return undefined; // Return undefined if no matching value is found
}

/**
 * Convert hex to rgba
 * @param hex Hexadecimal color code
 * @param alpha Opacity value between 0 and 1
 */
export function hexToRgba(hex: string, alpha: number = 1): string {
  // Ensure the hex code starts with #
  let sanitizedHex = hex.charAt(0) === '#' ? hex.substring(1) : hex;

  // Handle short-form like "#f03"
  if (sanitizedHex.length === 3) {
    sanitizedHex = sanitizedHex.split('').map((char) => char + char).join('');
  }

  if (sanitizedHex.length !== 6) {
    throw new Error('Invalid hex color.');
  }

  const bigint = parseInt(sanitizedHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r},${g},${b},${alpha})`;
}

export function isNumber(value: any): value is number {
  if (value === undefined) {
    return false;
  }

  value = parseFloat(value);
  return typeof value === 'number';
}

export function reformatString(input: string): string {
  // Split the string by '_' or '-'
  const words = input.split(/[-_]/g);
  
  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => 
    word.charAt(0).toUpperCase() + word.slice(1)
  );
  
  // Join the words by space and return
  return capitalizedWords.join(' ');
}


function stripAndFormat(str: string): string {
  const stripped = str.replace(/[-_]/g, ""); // Stripping _ and -
  return stripped;
}

export function mapDetectorName(inputName: string): string {
  const formattedInput = stripAndFormat(inputName);

  for (const original of DataCalibrationFactoriesName) {
    if (stripAndFormat(original) === formattedInput) {
      return inputName; // Return the input name when a match is found
    }
  }

  return inputName; // Return the input name itself if no match is found in originalList
}

export const fetchData = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      console.error(response.statusText);
      return "";
    }
    return await response.json();
  } catch (err: any) {
    console.error(err);
    return "";
  }
};

export function removeIdFromFileName(fileName: string): string {
  const parts = fileName.split('-');
  return parts.slice(1).join('-');
}
