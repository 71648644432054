import React, { useState, useEffect } from "react";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faSync } from "@fortawesome/free-solid-svg-icons";
import { GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES } from "../../../../redux/azimuthalIntegration/azimuthalIntegrationActions";
import { waitFiveSeconds } from "../../../../helpers/redux";

interface ImagePlayerProps {
  images: string[];
  dispatch: Dispatch;
  pipeline: boolean;

}

const ImagePlayer: React.FC<ImagePlayerProps> = ({ images, dispatch, pipeline=false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [speed, setSpeed] = useState(1);
  const [isVisible, setIsVisible] = useState<number[]>([0]); // Array to keep track of visible images

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isPlaying) {
      timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 1000 / speed);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex, isPlaying, images, speed]);

  useEffect(() => {
    setIsVisible([currentIndex]); // Show only the current image
  }, [currentIndex]);

  const handlePlayPause = () => {
    setIsPlaying((prevState) => !prevState);
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIndex = parseInt(event.target.value);
    setCurrentIndex(newIndex);
    setIsPlaying(false); // Stop automatic timer when using the slider
  };

  const handleSpeedChange = () => {
    const newSpeed = speed * 2;
    setSpeed(newSpeed > 16 ? 0.5 : newSpeed);
  };

  useEffect(() => {
    setCurrentIndex(0);
    setIsPlaying(true);
    setSpeed(1);
  }, [images]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="Images"
            className={isVisible.includes(index) ? 'object-cover' : 'hidden object-cover'}
            style={{ maxHeight: "40vh "}}
          />
        ))}
      </div>
      <div className="mt-4 w-64">
        <input
          type="range"
          min={0}
          max={images.length - 1}
          value={currentIndex}
          onChange={handleSliderChange}
          className="w-full"
        />
      </div>
      <div className="tools absolute top-4 left-2 text-xs">
        <button onClick={handlePlayPause} className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1">
          Play / Pause
          <FontAwesomeIcon
            icon={isPlaying ? faPause : faPlay}
            className="ml-2"
            size="lg"
          />
        </button>
        <button onClick={handleSpeedChange} className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full mr-1">
          Speed
          <span className="ml-2">{speed}x</span>
        </button>
        {pipeline && (
          <button
            className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-1 px-2 rounded-full"
            onClick={() => {
              dispatch({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES })
              toast.promise(waitFiveSeconds(2), {
                loading: "Reloading images...",
                success: "Images reloaded!",
                error: "",
              })
            }}
          >
            Refresh
            <FontAwesomeIcon
              icon={faSync}
              className="ml-2"
              size="lg"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default ImagePlayer;
