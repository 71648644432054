import { GlobalState } from "../../store/models";

export const selectLeBailCifFiles = (state: GlobalState) => state.leBailReducer.cifFiles;
export const selectLeBailCifTemplateFiles = (state: GlobalState) => state.leBailReducer.cifTemplateFiles;
export const selectLeBailCifFilesABC = (state: GlobalState) => state.leBailReducer.cifFilesABC;

export const selectLeBailInstrumentalFile = (state: GlobalState) => state.leBailReducer.instrumentalFile;
export const selectLeBailInstrumentalTemplateFile = (state: GlobalState) => state.leBailReducer.instrumentalTemplateFile;
export const selectLeBailInstrumentalFileWaveLength = (state: GlobalState) => state.leBailReducer.instrumentalFileWaveLength;

export const selectLeBailDataFiles = (state: GlobalState) => state.leBailReducer.dataFiles;
export const selectLeBailDataActiveFiles = (state: GlobalState) => state.leBailReducer.dataActiveFiles;
export const selectLeBailDataViewFile = (state: GlobalState) => state.leBailReducer.dataViewFile;

export const selectLeBailHistogram = (state: GlobalState) => state.leBailReducer.histogram;
export const selectLeBailRefinedHistogram = (state: GlobalState) => state.leBailReducer.refinedHistogram;

export const selectLeBailWavelength = (state: GlobalState) => state.leBailReducer.wavelength;

export const selectLeBailRangeHistory = (state: GlobalState) => state.leBailReducer.rangeHistory;
export const selectLeBailRangeLower = (state: GlobalState) => state.leBailReducer.rangeLower;
export const selectLeBailRangeHigher = (state: GlobalState) => state.leBailReducer.rangeHigher;
export const selectLeBailRangeInitial = (state: GlobalState) => state.leBailReducer.rangeInitial;

export const selectLeBailCycles = (state: GlobalState) => state.leBailReducer.cycles;

export const selectLeBail = (state: GlobalState) => state.leBailReducer;
