import { GlobalState } from "../../store/models";

export const getInlineOperationInProgress = (state: GlobalState) => state.generalReducer.inlineOperationInProgress;
export const getInInlineOperationInProgress = (state: GlobalState) => state.generalReducer.inInlineOperationInProgress;
export const getInInInlineOperationInProgress = (state: GlobalState) => state.generalReducer.inInInlineOperationInProgress;
export const selectInstanceId = (state: GlobalState) => state.generalReducer.instanceId;
export const selectProgress = (state: GlobalState) => state.generalReducer.progress;
export const selectProgressMessage = (state: GlobalState) => state.generalReducer.progressMessage;
export const selectProgressTitle = (state: GlobalState) => state.generalReducer.progressTitle;
export const selectDownloadInstanceId = (state: GlobalState) => state.generalReducer.downloadInstance;
export const selectFileDownloadProgress = (state: GlobalState) => state.generalReducer.fileUploadProgress;
export const selectUploadStatus = (state: GlobalState) => state.generalReducer.uploadStatus;
export const selectReloadFileSystem = (state: GlobalState) => state.generalReducer.reloadFileSystem;
export const selectGeneral = (state: GlobalState) => state.generalReducer;
export const selectFileFetchingInProgress = (state: GlobalState) => state.generalReducer.fileFetchingInProgress;
export const selectOperationInProgressDict = (state: GlobalState) => state.generalReducer.operationInProgressDict;

export const selectState = (state: GlobalState) => state;

export const selectPipelineProgress = (state: GlobalState) => state.generalReducer.pipeline.progress;
export const selectUserLoaded = (state: GlobalState) => state.generalReducer.userLoaded;