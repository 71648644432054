export const SET_AZIMUTHAL_INTEGRATION = 'SET_AZIMUTHAL_INTEGRATION';
export const SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_PARTITIONS = 'SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_PARTITIONS';
export const SET_AZIMUTHAL_INTEGRATION_PARTITIONS = 'SET_AZIMUTHAL_INTEGRATION_PARTITIONS';
export const SET_AZIMUTHAL_INTEGRATION_X_AXIS_UNIT = 'SET_AZIMUTHAL_INTEGRATION_X_AXIS_UNIT';
export const SET_AZIMUTHAL_INTEGRATION_RANGE_UPPER = 'SET_AZIMUTHAL_INTEGRATION_RANGE_UPPER';
export const SET_AZIMUTHAL_INTEGRATION_RANGE_LOWER = 'SET_AZIMUTHAL_INTEGRATION_RANGE_LOWER';
export const SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_POINTS = 'SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_POINTS';
export const SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_UPPER = 'SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_UPPER';
export const SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_LOWER = 'SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_LOWER';
export const SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_BINNING = 'SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_BINNING';
export const SET_AZIMUTHAL_INTEGRATION_INTEGRATION = 'SET_AZIMUTHAL_INTEGRATION_INTEGRATION';
export const SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_AZIMUTHAL_BINS = 'SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_AZIMUTHAL_BINS';
export const SET_AZIMUTHAL_INTEGRATION_ACTIVE_TAB = 'SET_AZIMUTHAL_INTEGRATION_ACTIVE_TAB';
export const SET_AZIMUTHAL_INTEGRATION_ANALYSIS_X = 'SET_AZIMUTHAL_INTEGRATION_ANALYSIS_X';
export const SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION = 'SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION';
export const SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING = 'SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING';
export const SET_AZIMUTHAL_INTEGRATION_ACTIVE_IMAGE_PATH = 'SET_AZIMUTHAL_INTEGRATION_ACTIVE_IMAGE_PATH';
export const SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE = 'SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE';
export const SET_AZIMUTHAL_INTEGRATION_IMAGE_VIDEO = 'SET_AZIMUTHAL_INTEGRATION_IMAGE_VIDEO';
export const SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING_INDEX = 'SET_AZIMUTHAL_INTEGRATION_ANALYSIS_CAKING_INDEX';
export const SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION_INDEX = 'SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INTEGRATION_INDEX';
export const SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INFO = 'SET_AZIMUTHAL_INTEGRATION_ANALYSIS_INFO';
export const SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE_ROW = 'SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE_ROW';
export const SET_AZIMUTHAL_INTEGRATION_IMAGE_COLOR_MAP = 'SET_AZIMUTHAL_INTEGRATION_IMAGE_COLOR_MAP';
export const SET_AZIMUTHAL_INTEGRATION_IMAGE_LOG = 'SET_AZIMUTHAL_INTEGRATION_IMAGE_LOG';
export const SET_AZIMUTHAL_INTEGRATION_IMAGE_COMBINED = 'SET_AZIMUTHAL_INTEGRATION_IMAGE_COMBINED';
export const SET_AZIMUTHAL_INTEGRATION_INTEGRATION_RESULT = 'SET_AZIMUTHAL_INTEGRATION_INTEGRATION_RESULT';
export const SET_AZIMUTHAL_INTEGRATION_CAKING_RESULT = 'SET_AZIMUTHAL_INTEGRATION_CAKING_RESULT';

export const GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION = 'GET_AZIMUTHAL_INTEGRATION_CALLBACK_INTEGRATION';
export const GET_AZIMUTHAL_INTEGRATION_CALLBACK_CAKING = 'GET_AZIMUTHAL_INTEGRATION_CALLBACK_CAKING';
export const GET_AZIMUTHAL_INTEGRATION_IMAGE = 'GET_AZIMUTHAL_INTEGRATION_IMAGE';
export const GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES = 'GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES';
export const GET_AZIMUTHAL_INTEGRATION_DOWNLOAD = 'GET_AZIMUTHAL_INTEGRATION_DOWNLOAD';

export const RESET_AZIMUTHAL_INTEGRATION = 'RESET_AZIMUTHAL_INTEGRATION';
