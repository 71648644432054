import toast from "react-hot-toast";
import { AzimuthalIntegrationPartition } from "../../../models/analysis.azimuthalIntegration.model";

export function validateInputAzimuthalIntegration(partitions: AzimuthalIntegrationPartition[], numberOfPoints: number, rangeAzimuthal: {lower: number, upper: number}, azimuthalBinning: boolean, azimuthalNumberOfBins: number, range: {lower: number, upper: number}) {
	for (let i = 0; i < partitions.length; i++) {
		if (partitions[i].dataFiles.length === 0) {
			toast.error(`Detector ${i + 1} must have at least one data file`);
			return false
		}
		if (partitions[i].calibrationFile === "") {
			toast.error(`Partition ${i + 1} must have a calibration file`);
			return false
		}
	}

	if (numberOfPoints < 1) {
		toast.error(`Number of points must be greater than 0`);
		return false
	}

	if (rangeAzimuthal.lower < 0 || rangeAzimuthal.lower > 360) {
		toast.error(`Azimuthal range lower must be between 0 and 360`);
		return false
	}

	if (rangeAzimuthal.upper < 0 || rangeAzimuthal.upper > 360) {
		toast.error(`Azimuthal range upper must be between 0 and 360`);
		return false
	}

	if (rangeAzimuthal.lower > rangeAzimuthal.upper) {
		toast.error(`Azimuthal range lower must be less than azimuthal range upper`);
		return false
	}

	if (azimuthalBinning && azimuthalNumberOfBins < 1) {
		toast.error(`Number of azimuthal bins must be greater than 0`);
		return false
	}

	// check that the range is valid
	if (range.lower > range.upper) {
		toast.error(`Range lower must be less than range upper`);
		return false
	}

	return true;
}
