import { apiPhaseQuantification, apiStressStrain, requestApi } from "../../api";
import { StressFitting, StressParams } from "../../models/analysis.general.model";

export const preProcessObject = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));

  delete newObj.histogram;
  delete newObj.fitting;
  delete newObj.partitionFitting;
  delete newObj.partition;

  return newObj;
}

export const remapObject = (data: { [dataFile: string]: any }): StressFitting => {
  const result: StressFitting = {};

  Object.keys(data).forEach(dataFile => {
    result[dataFile] = {};

    const fileData = data[dataFile];
    Object.keys(fileData).forEach(key => {
      const [peak, property] = key.split(/_(.+)/);

      if (!result[dataFile][peak]) {
        result[dataFile][peak] = {
          center: 0,
          center_err: 0,
          strain: 0,
          strain_err: 0,
          corr_angle: 0,
          rsquared: 0,
        };
      }

      switch (property) {
        case 'center':
          result[dataFile][peak].center = fileData[key];
          break;
        case 'center_err':
          result[dataFile][peak].center_err = fileData[key];
          break;
        case 'strain':
          result[dataFile][peak].strain = fileData[key];
          break;
        case 'strain_err':
          result[dataFile][peak].strain_err = fileData[key];
          break;
        case 'corr_angle':
          result[dataFile][peak].corr_angle = fileData[key];
          break;
        case 'rsquared':
          result[dataFile][peak].rsquared = fileData[key];
          break;
        default:
          console.log(`Unknown property: ${property}`);
          break;
      }
    });
  });

  return result;
};


export const remapObjectParams = (data: { [dataFile: string]: any }): StressParams => {
  const result: StressParams = {};

  Object.keys(data).forEach(dataFile => {
    result[dataFile] = {};

    const fileData = data[dataFile];
    Object.keys(fileData).forEach(key => {
      const [peak, property] = key.split(/_(.+)/);

      if (!result[dataFile][peak]) {
        result[dataFile][peak] = {
          "strain_xx": 0,
          "strain_xx: std_dev": 0,
          "strain_xz": 0,
          "strain_xz: std_dev": 0,
          "strain_zz": 0,
          "strain_zz: std_dev": 0,
          "stress_xx [MPa]": 0,
          "stress_xx: std_dev [MPa]": 0,
          "stress_xz [MPa]": 0,
          "stress_xz: std_dev [MPa]": 0,
          "stress_zz [MPa]": 0,
          "stress_zz: std_dev [MPa]": 0,
          "von_mises [MPa]": 0,
          "von_mises: std_dev [MPa]": 0,
        };
      }

      switch (property) {
        case 'strain_xx':
          result[dataFile][peak]["strain_xx"] = fileData[key];
          break;
        case 'strain_xx: std_dev':
          result[dataFile][peak]["strain_xx: std_dev"] = fileData[key];
          break;
        case 'strain_xz':
          result[dataFile][peak]["strain_xz"] = fileData[key];
          break;
        case 'strain_xz: std_dev':
          result[dataFile][peak]["strain_xz: std_dev"] = fileData[key];
          break;
        case 'strain_zz':
          result[dataFile][peak]["strain_zz"] = fileData[key];
          break;
        case 'strain_zz: std_dev':
          result[dataFile][peak]["strain_zz: std_dev"] = fileData[key];
          break;
        case 'stress_xx [MPa]':
          result[dataFile][peak]["stress_xx [MPa]"] = fileData[key];
          break;
        case 'stress_xx: std_dev [MPa]':
          result[dataFile][peak]["stress_xx: std_dev [MPa]"] = fileData[key];
          break;
        case 'stress_xz [MPa]':
          result[dataFile][peak]["stress_xz [MPa]"] = fileData[key];
          break;
        case 'stress_xz: std_dev [MPa]':
          result[dataFile][peak]["stress_xz: std_dev [MPa]"] = fileData[key];
          break;
        case 'stress_zz [MPa]':
          result[dataFile][peak]["stress_zz [MPa]"] = fileData[key];
          break;
        case 'stress_zz: std_dev [MPa]':
          result[dataFile][peak]["stress_zz: std_dev [MPa]"] = fileData[key];
          break;
        case 'von_mises [MPa]':
          result[dataFile][peak]["von_mises [MPa]"] = fileData[key];
          break;
        case 'von_mises: std_dev [MPa]':
          result[dataFile][peak]["von_mises: std_dev [MPa]"] = fileData[key];
          break;
        default:
          console.log(`Unknown property: ${property}`);
          break;
      }
    });
  });

  return result;
};


export const getStressStrainCallback = async (token, req) => {
  return await requestApi(`callback`, "POST", req, token, {}, apiStressStrain);
};

export const getStressStrain = async (token, req) => {
  return await requestApi(`caller`, "POST", req, token, {}, apiStressStrain);
};

export const getStressStrainCallbackFitting = async (token, req) => {
  return await requestApi(`callback-fitted`, "POST", req, token, {}, apiPhaseQuantification);
}

export const getStressStrainCallbackResult = async (token, req) => {
  return await requestApi(`callback-result`, "POST", req, token, {}, apiStressStrain);
}
