import { apiAzimuthalIntegration, apiGeneral, requestApi } from "../../api";

export const preProcessObject = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));

  delete newObj.analysis;
  delete newObj.integrationPipeline;

  return newObj;
}

export const getAzimuthalIntegrationCaller = async (token, req) => {
  return await requestApi(`on-demand-add-task`, "POST", req, token, {}, apiGeneral);
};

export const getAzimuthalIntegration = async (token, userId, projectId, partitions, xAxisUnit, range, numberOfPoints, azimuthalRange, azimuthalBinning, numberOfAzimuthalBins, integration) => {
  const req = {
    token,
    userId,
    projectId,
    partitions,
    xAxisUnit,
    range,
    numberOfPoints,
    azimuthalRange,
    azimuthalBinning,
    numberOfAzimuthalBins,
    integration,
  };

  return await requestApi(`/caller`, "POST", req, token, {}, apiAzimuthalIntegration);
};

export const getAzimuthalIntegrationImage = async (token, projectId, detectorFile, colorMap, log) => {
  const req = {
    token,
    projectId,
    detectorFile,
    colorMap,
    log,
  };

  return await requestApi(`/detector`, "POST", req, token, {}, apiGeneral);
};

export const getAzimuthalIntegrationCallbackIntegration = async (token, projectId, indexes) => {
  const req = {
    token,
    projectId,
    indexes
  };

  return await requestApi(`/callback-integration`, "POST", req, token, {}, apiAzimuthalIntegration);
}

export const getAzimuthalIntegrationCallbackCaking = async (token, projectId, index, cakingRange) => {
  const req = {
    token,
    projectId,
    index,
    cakingRange,
  };

  return await requestApi(`/callback-caking`, "POST", req, token, {}, apiAzimuthalIntegration);
}

export const getAzimuthalIntegrationCallbackImages = async (token, projectId ) => {
  const req = {
    token,
    projectId,
  };

  return await requestApi(`/callback-images`, "POST", req, token, {}, apiAzimuthalIntegration);
}
