import Loader from "react-loader-spinner";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getInInInlineOperationInProgress, getInInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { Dispatch } from "redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { selectLeBailDataFiles, selectLeBailDataViewFile, selectLeBailHistogram, selectLeBailRefinedHistogram } from "../../../redux/leBail/leBailSelectors";
import { SET_LE_BAIL_RANGE } from "../../../redux/leBail/leBailActions";
import { GraphData } from "./helpers";
import { GraphConfig, GraphLayout, GraphStyling } from "../../../constants/graphs";
import { HistogramGraph } from "../shared/graphs/histogram";


const LeBailGraph = ({ dispatch }: {dispatch: Dispatch}) => {
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress);
  const inInInlineOperationInProgress = useSelector(getInInInlineOperationInProgress);
	const dataFiles = useSelector(selectLeBailDataFiles);
	const dataViewFile = useSelector(selectLeBailDataViewFile);
	const refinedHistogram = useSelector(selectLeBailRefinedHistogram);
  const histogram = useSelector(selectLeBailHistogram);
  
  const [chartData, setChartData] = useState<any>([]);
  const [chartShapes, setChartShapes] = useState<any>([]);

  const handleClick = (event: any) => {
    if (event.points.length > 0) {
      dispatch({ type: SET_LE_BAIL_RANGE, payload: event.points[0].x });
      toast.success("Updated Range")
    }
  };

  useEffect(() => {
    const histogramGraphData = HistogramGraph(histogram);
    const refinedHistogramData = GraphData(dataViewFile, histogram, dataFiles, refinedHistogram);

    setChartData([ ...histogramGraphData.histogramData, ...refinedHistogramData] );
    setChartShapes([ ...histogramGraphData.phasesShapes ]);
  }, [dataFiles, dataViewFile, histogram, refinedHistogram]);

  return (
    <div className="relative">
      {(inInlineOperationInProgress || inInInlineOperationInProgress) &&
        <div className={"absolute top-2 right-2"}>
          <Loader type="Oval" color="#68d391" height={25} width={25} />
        </div>
      }
      <div className={"flex"}>
      </div>
      <div className={"flex justify-around"}>
        <Plot
          data={chartData}
          layout={{
            ...GraphLayout,
            shapes: chartShapes,
          }}
          config={GraphConfig}
          useResizeHandler={true}
          style={GraphStyling}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default LeBailGraph;
