import { StressStrainModel } from "../../models/analysis.stressStrain.model";
import { SET_STRESS_STRAIN_DATA_FILES, SET_STRESS_STRAIN_CIF_FILES, SET_STRESS_STRAIN_INSTRUMENTAL_FILE, SET_STRESS_STRAIN_WAVELENGTH, SET_STRESS_STRAIN_CIF_TEMPLATE_FILES, SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE, SET_STRESS_STRAIN, SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH, SET_STRESS_STRAIN_DATA_VIEW_FILE, SET_STRESS_STRAIN_RESET, SET_STRESS_STRAIN_DATA_ACTIVE_FILES, SET_STRESS_STRAIN_PARTITIONS, SET_STRESS_STRAIN_ACTIVE_INDEX, SET_STRESS_STRAIN_SELECTING_PEAKS, SET_STRESS_STRAIN_HISTOGRAM, SET_STRESS_STRAIN_PARAMETERS, SET_STRESS_STRAIN_FITTING, SET_STRESS_STRAIN_CIF_FILES_ABC, SET_STRESS_STRAIN_PLANE_STRESS_CONDITION, SET_STRESS_STRAIN_ROTATION_X, SET_STRESS_STRAIN_ROTATION_Y, SET_STRESS_STRAIN_ROTATION_Z, SET_STRESS_STRAIN_STRESS_PEAKS, SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN, SET_STRESS_STRAIN_PARAMETERS_RESULT, SET_STRESS_STRAIN_PIPELINE_PEAK_PARAMETERS } from "./stressStrainActions";

const initialState: StressStrainModel = {
  dataFiles: [],
  dataActiveFiles: [],
  dataViewFile: "",
  cifFiles: [],
  cifFilesABC: {},
  instrumentalFile: "",
  wavelength: 0,
  templateCifFiles: [],
  templateInstrumentalFile: "",
  instrumentalFileWaveLength: 0,
  partitions: [{
    name: "1",
    rangeHistory: [0, 0],
    rangeLower: {
      x: 0,
      y: 0
    },
    rangeHigher: {
      x: 0,
      y: 0
    },
    peaks: [],
  }],
  partition: {
    activeIndex: 0,
    selectingPeaks: false,
  },
  movingPeaks: false,
  fitting: {},
  histogram: {
    data: {
      x: [],
      y: []
    },
    phases: {}
  },
  partitionFitting: {},
  rotation: {
    x: 0,
    y: 0,
    z: 0
  },
  stressPeaks: [
    []
  ],
  planeStressCondition: true,
  stressFitting: {},
  stressResult: {},
  stressPipelinePeakParameters: {}
};

export default function stressStrainReducer(state = initialState, { type, payload }: { type: string; payload: any }): StressStrainModel {
  switch (type) {
    case SET_STRESS_STRAIN_DATA_FILES:
      return {
        ...state,
        dataFiles: payload,
      }
    case SET_STRESS_STRAIN_CIF_FILES:
      return {
        ...state,
        cifFiles: payload,
      }
    case SET_STRESS_STRAIN_INSTRUMENTAL_FILE:
      return {
        ...state,
        instrumentalFile: payload,
      }
    case SET_STRESS_STRAIN_WAVELENGTH:
      return {
        ...state,
        wavelength: payload,
      }
    case SET_STRESS_STRAIN_CIF_TEMPLATE_FILES:
      return {
        ...state,
        templateCifFiles: payload,
      }
    case SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE:
      return {
        ...state,
        templateInstrumentalFile: payload,
      }
    case SET_STRESS_STRAIN:
      return {
        ...payload
      }
    case SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload,
      }
    case SET_STRESS_STRAIN_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      }
    case SET_STRESS_STRAIN_RESET:
      return {
        ...initialState
      }
    case SET_STRESS_STRAIN_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload
      }
    case SET_STRESS_STRAIN_PARTITIONS:
      const newPartitions = [...payload];
      let updatedStressStrainPeaks = [...state.stressPeaks];

      // Loop through the new partitions and adjust the stressStrainPeaks dimensions
      newPartitions.forEach((partition, partitionIndex) => {
        // Ensure the stressStrainPeaks outer array has the correct number of partitions
        if (!updatedStressStrainPeaks[partitionIndex]) {
          updatedStressStrainPeaks[partitionIndex] = [];
        }

        // Adjust the inner array of peaks for this partition
        partition.peaks.forEach((peak, peakIndex) => {
          if (!updatedStressStrainPeaks[partitionIndex][peakIndex]) {
            // If there's no existing value, create a default StressStrainPeaks object
            updatedStressStrainPeaks[partitionIndex][peakIndex] = {
              youngsModulus: 0,
              poissonRatio: 0,
              tthRef: 0,
            };
          }
        });

        // If there are fewer peaks in the partition, trim the stressStrainPeaks array
        if (updatedStressStrainPeaks[partitionIndex].length > partition.peaks.length) {
          updatedStressStrainPeaks[partitionIndex] = updatedStressStrainPeaks[partitionIndex].slice(0, partition.peaks.length);
        }
      });

      // If there are fewer partitions, trim the stressStrainPeaks array
      if (updatedStressStrainPeaks.length > newPartitions.length) {
        updatedStressStrainPeaks = updatedStressStrainPeaks.slice(0, newPartitions.length);
      }

      return {
        ...state,
        partitions: newPartitions,
        stressPeaks: updatedStressStrainPeaks,
      };
    case SET_STRESS_STRAIN_ACTIVE_INDEX:
      return {
        ...state,
        partition: {
          ...state.partition,
          activeIndex: payload
        }
      }
    case SET_STRESS_STRAIN_SELECTING_PEAKS:
      return {
        ...state,
        partition: {
          ...state.partition,
          selectingPeaks: payload
        }
      }
    case SET_STRESS_STRAIN_HISTOGRAM:
      return {
        ...state,
        histogram: payload
      }
    case SET_STRESS_STRAIN_PARAMETERS:
      return {
        ...state,
        fitting: {
          ...payload
        }
      }
    case SET_STRESS_STRAIN_FITTING:
      return {
        ...state,
        partitionFitting: {
          [payload.file]: payload.data
        }
      }
    case SET_STRESS_STRAIN_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload
        }
      }
    case SET_STRESS_STRAIN_ROTATION_X:
      return {
        ...state,
        rotation: {
          ...state.rotation,
          x: payload
        }
      }
    case SET_STRESS_STRAIN_ROTATION_Y:
      return {
        ...state,
        rotation: {
          ...state.rotation,
          y: payload
        }
      }
    case SET_STRESS_STRAIN_ROTATION_Z:
      return {
        ...state,
        rotation: {
          ...state.rotation,
          z: payload
        }
      }
    case SET_STRESS_STRAIN_STRESS_PEAKS:
      return {
        ...state,
        stressPeaks: [...payload]
      }
    case SET_STRESS_STRAIN_PLANE_STRESS_CONDITION:
      return {
        ...state,
        planeStressCondition: payload
      }
    case SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN:
      return {
        ...state,
        stressFitting: {
          ...payload
        }
      }
    case SET_STRESS_STRAIN_PARAMETERS_RESULT:
      return {
        ...state,
        stressResult: {
          ...payload
        }
      }
    case SET_STRESS_STRAIN_PIPELINE_PEAK_PARAMETERS:
      return {
        ...state,
        stressPipelinePeakParameters: {
          ...payload
        }
      }
    default:
      return state;
  }
}
