import Loader from "react-loader-spinner";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getInInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { selectDislocationDensityDataViewFile, selectDislocationDensityHistogram, selectDislocationDensityK2CDK, selectDislocationDensityK2CLNA, selectDislocationDensityL0Analysis, selectDislocationDensityL0PickerData, selectDislocationDensityL0_selectionActive, selectDislocationDensityPartitionActiveIndex, selectDislocationDensityPartitionFitting, selectDislocationDensityPartitionSelectingPeaks, selectDislocationDensityPartitions, selectDislocationDensityRHO_selectionActive, selectDislocationDensityRhoAnalysis, selectDislocationDensityRhoPickerData, selectDislocationDensityWH } from "../../../redux/dislocationDensity/dislocationDensitySelectors";
import { DropDownOptions } from "../../../data/models";
import DropDown from "../shared/drop-down";
import { getDislocationDensityGraph, getDislocationDensityGraphLabels, GraphDropDowns, HandleClick } from "./helpers";
import { HistogramGraph, PartitionFittingGraph, PartitionSelectionHistogramRange } from "../shared/graphs/histogram";
import { GraphConfig, GraphLayout, GraphStyling } from "../../../constants/graphs";

const DislocationDensityGraph = ({dispatch }: { dispatch: Dispatch }) => {
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress)
  const dataViewFile = useSelector(selectDislocationDensityDataViewFile);
  const selectedPartitionIndex = useSelector(selectDislocationDensityPartitionActiveIndex);
  const selectPeaks = useSelector(selectDislocationDensityPartitionSelectingPeaks);
  const partitions = useSelector(selectDislocationDensityPartitions);
  const histogram = useSelector(selectDislocationDensityHistogram);
  const partitionFitting = useSelector(selectDislocationDensityPartitionFitting);
  const wh = useSelector(selectDislocationDensityWH);
  const rhoSelectionActive = useSelector(selectDislocationDensityRHO_selectionActive);
	const l0SelectionActive = useSelector(selectDislocationDensityL0_selectionActive);
  const k2_cdk = useSelector(selectDislocationDensityK2CDK);
  const k2c_lna = useSelector(selectDislocationDensityK2CLNA);
  const l0Picker = useSelector(selectDislocationDensityL0PickerData);
  const rhoPicker = useSelector(selectDislocationDensityRhoPickerData);
  const l0Analysis = useSelector(selectDislocationDensityL0Analysis);
  const rhoAnalysis = useSelector(selectDislocationDensityRhoAnalysis);

  const [activeTab, setActiveTab] = useState("histogram")
  const [chartFitting, setChartFitting] = useState<{ [key: string]: any[] }>({});
  const [chartShapes, setChartShapes] = useState<any>([]);
  const [reload, setReload] = useState(false);
  const [maxCoordinate, setMaxCoordinate] = useState<any>({ xLower: undefined, xHigher: undefined, yMax: undefined, yMin: undefined });
  const [visibleDiagrams, setVisibleDiagrams] = useState({ id: "diagrams", label: "Diagrams", options: [] } as DropDownOptions)

  useEffect(() => {
    setMaxCoordinate(PartitionSelectionHistogramRange(histogram.data.x, histogram.data.y, partitions, selectPeaks, selectedPartitionIndex));
  }, [selectPeaks, partitions, selectedPartitionIndex, reload, histogram]);

  const handleClick = (event: any) => {
    HandleClick(event, selectPeaks, selectedPartitionIndex, dispatch, rhoSelectionActive, l0SelectionActive);
    setReload(!reload);
  };

  useEffect(() => {
    const currentChartShapes = PartitionFittingGraph(partitionFitting, dataViewFile)
    const partitionHistogramGraph = HistogramGraph(histogram, partitions, selectedPartitionIndex);
    const dislocationDensityGraph = getDislocationDensityGraph(wh, k2_cdk, k2c_lna, l0Picker, rhoPicker, l0Analysis, rhoAnalysis)

    currentChartShapes["histogram"] = [...partitionHistogramGraph.histogramData, ...partitionHistogramGraph.traces];

    setChartFitting( { ...currentChartShapes, ...dislocationDensityGraph });

    setChartShapes([ ...partitionHistogramGraph.phasesShapes ]);
  }, [dataViewFile, histogram, k2_cdk, k2c_lna, l0Analysis, l0Picker, partitionFitting, partitions, rhoAnalysis, rhoPicker, selectedPartitionIndex, wh]);

  useEffect(() => {
    setVisibleDiagrams(GraphDropDowns(partitionFitting, dataViewFile, wh, k2_cdk, l0Picker, rhoPicker, l0Analysis, rhoAnalysis))
  }, [dataViewFile, k2_cdk, l0Analysis, l0Picker, partitionFitting, rhoAnalysis, rhoPicker, wh]);

  useEffect(() => {
    if (l0SelectionActive) {
      setActiveTab("l0");
    }
  }, [l0SelectionActive])

  useEffect(() => {
    if (rhoSelectionActive) {
      setActiveTab("rho");
    }
  }, [rhoSelectionActive])

  useEffect(() => {
    if (selectPeaks) {
      setActiveTab("histogram");
    }
  }, [selectPeaks])

  return (
    <div className="relative flex flex-col justify-center items-center">
      {(inInlineOperationInProgress || inInlineOperationInProgress) &&
        <div className={"absolute top-2 right-2"}>
          <Loader type="Oval" color="#68d391" height={25} width={25} />
        </div>
      }
      <div className={"flex justify-center items-center w-96 z-10"}>
        <DropDown
          DropDownOptions={visibleDiagrams}
          title={"Select Diagram"}
          setSelected={(value: string) => setActiveTab(value)}
          selected={activeTab}
          multiple={false}
          padding={"p-2"}
          closeOnClick={true}
        />
      </div>
      <div className={"flex justify-around w-full"}>
        <Plot
          data={
            chartFitting[activeTab] ? chartFitting[activeTab] : []
          }
          layout={{
            ...GraphLayout,
            xaxis: getDislocationDensityGraphLabels(maxCoordinate, activeTab, "x"),
            yaxis: getDislocationDensityGraphLabels(maxCoordinate, activeTab, "y"),
            shapes: activeTab === "histogram" ? chartShapes : [],
          }}
          config={GraphConfig}
          useResizeHandler={true}
          style={GraphStyling}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default DislocationDensityGraph;
