import { useEffect, useState } from "react";
import { GET_INSTANCE_RUNNING_STATUS, GET_ON_DEMAND_PROGRESS, GET_ON_DEMAND_STATUS } from "../../../redux/project/projectActions";


export const useInstanceStatusPolling = (instanceId, projectType, dispatch, pipeline?) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (pipeline) return;

    if (intervalId !== undefined) clearInterval(intervalId);

    if (instanceId !== "") {
      const newIntervalId = setInterval(() => {
        dispatch({ type: GET_INSTANCE_RUNNING_STATUS,
          payload: { instanceId: instanceId, projectType: projectType },
        });
      }, 5000);

      setIntervalId(newIntervalId);
    }

    return () => {
      if (intervalId !== undefined) clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, instanceId, projectType]);
};


export const OnDemandPolling = (analysisType, onDemandStatus, dispatch, pipeline) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (pipeline) return;

    if (intervalId !== undefined) clearInterval(intervalId);

    const newIntervalId = setInterval(() => {
      if (onDemandStatus === "RUNNING") dispatch({ type: GET_ON_DEMAND_PROGRESS, payload: { analysisType }});
    }, 5000);

    setIntervalId(newIntervalId);

    return () => {
      if (intervalId !== undefined) clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline, onDemandStatus]);
}


export const OnDemandStatusPolling = (analysisType, onDemandStatus, dispatch, pipeline) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (pipeline) return;

    if (intervalId !== undefined) clearInterval(intervalId);

    const intervalTime = onDemandStatus === "RUNNING" ? 15000 : 5000;

    const newIntervalId = setInterval(async () => {
      dispatch({ type: GET_ON_DEMAND_STATUS, payload: { analysisType }});
    }, intervalTime);

    setIntervalId(newIntervalId);

    return () => {
      if (intervalId !== undefined) clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pipeline, onDemandStatus]);
}