import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

export default function ProgressBar({progress=0, message, title, hideProgress=false}: {progress?: number, message: string, title: string, hideProgress?: boolean}) {
  return (
    <div
      className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg"
      style={{ transition: 'opacity 0.5s', width: "350px" }}
    >
      <div className="flex items-center">
        <div className="w-12 h-12 bg-blue-500 rounded-full">
          {/* Center the icon */}
          <div className="flex justify-center items-center h-full w-full">
            <FontAwesomeIcon icon={faGear} spin className="text-white" size='2x'/>
          </div>
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold">{title}</h4>
          <p className="text-sm text-gray-500">{message}</p>
        </div>
      </div>
      {hideProgress ? null : 
        <>
        <div className="bg-gray-300 h-4 rounded-lg mt-2">
          <div
            className="bg-blue-500 h-4 rounded-lg"
            style={{ width: `${progress}%`, transition: 'width 0.5s ease' }}
          ></div>
        </div>
        <p className="text-xs text-gray-500 mt-1">{`${progress.toFixed(2)}% done`}</p>
        </>
      }
    </div>
  );
}
