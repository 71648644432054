import { GlobalState } from "../../store/models";

export const selectDataCalibration = (state: GlobalState) => state.dataCalibrationReducer;
export const selectDataCalibrationDataFile = (state: GlobalState) => state.dataCalibrationReducer.dataFile;
export const selectDataCalibrationMetaFile = (state: GlobalState) => state.dataCalibrationReducer.metaFile;
export const selectDataCalibrationThresholdMask = (state: GlobalState) => state.dataCalibrationReducer.thresholdMask;
export const selectDataCalibrationCalibrantType = (state: GlobalState) => state.dataCalibrationReducer.calibrantType;
export const selectDataCalibrationWavelength = (state: GlobalState) => state.dataCalibrationReducer.wavelength;
export const selectDataCalibrationRingPicking = (state: GlobalState) => state.dataCalibrationReducer.ringPicking;
export const selectDataCalibrationNumberOfDataPoints = (state: GlobalState) => state.dataCalibrationReducer.numberOfDataPoints;
export const selectDataCalibrationRadialRange = (state: GlobalState) => state.dataCalibrationReducer.radialRange;
export const selectDataCalibrationAzimuthalRange = (state: GlobalState) => state.dataCalibrationReducer.azimuthalRange;
export const selectDataCalibrationPolarizationFactor = (state: GlobalState) => state.dataCalibrationReducer.polarizationFactor;
export const selectDataCalibrationUnit = (state: GlobalState) => state.dataCalibrationReducer.unit;
export const selectDataCalibrationDetectorType = (state: GlobalState) => state.dataCalibrationReducer.detectorType;
export const selectDataCalibrationDetectorSize = (state: GlobalState) => state.dataCalibrationReducer.detectorSize;
export const selectDataCalibrationDetectorPixelSize = (state: GlobalState) => state.dataCalibrationReducer.detectorPixelSize;
export const selectDataCalibrationMask = (state: GlobalState) => state.dataCalibrationReducer.mask;
export const selectDataCalibrationDrawingMode = (state: GlobalState) => state.dataCalibrationReducer.drawingMode;
export const selectDataCalibrationDrawings = (state: GlobalState) => state.dataCalibrationReducer.drawings;
export const selectDataCalibrationDrawingActiveIndex = (state: GlobalState) => state.dataCalibrationReducer.drawingActiveIndex;
export const selectDataCalibrationPeakThreshold = (state: GlobalState) => state.dataCalibrationReducer.peakThreshold;
export const selectDataCalibrationRingPickingRing = (state: GlobalState) => state.dataCalibrationReducer.ringPickingRing;
export const selectDataCalibrationRingPickingMode = (state: GlobalState) => state.dataCalibrationReducer.ringPickingMode;
export const selectDataCalibrationImageOptions = (state: GlobalState) => state.dataCalibrationReducer.imageOptions;
export const selectDataCalibrationActiveImagePath = (state: GlobalState) => state.dataCalibrationReducer.activeImagePath;
export const selectDataCalibrationImageMask = (state: GlobalState) => state.dataCalibrationReducer.imageMask;
export const selectDataCalibrationImageGeometry = (state: GlobalState) => state.dataCalibrationReducer.imageGeometry;
export const selectDataCalibrationCalibrationFile = (state: GlobalState) => state.dataCalibrationReducer.calibrationFile;
export const selectDataCalibrationAnalysis = (state: GlobalState) => state.dataCalibrationReducer.analysis;
export const selectDataCalibrationCustomDetector = (state: GlobalState) => state.dataCalibrationReducer.customDetector;
export const selectDataCalibrationMaskingFile = (state: GlobalState) => state.dataCalibrationReducer.maskingFile;