import { GlobalState } from "../../store/models";

export const selectPeakFitting = (state: GlobalState) => state.peakFittingReducer;

export const selectPeakFittingDataFiles = (state: GlobalState) => state.peakFittingReducer.dataFiles;
export const selectPeakFittingDataViewFile = (state: GlobalState) => state.peakFittingReducer.dataViewFile;
export const selectPeakFittingDataActiveFiles = (state: GlobalState) => state.peakFittingReducer.dataActiveFiles;

export const selectPeakFittingCifFiles = (state: GlobalState) => state.peakFittingReducer.cifFiles;
export const selectPeakFittingCifTemplateFiles = (state: GlobalState) => state.peakFittingReducer.templateCifFiles;
export const selectPeakFittingCifFilesABC = (state: GlobalState) => state.peakFittingReducer.cifFilesABC;

export const selectPeakFittingInstrumentalFile = (state: GlobalState) => state.peakFittingReducer.instrumentalFile;
export const selectPeakFittingInstrumentalTemplateFile = (state: GlobalState) => state.peakFittingReducer.templateInstrumentalFile;
export const selectPeakFittingInstrumentalFileWaveLength = (state: GlobalState) => state.peakFittingReducer.instrumentalFileWaveLength;

export const selectPeakFittingPartitions = (state: GlobalState) => state.peakFittingReducer.partitions;
export const selectPeakFittingMovingPeaks = (state: GlobalState) => state.peakFittingReducer.movingPeaks;

export const selectPeakFittingWavelength = (state: GlobalState) => state.peakFittingReducer.wavelength;

export const selectPeakFittingFitting = (state: GlobalState) => state.peakFittingReducer.fitting;

export const selectPeakFittingPartitionActiveIndex = (state: GlobalState) => state.peakFittingReducer.partition.activeIndex;
export const selectPeakFittingPartitionSelectingPeaks = (state: GlobalState) => state.peakFittingReducer.partition.selectingPeaks;

export const selectPeakFittingHistogram = (state: GlobalState) => state.peakFittingReducer.histogram;

export const selectPeakFittingPartitionFitting = (state: GlobalState) => state.peakFittingReducer.partitionFitting;
