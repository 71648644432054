import { compareKeysV2 } from "../../../helpers/general"
import { numberShortener } from "../../../helpers/name"


export const FilesMapping = (phaseQuantificationDataFiles: string[], debyeWellerParameters, phaseQuantificationFitting) => {
  let filesMapping = []
  let headers = []
  let parametersHeaders = []

  for (const file of phaseQuantificationDataFiles) {
    filesMapping.push({ fileName: file })
  }

  filesMapping = filesMapping.sort((a, b) => compareKeysV2(a.fileName, b.fileName))

  for (const key in debyeWellerParameters) {
    const debyeWeller = debyeWellerParameters[key]
    const index = filesMapping.findIndex((file) => file.fileName === key)
    if (index === -1) continue;

    for (const key in debyeWeller) {
      if (key === "std_dev") continue;
      
      filesMapping[index][`${key}: vol frac`] = numberShortener(debyeWeller[key]);
      filesMapping[index][`${key}: uncertainty`] = numberShortener(debyeWeller["std_dev"]);

      if (!headers.includes(`${key}: vol frac`)) headers.push(`${key}: vol frac`)
      if (!headers.includes(`${key}: uncertainty`)) headers.push(`${key}: uncertainty`)
    }
  }

  for (const key in phaseQuantificationFitting) {
    const fitting = phaseQuantificationFitting[key]
    const index = filesMapping.findIndex((file) => file.fileName === key)
    if (index === -1) continue;

    let newItems = {}
    for (const key in fitting) {
      for (const innerKey in fitting[key]) {
        newItems[`${key}: ${innerKey}`] = numberShortener(fitting[key][innerKey])
      }
    }

    const sortedKeys = Object.keys(newItems).sort((a, b) => {
      const matchA = a.match(/^(\D+)(\d+):/);
      const matchB = b.match(/^(\D+)(\d+):/);
      if (matchA && matchB) {
          const letterCompare = matchA[1].localeCompare(matchB[1]);
          if (letterCompare !== 0) return letterCompare;
          return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
      }
      return 0;
    });

    const sortedNewItems = {};
    for (const sortedKey of sortedKeys) {
      sortedNewItems[sortedKey] = newItems[sortedKey];
      if (!parametersHeaders.includes(sortedKey)) parametersHeaders.push(sortedKey)
    }

    filesMapping[index] = { ...filesMapping[index], ...sortedNewItems };
  }

  let sortedKeysParams = parametersHeaders.sort((a, b) => {
    const matchA = a.match(/^(\D+)(\d+):/);
    const matchB = b.match(/^(\D+)(\d+):/);
    if (matchA && matchB) {
        const letterCompare = matchA[1].localeCompare(matchB[1]);
        if (letterCompare !== 0) return letterCompare;
        return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
    }
    return 0;
  });

  return {
    filesMapping,
    headers,
    sortedKeysParams
  }
}